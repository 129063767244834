import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import Field from "~/components/Field";
import UserContentBlock from "~/components/UserContentBlock";
import { LineClamp } from "~/components/text";
import { platform_to_postClass_mapping, type Platform } from "~/constants";
import { useUserData } from "~/hooks/useUserNodePopUp";
import { text } from "~/styles/typography";
import { dateToNonstandardString } from "~/utils/datetime";
import { formatLargeNumber } from "~/utils/numberUtils";
import { getBasicStatisticsFromPostDetails } from "~/utils/postDetails";

import { infoByNodeType } from "../../constants";
import type { QueryPost } from "../../types";

import BaseProfile from "./BaseProfile";

const StyledUserContentBlock = styled(UserContentBlock)`
  background-color: var(--background-color-primary);
  flex-direction: column;
  overflow: hidden;
  display: flex;
  gap: var(--spacing-lg);
  padding: var(--spacing-lg);
  border-radius: 0;

  & > :first-child {
    padding: 0;
    margin-bottom: 0;
  }

  > img {
    border-radius: 0 0 0 var(--border-radius-xl);
  }

  [data-slot="user-names"] {
    display: flex;
    flex-direction: column-reverse;

    > [data-auto-header="true"] {
      button {
        color: var(--foreground-color-brand-primary);
      }
    }

    > span {
      ${text.md.medium}
    }
  }

  [data-slot="body"] {
    ${text.sm.medium}
  }

  div[data-slot="stats"] {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${text.sm.semibold}
  }

  [data-slot="date"] {
    ${text.sm.regular}
  }
`;

const AddtionalStatistic = styled(Field)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: var(--spacing-1);

  dd {
    order: 1;
    ${text.xs.semibold}
  }

  dt {
    order: 2;
    margin-bottom: 0;
    ${text.xs.semibold}
  }
`;

export interface PostProfileProps {
  conversationId: string;
  type: "posts";
  onDismiss?: () => void;
  data: QueryPost;
}

function usePostProfile(props: PostProfileProps) {
  const { conversationId, data, onDismiss } = props;
  const { data: userData } = useUserData({
    conversationId,
    rid: data.accountId,
  });
  const postClass = platform_to_postClass_mapping[data.platform as Platform];

  const stats: Record<string, number> = Object.entries(
    getBasicStatisticsFromPostDetails(postClass, data.additionalProperties),
  )
    .filter(([, v]) => v !== undefined)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  return {
    onDismiss,
    postData: data,
    stats,
    userData,
  };
}

export const PostProfile: FunctionComponent<PostProfileProps> = (props) => {
  const { onDismiss, postData, stats, userData } = usePostProfile(props);

  return (
    <BaseProfile
      heading="Post"
      iconName={infoByNodeType.posts.iconName}
      onDismiss={onDismiss}
    >
      <StyledUserContentBlock userDetails={userData}>
        <LineClamp data-slot="body" lines={3} title={postData.body}>
          {postData.body}
        </LineClamp>
        <div data-slot="stats">
          {Object.entries(stats).map(([k, v]) => (
            <AddtionalStatistic key={k} name={k} value={formatLargeNumber(v)} />
          ))}
        </div>
        <span data-slot="date">
          {dateToNonstandardString(new Date(postData.publishedAt), {
            dateStyle: "medium",
            timeStyle: "short",
          })}
        </span>
      </StyledUserContentBlock>
    </BaseProfile>
  );
};
