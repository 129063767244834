import { LogLevel, type LoggerFunction } from "./types";

const consoleLog: LoggerFunction = (level: LogLevel, ...msg: any[]) => {
  /* eslint-disable no-console
    -- the console logger needs to, you know, log to console */
  switch (level) {
    case LogLevel.Error: {
      console.error(...msg);
      break;
    }
    case LogLevel.Warn: {
      console.warn(...msg);
      break;
    }
    default: {
      console.log(...msg);
    }
  }
  /* eslint-enable no-console
     -- all done defining console logger fns */
};

export { consoleLog };
