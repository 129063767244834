import { useMemo } from "react";

import { type DomainSharedResponseDTO, type UrlSharedResponseDTO } from "~/dto";
import {
  type CoURLResponseDTO,
  CoURLResponseValidator,
} from "~/dto/coURLResponse";
import { DomainSharedResponseValidator } from "~/dto/domainSharedResponse";
import { UrlSharedResponseValidator } from "~/dto/urlSharedResponse";

import {
  type ConversationFilters,
  getConversationFilterRequestParams,
} from "./useConversationFilters";
import { useRemoteObject } from "./useRemoteData";

interface SharedURLOpts {
  url?: string;
  filters?: ConversationFilters | null;
  excludeTwitter?: boolean;
}

const useCoUrl = (
  conversationId: string,
  excludeTwitter: boolean,
  filters?: ConversationFilters,
) => {
  const params = getConversationFilterRequestParams(filters);

  params.set("conversation_id", conversationId);
  params.set("exclude_twitter", excludeTwitter ? "true" : "false");

  const apiEndpoint = conversationId
    ? `/api/v2/analysis/co-url?${params}`
    : undefined;

  return useRemoteObject<CoURLResponseDTO>(apiEndpoint, {
    schemaValidator: CoURLResponseValidator,
  });
};

const useFilteredCoUrl = (
  conversationId: string,
  filters: ConversationFilters,
  excludeTwitter: boolean,
) => {
  const remoteCoUrl = useCoUrl(conversationId, excludeTwitter);
  const { data: filteredData } = useCoUrl(
    conversationId,
    excludeTwitter,
    filters,
  );
  const activeNodeIds = useMemo(() => {
    const filteredUrls = filteredData?.data ?? [];
    const activeNodeIds = new Set<string>();
    filteredUrls.forEach((url) => {
      activeNodeIds.add(url.url);
      url.creator_rid.forEach((creatorRid) => {
        activeNodeIds.add(creatorRid);
      });
    });

    return activeNodeIds;
  }, [filteredData]);

  return {
    ...remoteCoUrl,
    activeNodeIds,
  };
};

const useSharedURLS = (
  conversationId: string | undefined,
  { url, filters, excludeTwitter }: SharedURLOpts,
) => {
  const params =
    filters !== null ? getConversationFilterRequestParams(filters) : null;

  const apiEndpoint = useMemo(() => {
    if (!conversationId) {
      return undefined;
    }

    if (!params) {
      return `/api/v2/analysis/urls-shared?conversation_id=${conversationId}`;
    }

    params.set("conversation_id", conversationId);

    if (url) {
      params.set("url", url);
    }

    if (excludeTwitter) {
      params.set("exclude_twitter", "true");
    }

    return `/api/v2/analysis/urls-shared?${params}`;
  }, [conversationId, url, excludeTwitter, params]);

  return useRemoteObject<UrlSharedResponseDTO>(apiEndpoint, {
    schemaValidator: UrlSharedResponseValidator,
  });
};

const useSharedDomains = (
  conversationId: string,
  filters?: ConversationFilters,
  excludeTwitter?: boolean,
) => {
  const params = getConversationFilterRequestParams(filters);
  const apiEndpoint = useMemo(() => {
    if (!conversationId) {
      return undefined;
    }

    params.set("conversation_id", conversationId);

    if (excludeTwitter) {
      params.append("exclude_twitter", "true");
    }

    return `/api/v2/analysis/domains-shared?${params}`;
  }, [conversationId, excludeTwitter, params]);

  return useRemoteObject<DomainSharedResponseDTO>(apiEndpoint, {
    schemaValidator: DomainSharedResponseValidator,
  });
};

export { useCoUrl, useFilteredCoUrl, useSharedURLS, useSharedDomains };
