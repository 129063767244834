import {
  NrmsConversationThemeMetricsListWithEnvelope,
  type NrmsConversationThemeMetricsDTO,
} from "~/dto/nrmsConversationThemeMetrics";

import { useCurrentUser } from "./useCurrentUser";
import { useRemoteCollection } from "./useRemoteData";
import { defaultGetFetcherFactory } from "./useRemoteData/utils";

export function useNrmsConversations() {
  const { hasPermission } = useCurrentUser();
  const apiEndpoint = hasPermission("VIEW_NRMS_CONVERSATION")
    ? "/api/v2/conversations/nrms"
    : undefined;

  return useRemoteCollection<NrmsConversationThemeMetricsDTO>(apiEndpoint, {
    getFetcher: defaultGetFetcherFactory,
    schemaValidator: NrmsConversationThemeMetricsListWithEnvelope,
  });
}
