import type { LanguageCode } from "~/constants";
import type { InsightRiskDTO } from "~/dto/insightRisk";

type RiskLevel = InsightRiskDTO["risk_level"];
type TypeName = InsightRiskDTO["type_name"];

type RiskLevelTextMap = Partial<
  Record<LanguageCode, Record<RiskLevel, string>>
>;
type TypeNameTextMap = Partial<Record<LanguageCode, Record<TypeName, string>>>;

const riskLevelTextMap: RiskLevelTextMap = {
  en: {
    LOW_RISK: "Low",
    MEDIUM_RISK: "Medium",
    HIGH_RISK: "High",
  },
};

const typeNameTitleMap: TypeNameTextMap = {
  en: {
    NAIVE_COORDINATION: "Coordination Detected",
    COORDINATION_WITH_OTHER_FACTORS: "Coordination with Other Factors Detected",
    NAIVE_INFLUENCER: "Influencer Detected",
    INFLUENCER_WITH_OTHER_FACTORS:
      "Potential Violent Rhetoric from Known Actor",
    GOVT_OFFICIAL_WITH_OTHER_FACTORS: "Government Official Involved",
    NAIVE_NICHENESS: "Nicheness Detected",
    NICHENESS_WITH_OTHER_FACTORS: "Nicheness with Other Factors Detected",
    STATE_ACTOR_WITH_OTHER_FACTORS: "State Actor with Other Factors Involved",
    NAIVE_VIOLENT_RHETORIC: "Potential Violent Rhetoric Detected",
    VIOLENT_RHETORIC_WITH_OTHER_FACTORS:
      "Potential Violent Rhetoric with Other Factors Detected",
  },
};

const riskEvaluationMap: TypeNameTextMap = {
  en: {
    NAIVE_COORDINATION:
      "{riskLevel} risk associated with coordination detected",
    COORDINATION_WITH_OTHER_FACTORS:
      "{riskLevel} risk due to coordination with other factors",
    NAIVE_INFLUENCER:
      "{riskLevel} risk involving an influencer with no other risk factors",
    INFLUENCER_WITH_OTHER_FACTORS:
      "{riskLevel} risk involving an influencer with other factors",
    GOVT_OFFICIAL_WITH_OTHER_FACTORS:
      "{riskLevel} risk from involvement of a government official",
    NAIVE_NICHENESS:
      "{riskLevel} risk identified due to activity by niche actors or on niche platforms with no other risk factors",
    NICHENESS_WITH_OTHER_FACTORS:
      "{riskLevel} risk identified due to activity by niche actors or on niche platforms with other factors",
    STATE_ACTOR_WITH_OTHER_FACTORS:
      "{riskLevel} risk linked to state actor involvement with other factors",
    NAIVE_VIOLENT_RHETORIC:
      "{riskLevel} risk due to potential violent rhetoric",
    VIOLENT_RHETORIC_WITH_OTHER_FACTORS:
      "{riskLevel} risk due to potential violent rhetoric influenced by other factors",
  },
};

function getRiskEvaluation(
  riskLevel: RiskLevel,
  typeName: TypeName,
  language: LanguageCode,
) {
  const riskLevelText = riskLevelTextMap?.[language]?.[riskLevel];
  const riskEvaluation = riskEvaluationMap?.[language]?.[typeName];
  if (!riskLevelText || !riskEvaluation) return "";
  return riskEvaluation?.replace("{riskLevel}", riskLevelText);
}

const typeNameDescriptionMap: TypeNameTextMap = {
  en: {
    NAIVE_COORDINATION:
      "Coordinated and likely inauthentic posts have been detected",
    COORDINATION_WITH_OTHER_FACTORS:
      "Coordinated and likely inauthentic posts have been detected",
    NAIVE_INFLUENCER: "Posts by influencers with no other factors detected",
    NAIVE_NICHENESS:
      "Posts by niche actors or on niche platforms with no other factors detected",
    GOVT_OFFICIAL_WITH_OTHER_FACTORS:
      "Official accounts of government officials are active in this conversation",
    INFLUENCER_WITH_OTHER_FACTORS: "Posts by influencers with risk factors",
    NICHENESS_WITH_OTHER_FACTORS:
      "Posts by niche actors or from niche platforms with risk factors",
    STATE_ACTOR_WITH_OTHER_FACTORS: "Posts by state actors with risk factors",
    NAIVE_VIOLENT_RHETORIC:
      "Posts which appear to contain threats of violence are present",
    VIOLENT_RHETORIC_WITH_OTHER_FACTORS:
      "Posts which appear to contain threats of violence are present",
  },
};

const typeNameIconMap: Record<TypeName, UntitledIcon> = {
  NAIVE_COORDINATION: "dataflow-01",
  NAIVE_INFLUENCER: "message-smile-square",
  NAIVE_NICHENESS: "search-md",
  NAIVE_VIOLENT_RHETORIC: "target-05",
  COORDINATION_WITH_OTHER_FACTORS: "dataflow-04",
  GOVT_OFFICIAL_WITH_OTHER_FACTORS: "bank",
  INFLUENCER_WITH_OTHER_FACTORS: "message-x-square",
  NICHENESS_WITH_OTHER_FACTORS: "search-refraction",
  STATE_ACTOR_WITH_OTHER_FACTORS: "user-square",
  VIOLENT_RHETORIC_WITH_OTHER_FACTORS: "target-04",
};

export {
  getRiskEvaluation,
  typeNameDescriptionMap,
  typeNameIconMap,
  typeNameTitleMap,
};
