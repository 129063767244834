import { extent } from "d3";
import { useMemo } from "react";

import { type BucketedPostCountResponseDTO } from "~/dto";
import { BucketedPostCountResponseValidator } from "~/dto/bucketedPostCountResponse";
import {
  CoordinationClusterProfileResponseValidator,
  type CoordinationClusterProfileResponseDTO,
} from "~/dto/coordinationClusterProfileResponse";
import {
  CoordinationClusterResponseValidator,
  type CoordinationClusterResponseDTO,
} from "~/dto/coordinationClusterResponse";
import {
  CoordinationEdgeProfileResponseValidator,
  type CoordinationEdgeProfileResponseDTO,
} from "~/dto/coordinationEdgeProfileResponse";
import {
  CoordinationNodeProfileResponseValidator,
  type CoordinationNodeProfileResponseDTO,
} from "~/dto/coordinationNodeProfileResponse";
import { type ActiveEdge, type Datum } from "~/pages/coordination/types";
import { filterRawDataByDatum } from "~/pages/coordination/utils";
import { fromDate } from "~/utils/datetime";

import {
  type ConversationFilters,
  getConversationFilterRequestParams,
} from "./useConversationFilters";
import { useRawData } from "./useRawData";
import { useRemoteObject } from "./useRemoteData";

const useCoordinationClusters = (
  conversationId: string,
  filters?: ConversationFilters,
) => {
  const params = getConversationFilterRequestParams(filters);
  params.set("conversation_id", conversationId);

  const apiEndpoint = conversationId
    ? `/api/v2/coordinations/clusters?${params}`
    : undefined;

  return useRemoteObject<CoordinationClusterResponseDTO>(apiEndpoint, {
    schemaValidator: CoordinationClusterResponseValidator,
  });
};

const useFilteredCoordinationClusters = (
  conversationId: string,
  filters: ConversationFilters,
) => {
  const allData = useCoordinationClusters(conversationId);
  const filteredData = useCoordinationClusters(conversationId, filters);
  const { activeEdges, activeIds } = useMemo(() => {
    const filteredClusters = filteredData.data?.data ?? [];
    const activeIds = new Set<string>();
    const activeEdges: ActiveEdge = {};
    filteredClusters.forEach((cluster) => {
      activeIds.add(cluster.cluster_id);
      cluster.vertices.forEach((vertice) => {
        activeIds.add(vertice);
      });
      cluster.edges.forEach((edge) => {
        const concatIds = `${edge.i}-${edge.j}`;
        activeEdges[concatIds] = edge.weight;
      });
    });
    return {
      activeEdges,
      activeIds,
    };
  }, [filteredData]);

  return {
    activeEdges,
    activeIds,
    data: allData.data,
    error: allData.error || filteredData.error,
    isLoading: allData.isLoading || filteredData.isLoading,
  };
};

const useEdgeProfile = (
  clusterId: string,
  conversationId: string,
  iRid: string,
  jRid: string,
  filters: ConversationFilters,
) => {
  const params = getConversationFilterRequestParams(filters);
  params.set("conversation_id", conversationId);
  params.set("i", iRid);
  params.set("j", jRid);

  const apiEndpoint = conversationId
    ? `/api/v2/coordinations/${clusterId}/edge?${params}`
    : undefined;

  return useRemoteObject<CoordinationEdgeProfileResponseDTO>(apiEndpoint, {
    schemaValidator: CoordinationEdgeProfileResponseValidator,
  });
};

const useNodeProfile = (
  clusterId: string,
  conversationId: string,
  userRid: string,
  filters: ConversationFilters,
) => {
  const params = getConversationFilterRequestParams(filters);
  params.set("conversation_id", conversationId);
  params.set("user_rid", userRid);

  const apiEndpoint = conversationId
    ? `/api/v2/coordinations/${clusterId}/node?${params}`
    : undefined;

  return useRemoteObject<CoordinationNodeProfileResponseDTO>(apiEndpoint, {
    schemaValidator: CoordinationNodeProfileResponseValidator,
  });
};

const useClusterProfile = (
  clusterId: string,
  conversationId: string,
  filters: ConversationFilters,
) => {
  const params = getConversationFilterRequestParams(filters);
  params.set("conversation_id", conversationId);

  const apiEndpoint = conversationId
    ? `/api/v2/coordinations/${clusterId}/cluster?${params}`
    : undefined;

  return useRemoteObject<CoordinationClusterProfileResponseDTO>(apiEndpoint, {
    schemaValidator: CoordinationClusterProfileResponseValidator,
  });
};

const useBucketedPostCounts = (
  conversationId: string,
  filters: ConversationFilters,
  num_of_buckets = 1000,
) => {
  const apiEndpoint = useMemo(() => {
    if (!conversationId) {
      return undefined;
    }
    const params = getConversationFilterRequestParams(filters);
    params.set("num_of_buckets", `${num_of_buckets}`);

    return `/api/v2/coordinations/${conversationId}/bucketed_post_counts?${params}`;
  }, [conversationId, filters, num_of_buckets]);

  return useRemoteObject<BucketedPostCountResponseDTO>(apiEndpoint, {
    schemaValidator: BucketedPostCountResponseValidator,
  });
};

const useCoordinationRawDataTable = (
  conversationId: string,
  selectedDatum: Datum | null,
  filters: ConversationFilters | null,
) => {
  const { data, error, isLoading } = useRawData(conversationId, filters, true);
  /*
    TODO: frontend cluster/edge/node filtering is not compatible with backend pagination.
    Pagination is in the frontend until cluster/edge/node filtering is added to backend.
    Posts are filtered by coordination in the backend so it is still a signicant amount filtered.
    */

  const { filteredData, availableDateRange } = useMemo(() => {
    const filteredData = filterRawDataByDatum(data, selectedDatum);
    const dateRange = filteredData
      ? extent(filteredData, (datum) => new Date(datum.published_at))
      : undefined;
    const availableDateRange =
      dateRange && dateRange[0] !== undefined && dateRange[1] !== undefined
        ? {
            start: fromDate(dateRange[0], "UTC"),
            end: fromDate(dateRange[1], "UTC"),
          }
        : undefined;

    return {
      availableDateRange,
      filteredData,
    };
  }, [data, selectedDatum]);

  return {
    availableDateRange,
    allData: data,
    data: filteredData,
    error,
    isLoading,
  };
};

export {
  useBucketedPostCounts,
  useClusterProfile,
  useCoordinationClusters,
  useCoordinationRawDataTable,
  useEdgeProfile,
  useFilteredCoordinationClusters,
  useNodeProfile,
};
