import { styled } from "@linaria/react";
import type { FunctionComponent } from "react";

import type { RawDetailsResponseDTO } from "~/dto";
import { text } from "~/styles/typography";
import { formatDate } from "~/utils/datetime";
import { formatLargeNumber } from "~/utils/numberUtils";
import { getBasicStatisticsFromPostDetails } from "~/utils/postDetails";

import { URLExternalLinkComponent } from "./ConnectionPreview";
import ErrorMessageContainer from "./ErrorMessageContainer";
import Field from "./Field";
import IconButton, { ButtonKind, ButtonSize } from "./library/IconButton";
import { LineClamp } from "./text";

interface PostDetailsProps extends Pick<RawDetailsResponseDTO, "data"> {
  error: any;
  isStatsLoading: boolean;
  isVisible: boolean;
}

const Post = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  overflow: hidden;
  ${text.sm.medium};

  > :first-child {
    flex-grow: 1;
    overflow: hidden;
    /* so URLs don't blow out the overflow ellipsis */
    overflow-wrap: anywhere;
  }
  > :last-child {
    flex-shrink: 0;
  }
`;

const PostBody = styled(LineClamp)`
  text-align: start;
`;

const PostStatisticContainer = styled.dl`
  display: flex;
  gap: var(--spacing-xl);
`;

const PostStatistic = styled(Field)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: var(--spacing-xs);

  dd {
    order: 1;
  }

  dt {
    order: 2;
    margin-bottom: 0;
  }
`;

const ErrorMessage = styled(ErrorMessageContainer)`
  ${text.sm.regular};
`;

const PlaceHolderText = styled.div`
  height: 1.5em;
  width: 75%;
  background-color: var(--background-color-quaternary);
  border-radius: var(--border-radius-sm);

  @media (prefers-reduced-motion: no-preference) {
    &[data-visible] {
      animation: pulse 1.5s ease 3;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

const UrlDateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  ${text.sm.regular}

  [data-button-icon] {
    font-size: ${text.md.regular.fontSize};
  }
`;

const PostDetails: FunctionComponent<PostDetailsProps> = ({
  data,
  error,
  isStatsLoading,
  isVisible,
}) => {
  const {
    additional_properties,
    body,
    class: postClass,
    published_at,
    source_url,
  } = data;

  let stats = getBasicStatisticsFromPostDetails(
    postClass,
    additional_properties,
  );
  stats = Object.entries(stats)
    .filter(([, v]) => v !== undefined)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

  return (
    <Post>
      <PostBody as="p" lines={4} title={body}>
        {body}
      </PostBody>
      {error ? (
        <ErrorMessage>Post statistics unavailable</ErrorMessage>
      ) : isStatsLoading ? (
        <PlaceHolderText data-visible={isVisible || undefined}>
          <span data-at-only>Loading post statistics</span>
        </PlaceHolderText>
      ) : (
        <PostStatisticContainer>
          {Object.entries(stats).map(([k, v]) => (
            <PostStatistic key={k} name={k} value={formatLargeNumber(v)} />
          ))}
        </PostStatisticContainer>
      )}
      <UrlDateContainer>
        <URLExternalLinkComponent url={source_url}>
          <IconButton
            aria-label="Open to preview post URL"
            data-kind={ButtonKind.Tertiary}
            data-size={ButtonSize.sm}
            disabled={!source_url}
            icon="link-03"
          />
        </URLExternalLinkComponent>
        <span>{formatDate(new Date(published_at))}</span>
      </UrlDateContainer>
    </Post>
  );
};

export default PostDetails;
