import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  type MouseEvent,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
  useRef,
  createContext,
} from "react";
import type { Key } from "react-aria-components";
import type { Index, Link, Node } from "regraph";

import { LoadingIndicator } from "~/components";
import ActorKeyInsights from "~/components/ActorKeyInsights";
import { useProfileDialogContext } from "~/components/ProfileDialogContext";
import SiteKeyInsights from "~/components/SiteKeyInsights";
import Button, { ButtonKind, ButtonSize } from "~/components/library/Button";
import { Checkbox } from "~/components/library/Checkbox";
import Icon from "~/components/library/Icon";
import IconButton from "~/components/library/IconButton";
import NestedMenu, {
  type Data,
  type Main,
} from "~/components/library/NestedMenu";
import { Option, Select } from "~/components/library/Select";
import { display, text } from "~/styles/typography";
import { setDifference } from "~/utils/arrayUtils";
import { useIsInternalOrg } from "~/utils/nav";

import {
  infoByEdgeType,
  infoByNodeType,
  nodeTypesByEdgeString,
} from "../constants";
import {
  type AvailableEdges,
  type NodeType,
  availableEdges,
  type AllAvailableEdges,
  isEdge,
  isPreset,
  type RegraphObject,
} from "../types";

import { ClusterByFilter } from "./ClusterByFilter";
import { useGraphContext } from "./GraphContext";
import SelectedDisplay from "./SelectedDisplay";
import StartingSelector from "./StartingSelector";

/* Temporary context to add regraph functionality to key insights  */
interface RegraphContextProps {
  regraphObject: RegraphObject | undefined;
  onURLPreviewClick: (id: string) => void;
}

export const RegraphContext = createContext<RegraphContextProps | undefined>(
  undefined,
);
const RegraphProvider: FunctionComponent<{
  children: ReactNode;
  regraphObject: RegraphObject | undefined;
  onURLPreviewClick: (id: string) => void;
}> = (props) => {
  const { children, onURLPreviewClick, regraphObject } = props;

  return (
    <RegraphContext.Provider
      value={{
        regraphObject,
        onURLPreviewClick,
      }}
    >
      {children}
    </RegraphContext.Provider>
  );
};

const CoordinationPostCheckbox = styled(Checkbox)`
  margin-top: var(--spacing-xl);
`;

const Title = styled.div`
  margin: var(--spacing-xl) 0;
  font-weight: var(--font-weight-medium);
  ${text.xl.medium}
`;

const ControlPanelCard = styled.aside`
  max-height: 100%;
  border: 1px solid var(--border-color-primary);
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: var(--background-color-tertiary);
  box-shadow: var(--shadow-lg);

  #graph-control-panel-label {
    border-bottom: var(--border-color-primary);
    padding: var(--spacing-lg);
    ${display.sm.regular};
  }

  > div:last-child {
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
  > div:last-child [data-slot="header"]:has([aria-expanded="false"]) {
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
`;

const StyledAccordionSection = styled.div`
  /* flex needed for overflow scrollbars to show up in the right place */
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-brand-primary);

  &:has([aria-expanded="true"]) {
    overflow: auto;
  }

  [data-slot="header"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${text.lg.medium};
    background-color: var(--background-color-primary);

    button[aria-controls] {
      flex-grow: 1;
      padding: var(--spacing-lg);
      text-align: start;
      font-size: var(--font-size-text-lg);
      background-color: transparent;
    }

    > div {
      padding: var(--spacing-lg);
    }

    &:hover {
      background-color: var(--background-color-bg-brand-primary);
    }

    &:has([aria-expanded="true"]) {
      ${display.xs.medium};
      background-color: var(--background-color-brand-secondary);

      button[aria-controls] {
        font-size: var(--font-size-display-xs);
      }
    }
  }

  & + & {
    border-top: 1px solid var(--border-color-primary);
  }

  [data-slot="content"] {
    background: var(--background-color-brand-primary);
    padding: var(--spacing-lg);
    scrollbar-width: thin;

    &:not([hidden]) {
      overflow: auto;
    }

    > [id$="desc"]:first-child {
      color: var(--text-color-tertiary);
      font-style: italic;
    }

    > #graph-control-panel-narrative-desc,
    #graph-control-panel-network-desc {
      display: block;
      margin-bottom: var(--spacing-xl);
    }
  }

  &#graph-control-panel-narrative {
    display: flex;
    border-top: 1px solid var(--border-color-primary);
  }

  [data-slot="content"]#graph-control-panel-network-panel {
    > div:last-child {
      max-height: 260px;
    }
    > div:nth-last-child(2) {
      max-height: 240px;
    }

    [role="tabpanel"] {
      padding: var(--spacing-xl) 0;
    }
  }
`;

const EdgeOptsContainer = styled.div`
  font-size: var(--font-size-text-xs);

  [data-button-icon] {
    width: 14px;
    height: 14px;
  }

  [role="menu"] header {
    user-select: none;
    text-transform: uppercase;
  }
`;

const AddEdgeButton = styled(Button)`
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;

  > span {
    display: flex;
    align-items: center;
  }
`;

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--background-color-primary);
  padding: var(--spacing-lg);
  border-radius: var(--border-radius-sm);
  box-shadow: var(--shadow-sm);

  > div:first-child {
    padding-bottom: var(--spacing-2xl);
    border-bottom: 1px solid var(--border-color-primary);
  }

  > div:last-child {
    padding-top: var(--spacing-2xl);
  }
`;

const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
`;

type EdgeOptsProps = {
  nodeType: NodeType;
};

const useEdgeOpts = (props: EdgeOptsProps) => {
  const { nodeType } = props;

  const {
    edge: [edges, setEdges],
    node: [nodes, setNodes],
  } = useGraphContext().graphState;
  const { addNode } = useGraphContext().legendData;

  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const ownCurrentEdges = edges.filter(
    (el) => (availableEdges[nodeType] as readonly string[]).indexOf(el) !== -1,
  );
  const onSelectLink = useCallback(
    (info: Data) => {
      const { value } = info;
      if (isEdge(value)) {
        const newNodes = nodeTypesByEdgeString[value];
        setNodes([...new Set<NodeType>([...nodes, ...newNodes])]);
        setEdges([...new Set<AllAvailableEdges>([...edges, value])]);
        newNodes.forEach(addNode);
      }
    },
    [addNode, edges, nodes, setEdges, setNodes],
  );

  const ownAvailableEdges = setDifference<string>(
    new Set<string>(Object.values(availableEdges[nodeType])),
    new Set<string>(ownCurrentEdges),
  ) as Set<AvailableEdges<typeof nodeType>>;

  const data: Main[] = useMemo(() => {
    const uniqueNodes = new Set<NodeType>();

    ownAvailableEdges.forEach((el) => {
      const { actionNodeType, receiverNodeType } = infoByEdgeType[el];
      const otherNode =
        actionNodeType === receiverNodeType
          ? actionNodeType
          : nodeType === actionNodeType
          ? receiverNodeType
          : actionNodeType;
      uniqueNodes.add(otherNode);
    });

    return [...uniqueNodes].map<Main>((node) => {
      const sub = [...ownAvailableEdges].reduce<Data[]>((acc, edge) => {
        const { actionNodeType, receiverNodeType, text } = infoByEdgeType[edge];
        if (
          (actionNodeType === node && receiverNodeType === nodeType) ||
          (actionNodeType === nodeType && receiverNodeType === node)
        ) {
          acc.push({ label: text, value: edge });
        }
        return acc;
      }, []);

      return {
        label: infoByNodeType[node].label,
        value: node,
        sub,
        headerLabel: `Connect ${infoByNodeType[nodeType].label}s & ${infoByNodeType[node].label}s`,
      };
    });
  }, [nodeType, ownAvailableEdges]);

  const onCloseMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onOpenMenu = useCallback(() => {
    setIsOpen(true);
  }, []);

  return {
    data,
    isOpen,
    onCloseMenu,
    onOpenMenu,
    onSelectLink,
    ownAvailableEdges,
    triggerRef,
  };
};

const EdgeOpts: FunctionComponent<EdgeOptsProps> = (props) => {
  const { nodeType } = props;
  const {
    data,
    isOpen,
    onCloseMenu,
    onOpenMenu,
    onSelectLink,
    ownAvailableEdges,
    triggerRef,
  } = useEdgeOpts(props);

  if (Object.values(availableEdges[nodeType]).length === 0) {
    return null;
  }

  return (
    <EdgeOptsContainer>
      {ownAvailableEdges.size > 0 ? (
        <>
          <AddEdgeButton
            ref={triggerRef}
            aria-controls="menu"
            aria-haspopup="true"
            data-kind={ButtonKind.Link}
            data-size={ButtonSize.sm}
            id="edge-selector-menu"
            onPress={onOpenMenu}
            preIcon="plus"
          >
            Add Relationship
          </AddEdgeButton>
          <NestedMenu
            data={data}
            headerLabel={`Connect to ${infoByNodeType[nodeType].label}s`}
            id="edge-selector-menu"
            isOpen={isOpen}
            offset={-10}
            onClose={onCloseMenu}
            onSubClick={onSelectLink}
            placement="top"
            triggerRef={triggerRef}
          />
        </>
      ) : null}
    </EdgeOptsContainer>
  );
};

type NodeAndEdgeOptsProps = {
  label: string;
  nodeType: NodeType;
};

const NodeAndEdgeOpts: FunctionComponent<NodeAndEdgeOptsProps> = (props) => {
  const { label, nodeType } = props;

  return (
    <NodeGroup>
      <div>
        <Icon family="untitled" name={infoByNodeType[nodeType].iconName} />
        <span>{label}</span>
      </div>
      <EdgeOpts nodeType={nodeType} />
    </NodeGroup>
  );
};

const NodeGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: var(--spacing-lg);
  align-items: center;

  > div:first-child {
    align-items: center;
    color: var(--text-color-primary);
    display: flex;
    gap: var(--spacing-xs);
    ${text.sm.medium}
  }
`;

const NodeEdgeSelector: FunctionComponent = () => {
  const {
    node: [nodes],
  } = useGraphContext().graphState;

  return (
    <SelectGroup>
      {nodes.map((node) => (
        <NodeAndEdgeOpts
          key={node}
          label={infoByNodeType[node].label}
          nodeType={node}
        />
      ))}
    </SelectGroup>
  );
};

type ControlPanelAccordionProps = {
  children: ReactNode;
  description?: string;
  headerId: string;
  isOpen: boolean;
  onClickHeader: (e: MouseEvent) => void;
  title: string;
};

const useControlPanelAccordion = (props: ControlPanelAccordionProps) => {
  const { description, headerId, isOpen, onClickHeader, title, ...otherProps } =
    props;
  const { exportChart } = useGraphContext();
  const elId = `graph-control-panel-${headerId}`;
  const onExportChart = useCallback(() => {
    exportChart(headerId);
  }, [exportChart, headerId]);

  return {
    description,
    elId,
    headerId,
    isOpen,
    onClickHeader,
    onExportChart,
    otherProps,
    title,
  };
};

const ControlPanelAccordion: FunctionComponent<ControlPanelAccordionProps> = (
  props,
) => {
  const { children } = props;
  const {
    description,
    elId,
    headerId,
    isOpen,
    onClickHeader,
    onExportChart,
    otherProps,
    title,
  } = useControlPanelAccordion(props);

  return (
    <StyledAccordionSection id={elId} {...otherProps}>
      <div data-slot="header">
        <button
          aria-controls={`${elId}-panel`}
          aria-expanded={isOpen}
          data-for={headerId}
          id={`${elId}-button`}
          onClick={onClickHeader}
          type="button"
        >
          {title}
        </button>
        {!isOpen ? null : (
          <IconButton
            aria-label="Download this graph"
            data-kind={ButtonKind.Tertiary}
            data-size={ButtonSize.sm}
            icon="download-01"
            onPress={onExportChart}
            type="button"
          />
        )}
      </div>
      <div
        aria-labelledby={`${elId}-button`}
        data-slot="content"
        hidden={!isOpen}
        id={`${elId}-panel`}
      >
        {!description ? null : <span id={`${elId}-desc`}>{description}</span>}
        {children}
      </div>
    </StyledAccordionSection>
  );
};

const MinEdgeSelect = styled(Select)`
  margin-top: var(--spacing-xl);
`;
const MinEdgeLoadingIndicator = styled(LoadingIndicator)`
  margin-top: var(--spacing-xl);
`;

const MinEdgeDropdown = () => {
  const {
    nodesByEdgeCount,
    graphState,
    layoutState: { resetLayout },
    isLoadingMinEdge,
  } = useGraphContext();
  const {
    numOfMinEdge: [numOfMinEdge, setNumOfMinEdge],
  } = graphState;

  const onMinEdgeDropdown = useCallback(
    (value: Key) => {
      setNumOfMinEdge(String(value));
      resetLayout();
    },
    [resetLayout, setNumOfMinEdge],
  );

  const minEdgeList = !nodesByEdgeCount
    ? [0]
    : Object.keys(nodesByEdgeCount).map((count) => Number(count));

  if (isLoadingMinEdge) {
    return <MinEdgeLoadingIndicator />;
  }

  return (
    <MinEdgeSelect
      label="Minimum Links to Other Nodes"
      onSelectionChange={onMinEdgeDropdown}
      selectedKey={numOfMinEdge[0] ?? null}
    >
      {minEdgeList.map((value) => (
        <Option key={value} id={String(value)}>
          {String(value)}
        </Option>
      ))}
    </MinEdgeSelect>
  );
};

const useControlPanel = (props: ControlPanelProps) => {
  const { conversationId } = props;
  const {
    chartRef,
    data,
    graphState,
    legendData,
    setSelection,
    layoutState: { resetLayout },
  } = useGraphContext();
  const {
    cluster: [, setSelectedGroup],
    colorGroupBy: [colorGroupBy, setColorGroupBy],
    edge: [, setSelectedEdge],
    node: [nodes, setSelectedNode],
    preset: [selectedPreset, setSelectedPreset],
    numOfMinEdge: [, setNumOfMinEdge],
    showCoordinatedPosts: [showCoordinatedPosts, setShowCoordinatedPosts],
  } = graphState;
  const { replaceNodes } = legendData;

  const onClickPreset = useCallback(
    (e: MouseEvent) => {
      const preset = e.currentTarget.getAttribute("data-for");
      if (!preset || !isPreset(preset)) return;
      switch (preset) {
        case "narrative":
          setColorGroupBy("threatLevel");
          setNumOfMinEdge("0");
          setSelectedEdge("");
          setSelectedGroup("topicId");
          setSelectedNode("posts");
          replaceNodes(["posts"]);
          resetLayout();
          break;
        case "network":
          setSelectedEdge("accountLinks");
          setSelectedGroup("");
          setColorGroupBy("");
          const nodes: NodeType[] = ["accounts", "links"];
          setSelectedNode(nodes);
          replaceNodes(nodes);
          resetLayout();
          break;
        case "coordination":
          setSelectedEdge("accountCoordinationEvidenceAccounts");
          setSelectedGroup(["coordinationClusterId", "platform"]);
          setSelectedNode("accounts");
          if (showCoordinatedPosts[0] === "true") {
            setSelectedNode(["accounts", "posts"]);
            replaceNodes(["accounts", "posts"]);
          } else {
            setSelectedNode("accounts");
            replaceNodes(["accounts"]);
          }
          setColorGroupBy("");
          replaceNodes(["accounts"]);
          resetLayout();
        case "custom":
          setColorGroupBy("");
          resetLayout();
          break;
      }
      setSelectedPreset(preset);
    },
    [
      setSelectedPreset,
      setColorGroupBy,
      setNumOfMinEdge,
      setSelectedEdge,
      setSelectedGroup,
      setSelectedNode,
      replaceNodes,
      resetLayout,
      showCoordinatedPosts,
    ],
  );
  const onSetColorGroupBy = useCallback(
    (value: Key) => {
      if (!value) {
        setColorGroupBy("");
        return;
      }
      setColorGroupBy(String(value));
    },
    [setColorGroupBy],
  );

  const { clearProfileData, setGraphData } = useProfileDialogContext();
  const onURLPreviewClick = useCallback(
    (url: string) => {
      if (!data) return;

      //for the URL popup, the id is the URL so must use the url to find the item
      const node = Object.values(data.nodes).find(
        (node) => url === node.data?.url,
      );
      if (node) {
        chartRef.current?.fit([`${node.data?.id}`]);
      }

      const onDismiss = () => {
        clearProfileData();
        setSelection((prev) => {
          if (!prev) return prev;
          return Object.keys(prev).reduce<Index<boolean | Node | Link>>(
            (acc, key) => {
              acc[key] = false;
              return acc;
            },
            {},
          );
        });
      };
      setGraphData({
        type: "links",
        id: url,
        onDismiss,
        conversationId,
        isRegraph: true,
      });

      if (!node || !node.data) return;
      setSelection({
        [node.data.id]: node,
      });
    },
    [
      chartRef,
      clearProfileData,
      conversationId,
      data,
      setGraphData,
      setSelection,
    ],
  );

  const regraphObject = useMemo(() => {
    if (!data) return undefined;
    return {
      data: { ...data.nodes, ...data.links },
      setSelection,
      chartRef,
    };
  }, [chartRef, data, setSelection]);

  const onChangeCoordinatedPosts = useCallback(
    (isSelected: boolean) => {
      if (isSelected) {
        setShowCoordinatedPosts("true");
        setSelectedNode(["accounts", "posts"]);
        replaceNodes(["accounts", "posts"]);
        setNumOfMinEdge("0");
      } else {
        setShowCoordinatedPosts("");
        setSelectedNode(["accounts"]);
        replaceNodes(["accounts"]);
      }
    },
    [replaceNodes, setNumOfMinEdge, setSelectedNode, setShowCoordinatedPosts],
  );

  return {
    colorGroupBy: colorGroupBy[0] ?? "",
    onClickPreset,
    onSetColorGroupBy,
    selectedPreset: selectedPreset[0],
    onPreviewClick: onURLPreviewClick,
    regraphObject,
    onChangeCoordinatedPosts,
    nodes,
  };
};

interface ControlPanelProps {
  conversationId: string;
}

const ControlPanel: FunctionComponent<ControlPanelProps> = (props) => {
  const { conversationId } = props;
  const {
    regraphObject,
    colorGroupBy,
    onPreviewClick,
    onClickPreset,
    onSetColorGroupBy,
    selectedPreset,
    onChangeCoordinatedPosts,
    nodes,
  } = useControlPanel(props);
  const hasDataAnalysis = useIsInternalOrg();

  const hasNodes = !!nodes.length;

  return (
    <ControlPanelCard {...props} aria-label="Graph Controls">
      <span id="graph-control-panel-label">Views</span>
      <ControlPanelAccordion
        description="Posts are clustered by narrative"
        headerId="narrative"
        isOpen={selectedPreset === "narrative" || !selectedPreset}
        onClickHeader={onClickPreset}
        title="Conversation Clusters"
      >
        <Select
          label="Color topics by"
          onSelectionChange={onSetColorGroupBy}
          selectedKey={colorGroupBy}
        >
          <Option key="" id="">
            None
          </Option>
          <Option key="threatLevel" id="threatLevel">
            Threat level
          </Option>
        </Select>
      </ControlPanelAccordion>
      <ControlPanelAccordion
        description="URLs shared by accounts"
        headerId="network"
        isOpen={selectedPreset === "network"}
        onClickHeader={onClickPreset}
        title="URL Sharing"
      >
        <MinEdgeDropdown />
        <Title>Key Insights</Title>
        <RegraphProvider
          onURLPreviewClick={onPreviewClick}
          regraphObject={regraphObject}
        >
          <SiteKeyInsights
            conversationId={conversationId}
            excludeTwitter={false}
          />
          <ActorKeyInsights conversationId={props.conversationId} />
        </RegraphProvider>
      </ControlPanelAccordion>
      <ControlPanelAccordion
        description="Evidence of coordination between accounts"
        headerId="coordination"
        isOpen={selectedPreset === "coordination"}
        onClickHeader={onClickPreset}
        title="Coordination"
      >
        <CoordinationPostCheckbox
          checked={nodes.includes("posts")}
          onChange={onChangeCoordinatedPosts}
        >
          Show Associated Posts
        </CoordinationPostCheckbox>
        <MinEdgeDropdown />
      </ControlPanelAccordion>
      {hasDataAnalysis && (
        <ControlPanelAccordion
          headerId="custom"
          isOpen={selectedPreset === "custom"}
          onClickHeader={onClickPreset}
          title="Custom"
        >
          {hasNodes ? (
            <>
              <SelectorContainer>
                <SelectedDisplay />
                <NodeEdgeSelector />
              </SelectorContainer>
              <MinEdgeDropdown />
              <ClusterByFilter />
            </>
          ) : (
            <StartingSelector />
          )}
        </ControlPanelAccordion>
      )}
    </ControlPanelCard>
  );
};

export default ControlPanel;
