import { gql } from "graphql-request";

import type { AllAvailableEdges, NodeType, ClusteringNodeKey } from "./types";

/**
 * Each fragment (with the exception of a few) has an overrideQueryFilters params (overrideQueryFilters: {replyPosts: Boolean, repostPosts: Boolean, quotePosts: Boolean})
 * This exists because without it (using accountPost as an example), the accountPost is actually a combination of account - posted/replied/reposted/quoted - post.
 * By using the filter, the data is limited to what the consumer wants.
 *
 * If true: Only return posts including that type
 * If false: Return posts that do not include that type
 * If null: override the arguments provided by the conversation filters and get all of that type
 * If unset (not set at all):  Include that type
 *
 * the exception are account - reposted/replied/quoted - account & post - reposted/replied/quoted - post
 *
 * the overrideQueryFilters also have { coordinationClusters: Boolean | clusterId }
 * It's the same as above (true, false, null, and unset) with the addition of clusterId
 * If clusterId is passed in, then only return object within that cluserId
 */

function getSubqueries(
  isCoordinationPreset: boolean,
): Partial<Record<NodeType | AllAvailableEdges | ClusteringNodeKey, string>> {
  const coordinationPresetString = isCoordinationPreset
    ? `overrideQueryFilters: { coordinationClusters: true }`
    : "";

  return {
    posts: `
      posts(
        paginationFilters: { limit: $limit }
        ${coordinationPresetString}
      ) {
        accountId
        additionalProperties
        body
        coordinationClusterId
        id
        publishedAt
        sourceDomain
        topicId
        type
      }
    `,
    accounts: `
      accounts(
        paginationFilters: { limit: $limit }
        ${coordinationPresetString}
      ) {
        coordinationClusterId
        displayName
        id
        platform
        screenName
        sourceDomain
      }
    `,
    domains: `
      domains(
        paginationFilters: { limit: $limit }
      ) {
        id
        name
      }
    `,
    links: `
      links(
        paginationFilters: { limit: $limit }
      ) {
        id
        url
      }
    `,
    hashtags: `
      hashtags(
        paginationFilters: { limit: $limit }
      ) {
        postId
        text
      }
    `,
    postHashtags: `
      postHashtags: hashtags(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: {replyPosts: false, repostPosts: false, quotePosts: false}
      ) {
        postId
        text
      }
    `,
    /* edges */
    accountPosts: `
      accountPosts(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: {replyPosts: false, repostPosts: false, quotePosts: false}
      ) {
        accountId
        postId
      }
    `,
    domainPosts: `
      domainPosts(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: {replyPosts: false, repostPosts: false, quotePosts: false}
      ) {
        domainId
        postId
      }
    `,
    linkPosts: `
      linkPosts(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: {replyPosts: false, repostPosts: false, quotePosts: false}
      ) {
        linkId
        postId
      }
    `,
    accountCoordinationEvidenceAccounts: `
      accountCoordinationEvidenceAccounts(
        paginationFilters: { limit: $limit }
      ) {
        fromAccountId
        toAccountId
        weight
      }
    `,
    accountHashtags: `
      accountHashtags(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: {replyPosts: false, repostPosts: false, quotePosts: false}
      ) {
        accountId
        text
      }
    `,
    accountLinks: `
      accountLinks(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: {replyPosts: false, repostPosts: false, quotePosts: false}
      ) {
        accountId
        linkId
      }
    `,
    domainLinks: `
      domainLinks(
        paginationFilters: { limit: $limit }
      ) {
        domainId
        linkId
      }
    `,
    accountDomains: `
      accountDomains(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: {replyPosts: false, repostPosts: false, quotePosts: false}
      ) {
        accountId
        domainId
      }
    `,
    accountQuotedAccounts: `
      accountQuotedAccounts(
        paginationFilters: { limit: $limit }
      ) {
        fromAccountId
        toAccountId
      }
    `,
    accountRepostedAccounts: `
      accountRepostedAccounts(
        paginationFilters: { limit: $limit }
      )  {
        fromAccountId
        toAccountId
      }
    `,
    accountRepliedAccounts: `
      accountRepliedAccounts(
        paginationFilters: { limit: $limit }
      ) {
        fromAccountId
        toAccountId
      }
    `,
    postQuotedPosts: `
      postQuotedPosts(
        paginationFilters: { limit: $limit }
      ) {
        fromPostId
        toPostId
      }
    `,
    postRepliedPosts: `
      postRepliedPosts(
        paginationFilters: { limit: $limit }
      ) {
        fromPostId
        toPostId
      }
    `,
    postRepostedPosts: `
      postRepostedPosts(
        paginationFilters: { limit: $limit }
      ) {
        fromPostId
        toPostId
      }
    `,
    accountQuotedPosts: `
      accountQuotedPosts: accountPosts(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: { quotePosts: true}
      ) {
        accountId
        postId
      }
    `,
    accountRepliedPosts: `
      accountRepliedPosts: accountPosts(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: { replyPosts: true }
      ) {
        accountId
        postId
      }
    `,
    accountRepostedPosts: `
      accountRepostedPosts: accountPosts(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: { repostPosts: true }
      ) {
        accountId
        postId
      }
    `,
    accountRepostedDomains: `
      accountRepostedDomains: accountDomains(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: { repostPosts: true }
      ) {
        accountId
        domainId
      }
    `,
    accountRepostedHashtags: `
      accountRepostedHashtags: accountHashtags(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: { repostPosts: true }
      ) {
        accountId
        text
      }
    `,
    accountRepostedLinks: `
      accountRepostedLinks: accountLinks(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: { repostPosts: true }
      ) {
        accountId
        linkId
      }
    `,
    postRepostedDomains: `
      postRepostedDomains: domainPosts(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: { repostPosts: true }
      ) {
        domainId
        postId
      }
    `,
    postRepostedHashtags: `
      postRepostedHashtags: hashtags(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: { repostPosts: true }
      ) {
        postId
        text
      }
    `,
    postRepostedLinks: `
      postRepostedLinks: linkPosts(
        paginationFilters: { limit: $limit }
        overrideQueryFilters: { repostPosts: true }
      ) {
        linkId
        postId
      }
    `,
    /* clusters */
    topicId: `
      topics(
        paginationFilters: { limit: $limit }
      ) {
        id
        ngrams
        postCount
        title
      }
    `,
  };
}

export const getNodesEdgesQuery = (
  selections: (NodeType | AllAvailableEdges | ClusteringNodeKey)[],
  isCoordinationPreset: boolean,
) => {
  if (!selections.length) {
    return "";
  }

  const subqueries = getSubqueries(isCoordinationPreset);

  const subqueriesStr = selections.reduce<string>((acc, el) => {
    if (el in subqueries) {
      return acc + subqueries[el];
    }
    return acc;
  }, ``);

  return gql`
  query nodesEdges(
    $limit: Int
  ) {
${subqueriesStr.replaceAll(/\s+/g, " ")}
}
`;
};

export const getPostsQuery = gql`
  query posts($limit: Int) {
    posts(paginationFilters: { limit: $limit }) {
      id
      publishedAt
    }
  }
`;

export const getURLSharingQuery = gql`
  query links($limit: Int) {
    links(
      paginationFilters: { limit: $limit, orderBy: ["posted_count:desc"] }
    ) {
      id
      url
      postedCount
      accountEdges
    }
  }
`;
