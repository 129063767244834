import * as Sentry from "@sentry/browser";
import { type ZodSchema } from "zod";

import { defaultLogger as logger } from "../useLogger";

export type AuthToken = string | null | undefined;

/* a small wrapper to add type-safety to the payload of a window.fetch response */
export type GenericFetcherFunction<T, K = RequestInfo> = (
  input: K,
  init?: RequestInit,
) => Promise<T>;
/* 'builder' functions are passed some current data and an update to that data to be sent over the
 * network, and return any changes to the current data that we wish to see on-screen immediately
 * without waiting for the network request to finish */
export type CreateBuilderFunction<T, U> = (payload: U) => Partial<T>;
export type UpdateBuilderFunction<T, U> = (current: T, update: U) => T;
/* 'factory' functions are responsible for creating builders and fetchers
 *
 * all fetcher functions except our get-fetcher, action-fetcher, and HTML-fetcher return a Promise which resolves
 * to the 'never' type since we don't actually care about the values they return.  after these
 * fetchers finish execution we drop their return values on the floor and invoke our get-fetcher to
 * ensure what we have locally is in sync with our API server */
export type ActionFetcherFactory<T, U> = (
  authToken: AuthToken,
  payload: T,
  verb: RequestInit["method"] | undefined,
  schemaValidator: U extends void ? void : ZodSchema<U>,
) => GenericFetcherFunction<U>;
export type CreateBuilderFactory<T, U> = () => CreateBuilderFunction<T, U>;
export type CreateFetcherFactory<T> = (
  authToken: AuthToken,
  payload: T,
) => GenericFetcherFunction<Response>;
export type GetFetcherFactory<T, K = RequestInfo> = (
  authToken: AuthToken,
  schemaValidator: ZodSchema<T>,
) => GenericFetcherFunction<T, K>;
export type RemoveFetcherFactory = (
  authToken: AuthToken,
  uuid?: string,
) => GenericFetcherFunction<never>;
export type UpdateBuilderFactory<T, U> = () => UpdateBuilderFunction<T, U>;
export type UpdateFetcherFactory<T, K = RequestInfo, U = never> = (
  authToken: AuthToken,
  payload: T,
) => GenericFetcherFunction<U, K>;

// @TODO: Define an enum type for error status definitions to be used consistently on both the client and server sides.
// Implementing this enum will streamline error management and improve the overall reliability of error handling across the application.
export class ResponseError extends Error {
  _status?: number;
  _statusDefinition?: string;
  _serverMessage?: string;

  constructor(
    status?: number,
    statusDefinition?: string,
    serverMessage?: string,
    message?: string,
    options?: ErrorOptions,
  ) {
    super(message, options);
    this._status = status;
    this._statusDefinition = statusDefinition;
    this._serverMessage = serverMessage;
  }

  get status(): number | undefined {
    return this._status;
  }

  get statusDefintion(): string | undefined {
    return this._statusDefinition;
  }

  get serverMessage(): string | undefined {
    return this._serverMessage;
  }
}

export function catchNetworkErrorFor(
  method: RequestInit["method"],
  url: RequestInfo,
) {
  return function (err: unknown) {
    Sentry.withScope((scope) => {
      scope.setContext("request", {
        method,
        url: String(url),
      });
      logger.error(err);
    });

    throw err;
  };
}

export const defaultActionFetcherFactory =
  <T = any, U = any>(
    authToken: AuthToken,
    payload: T,
    verb: RequestInit["method"],
  ) =>
  async (input: RequestInfo, init?: RequestInit) => {
    if (!authToken) {
      throw new Error(
        `can't use default fetchers to make unauthenticated API requests`,
      );
    }

    return window
      .fetch(input, {
        ...(init ?? {}),
        body: JSON.stringify(payload),
        headers: {
          ...(init?.headers ?? {}),
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": authToken,
        },
        method: verb,
      })
      .then((res) => res.json())
      .catch(catchNetworkErrorFor(verb, input)) as Promise<U>;
  };

export const voidResponseActionFetcherFactory: ActionFetcherFactory<
  any,
  void
> = (authToken, payload, verb) => async (input, init) => {
  if (!authToken) {
    throw new Error(
      `can't use default fetchers to make unauthenticated API requests`,
    );
  }
  return await window
    .fetch(input, {
      ...(init ?? {}),
      body: JSON.stringify(payload),
      headers: {
        ...(init?.headers ?? {}),
        "X-CSRF-TOKEN": authToken,
        "Content-Type": "application/json",
      },
      method: verb,
    })
    .then((res) => {
      if (!res.ok) {
        throw new Error(
          `error completing action - the http response was not in the 200 - 299 range, the http response was ${res.status}`,
        );
      }
    })
    .catch(catchNetworkErrorFor(verb, input));
};

export const defaultCreateBuilderFactory: CreateBuilderFactory<any, any> =
  () => (payload) =>
    payload;

export const defaultCreateFetcherFactory: CreateFetcherFactory<any> =
  (authToken, payload) => async (input, init) => {
    if (!authToken) {
      throw new Error(
        `can't use default fetchers to make unauthenticated API requests`,
      );
    }

    return window
      .fetch(input, {
        ...(init ?? {}),
        body: JSON.stringify(payload),
        headers: {
          ...(init?.headers ?? {}),
          "X-CSRF-TOKEN": authToken,
          "Content-Type": "application/json",
        },
        method: "POST",
      })
      .then(async (res) => {
        if (res.status >= 400) {
          let errorMessage = null;
          let errorStatusDefinition = null;

          try {
            const errData = await res.json();
            errorMessage = errData.msg;
            errorStatusDefinition = errData?.metadata?.errors[0];
          } catch (err) {
            console.error(
              "something happened in the parsing error data...",
              err,
            );
          }

          throw new ResponseError(
            res.status,
            errorStatusDefinition,
            errorMessage,
          );
        }
      })
      .catch(catchNetworkErrorFor("POST", input)) as Promise<never>;
  };

export const defaultGetFetcherFactory: GetFetcherFactory<any> =
  (authToken, schemaValidator) => async (input, init) => {
    if (!authToken) {
      throw new Error(
        `can't use default fetchers to make unauthenticated API requests`,
      );
    }

    const res = await window.fetch(input, {
      ...(init ?? {}),
      headers: {
        ...(init?.headers ?? {}),
        "X-CSRF-TOKEN": authToken,
      },
    });

    switch (true) {
      case res.status === 404: {
        /* some of our endpoints return this status code during normal operations, but
         * there's no JSON to parse so do nothing */
        return;
      }
      case res.status >= 400: {
        /* server (5xx) or client (4xx) went pffft, doesn't really matter here who */
        Sentry.withScope((scope) => {
          scope.setContext("request", {
            method: "GET",
            url: String(input),
          });
          logger.error(
            `unexpected ${res.status} response while fetching ${String(input)}`,
          );
        });

        throw new Error(`${res.status}`);
      }
      default: {
        const payload = await res.json();

        if (!schemaValidator) {
          return payload;
        }

        const parseResult = schemaValidator.safeParse(payload);

        if (parseResult.success) {
          /* zod is not correctly handling union types of partial
           * objects, so we can't use parseResult.data and instead must
           * use the unparsed data directly */
          return payload;
        } else {
          console.warn(
            `couldn't parse network payload for object at ${input}:`,
            parseResult.error.issues,
          );
        }
      }
    }
  };

export const defaultRemoveFetcherFactory: RemoveFetcherFactory =
  (authToken) => async (input, init) => {
    if (!authToken) {
      throw new Error(
        `can't use default fetchers to make unauthenticated API requests`,
      );
    }

    return window
      .fetch(input, {
        ...(init ?? {}),
        headers: {
          ...(init?.headers ?? {}),
          "X-CSRF-TOKEN": authToken,
        },
        method: "DELETE",
      })
      .catch(catchNetworkErrorFor("POST", input)) as Promise<never>;
  };

export const defaultUpdateBuilderFactory: UpdateBuilderFactory<any, any> =
  () => (current, update) => ({ ...current, ...update });

export const defaultUpdateFetcherFactory: UpdateFetcherFactory<any> =
  (authToken, payload) => async (input, init) => {
    if (!authToken) {
      throw new Error(
        `can't use default fetchers to make unauthenticated API requests`,
      );
    }

    return window
      .fetch(input, {
        ...(init ?? {}),
        body: JSON.stringify(payload),
        headers: {
          ...(init?.headers ?? {}),
          "X-CSRF-TOKEN": authToken,
          "Content-Type": "application/json",
        },
        method: "PUT",
      })
      .catch(catchNetworkErrorFor("PUT", input)) as Promise<never>;
  };

export const unauthenticatedActionFetcherFactory: ActionFetcherFactory<
  any,
  any
> =
  (_authToken, payload, verb = "POST", schemaValidator) =>
  async (input, init) => {
    const res = await window.fetch(input, {
      ...(init ?? {}),
      body: JSON.stringify(payload),
      headers: {
        ...(init?.headers ?? {}),
        "Content-Type": "application/json",
      },
      method: verb,
    });

    switch (true) {
      case res.status === 404: {
        return;
      }
      case res.status >= 400: {
        Sentry.withScope((scope) => {
          scope.setContext("request", {
            method: verb,
            url: String(input),
          });
          logger.error(
            `unexpected ${res.status} response while fetching ${String(input)}`,
          );
        });

        throw new Error(`error: ${res.status}`);
      }
      default: {
        const payload = await res.json();

        if (!schemaValidator) {
          return payload;
        }

        const parseResult = schemaValidator.safeParse(payload);

        if (parseResult.success) {
          /* zod is not correctly handling union types of partial
           * objects, so we can't use parseResult.data and instead must
           * use the unparse data directly */
          return payload;
        } else {
          console.warn(
            `couldn't parse network payload for object at ${input}:`,
            parseResult.error,
          );
        }
      }
    }
  };

export const unauthenticatedGetFetcherFactory: GetFetcherFactory<any> =
  (_authToken, schemaValidator) => async (input, init) => {
    const res = await window.fetch(input, {
      ...(init ?? {}),
      headers: {
        ...(init?.headers ?? {}),
      },
    });

    switch (true) {
      case res.status === 404: {
        /* some of our endpoints return this status code during normal operations, but
         * there's no JSON to parse so do nothing */
        return;
      }
      case res.status >= 400: {
        /* server (5xx) or client (4xx) went pffft, doesn't really matter here who */
        Sentry.withScope((scope) => {
          scope.setContext("request", {
            method: "GET",
            url: String(input),
          });
          logger.error(
            `unexpected ${res.status} response while fetching ${String(input)}`,
          );
        });

        throw new Error(`${res.status}`);
      }
      default: {
        const payload = await res.json();

        if (!schemaValidator) {
          return payload;
        }

        const parseResult = schemaValidator.safeParse(payload);

        if (parseResult.success) {
          /* zod is not correctly handling union types of partial
           * objects, so we can't use parseResult.data and instead must
           * use the unparsed data directly */
          return payload;
        } else {
          console.warn(
            `couldn't parse network payload for object at ${input}:`,
            parseResult.error,
          );
        }
      }
    }
  };
