import { styled } from "@linaria/react";
import {
  useCallback,
  useEffect,
  useState,
  type FunctionComponent,
  type ReactNode,
  useContext,
} from "react";

import { useRawDataCreatorDetails } from "~/hooks/useRawData";
import { RegraphContext } from "~/pages/Graph/components/ControlPanel";
import type { RegraphObject } from "~/pages/Graph/types";
import { text } from "~/styles/typography";

import {
  AdditionalActionsPanel,
  CopyToClipboard,
  OpenInNewTab,
} from "./AdditionalActionsPanel";
import { useProfileDialogContext } from "./ProfileDialogContext";
import Button, { ButtonKind, ButtonSize } from "./library/Button";
import Icon from "./library/Icon";
import Popover from "./library/Popover";
import Tooltip from "./library/Tooltip";

const IconCircle = styled(Icon)`
  border-radius: var(--border-radius-full);
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
`;

const URLIconCircle = styled(IconCircle)`
  color: var(--text-color-brand-primary);
`;

const UserIconCircle = styled(IconCircle)`
  color: var(--text-color-brand-primary);
`;

const PivotButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-md);
`;

interface URLConnectionPreviewProps {
  url: string;
  conversationId?: string;
}

interface UserConnectionPreviewProps {
  url?: string;
  screen_name: string;
  user_rid: string;
  conversationId?: string;
  user_class?: string;
  clusterId?: string;
  regraphObject?: RegraphObject;
}
export interface ExternalLinkComponentProps {
  children?: ReactNode;
  conversationId?: string;
  url?: string;
}

export const URLExternalLinkComponent: FunctionComponent<
  ExternalLinkComponentProps
> = (props) => {
  const { url, conversationId, children = url } = props;

  return url ? (
    <Popover triggerText={<TriggerText>{children}</TriggerText>}>
      <URLConnectionPreview conversationId={conversationId} url={url} />
    </Popover>
  ) : (
    <Tooltip contents="The URL is not available">{children}</Tooltip>
  );
};

const TriggerText = styled.span`
  ${text.sm.semibold};
  color: var(--text-color-brand-secondary);
`;

export const UserExternalLinkComponent: FunctionComponent<
  UserConnectionPreviewProps
> = (props) => {
  const {
    url,
    screen_name,
    conversationId,
    user_rid,
    user_class,
    clusterId,
    regraphObject,
  } = props;

  return (
    <Popover triggerText={<TriggerText>{screen_name}</TriggerText>}>
      <UserConnectionPreview
        clusterId={clusterId}
        conversationId={conversationId}
        regraphObject={regraphObject}
        screen_name={screen_name}
        url={url}
        user_class={user_class}
        user_rid={user_rid}
      />
    </Popover>
  );
};

export const URLConnectionPreview: FunctionComponent<
  URLConnectionPreviewProps
> = (props) => {
  const { conversationId, url } = props;
  const regraph = useContext(RegraphContext);
  const { clearProfileData, setURLData } = useProfileDialogContext();
  const onClose = useCallback(() => {
    clearProfileData();
  }, [clearProfileData]);
  const onPivotClick = useCallback(() => {
    if (regraph) {
      regraph.onURLPreviewClick(url);
      return;
    }

    setURLData({
      id: url,
      conversationId: conversationId,
      onDismiss: onClose,
    });
  }, [conversationId, onClose, regraph, setURLData, url]);
  const urlWithProtocol = url.includes("://") ? url : `//${url}`;

  return (
    <AdditionalActionsPanel>
      <PivotButton
        aria-label="Open URL profile"
        data-kind={ButtonKind.Tertiary}
        data-size={ButtonSize.sm}
        onPress={onPivotClick}
        type="button"
      >
        <URLIconCircle family="untitled" name="link-03" />
        <span title={url}>
          {url.length <= 25 ? url : `${url.substring(0, 25)}...`}
        </span>
      </PivotButton>
      <CopyToClipboard aria-label="Copy URL" text={urlWithProtocol} />
      <OpenInNewTab aria-label="Open URL in new tab" href={urlWithProtocol} />
    </AdditionalActionsPanel>
  );
};

export const UserConnectionPreview: FunctionComponent<
  UserConnectionPreviewProps
> = (props) => {
  const {
    url,
    screen_name,
    conversationId,
    user_rid,
    user_class,
    clusterId,
    regraphObject,
  } = props;
  const profileDialogContext = useProfileDialogContext();
  const { clearProfileData, setUserData, setCoordinationData, setGraphData } =
    profileDialogContext;
  const onClose = useCallback(() => {
    clearProfileData();
  }, [clearProfileData]);
  const onPivotClick = useCallback(() => {
    if (regraphObject && conversationId) {
      setGraphData({
        type: "accounts",
        conversationId,
        accountId: user_rid,
        onDismiss: onClose,
      });
      regraphObject.setSelection({
        [user_rid]: regraphObject.data[user_rid],
      });
      regraphObject.chartRef?.current?.fit([user_rid]);
      return;
    } else if (clusterId && conversationId) {
      setCoordinationData({
        type: "node",
        clusterId: clusterId,
        conversationId: conversationId,
        userRid: user_rid,
        onDismiss: onClose,
      });
      return;
    }

    setUserData({
      user_rid: user_rid,
      conversationId: conversationId,
      onDismiss: onClose,
      user_class: user_class,
    });
  }, [
    regraphObject,
    conversationId,
    clusterId,
    setUserData,
    user_rid,
    onClose,
    user_class,
    setGraphData,
    setCoordinationData,
  ]);
  const [mounted, setMounted] = useState<boolean>(false);
  const { data: userDetails } = useRawDataCreatorDetails({
    conversationId: conversationId,
    rid: mounted ? user_rid : undefined,
  });
  const userUrl = url ?? userDetails?.url;

  /* delay making the network request until this component has been rendered
   * on screen */
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <AdditionalActionsPanel>
      <PivotButton
        aria-label="Open user profile"
        data-kind={ButtonKind.Tertiary}
        data-size={ButtonSize.sm}
        onPress={onPivotClick}
        type="button"
      >
        <UserIconCircle family="untitled" name="user-01" />
        <span title={screen_name}>{screen_name}</span>
      </PivotButton>
      {userUrl && (
        <>
          <CopyToClipboard aria-label="Copy screen name" text={screen_name} />
          <OpenInNewTab aria-label="Open account in new tab" href={userUrl} />
        </>
      )}
    </AdditionalActionsPanel>
  );
};
