import { useCallback } from "react";

import { type ProjectDTO } from "~/dto";
import { ProjectListValidator } from "~/dto/project";

import {
  useRemoteCollection,
  useRemoteObjectFromCollection,
} from "./useRemoteData";
import { useSession } from "./useSession";

const useProjects = () => {
  const { userClaims } = useSession();

  const createBuilder = useCallback(
    () => (payload: Partial<ProjectDTO>) => ({
      ...payload,
      created_at: new Date().toUTCString().slice(0, -1),
      id: "",
      organization_id: userClaims?.organization_id,
    }),
    [userClaims],
  );

  const collection = useRemoteCollection<ProjectDTO>(`/api/v2/project`, {
    createBuilder,
    schemaValidator: ProjectListValidator,
  });
  const createWithOrgId = useCallback(
    async (payload: Partial<ProjectDTO>) => {
      collection.create({
        organization_id: userClaims?.organization_id,
        ...payload,
      });
    },
    [collection, userClaims],
  );

  return {
    ...collection,
    create: createWithOrgId,
  };
};

const useOneProject = (id?: string) =>
  useRemoteObjectFromCollection<ProjectDTO>(`/api/v2/project`, id, {
    schemaValidator: ProjectListValidator,
  });

const useProjectByName = (projectName: string) => {
  const { data: projects = [] } = useProjects();
  return projects.find((project) => project.name === projectName);
};

export { useOneProject, useProjects, useProjectByName };
