const truncateString = (str: string | undefined, limit: number) => {
  /**
   * truncates the input string to the given character length (and appends an ellipsis if needed)
   * @param {string?} str the input string
   * @param {number} limit a max length (in chars)
   */

  if (str) {
    if (str.length > limit) {
      return str.substring(0, limit) + "...";
    } else {
      return str;
    }
  } else {
    return "";
  }
};

function pluralize(
  count: number,
  singular: string,
  plural = `${singular}s`,
  excludeCount = false,
): string {
  if (excludeCount) {
    return count === 1 ? singular : plural;
  }
  return `${count} ${count === 1 ? singular : plural}`;
}

function formatAttributeName(attribute: string) {
  return attribute.split("_").join(" ");
}

const defaultListFormatOptions: Intl.ListFormatOptions = {
  style: "long",
  type: "conjunction",
};

function formatList(
  list: string[] | null | undefined,
  options?: Intl.ListFormatOptions,
): string | undefined {
  if (!list) {
    return undefined;
  }

  const formatter = new Intl.ListFormat("en", {
    ...defaultListFormatOptions,
    ...options,
  });

  return formatter.format(list);
}

export { truncateString, pluralize, formatAttributeName, formatList };
