import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import LoadingIndicator from "./library/LoadingIndicator/LoadingIndicator";

const OutletWithSuspense = () => (
  <Suspense fallback={<LoadingIndicator />}>
    <Outlet />
  </Suspense>
);

export default OutletWithSuspense;
