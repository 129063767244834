import { styled } from "@linaria/react";
import {
  useCallback,
  useState,
  type Dispatch,
  type MouseEvent,
  type RefObject,
  type SetStateAction,
  type FunctionComponent,
} from "react";
import { Heading, Popover, type PopoverProps } from "react-aria-components";

import { text } from "~/styles/typography";

import Icon from "./Icon";

const StyledMenu = styled.div`
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-secondary);
  box-shadow: var(--shadow-lg);
  border-radius: var(--border-radius-md);
  max-height: 265px;
  width: 240px;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  background-color: var(--background-color-primary);
`;

const StyledHeading = styled(Heading)`
  width: 100%;
  display: block;
  padding: var(--spacing-lg) var(--spacing-xl);
  color: var(--text-color-secondary);
  ${text.sm.semibold}
`;

const StyledContent = styled.div`
  border-top: 1px solid var(--border-color-primary);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SyledButton = styled.button`
  width: 100%;
  background-color: var(--background-color-primary);
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  text-align: left;

  &:hover {
    background: var(--background-color-brand-primary);
  }
`;

const MainButton = styled(SyledButton)`
  padding: var(--spacing-lg) var(--spacing-xl);
  justify-content: space-between;
  ${text.sm.medium}
`;

const SubButton = styled(SyledButton)`
  padding: var(--spacing-lg) var(--spacing-xl);
  ${text.sm.medium}
`;

const HeaderButton = styled(SyledButton)`
  padding: var(--spacing-lg) var(--spacing-xl);
  color: var(--text-color-secondary);
  ${text.sm.semibold};
`;

const StyledArrow = styled(Icon)`
  height: 20px;
  width: 20px;
`;

export interface Main extends Data {
  sub: Data[];
  headerLabel: string;
}

export interface Data {
  label: string;
  value: string;
}

interface NestedMenuProps {
  currentMain: Main | undefined;
  data: Main[];
  headerLabel: string;
  id: string;
  onSubClick: (info: Data) => void;
  onClose: () => void;
  setCurrentMain: Dispatch<SetStateAction<Main | undefined>>;
}

export const NestedMenu: FunctionComponent<NestedMenuProps> = (props) => {
  const {
    data,
    currentMain,
    headerLabel,
    id,
    onSubClick,
    onClose,
    setCurrentMain,
  } = props;

  const onMain = useCallback(
    (e: MouseEvent) => {
      const value = e.currentTarget.getAttribute("data-slot");
      if (!value) return;
      const main = data.find((d) => d.value === value);
      if (!main) return;
      setCurrentMain(main);
    },
    [data, setCurrentMain],
  );

  const onSub = useCallback(
    (e: MouseEvent) => {
      const value = e.currentTarget.getAttribute("data-slot");
      const sub = currentMain?.sub.find((d) => d.value === value);
      if (!sub) return;
      onSubClick(sub);
      setCurrentMain(undefined);
      onClose();
    },
    [currentMain?.sub, onClose, onSubClick, setCurrentMain],
  );

  const onHeader = useCallback(() => {
    setCurrentMain(undefined);
  }, [setCurrentMain]);

  return (
    <StyledMenu aria-labelledby={id} role="menu">
      <HeaderContainer>
        {currentMain ? (
          <HeaderButton onClick={onHeader}>
            <StyledArrow family="untitled" name="arrow-narrow-left" />
            {currentMain.headerLabel}
          </HeaderButton>
        ) : (
          <StyledHeading>{headerLabel}</StyledHeading>
        )}
      </HeaderContainer>
      <StyledContent>
        {currentMain
          ? currentMain.sub.map((sub) => (
              <SubButton
                key={sub.value}
                data-slot={sub.value}
                onClick={onSub}
                role="menuitem"
              >
                {sub.label}
              </SubButton>
            ))
          : data.map((main) => (
              <MainButton
                key={main.value}
                data-slot={main.value}
                onClick={onMain}
                role="menuitem"
              >
                {main.label}
                <StyledArrow family="untitled" name="chevron-right" />
              </MainButton>
            ))}
      </StyledContent>
    </StyledMenu>
  );
};

interface NestedMenuWrapperProps
  extends Omit<NestedMenuProps, "currentMain" | "setCurrentMain">,
    Omit<PopoverProps, "triggerRef"> {
  triggerRef: RefObject<HTMLButtonElement>;
}

const NestedMenuWrapper: FunctionComponent<NestedMenuWrapperProps> = (
  props,
) => {
  const { data, headerLabel, id, onSubClick, onClose, ...otherProps } = props;
  const [currentMain, setCurrentMain] = useState<Main | undefined>(undefined);

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        setCurrentMain(undefined);
        onClose();
      }
    },
    [onClose],
  );

  return (
    <Popover onOpenChange={handleOpenChange} {...otherProps}>
      <NestedMenu
        currentMain={currentMain}
        data={data}
        headerLabel={headerLabel}
        id={id}
        onClose={onClose}
        onSubClick={onSubClick}
        setCurrentMain={setCurrentMain}
      />
    </Popover>
  );
};

export default NestedMenuWrapper;
