import { type FunctionComponent, useMemo, useContext } from "react";

import { useActorPostCounts } from "~/hooks/useActorKeyInsights";
import { useConversationFilters } from "~/hooks/useConversationFilters";
import { RegraphContext } from "~/pages/Graph/components/ControlPanel";

import {
  type ActorKeyInsightsProps,
  getSortedData,
  getColumnData,
} from "./ActorKeyInsights";
import {
  ActorTable,
  NoDataPlaceholder,
  PaddedLoadingIndicator,
} from "./NetworkKeyInsights";

const AccountKeyInsights: FunctionComponent<ActorKeyInsightsProps> = (
  props,
) => {
  const { conversationId } = props;
  const filters = useConversationFilters();

  const { data: postData, isLoading } = useActorPostCounts(
    conversationId,
    filters,
  );
  const regraph = useContext(RegraphContext);
  const { columnData, sortedData } = useMemo(() => {
    const columnData = getColumnData(
      conversationId,
      "Posts",
      regraph?.regraphObject,
    );
    const sortedData = getSortedData(postData);
    return {
      columnData,
      sortedData,
    };
  }, [conversationId, postData, regraph?.regraphObject]);

  return (
    <>
      <ActorTable
        aria-busy={isLoading}
        disableFilter
        id="account-key-insights-table"
        tableColumns={columnData}
        tableData={sortedData}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && postData?.data?.length === 0 && <NoDataPlaceholder />}
    </>
  );
};

export default AccountKeyInsights;
