import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  type HTMLProps,
  type StyledComponent,
} from "react";

import Icon from "./Icon";

export type ExternalLinkProps = StyledComponent &
  Omit<HTMLProps<HTMLAnchorElement>, "as"> & {
    href: string;
    showIcon?: true;
    text?: string;
  };

export const StyledExternalLink = styled.a`
  cursor: pointer;

  > [data-icon] {
    font-size: inherit;
    margin-left: 0.25em;
  }

  &:focus-visible {
    border-radius: var(--border-radius-button);
    outline: 2px solid var(--background-color-button-primary);
  }
`;

const NewWindowIcon = styled(Icon)`
  color: var(--color-utility-brand-700);
`;

/**
 * This component should be used whenever we offer the user a hyperlink to an external website.
 */
export const ExternalLink: FunctionComponent<ExternalLinkProps> = (props) => {
  const { children, href, showIcon, text, ...otherProps } = props;

  return (
    <StyledExternalLink
      href={href}
      referrerPolicy="no-referrer"
      rel="nofollow noopener"
      target="_blank"
      {...otherProps}
    >
      {children ?? text}

      <span data-at-only>opens in new window</span>

      {showIcon && (
        <NewWindowIcon
          family="untitled"
          name="share-04"
          title="opens in new window"
        />
      )}
    </StyledExternalLink>
  );
};
