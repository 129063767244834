import { styled } from "@linaria/react";
import { type PageComponent } from "react";
import { Helmet } from "react-helmet";
import { useOutlet } from "react-router-dom";

import { SinglePaneLayout, TwoPaneContainer } from "~/components/Layout";
import Outlet from "~/components/Outlet";
import ProjectPicker from "~/components/ProjectPicker";

import Welcome from "./welcome";

const StyledTwoPaneContainer = styled(TwoPaneContainer)`
  overflow: hidden;

  > :last-child {
    overflow-x: hidden;
  }
`;

const Dashboard: PageComponent = () => {
  const outlet = useOutlet();

  return (
    <>
      <Helmet>
        <title>AG Artemis Dashboard</title>
        <meta content="AG Artemis Dashboard page" name="description" />
      </Helmet>

      <StyledTwoPaneContainer>
        <ProjectPicker aria-owns="details" />
        <SinglePaneLayout id="details">
          {outlet ? <Outlet /> : <Welcome />}
        </SinglePaneLayout>
      </StyledTwoPaneContainer>
    </>
  );
};

export default Dashboard;
