import { styled } from "@linaria/react";
import { useRef, type FunctionComponent } from "react";

import type { NrmsConversationThemeMetricsDTO } from "~/dto/nrmsConversationThemeMetrics";
import {
  useExpandedRowDetails,
  useRawDataCreatorDetails,
} from "~/hooks/useRawData";
import { useIsVisible } from "~/pages/ConversationDashboard/utils";
import { text } from "~/styles/typography";

import CenteredLayout from "../CenteredLayout";
import ErrorMessageContainer from "../ErrorMessageContainer";
import PostDetails from "../PostDetails";
import Section, { Heading } from "../Section";
import UserContentBlock from "../UserContentBlock";
import { LoadingIndicator } from "../library";

import NarrativeSummaryScore from "./NarrativeSummaryScore";
import NarrativeVolumeBarChart from "./NarrativeVolumeBarChart";
import { EvergreenThemeLabels } from "./constants";

type ThemeInModuleProps = Omit<NrmsConversationThemeMetricsDTO, "id">;

interface PostCardProps {
  conversationId: string;
  parentElementId: string;
  postId: string;
}

const ModuleWrapper = styled(Section)`
  border: 1px solid var(--border-color-primary);
  border-radius: var(--border-radius-sm);
  overflow: hidden;
`;

const ModuleTitleWrapper = styled.div`
  background-color: var(--foreground-color-tertiary_hover);
  padding: var(--spacing-xs) var(--spacing-md);

  & > [data-auto-header] {
    color: var(--text-color-white);
    ${text.lg.regular};
  }
`;

const ModuleContentWrapper = styled.div`
  padding: var(--spacing-lg) var(--spacing-md);

  [data-auto-header] {
    margin-bottom: var(--spacing-lg);
    ${text.md.regular};
  }
`;

const ModuleTopContent = styled(Section)`
  margin-bottom: var(--spacing-6xl);

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const ModuleBottomContent = styled(Section)`
  margin-bottom: var(--spacing-3xl);
`;

const ErrorMessage = styled(ErrorMessageContainer)`
  ${text.sm.regular};
`;

const NoResultMessage = styled.div`
  ${text.sm.regular};
`;

const PostCard: FunctionComponent<PostCardProps> = ({
  conversationId,
  parentElementId,
  postId,
}) => {
  const postRef = useRef<HTMLDivElement>(null);

  const { isVisible } = useIsVisible(postRef, {
    root: document.getElementById(parentElementId),
    threshold: 0.5,
    disabled: !parentElementId,
  });
  const {
    data: postDetails,
    error: postDetailsError,
    isLoading: isPostDetailsLoading,
  } = useExpandedRowDetails(postId, conversationId);
  const { data: creatorDetails } = useRawDataCreatorDetails({
    conversationId,
    rid: postDetails?.data.user_rid,
  });

  const userDetails = {
    avatar_url: creatorDetails?.avatar_url ?? undefined,
    class: postDetails?.data.user_class,
    display_name: postDetails?.data.display_name,
    id: postDetails?.data.user_rid,
    screen_name: postDetails?.data.screen_name ?? undefined,
  };
  const urlExternalLink = {
    conversationId,
    url: postDetails?.data.source_url,
  };

  if (isPostDetailsLoading) {
    return <LoadingIndicator />;
  }

  if (postDetailsError) {
    return (
      <CenteredLayout>
        <ErrorMessage>
          An error occurred while loading the recent post
        </ErrorMessage>
      </CenteredLayout>
    );
  }

  if (!postDetails) return null;

  return (
    <UserContentBlock
      ref={postRef}
      urlExternalLink={urlExternalLink}
      userDetails={userDetails}
    >
      <PostDetails
        data={postDetails.data}
        error={postDetailsError}
        isStatsLoading={!postDetails && (isPostDetailsLoading || !isVisible)}
        isVisible={isVisible}
      />
    </UserContentBlock>
  );
};

const ThemeInModule: FunctionComponent<ThemeInModuleProps> = ({
  conversation_id,
  post_ids,
  theme_name,
  volume_history,
  volume_percentage,
}) => {
  return (
    <ModuleWrapper
      as="article"
      data-testid={`theme-in-module_${theme_name}`}
      id={`theme-in-module_${theme_name}`}
    >
      <ModuleTitleWrapper>
        <Heading>{EvergreenThemeLabels[theme_name]}</Heading>
      </ModuleTitleWrapper>
      <ModuleContentWrapper>
        <ModuleTopContent as="article">
          <Heading>Volume Trend</Heading>
          <div>
            {volume_history && volume_history.length > 0 ? (
              <NarrativeVolumeBarChart
                theme_name={theme_name}
                volume_history={volume_history}
              />
            ) : (
              <NoResultMessage>No volume history found</NoResultMessage>
            )}
            <NarrativeSummaryScore
              theme_name={theme_name}
              volume_percentage={volume_percentage}
            />
          </div>
        </ModuleTopContent>
        <ModuleBottomContent as="article">
          <Heading>Recent High-Engagement Post</Heading>
          {post_ids && post_ids.length > 0 ? (
            <PostCard
              key={`${theme_name}-${post_ids[0]}`}
              conversationId={conversation_id}
              parentElementId={`theme-in-module_${theme_name}`}
              postId={post_ids[0]}
            />
          ) : (
            <NoResultMessage>No posts found</NoResultMessage>
          )}
        </ModuleBottomContent>
      </ModuleContentWrapper>
    </ModuleWrapper>
  );
};

export default ThemeInModule;
