import { type FunctionComponent } from "react";

import { useSharedDomains } from "~/hooks/useCoUrl";
import { useConversationFilters } from "~/hooks/useConversationFilters";

import {
  KeyInsightsTable,
  NoDataPlaceholder,
  PaddedLoadingIndicator,
} from "./NetworkKeyInsights";
import { type TableColumn } from "./Table";
import { ExternalLink } from "./library/ExternalLink";

interface DomainKeyInsightsProps {
  conversationId: string;
  excludeTwitter: boolean;
}

interface DomainInsightsTableData {
  domain: string;
  value: number;
}

const columnData: TableColumn<DomainInsightsTableData>[] = [
  {
    field: "domain",
    label: "Domains",
    filterable: false,
    renderFn: ({ data }) => (
      <ExternalLink href={"//".concat(data.domain)}>{data.domain}</ExternalLink>
    ),
  },
  {
    field: "value",
    label: "Posts",
    filterable: false,
  },
];

const DomainKeyInsights: FunctionComponent<DomainKeyInsightsProps> = (
  props,
) => {
  const filters = useConversationFilters();
  const { data, isLoading } = useSharedDomains(
    props.conversationId,
    filters,
    props.excludeTwitter,
  );

  return (
    <>
      <KeyInsightsTable
        aria-label="Domains Shared"
        disableFilter
        id="domain-key-insights-table"
        tableColumns={columnData}
        tableData={data?.data ?? []}
        virtual={false}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && data?.data.length === 0 && <NoDataPlaceholder />}
    </>
  );
};

export default DomainKeyInsights;
