import { type ContextComponent } from "react";
import { RouterProvider } from "react-aria-components";
import { useNavigate } from "react-router-dom";

import { Provider as DocumentLevelProvider } from "./components/Section";
import { CurrentUserProvider } from "./hooks/useCurrentUser";
import { ModalProvider } from "./hooks/useModal";
import { SessionProvider } from "./hooks/useSession";
import { ThemeProvider } from "./hooks/useTheme";
import { withProfiler } from "./utils/errorReporting";

const AppProvider: ContextComponent = ({ children }) => {
  const navigate = useNavigate();

  return (
    <SessionProvider>
      <CurrentUserProvider>
        <RouterProvider navigate={navigate}>
          <ThemeProvider>
            <DocumentLevelProvider value={1}>
              <ModalProvider>{children}</ModalProvider>
            </DocumentLevelProvider>
          </ThemeProvider>
        </RouterProvider>
      </CurrentUserProvider>
    </SessionProvider>
  );
};

export default withProfiler(AppProvider, { name: "ArtemisApp" });
