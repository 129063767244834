import {
  type FormEventHandler,
  type FormSubmitEvent,
  type FunctionComponent,
  type ReactNode,
  createContext,
  useContext,
} from "react";
import {
  type FormProps as BaseFormProps,
  Form as BaseForm,
} from "react-aria-components";

export enum FormSize {
  sm = "sm",
  md = "md",
}

type FormContextProviderProps = {
  children: ReactNode;
  size: FormSize;
};

const FormContext = createContext<FormSize>(FormSize.md);

export const FormContextProvider: FunctionComponent<
  FormContextProviderProps
> = (props) => {
  const { size, children } = props;
  return <FormContext.Provider value={size}>{children}</FormContext.Provider>;
};

export function useFormSize<T extends { size?: FormSize }>(
  props: T,
): { size: FormSize } {
  const context = useContext(FormContext);
  return {
    size: props.size ?? context,
  };
}

export type FormProps = Omit<BaseFormProps, "onSubmit"> & {
  id?: string;
  size?: FormSize;
  onSubmit?: (event: FormSubmitEvent) => void;
};

const Form: FunctionComponent<FormProps> = (props) => {
  const { size = FormSize.md, children, onSubmit, ...rest } = props;
  return (
    <BaseForm
      {...rest}
      data-size={size}
      onSubmit={onSubmit as FormEventHandler}
    >
      <FormContext.Provider value={size}>{children}</FormContext.Provider>
    </BaseForm>
  );
};

export default Form;
