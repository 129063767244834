import { styled } from "@linaria/react";
import {
  type FormContents,
  type FormSubmitEvent,
  type FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useInsightCreator } from "~/hooks/useInsights";

import ErrorMessageContainer from "../ErrorMessageContainer";
import ModalDialog, {
  DialogHeadingContainer,
  DialogTitle,
  XButton,
} from "../ModalDialog";
import { Heading } from "../Section";
import Icon from "../library/Icon";

import InsightsDialogButtonPanel from "./InsightsDialogButtonPanel";
import InsightsForm from "./InsightsForm";
import {
  type CreateInsightsFormSharedContents,
  type InsightsDialogSharedProps,
} from "./types";
import {
  StyledCard,
  isAccountFormContents,
  isDomainFormContents,
  insightFromAccountForm,
  insightFromDomainForm,
} from "./utils";

const FormSubsectionHeading = styled(Heading)`
  font-size: var(--font-size-form-subsection-heading);
`;

const ErrorMessage = styled(ErrorMessageContainer)`
  padding: var(--spacing-xl);
`;

const DialogContentsContainer = styled.div`
  ${FormSubsectionHeading} {
    margin: var(--spacing-xl);
  }
`;

const FlexCard = styled(StyledCard)`
  width: 500px;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  ${DialogContentsContainer} {
    flex-grow: 1;
    overflow: auto;
  }
`;

const FlexedDialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
`;

/* this dialog is wildly variable in height so let's set a fixed position for its
 * top and let it resize downwards as it needs to */
const FixedTopDialog = styled(ModalDialog)`
  max-height: 100vh;
  align-items: start;
  padding-top: 10vh;
  padding-bottom: 5vh;
`;

const useCreateInsightsDialog = (props: InsightsDialogSharedProps) => {
  const { insight, isOpen, onSuccess } = props;
  const dialogTitle = insight ? "Approve Insight" : "Add Insight";
  const formId = "insight-dialog-add-insight-form";
  const [error, setError] = useState<string | undefined>();
  const { create, refresh, remove, update } = useInsightCreator();
  const onDelete = useCallback(async () => {
    if (!insight) {
      return;
    }

    try {
      await remove(insight);
      onSuccess();
    } catch (ex) {
      setError(String(ex));
    }
  }, [insight, onSuccess, remove]);
  const onSubmit = useCallback(
    async (e: FormSubmitEvent) => {
      e.preventDefault();

      const submitButton = e.nativeEvent.submitter;
      const form =
        e.currentTarget as FormContents<CreateInsightsFormSharedContents>;
      const buttonAction = submitButton.getAttribute("value");

      submitButton.setAttribute("disabled", "disabled");

      try {
        setError(undefined);

        if (isDomainFormContents(form)) {
          const payload = insightFromDomainForm(form, insight, buttonAction);
          const link = form.elements.link.value.trim();

          if (!link || !link.includes(".")) {
            throw new Error(
              "a fully-qualified domain name, including TLD, must be provided.",
            );
          }

          if (
            !payload.additional_properties.country_of_origin &&
            !payload.additional_properties.operating_country &&
            !payload.additional_properties.state_association &&
            (!payload.additional_properties.subcategory ||
              payload.additional_properties.subcategory.length === 0) &&
            (!payload.additional_properties.language ||
              payload.additional_properties.language.length === 0)
          ) {
            throw new Error(
              "FQDN, and at least one other field besides Outlet, are required.",
            );
          }

          if (insight) {
            await update(payload);
          } else {
            await create({
              insight: payload,
              link,
            });
          }
        } else if (isAccountFormContents(form)) {
          if (
            !form.elements.user_id.value &&
            !form.elements.screen_name.value &&
            !form.elements.link.value &&
            !form.elements.name.value
          ) {
            throw new Error(
              "At least one Account Identification field is required.",
            );
          }

          const payload = insightFromAccountForm(form, insight, buttonAction);

          if (insight) {
            await update(payload);
          } else {
            await create({
              insight: payload,
              link: form.elements.link.value,
              platform: form.elements.platform.value,
              screen_name: form.elements.screen_name.value,
              user_id: form.elements.user_id.value,
            });
          }
        } else {
          throw new Error(`unknown insight type selected`);
        }

        onSuccess();
      } catch (ex) {
        setError(String(ex));
      }

      submitButton.removeAttribute("disabled");
    },
    [insight, create, onSuccess, update],
  );

  useEffect(() => {
    setError(undefined);
  }, [isOpen]);

  return {
    dialogTitle,
    error,
    formId,
    onDelete,
    onSubmit,
    refresh,
  };
};

const InsightsDialog: FunctionComponent<InsightsDialogSharedProps> = (
  props,
) => {
  const { insight, isOpen, onCancel } = props;
  const { dialogTitle, error, formId, onDelete, onSubmit, refresh } =
    useCreateInsightsDialog(props);

  return (
    <FixedTopDialog isOpen={isOpen}>
      <FlexCard>
        <DialogHeadingContainer>
          <FlexedDialogTitle id="profile-locations-dialog-title">
            <Icon family="untitled" name="message-check-square" />
            {dialogTitle}
          </FlexedDialogTitle>
          <XButton onPress={onCancel} />
        </DialogHeadingContainer>
        <DialogContentsContainer>
          {
            /* don't render this component - and kick off its network requests -
             * until the dialog is shown */
            isOpen && (
              <InsightsForm
                formId={formId}
                insight={insight}
                onSubmit={onSubmit}
              />
            )
          }
        </DialogContentsContainer>
        <ErrorMessage>{error}</ErrorMessage>
        <InsightsDialogButtonPanel
          formId={formId}
          insight={insight}
          mode={insight ? "Review" : "Create"}
          onCancel={onCancel}
          onDelete={onDelete}
          refresh={refresh}
        />
      </FlexCard>
    </FixedTopDialog>
  );
};

export default InsightsDialog;
