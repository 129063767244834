import { styled } from "@linaria/react";
import { useCallback, type FunctionComponent, type MouseEvent } from "react";

import Icon from "~/components/library/Icon";
import { Tag, TagGroup } from "~/components/library/Tags";
import { text } from "~/styles/typography";

import { infoByNodeType } from "../constants";
import { isNodeType, nodeTypes } from "../types";

import { useGraphContext } from "./GraphContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);

  > span {
    color: var(--text-color-primary);
    ${text.sm.semibold}
  }

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--spacing-md);
  }
`;

const StyledTagGroup = styled(TagGroup)`
  [data-slot="tagList"] {
    gap: var(--spacing-md);
  }
`;

const StyledTag = styled(Tag)`
  padding: var(--spacing-xxs) var(--spacing-md) var(--spacing-xxs)
    var(--spacing-sm);

  > div > [data-slot="content"] {
    padding: 0;
    button {
      align-items: center;
      background-color: var(--background-color-primary);
      color: var(--color-utility-brand-600);
      display: flex;
      gap: var(--spacing-xxs);
      width: 100%;
      ${text.sm.medium}
    }
  }
`;

const tagItems = nodeTypes.map((nodeType) => ({
  id: nodeType,
  value: nodeType,
  label: infoByNodeType[nodeType].label,
  iconName: infoByNodeType[nodeType].iconName,
}));

const useStartingSelecor = () => {
  const { graphState } = useGraphContext();
  const {
    node: [, setSelectedNode],
  } = graphState;

  const { addNode } = useGraphContext().legendData;

  const handleNodeButtonPress = useCallback(
    (e: MouseEvent) => {
      const nodeType = e.currentTarget.getAttribute("data-for");
      if (nodeType && isNodeType(nodeType)) {
        setSelectedNode(nodeType);
        addNode(nodeType);
      }
    },
    [addNode, setSelectedNode],
  );

  return {
    handleNodeButtonPress,
  };
};

const StartingSelector: FunctionComponent = () => {
  const { handleNodeButtonPress } = useStartingSelecor();

  return (
    <Container>
      <span>Select Starting Topic</span>
      <div>
        <StyledTagGroup aria-label="add nodes and edges" items={tagItems}>
          {({ value, label, iconName }) => (
            <StyledTag key={value} textValue={label}>
              <button
                data-for={value}
                onClick={handleNodeButtonPress}
                type="button"
              >
                <Icon family="untitled" name={iconName} />
                {label}
              </button>
            </StyledTag>
          )}
        </StyledTagGroup>
      </div>
    </Container>
  );
};

export default StartingSelector;
