import { styled } from "@linaria/react";
import {
  type ComponentPropsWithoutRef,
  type FunctionComponent,
  useCallback,
} from "react";

import { ExternalLink } from "./library/ExternalLink";
import IconButton, {
  type IconButtonProps,
  ButtonKind,
  ButtonSize,
} from "./library/IconButton";

type OpenInNewTabProps = Omit<ComponentPropsWithoutRef<"a">, "children"> & {
  href: string;
};

type CopyToClipboardProps = Omit<
  IconButtonProps,
  "children" | "data-kind" | "data-size" | "onPress" | "aria-label" | "icon"
> & {
  "aria-label"?: string;
  text: string;
};

export const AdditionalActionsPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--font-size-additional-actions-panel);
  gap: var(--spacing-additional-actions-panel);

  a {
    line-height: 1; /* vertically center open-in-new-tab icon link */
  }
`;

export const OpenInNewTab: FunctionComponent<OpenInNewTabProps> = (props) => {
  return <ExternalLink {...props} showIcon />;
};

export const CopyToClipboard: FunctionComponent<CopyToClipboardProps> = (
  props,
) => {
  const { "aria-label": ariaLabel, text, ...rest } = props;
  const label = ariaLabel ?? "Copy to clipboard";

  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(text);
  }, [text]);

  return (
    <IconButton
      {...rest}
      aria-label={label}
      data-kind={ButtonKind.Tertiary}
      data-size={ButtonSize.sm}
      icon="copy-01"
      onPress={copyToClipboard}
      type="button"
    />
  );
};
