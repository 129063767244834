import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import type { LoginRequestDTO, LoginResponseDTO } from "~/dto";
import { LoginResponseValidator } from "~/dto/loginResponse";
import { logEvent } from "~/utils/analytics";

import { useRemoteAction } from "./useRemoteData";
import {
  type ActionFetcherFactory,
  catchNetworkErrorFor,
} from "./useRemoteData/utils";
import { useSession } from "./useSession";

/* the default action-fetcher assumes an auth token, which we don't yet have (because this code
 * assumes we're not logged in) */
const loginFetcherActionFactory: ActionFetcherFactory<
  LoginRequestDTO,
  LoginResponseDTO
> = (authToken, payload) => async (input, init) => {
  return window
    .fetch(input, {
      ...(init ?? {}),
      body: JSON.stringify(payload),
      headers: {
        ...(init?.headers ?? {}),
        "Content-Type": "application/json",
        ...(authToken ? { "X-CSRF-TOKEN": authToken } : {}),
      },
      method: "POST",
    })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      throw new Error(`Error logging in: ${res.status}`);
    })
    .catch(catchNetworkErrorFor("POST", input));
};

function useLogin() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { onNewSession } = useSession();
  const { data, error, execute } = useRemoteAction<
    LoginRequestDTO,
    LoginResponseDTO
  >("/api/v2/user/login", {
    actionFetcher: loginFetcherActionFactory,
    schemaValidator: LoginResponseValidator,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    logEvent("login");

    /* MFA login */

    if (data.location) {
      window.location.href = data.location;

      return;
    }

    /* non-MFA login */

    if (!data.user) {
      return;
    }

    if (data.change_password === true) {
      onNewSession({ user: data.user });
      navigate("/changePassword", { replace: true });

      return;
    } else if (data.require_mfa_enabled) {
      onNewSession({ user: data.user });
      navigate("/mfa-required", { replace: true });

      return;
    } else if (data.roles && data.organization_id) {
      onNewSession({
        exp: data.exp,
        claims: { organization_id: data.organization_id, roles: data.roles },
        user: data.user,
      });
      navigate(searchParams.get("next") ?? "/");
    }
  }, [data, navigate, searchParams, onNewSession]);

  return {
    execute,
    error,
  };
}

export { useLogin };
