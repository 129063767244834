import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  type ComponentPropsWithoutRef,
  type RefObject,
  type ReactNode,
} from "react";
import {
  Button,
  Tooltip as ReactAriaTooltip,
  TooltipTrigger,
  type TooltipProps as ReactAriaTooltipProps,
  TooltipTriggerStateContext,
} from "react-aria-components";

import { OverlayArrow } from "~/styles/overlays";
import { text } from "~/styles/typography";

export type TooltipProps = ReactAriaTooltipProps &
  ComponentPropsWithoutRef<"div"> & {
    as?: string;
    placement?: "top" | "bottom";
    contents: ReactNode;
    supportingContents?: ReactNode;
  };

const SupportingContents = styled.div`
  ${text.xs.medium};
  color: var(--tooltip-supporting-text);
`;

const TooltipArrow = styled(OverlayArrow)`
  svg {
    fill: var(--background-color-primary-solid);
  }
`;

const StyledTooltip = styled(ReactAriaTooltip)`
  ${text.xs.semibold};
  --overlay-arrow-offset: -6px;
  --arrow-height: 9px;
  --tooltip-animation-duration: 200ms;

  border-radius: var(--radius-md, 0.5rem);
  display: flex;
  padding: var(--spacing-md, 0.5rem) var(--spacing-lg, 0.75rem);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: var(--text-color-white); /* FIXME: not semantic */
  background: var(--background-color-primary-solid);
  transform: translate3d(0, 0, 0);

  box-shadow: var(--shadow-lg);

  &[data-placement^="top"] {
    --origin: translateY(4px);

    margin-bottom: var(--arrow-height);
  }

  &[data-placement^="bottom"] {
    --origin: translateY(-4px);

    margin-top: var(--arrow-height);
  }

  @media (prefers-reduced-motion: no-preference) {
    &[data-entering] {
      animation: slide var(--tooltip-animation-duration);
    }

    &[data-exiting] {
      animation: slide var(--tooltip-animation-duration) reverse ease-in;
    }

    @keyframes slide {
      from {
        transform: var(--origin);
        opacity: 0;
      }

      to {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
`;

/* hoverables need to be focusable elements */
const StyledHotspot = styled(Button)`
  background: transparent;
  /* I'd really like to use display:contents here but that messes up the position
   * of the tooltip.  if you need to target in CSS something which has a tooltip
   * wrapped around it then you can use [data-has-tooltip] */
`;

const Tooltip: FunctionComponent<TooltipProps> = (props) => {
  const {
    as,
    children,
    contents,
    placement,
    defaultOpen,
    supportingContents,
    ...otherProps
  } = props;

  return (
    <TooltipTrigger closeDelay={250} defaultOpen={defaultOpen} delay={250}>
      <StyledHotspot data-has-tooltip>{children}</StyledHotspot>
      <StyledTooltip
        defaultOpen={defaultOpen}
        placement={placement}
        {...otherProps}
      >
        <TooltipArrow data-placement={placement} />
        <>
          {contents}
          {supportingContents ? (
            <SupportingContents>{supportingContents}</SupportingContents>
          ) : null}
        </>
      </StyledTooltip>
    </TooltipTrigger>
  );
};

export const TooltipBody: FunctionComponent<
  TooltipProps & { triggerRef: RefObject<Element> }
> = (props) => {
  const { contents, supportingContents, triggerRef, ...otherProps } = props;

  return (
    <TooltipTriggerStateContext.Provider
      value={{ isOpen: true, open() {}, close() {} }}
    >
      <StyledTooltip defaultOpen triggerRef={triggerRef} {...otherProps}>
        {props.placement && <TooltipArrow data-placement={props.placement} />}
        <>
          {contents}
          {supportingContents ? (
            <SupportingContents>{supportingContents}</SupportingContents>
          ) : null}
        </>
      </StyledTooltip>
    </TooltipTriggerStateContext.Provider>
  );
};

export default Tooltip;
