import { styled } from "@linaria/react";
import { type PageComponent } from "react";
import { Helmet } from "react-helmet";

import Header from "~/components/Header";
import LoginForm from "~/components/LoginForm";

const CenteredFixedTopLayout = styled.main`
  display: flex;
  padding-top: 33vh;
  justify-content: center;
  align-items: center;

  > :first-child {
    width: 400px;
    padding: 0 var(--spacing-2xl) var(--spacing-2xl);
  }
`;

const Login: PageComponent = () => {
  return (
    <>
      <Helmet>
        <title>AG Artemis</title>
        <meta content="AG Artemis Login Page" name="description" />
      </Helmet>
      <Header />
      <CenteredFixedTopLayout>
        <LoginForm title="Log In to Get Started" />
      </CenteredFixedTopLayout>
    </>
  );
};

export default Login;
