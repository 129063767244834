import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  type ReactNode,
  type StyledComponent,
  useEffect,
  useRef,
} from "react";

import { useModal } from "~/hooks/useModal";
import { text } from "~/styles/typography";

import Portal from "./Portal";
import { Heading, Provider as DocumentLevelProvider } from "./Section";
import { type ButtonProps, ButtonKind, ButtonSize } from "./library/Button";
import IconButton from "./library/IconButton";

interface ModalDialogProps extends StyledComponent {
  children: ReactNode;
  isOpen: boolean;
}

type XButtonProps = Omit<ButtonProps, "data-size" | "data-kind">;

const ModalUnderlay = styled.dialog`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-alpha-black-20);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: var(--text-color-primary);
`;

const DialogTitle = styled(Heading)`
  ${text.md.medium};
`;

const DialogHeadingContainer = styled.div`
  --foreground-color-modal-dialog-header: var(--color-white); /* TODO */

  padding: var(--spacing-xxs) var(--spacing-md) var(--spacing-xxs)
    var(--spacing-xl);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--foreground-color-modal-dialog-header);
  background-color: var(--background-color-brand-section);
`;

const DialogContentsContainer = styled.div`
  padding: 0 var(--spacing-xl);
`;

const DialogBottomContainer = styled.div`
  border-top: var(--border-modal-dialog-content-container);
  padding: var(--spacing-xl);
  display: flex;
  justify-content: right;

  button {
    justify-self: right;
    margin: var(--spacing-xs);
  }
`;

const StyledXButton = styled(IconButton)`
  && {
    padding: var(--spacing-xs);
    color: var(--foreground-color-modal-dialog-header);
  }
`;

const XButton: FunctionComponent<XButtonProps> = (props) => {
  return (
    <StyledXButton
      {...props}
      aria-label="dismiss this dialog"
      data-kind={ButtonKind.Tertiary}
      data-size={ButtonSize.sm}
      icon="x"
      type="button"
    />
  );
};

const ModalDialog: FunctionComponent<ModalDialogProps> = (props) => {
  const { isOpen, ...otherProps } = props;
  const { add, remove } = useModal();
  const mounted = useRef<boolean>(false);

  useEffect(() => {
    if (!mounted.current) {
      return;
    }

    if (isOpen) {
      add();
    } else {
      remove();
    }
  }, [add, isOpen, remove]);

  /* effects are run in order; keep me last */
  useEffect(() => {
    mounted.current = true;

    return function cleanup() {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    document
      .querySelector<HTMLInputElement>(
        `#modal-dialog input:not([type="hidden"]):not([disabled="true"]),
             #modal-dialog select:not([disabled="true"]),
             #modal-dialog textarea:not([disabled="true"])`,
      )
      ?.focus();
  }, [isOpen]);

  return (
    /* a document's heading levels get reset inside of modal dialogs */
    <Portal to="#modal-dialog">
      <DocumentLevelProvider value={1}>
        {isOpen && <ModalUnderlay {...otherProps} />}
      </DocumentLevelProvider>
    </Portal>
  );
};

export default ModalDialog;

export {
  DialogBottomContainer,
  DialogContentsContainer,
  DialogHeadingContainer,
  DialogTitle,
  XButton,
};
