import {
  type DateValue,
  type CalendarDate,
  Time,
  toZoned,
  toCalendarDateTime,
  toCalendarDate,
  fromDate,
} from "@internationalized/date";
import type { Duration } from "date-fns";

export {
  type DateValue,
  Time,
  fromDate,
  parseDate,
  parseTime,
  toCalendarDateTime,
  today,
  toZoned,
} from "@internationalized/date";
export { type DateRange } from "react-aria-components";

export const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24;

export const formatDate = (dateObject?: Date) =>
  dateObject && isValidDate(dateObject)
    ? dateObject.toISOString().split("T")[0]
    : undefined;

export const dateToNonstandardString = (
  dateObject: Date | undefined,
  opts: Intl.DateTimeFormatOptions,
) =>
  dateObject && isValidDate(dateObject)
    ? new Intl.DateTimeFormat("en-us", opts).format(dateObject)
    : undefined;

export const dateCompare = (
  d1: Date | string | number,
  d2: Date | string | number,
) => new Date(d1).getTime() - new Date(d2).getTime();

export const areDatesEqual = (
  d1: Date | string | number,
  d2: Date | string | number,
) => dateCompare(d1, d2) === 0;

export const isValidDate = (date: Date) => !isNaN(date.getTime());

export const formatDateAGStandard = (dateObject?: Date) =>
  dateToNonstandardString(dateObject, { dateStyle: "medium" });

/**
 * Checks if a given date is between a minimum and maximum date.
 */
export const isBetweenDates = (date: Date, minDate: Date, maxDate: Date) => {
  if (minDate > maxDate) {
    throw new Error("Max date cannot be earlier than the min date.");
  }
  return date >= minDate && date <= maxDate;
};

/**
 * Format a date for CSV export so that Excel will recognize it as a date. If the date is invalid,
 * return an empty string.
 */
export const formatDateForCSV = (dateObject?: Date) =>
  formatDate(dateObject) ?? "";

/**
 * Format a time for CSV export so that Excel will recognize it as a time. If the date is invalid,
 * return an empty string.
 */
export const formatTimeForCSV = (dateObject?: Date) =>
  dateToNonstandardString(dateObject, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "UTC",
  }) ?? "";

/**
 * Format a date and time for CSV export so that Excel will recognize it as a date and time. If the
 * date is invalid, return an empty string. This is a workaround for Excel's inability to parse
 * ISO-8601 dates.
 */
export const formatDateTimeForCSV = (dateObject?: Date) =>
  dateObject && isValidDate(dateObject)
    ? `${formatDateForCSV(dateObject)} ${formatTimeForCSV(dateObject)}`
    : "";

export const generateDayRanges = (minDate: Date, maxDate: Date) => {
  const dateRanges = [];
  const currentDate = new Date(minDate);
  const maxDateString = formatDate(maxDate) ?? "";

  while ((formatDate(currentDate) ?? "") <= maxDateString) {
    dateRanges.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateRanges;
};

export const roundToNearestDay = (d: Date) =>
  new Date(Math.round(d.valueOf() / MILLISECONDS_IN_DAY) * MILLISECONDS_IN_DAY);

export const getDayFor = (d: Date) =>
  new Date(Math.floor(d.getTime() / MILLISECONDS_IN_DAY) * MILLISECONDS_IN_DAY);

/**
 * formats a date and time for Python to ingest
 */
export const pythonISOString = (d: Date | DateValue) => {
  const absoluteString =
    d instanceof Date ? d.toISOString() : toZoned(d, "UTC").toAbsoluteString();

  return absoluteString.replace("Z", "+00:00");
};

export const startOfDayUTC = (d: Date) => {
  /**
   * Our servers are in Virginia (Eastern Time zone), therefore there is a constant 5 hours offset between UTC.
   * When given a Date object, it returns a Date object in the same date but set to 00:00 in UTC.
   */
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();

  return new Date(Date.UTC(year, month, day));
};

/**
 * Returns a date that is within the specified range of minimum and maximum dates.
 */
export function restrictDates(date: Date, minDate: Date, maxDate: Date): Date {
  if (minDate > maxDate) {
    throw new Error("Max date cannot be earlier than the min date.");
  }
  return new Date(
    Math.max(minDate.getTime(), Math.min(date.getTime(), maxDate.getTime())),
  );
}

export const dateToCalendarDate = (date: Date) =>
  toCalendarDate(fromDate(date, "UTC"));
export const isoDateToCalendarDate = (isoDate: string) =>
  dateToCalendarDate(new Date(isoDate));

const startOfDay = () => new Time();
const endOfDay = () => new Time().subtract({ milliseconds: 1 });

export const getStartOfDay = (date: CalendarDate) =>
  toCalendarDateTime(date, startOfDay());
export const getEndOfDay = (date: CalendarDate) =>
  toCalendarDateTime(date, endOfDay());

export function getFirstNonZeroTimeUnit(duration: Duration) {
  const timeUnits: [number | undefined, string][] = [
    [duration.years, "year"],
    [duration.months, "month"],
    [duration.weeks, "week"],
    [duration.days, "day"],
    [duration.hours, "hour"],
    [duration.minutes, "minute"],
    [duration.seconds, "second"],
  ];

  for (const [value, label] of timeUnits) {
    if (value && value > 0) {
      return {
        value,
        label,
      };
    }
  }

  return {
    value: 0,
    label: "second",
  };
}
