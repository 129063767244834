import { styled } from "@linaria/react";
import { type RefObject, useRef } from "react";
import { type AriaLinkOptions, useLink } from "react-aria";
import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from "react-router-dom";

export type LinkProps = AriaLinkOptions &
  Omit<RouterLinkProps, "to"> & {
    /* prefer the platform-native attribute to the component prop */
    href: string;
  };

const StyledLink = styled(RouterLink)`
  color: var(--text-color-brand-secondary);
  background: transparent;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const useAnchorProps = (
  props: LinkProps,
  ref: RefObject<HTMLAnchorElement>,
): RouterLinkProps => {
  const { href, ...otherProps } = props;
  const { linkProps } = useLink(props, ref);

  return {
    to: href,
    ...linkProps,
    ...otherProps,
  };
};

const A = (props: LinkProps) => {
  return <StyledLink {...useAnchorProps(props, useRef(null))} />;
};

export default A;
