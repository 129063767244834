import { useCallback } from "react";

import { useCurrentUser } from "./useCurrentUser";
import { useRemoteAction } from "./useRemoteData";
import { voidResponseActionFetcherFactory } from "./useRemoteData/utils";
import { useSession } from "./useSession";

function useLogout() {
  const { onEndSession } = useSession();
  const { SSOLogoutURL } = useCurrentUser();
  const { error, execute } = useRemoteAction<void, void>(
    "/api/v2/user/logout",
    {
      actionFetcher: voidResponseActionFetcherFactory,
      schemaValidator: undefined,
      verb: "GET",
    },
  );
  const doLogout = useCallback(async () => {
    try {
      if (SSOLogoutURL) {
        window.location.assign(SSOLogoutURL);
      }
      await execute();
    } catch (ex: unknown) {
      /* nothing to do here; network errors are already caught and logged elsewhere */
    }

    onEndSession();
  }, [execute, onEndSession, SSOLogoutURL]);

  return {
    error,
    execute: doLogout,
  };
}

export { useLogout };
