import { styled } from "@linaria/react";
import {
  type HTMLAttributes,
  type ReactNode,
  type StyledComponent,
  type FunctionComponent,
} from "react";

import { text } from "~/styles/typography";

interface FieldProps extends HTMLAttributes<HTMLDivElement>, StyledComponent {
  name: string;
  value: ReactNode | undefined;
  title?: string;
  children?: ReactNode;
}

const FieldDescription = styled.dt`
  ${text.xs.regular};
`;

const FieldValue = styled.dd`
  ${text.sm.semibold};

  & + * {
    margin-top: var(--spacing-lg);
  }
`;

const Field: FunctionComponent<FieldProps> = (props) => {
  const { name, value, ...otherProps } = props;
  const a11y = otherProps.title ? {} : { "aria-label": name };

  return (
    <div {...otherProps}>
      <FieldDescription {...a11y}>{name}</FieldDescription>
      {props.children ? (
        /* eslint-disable-next-line react/jsx-no-useless-fragment
                -- children may be an array */
        <>{props.children}</>
      ) : (
        <FieldValue>{value}</FieldValue>
      )}
    </div>
  );
};

const RegularField = styled(Field)`
  display: flex;
  flex-direction: column;
`;

const ReversedField = styled(RegularField)`
  flex-direction: column-reverse;
`;

export { FieldDescription, FieldValue, ReversedField };

export default RegularField;
