import { type ImgHTMLAttributes, type FunctionComponent } from "react";

import {
  type DataSource,
  dataSources,
  dataSourceFilledImages,
  dataSourceImages,
} from "~/constants";

export type DataSourceIconProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  "src"
> & {
  name: DataSource;
  isFilledImage?: boolean;
};

const DataSourceIcon: FunctionComponent<DataSourceIconProps> = (props) => {
  const { name, isFilledImage = false, ...otherProps } = props;

  if (!dataSources[name]) {
    console.warn(`no icon found for ${name}`);
  }

  return (
    <img
      {...otherProps}
      alt={name}
      src={
        isFilledImage ? dataSourceFilledImages[name] : dataSourceImages[name]
      }
      title={dataSources[name]}
    />
  );
};

export default DataSourceIcon;
