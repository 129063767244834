import { useCallback, useEffect, useState } from "react";
import { type ZodSchema } from "zod";

import { useLogger } from "../useLogger";
import { useSession } from "../useSession";

import {
  type ActionFetcherFactory,
  defaultActionFetcherFactory,
} from "./utils";

interface RemoteActionOpts<ActionRequestShape, ActionResponseShape> {
  actionFetcher?: ActionFetcherFactory<ActionRequestShape, ActionResponseShape>;
  schemaValidator: ActionResponseShape extends void
    ? void
    : ZodSchema<ActionResponseShape>;
  verb?: RequestInit["method"];
}

/* SWR is for keeping local data in sync with remote data.  it's not a good fit for cases
 * where there's no data to keep in sync like login/logout actions, pings and polls,
 * fire-and-forget commands, etc., so let's keep a separate custom hook for those cases */
export function useRemoteAction<RequestShape, ResponseShape>(
  apiEndpointPath: string | undefined,
  {
    actionFetcher = defaultActionFetcherFactory,
    schemaValidator,
    verb = "POST",
  }: RemoteActionOpts<RequestShape, ResponseShape>,
) {
  const logger = useLogger();
  const { authToken } = useSession();
  const [data, setData] = useState<ResponseShape | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [validatedData, setValidatedData] = useState<
    ResponseShape | undefined
  >();
  const execute = useCallback(
    async (payload: RequestShape) => {
      if (!apiEndpointPath) {
        return;
      }

      try {
        const response = await actionFetcher(
          authToken,
          payload,
          verb,
          schemaValidator,
        )(apiEndpointPath);
        setData(response);
        return response;
      } catch (ex) {
        setError(String(ex));

        throw ex;
      }
    },
    [actionFetcher, apiEndpointPath, authToken, schemaValidator, verb],
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    if (schemaValidator) {
      const parseResult = schemaValidator.safeParse(data);

      if (parseResult.success) {
        setValidatedData(parseResult.data);
      } else {
        logger.warn(
          `couldn't parse network payload for action at ${apiEndpointPath}:`,
          parseResult.error,
        );
      }
    } else {
      setValidatedData(data);
    }
  }, [apiEndpointPath, data, logger, schemaValidator]);

  return {
    data: validatedData,
    error,
    execute,
  };
}
