import * as Sentry from "@sentry/browser";
import { setUser as setSentryUser } from "@sentry/minimal";

import type { UserDTO } from "~/dto";

import { isErrorReportingEnabled } from "./errorReporting";
import { LogLevel } from "./loggers/types";

const SERVICES = ["sentry"] as const;

type EventOpts = {
  category?: string;
  extraData?: Record<string, any>;
  level?: LogLevel;
  services?: (typeof SERVICES)[number][];
};

const sentrySeverityLevels = {
  [LogLevel.Debug]: "debug",
  [LogLevel.Log]: "info",
  [LogLevel.Warn]: "warning",
  [LogLevel.Error]: "error",
} as const;

export function logEvent(description: string, opts?: EventOpts) {
  const { category, extraData, level, services = SERVICES } = opts ?? {};

  if (process.env.ARTEMIS_ENV === "development") {
    console.log("event:", description, opts ?? {});
  }

  if (services.includes("sentry")) {
    Sentry.addBreadcrumb({
      message: description,
      ...(level !== undefined
        ? { level: sentrySeverityLevels[level] }
        : undefined),
      ...(category ? { category: category } : undefined),
      ...(extraData ? { data: extraData } : undefined),
    });
  }
}

export function init() {
  if (!window.pendo) {
    return;
  }

  window.pendo.initialize();
}

export function identifyUser(user: UserDTO | null | undefined) {
  identifyUserInSentry(user);
  identifyUserInPendo(user);
}

function identifyUserInSentry(user: UserDTO | null | undefined) {
  if (!isErrorReportingEnabled) return;

  if (user) {
    setSentryUser({
      id: user.id,
      email: user.email,
    });
  } else {
    setSentryUser(null);
  }
}

function identifyUserInPendo(user: UserDTO | null | undefined) {
  if (!window.pendo) {
    return;
  }

  if (user) {
    window.pendo.identify({
      visitor: {
        id: user.id,
        email: user.email,
      },
      account: {
        id: user.organization_id,
      },
    });
  } else {
    window.pendo.identify(""); // Clear the user's identity
  }
}
