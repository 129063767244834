import { css, cx } from "@linaria/core";
import { type FunctionComponent } from "react";

import Button, {
  type ButtonProps,
  type ToggleButtonProps,
  ButtonKind,
  ToggleButton,
} from "./Button";

/* Same enum from ./Button, bandaid fix to allow jest tests to go through */
enum ButtonSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
}

export type IconButtonProps = Omit<
  ButtonProps,
  "preIcon" | "postIcon" | "children"
> & {
  "aria-label": string;
  icon: UntitledIcon;
};

export type IconToggleButtonProps = Omit<
  ToggleButtonProps,
  "preIcon" | "postIcon" | "children"
> & {
  "aria-label": string;
  icon: UntitledIcon | ((isSelected: boolean) => UntitledIcon);
};

const iconButtonSizes = css`
  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &[data-size="${ButtonSize.xs}"] {
    padding: var(--spacing-xs, 0.25rem);
  }
  &[data-size="${ButtonSize.sm}"] {
    padding: var(--spacing-md, 0.5rem);
  }
  &[data-size="${ButtonSize.md}"] {
    padding: 10px;
  }
  &[data-size="${ButtonSize.lg}"] {
    padding: var(--spacing-lg, 0.5rem);
  }
  &[data-size="${ButtonSize.xl}"] {
    padding: 14px;
  }
  &[data-size="${ButtonSize.xxl}"] {
    padding: var(--spacing-xl, 0.5rem);
  }
`;

const IconButton: FunctionComponent<IconButtonProps> = (
  props: IconButtonProps,
) => {
  const { className, icon, ...otherProps } = props;

  return (
    <Button
      {...otherProps}
      className={cx(className, iconButtonSizes)}
      preIcon={icon as UntitledIcon}
      title={props["aria-label"]}
    />
  );
};

const IconToggleButton: FunctionComponent<IconToggleButtonProps> = (props) => {
  const { className, icon, ...otherProps } = props;

  return (
    <ToggleButton
      {...otherProps}
      className={cx(className, iconButtonSizes)}
      preIcon={icon}
      /* React Aria's ToggleButton doesn't pass the title prop to the button
      element, but we already require aria-label for accessibility.
      title={props["aria-label"]} */
    />
  );
};

export default IconButton;
export { ButtonKind, ButtonSize, IconToggleButton };
