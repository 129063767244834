import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import { type DataSource } from "~/constants";
import {
  type ActorKeyInsightsResponseDTO,
  type ActorKeyInsightsDTO,
} from "~/dto";
import type { RegraphObject } from "~/pages/Graph/types";
import { text } from "~/styles/typography";

import AccountKeyInsights from "./AccountKeyInsights";
import AmplifierKeyInsights from "./AmplifierKeyInsights";
import { UserExternalLinkComponent } from "./ConnectionPreview";
import DataSourceIcon from "./DataSourceIcon";
import InfluencerKeyInsights from "./InfluencerKeyInsights";
import { type KeyInsightsProps } from "./NetworkKeyInsights";
import { type TableColumn } from "./Table";
import Tabs, { TabItem, TabKind, TabSize } from "./library/Tabs";

const FixedHeightTabs = styled(Tabs)`
  > [role="tablist"] {
    flex-shrink: 0;
    grid-gap: var(--spacing-xl);
    ${text.md.semibold};
    border-bottom: none;
    margin: 0;
    ${text.sm.regular};

    & + [role="tab"] {
      padding: 0 0 var(--spacing-xxs) 0;
    }
  }

  > [role="tabpanel"] {
    min-height: 0;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-3xl) 0 0;

    > :last-child {
      flex-grow: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const ActorKeyInsightsSubTabs = styled(Tabs)`
  > [role="tabpanel"] {
    display: flex;
    flex-direction: column;
    scrollbar-width: thin;
  }
`;

const UserNameTD = styled.div`
  display: grid;
  grid-template-rows: 1;
  grid-template-columns: 30px 1fr;
`;

const SourceCircle = styled.div`
  background-color: var(--color-white);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px var(--color-twitter-icon);
`;

const StyledDataSourceIcon = styled(DataSourceIcon)`
  height: 10px;
`;

export const InfoText = styled.div`
  flex-shrink: 0;
  ${text.xs.regular};
  color: var(--color-black);
  font-style: italic;
  padding-bottom: var(--spacing-xl);

  [data-icon] {
    font-size: var(--font-size-700);
    margin-right: var(--spacing-md);
  }
`;

export interface ActorInsightsTableData {
  name: string;
  url: string | undefined;
  rid: string;
  post_class: string;
  actor_class: string;
  value: number;
}

export interface ActorKeyInsightsProps {
  conversationId: string;
}

export function getUserName(actor: ActorKeyInsightsDTO) {
  return actor.screen_name || actor.display_name || actor.user_id || "Unknown";
}

export function getColumnData(
  conversationId: string,
  label: string,
  regraphObject?: RegraphObject,
) {
  const columnData: TableColumn<ActorInsightsTableData>[] = [
    {
      field: "name",
      label: "Accounts",
      filterable: false,
      renderFn: ({ data }) => (
        <UserNameTD>
          <SourceCircle>
            <StyledDataSourceIcon name={data.post_class as DataSource} />
          </SourceCircle>
          <UserExternalLinkComponent
            conversationId={conversationId}
            regraphObject={regraphObject}
            screen_name={data.name}
            url={data.url}
            user_class={data.actor_class}
            user_rid={data.rid}
          />
        </UserNameTD>
      ),
    },
    {
      field: "value",
      label: label,
      filterable: false,
      renderFn: ({ data }) => <span>{data.value.toLocaleString()}</span>,
    },
  ];
  return columnData;
}

export function getSortedData(data: ActorKeyInsightsResponseDTO | undefined) {
  return (
    data?.data.map((actor) => ({
      name: getUserName(actor),
      url: actor.url,
      rid: actor.rid,
      value: actor.value,
      post_class: actor.post_class ?? actor.actor_class,
      actor_class: actor.actor_class,
    })) ?? []
  );
}

const ActorKeyInsights: FunctionComponent<KeyInsightsProps> = (props) => {
  const { conversationId } = props;

  return (
    <FixedHeightTabs data-kind={TabKind.Outer} data-size={TabSize.sm}>
      <TabItem id="account-post-counts-table" title="Accounts">
        <AccountKeyInsights conversationId={conversationId} />
      </TabItem>
      <TabItem id="influencer-tabs" title="Influencers">
        <InfluencerKeyInsights conversationId={conversationId} />
      </TabItem>
      <TabItem id="amplifier-tabs" title="Amplifiers">
        <AmplifierKeyInsights conversationId={conversationId} />
      </TabItem>
    </FixedHeightTabs>
  );
};

export default ActorKeyInsights;
