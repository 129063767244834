import { useEffect, useState } from "react";

import { consoleLog } from "~/utils/loggers/console";
import { remoteLog } from "~/utils/loggers/remote";
import { sentryLog } from "~/utils/loggers/sentry";
import {
  type BaseLoggerFunction,
  LogLevel,
  type LoggerFunction,
  LoggerIntegrations,
  type LoggerLevels,
  type LoggerOpts,
} from "~/utils/loggers/types";

type EnhancedLogger = LoggerFunction & {
  debug: BaseLoggerFunction;
  error: BaseLoggerFunction;
  log: BaseLoggerFunction;
  warn: BaseLoggerFunction;
};

const loggers: Record<LoggerIntegrations, LoggerFunction> = {
  [LoggerIntegrations.Console]: consoleLog,
  [LoggerIntegrations.Remote]: remoteLog,
  [LoggerIntegrations.Sentry]: sentryLog,
};

/* each log emitter has a minimum severity level.  these are the default levels; users can apply
 * custom thresholds for their specific use-case by passing a LogLevels object to `createLogger` */
const defaultLevels: LoggerLevels = {
  [LoggerIntegrations.Console]: LogLevel.Log,
  [LoggerIntegrations.Remote]: LogLevel.Warn,
  [LoggerIntegrations.Sentry]: LogLevel.Warn,
};

function createLogger(opts: LoggerOpts = {}) {
  const { integrations } = opts ?? {};
  const logLevels = Object.assign({}, defaultLevels, integrations ?? {});
  const logFn = (level: LogLevel, ...msg: unknown[]) => {
    Object.entries<LogLevel | false>(logLevels)
      .filter(([, v]) => v !== false && level >= v)
      /* `Object.entries` erases type information for keys :( */
      .forEach(([k]) =>
        loggers[k as LoggerIntegrations].apply(null, [level, ...msg]),
      );
  };
  const log = (...msg: unknown[]) => {
    logFn(LogLevel.Log, ...msg);
  };

  return Object.assign(log, {
    debug(...msg: unknown[]) {
      logFn(LogLevel.Debug, ...msg);
    },
    error(...msg: unknown[]) {
      logFn(LogLevel.Error, ...msg);
    },
    log(...msg: unknown[]) {
      logFn(LogLevel.Log, ...msg);
    },
    warn(...msg: unknown[]) {
      logFn(LogLevel.Warn, ...msg);
    },
  });
}

const defaultLogger = createLogger({});

const useLogger = (opts?: LoggerOpts) => {
  /* putting a function into a useState bucket means we have to use the function form of the
   * state-setter (or else react would try to invoke our function as the state-setter and
   * typescript will complain).
   * we're using useState instead of useRef since logger options could change during
   * normal operation */
  const [logger, setLogger] = useState<EnhancedLogger>(() =>
    createLogger(opts),
  );

  useEffect(() => {
    setLogger(() => (opts ? createLogger(opts) : defaultLogger));
  }, [opts]);

  return logger;
};

export { defaultLogger, useLogger };
