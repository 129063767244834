import type {
  ReactNode,
  Dispatch,
  MutableRefObject,
  SetStateAction,
} from "react";
import type { Chart, Index, Node, Link, Items } from "regraph";

import type { RawDetailsResponseDTO } from "~/dto";

export type ArtemisVisualization = Chart<GraphNode, ClusteringNodeKey>;

export const clusteringNodeKeys = [
  "coordinationClusterId",
  "platform",
  "topicId",
] as const;

export type ClusteringNodeKey = (typeof clusteringNodeKeys)[number];
export type ExtraNodeKey = "body" | "url" | "domain" | "type";

/** maps of node types to available edges */
export const availableEdges = {
  accounts: [
    "accountPosts",
    "accountRepostedPosts",
    "accountQuotedPosts",
    "accountRepliedPosts",
    "accountCoordinationEvidenceAccounts",
    "accountLinks",
    "accountRepostedLinks",
    "accountHashtags",
    "accountRepostedHashtags",
    "accountDomains",
    "accountRepostedDomains",
    "accountRepostedAccounts",
    "accountQuotedAccounts",
    "accountRepliedAccounts",
  ],
  domains: [
    "domainPosts",
    "postRepostedDomains",
    "accountDomains",
    "accountRepostedDomains",
    "domainLinks",
  ],
  links: [
    "linkPosts",
    "accountLinks",
    "accountRepostedLinks",
    "postRepostedLinks",
    "domainLinks",
  ],
  posts: [
    "accountPosts",
    "accountRepostedPosts",
    "accountQuotedPosts",
    "accountRepliedPosts",
    "domainPosts",
    "postRepostedDomains",
    "linkPosts",
    "postRepostedLinks",
    "postHashtags",
    "postRepostedPosts",
    "postQuotedPosts",
    "postRepliedPosts",
    "postRepostedHashtags",
  ],
  hashtags: [
    "postHashtags",
    "accountHashtags",
    "accountRepostedHashtags",
    "postRepostedHashtags",
  ],
} as const;

export type NodeType = keyof typeof availableEdges;
export const nodeTypes = Object.keys(availableEdges) as NodeType[];
export function isNodeType(x: unknown): x is NodeType {
  return typeof x === "string" && x in availableEdges;
}

export type AvailableEdges<T extends keyof typeof availableEdges = NodeType> =
  (typeof availableEdges)[T][number];
export type AllAvailableEdges = AvailableEdges;
const allEdgesSet = new Set<string>(
  Object.values(availableEdges).flatMap((edges) => edges),
);

export function isEdge(x: unknown): x is AllAvailableEdges {
  return x !== null && typeof x === "string" && allEdgesSet.has(x);
}

export type GraphNode = {
  id: string;
} & {
  [K in ClusteringNodeKey]?: string | null;
} & {
  [K in ExtraNodeKey]?: string;
};

export type IndexNode = Index<Node<QueryNodes>>;
export type IndexLink = Index<Link<QueryNodes>>;
export interface RegraphData {
  nodes: IndexNode;
  links: IndexLink;
}
interface NodeData {
  posts?: QueryPost[];
  accounts?: QueryAccount[];
  domains?: QueryDomain[];
  links?: QueryURLs[];
  hashtags?: QueryHashtag[];
}

export type Preset = "narrative" | "network" | "coordination" | "custom";
export function isPreset(value: string): value is Preset {
  return ["narrative", "network", "coordination", "custom"].includes(value);
}

type Edges =
  | AccountPosts
  | DomainPosts
  | LinkPosts
  | AccountCoordinationEvidenceAccounts
  | QueryHashtag
  | AccountLinks
  | AccountHashtags
  | AccountDomains
  | AccountToAccount
  | PostToPost
  | DomainLinks;

export interface EdgeData {
  accountPosts?: AccountPosts[];
  domainPosts?: DomainPosts[];
  linkPosts?: LinkPosts[];
  accountCoordinationEvidenceAccounts?: AccountCoordinationEvidenceAccounts[];
  postHashtags?: QueryHashtag[];
  postRepostedHashtags?: QueryHashtag[];
  accountLinks?: AccountLinks[];
  accountHashtags?: AccountHashtags[];
  accountRepostedHashtags?: AccountHashtags[];
  accountQuotedPosts?: AccountPosts[];
  accountRepliedPosts?: AccountPosts[];
  accountRepostedPosts?: AccountPosts[];
  accountDomains?: AccountDomains[];
  accountRepostedDomains?: AccountDomains[];
  accountRepostedLinks?: AccountLinks[];
  postRepostedDomains?: DomainPosts[];
  postRepostedLinks?: LinkPosts[];
  accountRepliedAccounts?: AccountToAccount[];
  accountQuotedAccounts?: AccountToAccount[];
  accountRepostedAccounts?: AccountToAccount[];
  domainLinks?: DomainLinks[];
  postQuotedPosts?: PostToPost[];
  postRepliedPosts?: PostToPost[];
  postRepostedPosts?: PostToPost[];
}

export interface ClusterData {
  topics?: Topics[];
}

/*  Type for incoming Data from GraphQL  */
export type QueryData = NodeData & EdgeData & ClusterData;

export interface TimeData {
  posts?: {
    id: string;
    publishedAt: string;
  }[];
}

export interface QueryAccount extends GraphNode {
  id: string;
  displayName: string;
  platform: string;
  screenName: string;
  sourceDomain: string;
}

export type QueryNodes =
  | QueryPost
  | QueryURLs
  | QueryDomain
  | QueryHashtag
  | QueryAccount
  | QueryTopic;

export interface QueryPost extends GraphNode {
  accountId: string;
  additionalProperties: RawDetailsResponseDTO["data"]["additional_properties"];
  id: string;
  body: string;
  publishedAt: string;
  sourceDomain: string;
  topicId: string;
  type: string;
}

export interface QueryURLs extends GraphNode {
  id: string;
  url: string;
  // the bottom keys only appear for URL Sharing
  postedCount?: number;
  accountEdges?: QueryAccount[];
}

export interface QueryDomain extends GraphNode {
  id: string;
  name: string;
}

export interface QueryHashtag extends GraphNode {
  postId: string;
  text: string;
}

export type QueryTopic = GraphNode & Topics;

/* edge types */

export interface AccountPosts {
  accountId: string;
  postId: string;
}

export interface DomainPosts {
  domainId: string;
  postId: string;
}

export interface AccountDomains {
  domainId: string;
  accountId: string;
}

export interface LinkPosts {
  linkId: string;
  postId: string;
}

export interface AccountCoordinationEvidenceAccounts {
  fromAccountId: string;
  toAccountId: string;
  weight: number;
}

export interface AccountHashtags {
  accountId: string;
  text: string;
}

export interface AccountLinks {
  accountId: string;
  linkId: string;
}

export interface PostToPost {
  fromPostId: string;
  toPostId: string;
}

export interface AccountToAccount {
  fromAccountId: string;
  toAccountId: string;
}

export interface DomainLinks {
  linkId: string;
  domainId: string;
}

/* cluster data */
export interface Topics {
  id: string;
  ngrams: string[];
  postCount: number;
  title: string | null;
}

// TODO: these typedefs are driven by the first use-case (of setting url query
// params); ideally we'd refactor so it would be the other way around
export type GraphStateEntry<T> = [
  T[],
  (selection: T | T[] | "" | ((prev: T[]) => T[])) => void,
];
export type GraphState = {
  cluster: GraphStateEntry<ClusteringNodeKey>;
  colorGroupBy: GraphStateEntry<string>;
  edge: GraphStateEntry<AllAvailableEdges>;
  node: GraphStateEntry<NodeType>;
  preset: GraphStateEntry<Preset>;
  numOfMinEdge: GraphStateEntry<string>;
  showCoordinatedPosts: GraphStateEntry<string>;
};
export type GraphContextProviderProps = {
  children: ReactNode;
  value: {
    conversationId: string;
    graphState: GraphState;
  };
};

export function isPostNode(x: Node): x is Node<QueryPost> {
  return "data" in x && x.data.type === "posts";
}

export function isAccountNode(x: Node): x is Node<QueryAccount> {
  return "data" in x && x.data.type === "accounts";
}

export function isDomainNode(x: Node): x is Node<QueryDomain> {
  return "data" in x && x.data.type === "domains";
}

export function isUrlNode(x: Node): x is Node<QueryURLs> {
  return "data" in x && x.data.type === "links";
}

export function isHashtagNode(x: Node): x is Node<QueryHashtag> {
  return "data" in x && x.data.type === "hashtags";
}

export function isTopicNode(x: Node): x is Node<QueryTopic> {
  return "data" in x && x.data.type === "topics";
}

export function isLink(x: Node | Link): x is Link<QueryNodes> {
  return "id1" in x && "id2" in x;
}

export function isAccountCoordinationEvidenceAccounts(
  x: Edges,
): x is AccountCoordinationEvidenceAccounts {
  return "fromAccountId" in x && "toAccountId" in x && "weight" in x;
}

export function isClusteringNodeKey(x: unknown): x is ClusteringNodeKey {
  return typeof x === "string" && clusteringNodeKeys.some((el) => el === x);
}

export interface URLSharingData {
  links: {
    id: string;
    url: string;
    postedCount: number;
    accountEdges: QueryAccount[];
  }[];
}

export type LegendItem = {
  main: string;
  text: string;
  id: string;
  label: string;
};

export interface ColorMap {
  coordination: Map<string, LegendItem>;
  narratives: Map<string, LegendItem>;
  nodes: Map<NodeType, LegendItem>;
}

/*
TODO: temporary typing to enable current existing popups with regraph
Once the other tabs are removed, we can look to refactor this
*/
export interface RegraphObject {
  setSelection: Dispatch<SetStateAction<Index<boolean | Node | Link>>>;
  data: Items<QueryNodes>;
  chartRef?: MutableRefObject<ArtemisVisualization | null>;
}

export type NodesByEdgeCount = {
  [key: number]: IndexNode;
};

export interface ProcessedData {
  processedEdges: IndexLink;
  nodesWithMultipleConnections: IndexNode;
  nodesWithSingleConnection: IndexNode;
  nodesWithoutConnections: IndexNode;
}
