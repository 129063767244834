import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
  type FunctionComponent,
  type MutableRefObject,
  createRef,
  type SetStateAction,
  type Dispatch,
} from "react";
import type { Chart, Index, Link, Node } from "regraph";

import { useSubcategoryByTopic } from "~/hooks/useBubbleGraph";
import {
  getConversationFilterRequestParams,
  useConversationFilters,
} from "~/hooks/useConversationFilters";
import { useRemoteQuery } from "~/hooks/useRemoteQuery";

import { useLegend, type LegendData } from "../hooks/useLegend";
import { useNodesByEdgeCount } from "../hooks/useNodesByEdgeCount";
import { getNodesEdgesQuery, getURLSharingQuery } from "../query";
import {
  type ArtemisVisualization,
  type QueryData,
  type GraphContextProviderProps,
  type GraphState,
  type RegraphData,
  type IndexNode,
  type IndexLink,
  type NodesByEdgeCount,
} from "../types";
import {
  convertTopicToNodes,
  convertURLSharingDataToRegraph,
  getClusterData,
  getFilteredNodes,
  processEdges,
  processNodes,
  type RegraphClusterData,
} from "../utils";

const LAYOUT: Chart.LayoutOptions = {
  name: "organic",
  tightness: 2,
};

/* number of urls to fetch for URL Sharing tab */
const numOfURLS = 300;

export const GraphContext = createContext<{
  chartRef: MutableRefObject<ArtemisVisualization | null>;
  clusterData: RegraphClusterData;
  data?: RegraphData;
  error?: string;
  exportChart: (slug: string) => void;
  exportChartBusy: boolean;
  graphState: GraphState;
  legendData: LegendData;
  isLoading: boolean;
  selection: Index<boolean | Node | Link>;
  setSelection: Dispatch<SetStateAction<Index<boolean | Node | Link>>>;
  nodesByEdgeCount: NodesByEdgeCount | undefined;
  layoutState: {
    layout: Chart.LayoutOptions;
    resetLayout: () => void;
  };
  isLoadingMinEdge: boolean;
}>({
  chartRef: createRef<ArtemisVisualization | null>(),
  clusterData: {},
  exportChart() {},
  exportChartBusy: false,
  graphState: {} as GraphState, // gets initialized before use
  legendData: {} as LegendData, // gets initialized before use
  isLoading: false,
  selection: {},
  setSelection: () => {},
  nodesByEdgeCount: undefined,
  layoutState: {
    layout: LAYOUT,
    resetLayout: () => {},
  },
  isLoadingMinEdge: false,
});

export const useGraphContext = () => useContext(GraphContext);

export const GraphContextProvider: FunctionComponent<
  GraphContextProviderProps
> = (props) => {
  const {
    children,
    value: { conversationId, graphState },
  } = props;
  const chartRef = useRef<ArtemisVisualization>(null);
  const [layout, setLayout] = useState(LAYOUT);
  const [exportChartBusy, setExportChartBusy] = useState<boolean>(false);
  const exportChart = useCallback(
    async (slug: string, extents: "chart" | "view" = "chart") => {
      if (!chartRef.current) {
        return;
      }
      setExportChartBusy(true);

      const img = await chartRef.current.export({
        type: "png",
        extents,
        fitTo: { width: 1920, height: 1080 },
      });

      setExportChartBusy(false);
      img.download(`artemis-${slug}-${conversationId}`);
    },
    [conversationId],
  );
  const conversationFilters = useConversationFilters();
  const {
    cluster: [selectedGroup],
    colorGroupBy: [colorGroupBy],
    edge: [selectedEdge],
    node: [selectedNode],
    preset: [selectedPreset],
    numOfMinEdge: [numOfMinEdge, setNumOfMinEdge],
  } = graphState;
  const minEdgeNumber = numOfMinEdge[0] ? Number(numOfMinEdge[0]) : 0;
  const preset = selectedPreset[0];
  const isNarrativePreset = preset === "narrative";

  const filters = useMemo(
    () => getConversationFilterRequestParams(conversationFilters ?? undefined),
    [conversationFilters],
  );

  const { subcategoryByTopic } = useSubcategoryByTopic(
    conversationId,
    conversationFilters,
  );

  const selectedGraphItems = useMemo(() => {
    return [...selectedNode, ...selectedEdge, ...selectedGroup];
  }, [selectedNode, selectedEdge, selectedGroup]);

  const { query, variables } = useMemo(() => {
    switch (preset) {
      case "narrative": {
        return {
          query: getNodesEdgesQuery(["topicId"], false),
          variables: {
            limit: 10000,
          },
        };
      }
      case "network": {
        return {
          query: getURLSharingQuery,
          variables: {
            limit: numOfURLS,
          },
        };
      }
      default: {
        return {
          query: getNodesEdgesQuery(
            selectedGraphItems,
            preset === "coordination",
          ),
          variables: {
            limit: 10000,
          },
        };
      }
    }
  }, [preset, selectedGraphItems]);

  const {
    data: queryData,
    error,
    isLoading,
  } = useRemoteQuery<QueryData>(
    selectedGraphItems.length
      ? `/api/v2/graphql?conversation_id=${conversationId}&${filters}`
      : undefined,
    query,
    variables,
  );

  const clusterData = useMemo(
    () => getClusterData(queryData, subcategoryByTopic),
    [queryData, subcategoryByTopic],
  );

  const maxCoordinationWeight = useMemo(() => {
    if (
      !queryData?.accountCoordinationEvidenceAccounts ||
      preset !== "coordination"
    ) {
      return undefined;
    }

    return queryData.accountCoordinationEvidenceAccounts.reduce(
      (maxWeight, el) => {
        return Math.max(maxWeight, el.weight);
      },
      0,
    );
  }, [preset, queryData?.accountCoordinationEvidenceAccounts]);

  const legendData = useLegend({
    selectedNode,
    maxCoordinationWeight,
  });

  const unfilteredData = useMemo(() => {
    if (!queryData) {
      return undefined;
    }

    let processedEdges: IndexLink = {};
    let nodeConnections: Map<string, string[]> = new Map();

    /* 
    We can reduce the load on regraph's "component" function by already calculating easy clusters 
    (ones without edges and ones with only one edge toward each other.) Clusters without edges are 
    guaranteed to be a component of 1 node & 0 edge. Clusters with only a single connection 
    are also guaranteed to be a component of 2 nodes & 1 edge.
    */
    let nodesWithMultipleConnections: IndexNode = {};
    let nodesWithSingleConnection: IndexNode = {};
    let nodesWithoutConnections: IndexNode = {};

    switch (preset) {
      case "narrative":
        /* narratives has no edges, only the processedNodesWithoutEdges is needed */
        nodesWithoutConnections = convertTopicToNodes(
          legendData.colorData,
          colorGroupBy,
          queryData,
          selectedPreset,
          clusterData.topicData,
        );
        break;
      case "network":
        const urlRegraph = convertURLSharingDataToRegraph(
          queryData,
          legendData.colorData,
        );
        if (!urlRegraph) return undefined;
        ({
          processedEdges,
          nodesWithMultipleConnections,
          nodesWithSingleConnection,
          nodesWithoutConnections,
        } = urlRegraph);
        break;
      default:
        ({ nodeConnections, processedEdges } = processEdges(
          legendData.colorData,
          queryData,
          selectedEdge,
          selectedPreset[0],
        ));
        ({
          nodesWithMultipleConnections,
          nodesWithoutConnections,
          nodesWithSingleConnection,
        } = processNodes(
          legendData.colorData,
          nodeConnections,
          selectedPreset[0],
          queryData,
        ));
        break;
    }

    return {
      processedEdges,
      nodesWithMultipleConnections,
      nodesWithoutConnections,
      nodesWithSingleConnection,
    };
  }, [
    queryData,
    preset,
    legendData.colorData,
    colorGroupBy,
    selectedPreset,
    clusterData.topicData,
    selectedEdge,
  ]);

  const [selection, setSelection] = useState<Index<boolean | Node | Link>>({});

  const { isLoading: isLoadingMinEdge, nodesByEdgeCount } = useNodesByEdgeCount(
    unfilteredData,
    selectedEdge,
    setNumOfMinEdge,
  );

  const data: RegraphData | undefined = useMemo(() => {
    if (!unfilteredData) return undefined;

    const {
      nodesWithMultipleConnections,
      nodesWithSingleConnection,
      nodesWithoutConnections,
      processedEdges,
    } = unfilteredData;
    const nodes = Object.assign(
      {},
      nodesWithMultipleConnections,
      nodesWithSingleConnection,
      nodesWithoutConnections,
    );
    const filteredNodes =
      isNarrativePreset || !selectedEdge.length
        ? nodes
        : getFilteredNodes(nodes, nodesByEdgeCount, minEdgeNumber);

    return {
      nodes: filteredNodes,
      links: processedEdges,
    };
  }, [
    unfilteredData,
    isNarrativePreset,
    selectedEdge.length,
    nodesByEdgeCount,
    minEdgeNumber,
  ]);

  const resetLayout = useCallback(() => {
    setLayout((prev) => ({ ...prev }));
  }, [setLayout]);

  const contextValue = {
    chartRef,
    clusterData,
    data,
    error,
    exportChartBusy,
    exportChart,
    graphState,
    legendData,
    isLoading,
    selection,
    setSelection,
    nodesByEdgeCount,
    layoutState: {
      layout,
      resetLayout,
    },
    isLoadingMinEdge,
  };

  return (
    <GraphContext.Provider value={contextValue}>
      {children}
    </GraphContext.Provider>
  );
};
