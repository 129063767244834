import { styled } from "@linaria/react";
import { type ComponentPropsWithoutRef, type FunctionComponent } from "react";

import { AlertIntent, alertIntentIcons } from "~/hooks/useNotify";
import { text } from "~/styles/typography";

import Portal from "./Portal";
import Icon from "./library/Icon";

type AlertBannerProps = ComponentPropsWithoutRef<"div"> & {
  /** Determines the color of the banner. Defaults to "info". */
  "data-intent"?: AlertIntent;

  /** Use this to dynamically show/hide the banner. Defaults to true, so you can omit this prop when you need it to always be visible. */
  isOpen?: boolean;
};

const Banner = styled.div`
  position: fixed;
  top: var(--spacing-xl);
  left: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--spacing-xl) var(--spacing-3xl);
  border: 1px solid;
  border-radius: var(--border-radius-md);
  ${text.md.regular};
  gap: var(--spacing-xl);
  box-shadow: var(--shadow-md);
  transform: translateX(-50%);

  [data-icon] {
    width: 28px;
    height: 28px;
  }

  &[data-intent="info"] {
    /* FIXME: no semantic colors available */
    border-color: var(--color-utility-blue-light-100);
    color: var(--color-utility-blue-light-700);
    background-color: var(--color-utility-blue-light-50);
  }

  &[data-intent="warning"] {
    border-color: var(--background-color-warning-secondary);
    background-color: var(--background-color-warning-primary);
    color: var(--text-color-warning-primary);
  }

  &[data-intent="success"] {
    border-color: var(--background-color-success-secondary);
    background-color: var(--background-color-success-primary);
    color: var(--text-color-success-primary);
  }

  &[data-intent="danger"] {
    border-color: var(--background-color-error-secondary);
    background-color: var(--background-color-error-primary);
    color: var(--text-color-error-primary);
  }
`;

function useAlertBanner(props: AlertBannerProps) {
  const {
    isOpen = true,
    "data-intent": intent = AlertIntent.Info,
    ...rest
  } = props;

  return {
    ...rest,
    isOpen,
    "data-intent": intent,
    // TODO: Ideally, this would have a role of "alert"
    /*
     * That way, it would be announced by screen readers. However, we need to
     * render the outer "alert" element first and then the contents of the
     * alert after, so the alert is announced.
     *
     * In addition, if the banner is dismissible or contains an interactive
     * element like a link, then it should have a role of "alertdialog" and
     * should automatically gain focus when it appears. */
    // role: "alert",
    children: (
      <>
        <Icon family="untitled" name={alertIntentIcons[intent]} />
        <span>{rest.children}</span>
      </>
    ),
  };
}

export const AlertBanner: FunctionComponent<AlertBannerProps> = (props) => {
  const { isOpen, ...alertProps } = useAlertBanner(props);

  return (
    <Portal to="#alert-banner">{isOpen && <Banner {...alertProps} />}</Portal>
  );
};
