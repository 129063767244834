import { styled } from "@linaria/react";
import { type FunctionComponent, useCallback, useState } from "react";
import { type PressEvent } from "react-aria";

import { type InsightDTO } from "~/dto/insight";
import { useCurrentUser } from "~/hooks/useCurrentUser";

import ConfirmationForm from "../ConfirmationForm";
import ModalDialog, { DialogBottomContainer } from "../ModalDialog";
import Button, { ButtonKind, ButtonSize } from "../library/Button";

import TagSet from "./TagSet";

type InsightsDialogButtonPanelProps = {
  insight?: InsightDTO;
  formId: string;
  mode: "Add" | "Create" | "Review" | "Edit";
  onCancel: (e: PressEvent) => void;
  onDelete?: (e: PressEvent) => void;
  refresh: () => void;
};

const Message = styled.div`
  margin-bottom: var(--spacing-xl);
`;

const DeleteMessage = styled.div`
  dl {
    border: var(--border-group);
    border-radius: var(--border-radius-default);
    padding: var(--spacing-md);
  }
`;

const InsightsDialogButtons = styled(DialogBottomContainer)`
  display: grid;
  grid-template-columns: 1fr max-content max-content;

  [data-destructive] {
    justify-self: start;
  }
`;

const useButtonPanel = (props: InsightsDialogButtonPanelProps) => {
  const { insight, formId, mode, onCancel, onDelete, refresh } = props;
  const { isGlobalAdmin, isSeniorAnalyst } = useCurrentUser();
  const status = insight?.status;
  /* senior analysts can delete whatever; regular analysts can only delete
   * insights which have not yet been approved or rejected */
  const canDelete =
    isGlobalAdmin() || isSeniorAnalyst() || status === "pending";
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
  const preOnDelete = useCallback(() => {
    setShowDeleteConfirm(true);
  }, []);
  const onDeleteCancel = useCallback(() => {
    setShowDeleteConfirm(false);
  }, []);
  const onDeleteConfirm = useCallback(
    (e: PressEvent) => {
      setShowDeleteConfirm(false);
      onDelete?.(e);
    },
    [onDelete],
  );

  return {
    insight,
    formId,
    mode,
    onCancel,
    onDelete: canDelete ? preOnDelete : undefined,
    onDeleteCancel,
    onDeleteConfirm,
    refresh,
    showDeleteConfirm,
  };
};
const InsightsDialogButtonPanel: FunctionComponent<
  InsightsDialogButtonPanelProps
> = (props) => {
  const {
    insight,
    formId,
    mode,
    onCancel,
    onDelete,
    onDeleteCancel,
    onDeleteConfirm,
    refresh,
    showDeleteConfirm,
  } = useButtonPanel(props);

  return (
    <>
      <InsightsDialogButtons>
        {insight && onDelete ? (
          <Button
            data-destructive
            data-kind={ButtonKind.Tertiary}
            data-size={ButtonSize.md}
            onPress={onDelete}
            preIcon="trash-01"
            type="button"
          >
            Delete
          </Button>
        ) : (
          <span />
        )}
        {mode === "Review" ? (
          <>
            <Button
              data-destructive
              data-kind={ButtonKind.Secondary}
              data-size={ButtonSize.md}
              form={formId}
              preIcon="x"
              type="submit"
              value="Decline"
            >
              Decline
            </Button>
            <Button
              data-kind={ButtonKind.Primary}
              data-size={ButtonSize.md}
              form={formId}
              preIcon="check"
              type="submit"
              value="Approve"
            >
              Approve
            </Button>
          </>
        ) : mode === "Create" ? (
          <>
            <Button
              data-kind={ButtonKind.Tertiary}
              data-size={ButtonSize.md}
              onPress={onCancel}
              type="button"
            >
              Cancel
            </Button>
            <Button
              data-kind={ButtonKind.Primary}
              data-size={ButtonSize.md}
              form={formId}
              type="submit"
            >
              Submit
            </Button>
          </>
        ) : (
          <>
            <Button
              data-kind={ButtonKind.Tertiary}
              data-size={ButtonSize.md}
              onPress={onCancel}
              type="button"
            >
              Cancel
            </Button>
            <Button
              data-kind={ButtonKind.Primary}
              data-size={ButtonSize.md}
              form={formId}
              type="submit"
            >
              {insight ? "Update" : "Submit"}
            </Button>
          </>
        )}
      </InsightsDialogButtons>

      <ModalDialog isOpen={showDeleteConfirm}>
        <ConfirmationForm
          cancelButton={
            <Button
              data-kind={ButtonKind.Tertiary}
              data-size={ButtonSize.md}
              onPress={onDeleteCancel}
              type="button"
            >
              Cancel
            </Button>
          }
          confirmButton={
            <Button
              data-kind={ButtonKind.Primary}
              data-size={ButtonSize.md}
              onPress={onDeleteConfirm}
              type="button"
            >
              Delete
            </Button>
          }
          message={
            insight /* makes tsc happy */ && (
              <DeleteMessage>
                <Message>Are you sure you want to delete this insight?</Message>
                <TagSet insight={insight} refresh={refresh} />
              </DeleteMessage>
            )
          }
          title="Confirmation"
        />
      </ModalDialog>
    </>
  );
};

export default InsightsDialogButtonPanel;
