import {
  type BlogPropertiesDTO,
  type BoardPropertiesDTO,
  type EightKunPropertiesDTO,
  type FourChanPropertiesDTO,
  type RawDetailsDTO,
  type TwitterPropertiesDTO,
  type NewsPropertiesDTO,
  type GabPropertiesDTO,
  type GettrPropertiesDTO,
  type KiwiFarmsPropertiesDTO,
  type MeWePropertiesDTO,
  type MindsPropertiesDTO,
  type ParlerPropertiesDTO,
  type PoalPropertiesDTO,
  type RedditPropertiesDTO,
  type TruthSocialPropertiesDTO,
  type WimkinPropertiesDTO,
} from "~/dto";

export type BlogNewsProperties = BlogPropertiesDTO | NewsPropertiesDTO;
export type EightKunFourChanProperties =
  | EightKunPropertiesDTO
  | FourChanPropertiesDTO;

export function isBlogsNewsDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is BlogNewsProperties {
  return (
    (cls === "BlogPost" || cls === "NewsPost") && addlDetails !== undefined
  );
}

export function isBoardsDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is BoardPropertiesDTO {
  return cls === "MessageBoardPost" && addlDetails !== undefined;
}

export function isEightKunFourChanDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is EightKunFourChanProperties {
  return (
    (cls === "EightKunPost" || cls === "FourChanPost") &&
    addlDetails !== undefined
  );
}

export function isGabDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is GabPropertiesDTO {
  return cls === "GabPost" && addlDetails !== undefined;
}

export function isGettrDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is GettrPropertiesDTO {
  return cls === "GettrPost" && addlDetails !== undefined;
}

export function isKiwiFarmsDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is KiwiFarmsPropertiesDTO {
  return cls === "KiwiFarmsPost" && addlDetails !== undefined;
}

export function isMeweDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is MeWePropertiesDTO {
  return cls === "MeWePost" && addlDetails !== undefined;
}

export function isMindsDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is MindsPropertiesDTO {
  return cls === "MindsPost" && addlDetails !== undefined;
}

export function isParlerDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is ParlerPropertiesDTO {
  return cls === "ParlerPost" && addlDetails !== undefined;
}

export function isPoalDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is PoalPropertiesDTO {
  return cls === "PoalPost" && addlDetails !== undefined;
}

export function isRedditDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is RedditPropertiesDTO {
  return cls === "RedditPost" && addlDetails !== undefined;
}

export function isTelegramDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is TwitterPropertiesDTO {
  return cls === "TelegramPost" && addlDetails !== undefined;
}

export function isTruthSocialDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is TruthSocialPropertiesDTO {
  return cls === "TruthSocialPost" && addlDetails !== undefined;
}

export function isTwitterDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is TwitterPropertiesDTO {
  return (
    (cls === "Tweet" || cls === "TwitterPost") && addlDetails !== undefined
  );
}

export function isWimkinDetails(
  addlDetails: RawDetailsDTO["additional_properties"] | undefined,
  cls: string,
): addlDetails is WimkinPropertiesDTO {
  return cls === "WimkinPost" && addlDetails !== undefined;
}

export function getBasicStatisticsFromPostDetails(
  postClass: string | undefined,
  additionalProperties: RawDetailsDTO["additional_properties"] | undefined,
) {
  if (!additionalProperties || !postClass) {
    return {};
  }

  /* return a Record<string, number> with keys in a specific order: (the
   * platform's equivalent of) likes, comments, and shares.  note that not all
   * platforms have all stats */

  if (isBlogsNewsDetails(additionalProperties, postClass)) {
    return {
      comments: additionalProperties.comment_count,
    };
  }
  if (isBoardsDetails(additionalProperties, postClass)) {
    return {}; /* no applicable statistics */
  }
  if (isEightKunFourChanDetails(additionalProperties, postClass)) {
    return {
      replies: additionalProperties.reply_count,
    };
  }
  if (isGabDetails(additionalProperties, postClass)) {
    return {
      favorites: additionalProperties.favorite_count,
      replies: additionalProperties.reply_count,
      reblogs: additionalProperties.reblog_count,
    };
  }
  if (isGettrDetails(additionalProperties, postClass)) {
    return {
      likes: additionalProperties.like_count,
      shares: additionalProperties.share_count,
    };
  }
  if (isKiwiFarmsDetails(additionalProperties, postClass)) {
    return {};
  }
  if (isMeweDetails(additionalProperties, postClass)) {
    return {
      likes: additionalProperties.like_count,
      comments: additionalProperties.comment_count,
      shares: additionalProperties.share_count,
    };
  }
  if (isMindsDetails(additionalProperties, postClass)) {
    return {
      comments: additionalProperties.comment_count,
      shares: additionalProperties.remind_count,
    };
  }
  if (isParlerDetails(additionalProperties, postClass)) {
    return {
      upvotes: additionalProperties.upvote_count,
      comments: additionalProperties.comment_count,
      reposts: additionalProperties.repost_count,
    };
  }
  if (isPoalDetails(additionalProperties, postClass)) {
    return {
      upvotes: additionalProperties.upvote_count,
    };
  }
  if (isRedditDetails(additionalProperties, postClass)) {
    return {
      score: additionalProperties.score,
      comments: additionalProperties.comment_count,
    };
  }
  if (isTelegramDetails(additionalProperties, postClass)) {
    return {
      favorites: additionalProperties.favorite_count,
      comments: additionalProperties.reply_count,
      shares: additionalProperties.retweet_count,
    };
  }
  if (isTruthSocialDetails(additionalProperties, postClass)) {
    return {
      favorites: additionalProperties.favorite_count,
      replies: additionalProperties.reply_count,
      reblogs: additionalProperties.reblog_count,
    };
  }
  if (isTwitterDetails(additionalProperties, postClass)) {
    return {
      likes: additionalProperties.favorite_count,
      replies: additionalProperties.reply_count,
      retweets: additionalProperties.retweet_count,
    };
  }
  if (isWimkinDetails(additionalProperties, postClass)) {
    return {
      comments: additionalProperties.comment_count,
    };
  }

  return {};
}
