import { styled } from "@linaria/react";
import { type HTMLAttributes, type FunctionComponent } from "react";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  align-self: stretch;
  justify-content: center;
`;

const CenteredLayout: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...otherProps
}) => {
  return <Container {...otherProps}>{children}</Container>;
};

export default CenteredLayout;
