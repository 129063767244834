import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
import { mergeRefs, useObjectRef } from "@react-aria/utils";
import {
  type AriaAttributes,
  type StyledComponent,
  type ForwardedRef,
  type ReactNode,
  forwardRef,
  useLayoutEffect,
  useMemo,
  useRef,
} from "react";
import {
  type ButtonProps as ReactAriaButtonProps,
  type ToggleButtonProps as ReactAriaToggleButtonProps,
  Button as ReactAriaButton,
  ToggleButton as ReactAriaToggleButton,
} from "react-aria-components";
import { Link } from "react-router-dom";

import { text } from "~/styles/typography";

import { type LinkProps, useAnchorProps } from "./A";
import Icon from "./Icon";

export enum ButtonKind {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Link = "link",
}

export enum ButtonSize {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
}

export type ButtonProps = Omit<ReactAriaButtonProps, "isDisabled"> &
  StyledComponent & {
    "aria-current"?: AriaAttributes["aria-current"];
    "data-destructive"?: boolean;
    "data-kind": ButtonKind;
    "data-size": ButtonSize;
    /* prefer the native attribute over the react-aria-components prop name for
     * this component's interface */
    disabled?: boolean;
    preIcon?: UntitledIcon;
    postIcon?: UntitledIcon;
    title?: string;
  };

export type ToggleButtonProps = Omit<
  ReactAriaToggleButtonProps,
  "isDisabled" | "children"
> &
  StyledComponent & {
    children?: ReactNode | ((isSelected: boolean) => ReactNode);
    "data-size": ButtonSize;
    disabled?: boolean;
    preIcon?: UntitledIcon | ((isSelected: boolean) => UntitledIcon);
    postIcon?: UntitledIcon | ((isSelected: boolean) => UntitledIcon);
    title?: string;
  };

export type LinkButtonProps = LinkProps &
  StyledComponent & {
    "data-kind": ButtonKind;
    "data-size": ButtonSize;
    preIcon?: UntitledIcon;
    postIcon?: UntitledIcon;
  };

// link buttons use button's styles to indicate focus/hover states, so we can remove the underline
const StyledLink = styled(Link)`
  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const buttonBase = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: var(--button-border, 0);
  border-radius: var(--button-border-radius, 0);
  padding: var(--button-padding-vertical, 0) var(--button-padding-horizontal, 0);
  gap: var(--button-gap, 0);

  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-property: background-color, border-color, color, box-shadow;

  @media (prefers-reduced-motion) {
    transition: none;
  }
`;

const buttonSizes = css`
  &[data-size="${ButtonSize.xs}"] {
    --button-padding-vertical: var(--spacing-xs, 0.25rem);
    --button-padding-horizontal: var(--spacing-sm, 0.375rem);
    --button-gap: var(--spacing-xs, 0.25rem);

    ${text.xs.semibold}
  }

  &[data-size="${ButtonSize.sm}"] {
    --button-padding-vertical: var(--spacing-md, 0.5rem);
    --button-padding-horizontal: var(--spacing-lg, 0.75rem);
    --button-gap: var(--spacing-xs, 0.25rem);

    ${text.sm.semibold}
  }

  &[data-size="${ButtonSize.md}"] {
    --button-padding-vertical: 0.625rem;
    --button-padding-horizontal: 0.875rem;
    --button-gap: var(--spacing-xs, 0.25rem);

    ${text.sm.semibold}
  }

  &[data-size="${ButtonSize.lg}"] {
    --button-padding-vertical: 0.625rem;
    --button-padding-horizontal: var(--spacing-xl, 1rem);
    --button-gap: var(--spacing-sm, 0.375rem);

    ${text.md.semibold}
  }

  &[data-size="${ButtonSize.xl}"] {
    --button-padding-vertical: var(--spacing-lg, 0.75rem);
    --button-padding-horizontal: 1.125rem;
    --button-gap: var(--spacing-sm, 0.375rem);

    ${text.md.semibold}
  }

  &[data-size="${ButtonSize.xxl}"] {
    --button-padding-vertical: var(--spacing-xl, 1rem);
    --button-padding-horizontal: 1.375rem;
    --button-gap: 0.625rem;

    ${text.lg.semibold}
  }

  [data-button-icon] {
    font-size: 20px;
    display: block;
  }

  &[data-size="${ButtonSize.xs}"] [data-button-icon] {
    font-size: 16px;
  }
  &[data-size="${ButtonSize.xxl}"] [data-button-icon] {
    font-size: 24px;
  }
`;

const buttonKinds = css`
  &[data-kind="${ButtonKind.Primary}"] {
    --button-border-radius: var(--border-radius-md, 0.5rem);
    --button-border: 1px solid var(--button-primary-border);
    background: var(--button-primary-bg);
    color: var(--button-primary-fg);
    box-shadow: var(--shadow-xs);

    &:hover {
      --button-border: 1px solid var(--button-primary-border_hover);
      background: var(--button-primary-bg_hover);
      box-shadow: var(--shadow-xs);
    }

    &:focus {
      --button-border: 1px solid var(--button-primary-border);
      background: var(--button-primary-bg);
      box-shadow: var(--ring-brand-shadow-xs);
      outline: none;
    }

    &[disabled] {
      --button-border: 1px solid var(--border-color-disabled_subtle);
      color: var(--foreground-color-disabled);
      background: var(--background-color-disabled);
      box-shadow: var(--shadow-xs);
    }
  }

  &[data-kind="${ButtonKind.Secondary}"] {
    --button-border-radius: var(--border-radius-md, 0.5rem);
    --button-border: 1px solid var(--button-secondary-color-border);
    background: var(--button-secondary-color-bg);
    color: var(--button-secondary-color-fg);
    box-shadow: var(--shadow-xs);

    &:hover {
      --button-border: 1px solid var(--button-secondary-color-border_hover);
      background: var(--button-secondary-color-bg_hover);
      box-shadow: var(--shadow-xs);
    }

    &:focus {
      --button-border: 1px solid var(--button-secondary-color-border);
      background: var(--button-secondary-color-bg);
      box-shadow: var(--ring-brand-shadow-xs);
      outline: none;
    }

    &[disabled] {
      --button-border: 1px solid var(--border-color-disabled_subtle);
      background: var(--background-color-primary);
      color: var(--foreground-color-disabled);
      box-shadow: var(--shadow-xs);
    }
  }

  &[data-kind="${ButtonKind.Tertiary}"] {
    --button-border-radius: var(--border-radius-md, 0.5rem);
    background: transparent; /* danorz - had to manually add this */
    color: var(--button-tertiary-color-fg);

    &:hover {
      background: var(--button-tertiary-color-bg_hover);
      color: var(--button-tertiary-color-fg_hover);
    }

    &[disabled] {
      background: var(--background-color-primary);
      color: var(--foreground-color-disabled);
    }
  }

  &[data-kind="${ButtonKind.Link}"] {
    display: inline-flex;
    padding: var(--spacing-none, 0rem);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-sm, 0.375rem);
    color: var(--button-tertiary-color-fg);
    background: transparent; /* danorz - had to manually add this */
    cursor: pointer; /* danorz - had to manually add this */

    &:hover {
      color: var(--button-tertiary-color-fg_hover);
    }

    &:focus {
    }

    &[disabled] {
      color: var(--foreground-color-disabled);
      cursor: not-allowed;
    }
  }

  &&[data-destructive="true"] {
    &[data-kind="${ButtonKind.Primary}"] {
      --button-border: 1px solid var(--button-primary-error-border);
      background: var(--button-primary-error-bg);
      color: var(--foreground-color-white);
      box-shadow: var(--shadow-xs);

      &:hover {
        --button-border: 1px solid var(--button-primary-error-border_hover);
        background: var(--button-primary-error-bg_hover);
        box-shadow: var(--shadow-xs);
      }

      &:focus {
        --button-border: 1px solid var(--button-primary-error-border);
        background: var(--button-primary-error-bg);
        box-shadow: var(--ring-error-shadow-xs);
      }

      &[disabled] {
        --button-border: 1px solid var(--border-color-disabled_subtle);
        background: var(--background-color-disabled);
        box-shadow: var(--shadow-xs);
      }
    }

    &[data-kind="${ButtonKind.Secondary}"] {
      --button-border: 1px solid var(--button-secondary-error-border);
      background: var(--button-secondary-error-bg);
      color: var(--button-secondary-error-fg);
      box-shadow: var(--shadow-xs);

      &:hover {
        --button-border: 1px solid var(--button-secondary-error-border_hover);
        background: var(--button-secondary-error-bg_hover);
        color: var(--button-secondary-error-fg_hover);
        box-shadow: var(--shadow-xs);
      }

      &:focus {
        --button-border: 1px solid var(--button-secondary-error-border);
        background: var(--button-secondary-error-bg);
        box-shadow: var(--ring-error-shadow-xs);
      }

      &[disabled] {
        --button-border: 1px solid var(--border-color-disabled_subtle);
        background: var(--background-color-primary);
        color: var(--foreground-color-disabled);
        box-shadow: var(--shadow-xs);
      }
    }

    &[data-kind="${ButtonKind.Tertiary}"] {
      color: var(--button-tertiary-error-fg);

      &:hover {
        background: var(--button-tertiary-error-bg_hover);
        color: var(--button-tertiary-error-fg_hover);
      }

      &:focus {
      }

      &[disabled] {
        color: var(--foreground-color-disabled);
      }
    }

    &[data-kind="${ButtonKind.Link}"] {
      color: var(--button-tertiary-error-fg);
      background: transparent; /* danorz - had to add this manually */
      cursor: pointer; /* danorz - had to add this manually */

      &:hover {
        color: var(--button-tertiary-error-fg_hover);
      }

      &:focus {
      }

      &[disabled] {
        color: var(--foreground-color-disabled);
      }
    }
  }
`;

const toggleButtonStates = css`
  --button-border-radius: var(--border-radius-md, 0.5rem);
  --button-border: 1px solid var(--border-color-primary);
  background: var(--background-color-primary);
  color: var(--text-color-secondary);
  box-shadow: var(--shadow-xs);

  &:hover {
    background: var(--background-color-primary_hover);
    color: var(--text-color-secondary_hover);
  }

  &:focus {
    box-shadow: var(--ring-brand-shadow-xs);
    outline: none;
  }

  &[disabled] {
    --button-border: 1px solid var(--border-color-disabled_subtle);
    background: var(--background-color-primary);
    color: var(--foreground-color-disabled);
  }

  &[data-selected] {
    --button-border: 1px solid var(--border-color-brand-solid);
    background: var(--background-color-brand-solid);
    color: var(--foreground-color-white);

    &:hover {
      --button-border: 1px solid var(--button-primary-border_hover);
      background: var(--background-color-brand-solid_hover);
    }

    &:focus {
      box-shadow: var(--ring-brand-shadow-xs);
      outline: none;
    }

    &[disabled] {
      --button-border: 1px solid var(--border-color-disabled_subtle);
      color: var(--foreground-color-disabled);
      background: var(--background-color-disabled);
    }
  }
`;

const Button = (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const { children, className, disabled, postIcon, preIcon, ...otherProps } =
    props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const mergedRef = useObjectRef(
    useMemo(() => mergeRefs(ref, buttonRef), [ref]),
  );

  // TODO: remove this when https://github.com/adobe/react-spectrum/issues/6159 is fixed and released
  useLayoutEffect(() => {
    const el = buttonRef.current;
    if (!el) return;

    if (props["aria-current"] == null && el.hasAttribute("aria-current")) {
      el.removeAttribute("aria-current");
    } else if (props["aria-current"] != null) {
      el.setAttribute("aria-current", String(props["aria-current"]));
    }
    // No dependency array here. Any render could update the button attributes,
    // so we need this to run on every render.
  });

  return (
    <ReactAriaButton
      ref={mergedRef}
      className={cx(className, buttonBase, buttonSizes, buttonKinds)}
      isDisabled={disabled}
      {...otherProps}
    >
      {(values) => (
        <>
          {preIcon ? (
            <span title={otherProps["title"] ?? otherProps["aria-label"]}>
              <Icon data-button-icon family="untitled" name={preIcon} />
            </span>
          ) : null}
          {typeof children === "function" ? children(values) : children}
          {postIcon ? (
            <span title={otherProps["title"] ?? otherProps["aria-label"]}>
              <Icon data-button-icon family="untitled" name={postIcon} />
            </span>
          ) : null}
        </>
      )}
    </ReactAriaButton>
  );
};

export default forwardRef(Button);

const ToggleButton = (
  props: ToggleButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const { children, className, disabled, postIcon, preIcon, ...otherProps } =
    props;

  return (
    <ReactAriaToggleButton
      ref={ref}
      className={cx(className, buttonBase, buttonSizes, toggleButtonStates)}
      isDisabled={disabled}
      {...otherProps}
    >
      {({ isSelected }) => (
        <>
          {preIcon ? (
            <Icon
              data-button-icon
              family="untitled"
              name={
                typeof preIcon === "function" ? preIcon(isSelected) : preIcon
              }
            />
          ) : null}
          {typeof children === "function" ? children(isSelected) : children}
          {postIcon ? (
            <Icon
              data-button-icon
              family="untitled"
              name={
                typeof postIcon === "function" ? postIcon(isSelected) : postIcon
              }
            />
          ) : null}
        </>
      )}
    </ReactAriaToggleButton>
  );
};

const LinkButton = (
  props: LinkButtonProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) => {
  const { children, className, preIcon, postIcon, ...otherProps } = props;
  const linkRef = useRef<HTMLAnchorElement>(null);
  const mergedRef = useObjectRef(useMemo(() => mergeRefs(ref, linkRef), [ref]));

  return (
    <StyledLink
      ref={mergedRef}
      className={cx(className, buttonBase, buttonSizes, buttonKinds)}
      {...useAnchorProps(otherProps, mergedRef)}
    >
      {preIcon ? (
        <span title={otherProps["title"] ?? otherProps["aria-label"]}>
          <Icon data-button-icon family="untitled" name={preIcon} />
        </span>
      ) : null}
      {children}
      {postIcon ? (
        <span title={otherProps["title"] ?? otherProps["aria-label"]}>
          <Icon data-button-icon family="untitled" name={postIcon} />
        </span>
      ) : null}
    </StyledLink>
  );
};

const ForwardedToggleButton = forwardRef(ToggleButton);
const ForwardedLinkButton = forwardRef(LinkButton);

export {
  ForwardedToggleButton as ToggleButton,
  ForwardedLinkButton as LinkButton,
};
