export enum LoggerIntegrations {
  Console = "console",
  Remote = "remote",
  Sentry = "sentry",
}

/* we want explicit enum values here so that we can ensure the different log levels are in the
 * correct relative priority order */
export enum LogLevel {
  Debug = 1,
  Log = 2,
  Warn = 3,
  Error = 4,
}

export type LoggerLevels = Record<LoggerIntegrations, LogLevel | false>;

export interface LoggerOpts {
  integrations?: LoggerLevels;
}
export type BaseLoggerFunction = (...msg: any[]) => void;
export type LoggerFunction = (level: LogLevel, ...msg: any[]) => void;
