import {
  type ContextComponent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const ModalContext = createContext({
  add() {},
  remove() {},
});

const ModalProvider: ContextComponent = ({ children }) => {
  const [count, setCount] = useState<number>(0);
  const add = useCallback(() => setCount((c) => c + 1), []);
  const remove = useCallback(() => setCount((c) => c - 1), []);

  useEffect(() => {
    if (count === 0) {
      document
        .querySelector("#modal-dialog")
        ?.setAttribute("aria-hidden", "true");
      document.querySelector("#dialog")?.removeAttribute("aria-hidden");
      document.querySelector("#app")?.removeAttribute("aria-hidden");
    } else {
      document.querySelector("#modal-dialog")?.removeAttribute("aria-hidden");
      document.querySelector("#dialog")?.setAttribute("aria-hidden", "true");
      document.querySelector("#app")?.setAttribute("aria-hidden", "true");
    }
  }, [count]);

  return (
    <ModalContext.Provider value={{ add, remove }}>
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext);

export { ModalProvider, useModal };
