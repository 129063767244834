import { styled } from "@linaria/react";
import {
  useCallback,
  type FunctionComponent,
  type HTMLAttributes,
  type ReactNode,
} from "react";
import { Heading } from "react-aria-components";

import { DialogHeading, DialogXButton } from "~/components/Dialog";
import Section from "~/components/Section";
import { ButtonSize } from "~/components/library/Button";
import Icon from "~/components/library/Icon";
import IconButton, { ButtonKind } from "~/components/library/IconButton";
import { Truncated } from "~/components/text";
import { text } from "~/styles/typography";

const Container = styled.div`
  width: 300px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: var(--border-radius-xs);
  box-shadow: var(--shadow-sm);
`;

const StyledHeading = styled(DialogHeading)`
  display: flex;
  justify-content: space-between;
  background-color: var(--color-blue-950);
  align-items: center;
  justify-content: space-between;
  color: var(--text-color-white);
  padding: var(--spacing-xs) var(--spacing-md);

  > div {
    display: flex;
    gap: var(--spacing-sm);
    align-items: center;
  }

  ${DialogXButton} {
    padding: 0;
  }
`;

const HeadingText = styled(Heading)`
  display: flex;
  color: var(--text-color-white);
  ${text.sm.medium}
`;

const Content = styled.div`
  background-color: var(--background-color-brand-primary);
`;

interface BaseProfileProps extends HTMLAttributes<HTMLDivElement> {
  heading: string;
  iconName?: UntitledIcon;
  onDismiss?: () => void;
}

const BaseProfile: FunctionComponent<BaseProfileProps> = (props) => {
  const { heading, iconName, onDismiss, ...rest } = props;

  return (
    <Container {...rest}>
      <StyledHeading className="dialog-heading">
        <div>
          {iconName && <Icon family="untitled" name={iconName} />}
          <HeadingText>{heading}</HeadingText>
        </div>
        <DialogXButton onPress={onDismiss} />
      </StyledHeading>
      <Content id="profile-content">{props.children}</Content>
    </Container>
  );
};

export interface InfoProps {
  content?: ReactNode;
  text: string;
}

interface InfoDisplayProps {
  data: InfoProps;
}

const StyledSection = styled(Section)`
  display: flex;
  border-radius: var(--border-radius-xxs);
  background-color: var(--background-color-primary);
  padding: var(--spacing-lg);
  justify-content: space-between;
  align-items: center;
  ${text.sm.regular}
`;

const CopyButton = styled(IconButton)`
  > span {
    display: flex;
    align-items: center;
  }
`;

export const InfoDisplay: FunctionComponent<InfoDisplayProps> = (props) => {
  const { data } = props;

  const copyToClipboard = useCallback(async () => {
    await navigator.clipboard.writeText(data.text);
  }, [data.text]);

  return (
    <StyledSection>
      <Truncated text={data.text}>{data.content ?? data.text}</Truncated>
      <CopyButton
        aria-label="copy text to clipboard"
        data-kind={ButtonKind.Tertiary}
        data-size={ButtonSize.sm}
        icon="copy-01"
        onPress={copyToClipboard}
      />
    </StyledSection>
  );
};

export default BaseProfile;
