import { useMemo, type FunctionComponent } from "react";

import { BaseUserProfile } from "~/components/UserNodePopUp";
import { useUserData } from "~/hooks/useUserNodePopUp";

export interface AccountProfileProps {
  conversationId: string;
  accountId: string;
  type: "accounts";
  onDismiss?: () => void;
}

function useAccountProfile(props: AccountProfileProps) {
  const { accountId, conversationId, onDismiss } = props;
  const { data = {} } = useUserData({
    conversationId: conversationId,
    rid: accountId,
  });

  const { additional_properties, class: cls, id, ...standardUserData } = data;
  const { created_at = "", location = "" } = standardUserData;
  const showPopUpData = Object.values(standardUserData)
    .filter(Boolean)
    .some((x) => x.length > 0);
  const additionalProperties = useMemo(
    () =>
      Object.entries(additional_properties ?? {})
        .filter(([, v]) => Boolean(v))
        .sort((a, b) => a[0].toLowerCase().localeCompare(b[0].toLowerCase())),
    [additional_properties],
  );
  const noData =
    !(created_at || location) && !(additionalProperties.length > 0);

  return {
    additionalProperties,
    created_at,
    data,
    location,
    onDismiss,
    noData,
    showPopUpData,
  };
}

export const AccountProfile: FunctionComponent<AccountProfileProps> = (
  props,
) => {
  const {
    additionalProperties,
    created_at,
    data,
    location,
    onDismiss,
    noData,
    showPopUpData,
  } = useAccountProfile(props);

  return (
    <BaseUserProfile
      additionalProperties={additionalProperties}
      created_at={created_at}
      location={location}
      noData={noData}
      onDismiss={onDismiss}
      showPopUpData={showPopUpData}
      userDetails={data}
    />
  );
};
