import type { FunctionComponent } from "react";

import { infoByNodeType } from "../../constants";
import type { QueryDomain } from "../../types";

import BaseProfile, { InfoDisplay, type InfoProps } from "./BaseProfile";

export interface DomainProfileProps {
  conversationId: string;
  type: "domains";
  onDismiss?: () => void;
  data: QueryDomain;
}

function useDomainProfile(props: DomainProfileProps) {
  const { data, onDismiss } = props;

  const formattedData: InfoProps = {
    text: data.body ?? "",
  };

  return {
    data: formattedData,
    onDismiss,
  };
}

export const DomainProfile: FunctionComponent<DomainProfileProps> = (props) => {
  const { data, onDismiss } = useDomainProfile(props);

  return (
    <BaseProfile
      heading="Domain"
      iconName={infoByNodeType.domains.iconName}
      onDismiss={onDismiss}
    >
      <InfoDisplay data={data} />
    </BaseProfile>
  );
};
