import { styled } from "@linaria/react";
import { type ScaleOrdinal } from "d3";
import { type FunctionComponent } from "react";

import { Heading } from "~/components/Section";
import { text } from "~/styles/typography";

import { MAX_WEIGHT } from "../constants";

const LEGEND_NUMBERS = Array.from(
  { length: MAX_WEIGHT },
  (_, index) => index + 1,
);

const LegendContainer = styled.figcaption`
  flex-direction: row;
  width: fit-content;
  height: fit-content;
  user-select: none;
  pointer-events: none;
  display: flex;
  background: var(--color-white);
  box-shadow: var(--shadow-lg);
  border-radius: var(--border-radius-md);
`;

const LegendHeading = styled(Heading)`
  display: flex;
  align-items: center;
  ${text.xs.regular};
  padding: 0 var(--spacing-lg);
  border-right: var(--border-group);
`;

const LabelList = styled.ul`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-lg);
  padding: var(--spacing-md) var(--spacing-lg);
`;

const LabelItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${text.xs.regular};

  > div {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-xs);
    align-items: center;
  }
`;

const ColorBlock = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  color: var(--color-white);
  height: 10px;
  width: 20px;
  padding: 0 var(--spacing-xs);
`;

interface LegendProps {
  linkColorScale: ScaleOrdinal<number, string, never>;
}

export const Legend: FunctionComponent<LegendProps> = (props) => {
  const { linkColorScale } = props;

  return (
    <LegendContainer>
      <LegendHeading>Evidence</LegendHeading>
      <LabelList>
        {LEGEND_NUMBERS.map((num) => (
          <LabelItem key={num}>
            <div>
              <span>{num}</span>
              <ColorBlock color={linkColorScale(num)} />
            </div>
          </LabelItem>
        ))}
      </LabelList>
    </LegendContainer>
  );
};
