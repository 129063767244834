import { styled } from "@linaria/react";
import { useCallback, useMemo, type FunctionComponent } from "react";
import { type PressEvent } from "react-aria";

import { useProfileDialogContext } from "~/components/ProfileDialogContext";
import Section, { Heading } from "~/components/Section";
import { type TableColumn } from "~/components/Table";
import { BaseUserProfile, StyledDialogTable } from "~/components/UserNodePopUp";
import Button, { ButtonKind, ButtonSize } from "~/components/library/Button";
import { useConversationFilters } from "~/hooks/useConversationFilters";
import { useNodeProfile } from "~/hooks/useCoordination";
import { useUserData } from "~/hooks/useUserNodePopUp";
import { text } from "~/styles/typography";

import { type NodeCoordination } from "../types";

interface AccountsTableData {
  screen_name: string;
  matches: number;
  rid: string;
  profile_url: string;
  clusterId: string;
}

function getLowerAndHigherRIDs(rid1: string, rid2: string) {
  const [lowerRid, higherRid] = [rid1, rid2].sort();
  return { lowerRid, higherRid };
}

const AccountsBox = styled(Section)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0;
`;

const AccountsLabel = styled(Heading)`
  padding: var(--spacing-md);
  ${text.md.semibold};
  ${text.sm.regular};

  > span {
    margin-left: var(--gutter-25);
    ${text.xs.regular};
    color: var(--color-gray-500);
  }
`;

const EdgePopupButton = styled(Button)`
  color: magenta;
`;

const getUserColumnData = (
  onClick: (e: PressEvent) => void,
): TableColumn<AccountsTableData>[] => [
  {
    field: "name",
    label: "",
    filterable: false,
    sortable: false,
    renderFn: ({ data }) => (
      <EdgePopupButton
        data-for={data.rid}
        data-kind={ButtonKind.Tertiary}
        data-size={ButtonSize.sm}
        onPress={onClick}
        type="button"
      >
        {data.screen_name}
      </EdgePopupButton>
    ),
  },
  {
    field: "matches",
    label: "Evidence",
    filterable: false,
  },
];

const useNodePopUp = (props: NodeCoordination) => {
  const { conversationId, clusterId, userRid } = props;
  const filters = useConversationFilters();
  const { data: nodeProfileData } = useNodeProfile(
    clusterId,
    conversationId,
    userRid,
    filters,
  );

  const { data = {} } = useUserData({
    conversationId,
    rid: userRid,
  });
  const {
    additional_properties,
    class: cls,
    id,
    rid,
    ...standardUserData
  } = data;
  const { created_at = "", location = "" } = standardUserData;
  const profileDialogContext = useProfileDialogContext();
  const { setCoordinationData, clearProfileData } = profileDialogContext;
  const rowData: AccountsTableData[] = useMemo(() => {
    const rowData = nodeProfileData?.data?.connected_accounts.map(
      (account) => ({
        screen_name: account.screen_name,
        matches: account.weight.coordination_types,
        rid: account.rid,
        profile_url: account.profile_url,
        clusterId,
      }),
    );

    return rowData ?? [];
  }, [clusterId, nodeProfileData?.data?.connected_accounts]);
  const handleOnClick = useCallback(
    (event: PressEvent) => {
      const target = event.target;
      const clickedRid = target.getAttribute("data-for");

      if (!clickedRid || typeof clickedRid !== "string") {
        return;
      }

      // TODO: user_i and user_j is assigned by their RIDS. Look at this once we translate to snowflake.
      const { lowerRid, higherRid } = getLowerAndHigherRIDs(
        userRid,
        clickedRid,
      );

      setCoordinationData({
        type: "edge",
        clusterId: clusterId,
        conversationId: conversationId,
        sourceUserRid: lowerRid,
        targetUserRid: higherRid,
        onDismiss: clearProfileData,
      });
    },
    [clearProfileData, clusterId, conversationId, setCoordinationData, userRid],
  );
  const columnData = useMemo(() => {
    return conversationId ? getUserColumnData(handleOnClick) : [];
  }, [conversationId, handleOnClick]);
  const showPopUpData = Object.values(standardUserData).some(
    (x) => x.length > 0,
  );
  const additionalProperties = useMemo(
    () =>
      Object.entries(additional_properties ?? {}).sort((a, b) =>
        a[0].toLowerCase().localeCompare(b[0].toLowerCase()),
      ),
    [additional_properties],
  );
  const noData =
    !(created_at || location) &&
    !(additionalProperties.length > 0) &&
    !(rowData.length > 0);

  return {
    additionalProperties,
    created_at,
    location,
    noData,
    showPopUpData,
    data,
    rowData,
    columnData,
  };
};

export const NodePopUp: FunctionComponent<NodeCoordination> = (props) => {
  const { onDismiss } = props;
  const {
    additionalProperties,
    created_at,
    location,
    noData,
    showPopUpData,
    data,
    rowData,
    columnData,
  } = useNodePopUp(props);

  return (
    <BaseUserProfile
      additionalProperties={additionalProperties}
      created_at={created_at}
      location={location}
      noData={noData}
      onDismiss={onDismiss}
      showPopUpData={showPopUpData}
      userDetails={data}
    >
      {rowData.length > 0 && (
        <AccountsBox aria-describedby="node-popup-connected-accounts">
          <AccountsLabel id="node-popup-connected-accounts">
            Connected Accounts
            <span>{`(${rowData.length})`}</span>
          </AccountsLabel>
          <StyledDialogTable
            aria-labelledby="node-popup-connected-accounts"
            disableFilter
            id="connected-accounts-table"
            tableColumns={columnData}
            tableData={rowData}
          />
        </AccountsBox>
      )}
    </BaseUserProfile>
  );
};
