import { useMemo } from "react";

import {
  type URLNodeUsersInfoResponseDTO,
  URLNodeUsersInfoResponseValidator,
} from "~/dto/URLNodeUsersInfoResponse";
import {
  type DomainDetailsResponseDTO,
  DomainDetailsResponseValidator,
} from "~/dto/domainDetailsResponse";

import { useRemoteObject } from "./useRemoteData";

const useURLNodeUsersData = (
  conversationId: string | undefined,
  url: string,
) => {
  const apiEndpoint = useMemo(() => {
    if (!conversationId) {
      return undefined;
    }

    const params = new URLSearchParams({
      conversation_id: conversationId,
      url,
    });

    return `/api/v2/analysis/url-node-users-info?${params}`;
  }, [conversationId, url]);

  return useRemoteObject<URLNodeUsersInfoResponseDTO>(apiEndpoint, {
    schemaValidator: URLNodeUsersInfoResponseValidator,
  });
};

const useDomainDetailsData = (
  domain: string,
  conversationId: string | undefined,
  history = false,
  maxResults = 10,
) => {
  const apiEndpoint = useMemo(() => {
    if (!conversationId) {
      return undefined;
    }

    const params = new URLSearchParams({
      conversation_id: conversationId,
    });

    if (history || maxResults !== 10) {
      params.append("history", history.toString());
      params.append("maxResults", maxResults.toString());
    }

    return `/api/v2/analysis/domain-details/${domain}?${params}`;
  }, [conversationId, domain, history, maxResults]);

  return useRemoteObject<DomainDetailsResponseDTO>(apiEndpoint, {
    schemaValidator: DomainDetailsResponseValidator,
  });
};

export { useURLNodeUsersData, useDomainDetailsData };
