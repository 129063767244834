import { extent } from "d3-array";
import { gql } from "graphql-request";
import snakeCase from "lodash/snakeCase";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  type CustomTableFilterEvent,
  type CustomTableSortEvent,
} from "~/components/Table";
import { type PageChangeData } from "~/components/library/Pagination";
import type { MetadataDTO } from "~/dto/metadata";
import { type RawDataDTO, RawDataListWithEnvelope } from "~/dto/rawData";
import type { RawDataGraphDTO } from "~/dto/rawDataGraph";
import type { RawDataGraphResponseDTO } from "~/dto/rawDataGraphResponse";
import { RawDataListValidator, type RawDataListDTO } from "~/dto/rawDataList";
import {
  type RawDetailsResponseDTO,
  RawDetailsResponseValidator,
} from "~/dto/rawDetailsResponse";
import {
  UserDetailsListResponseValidator,
  type UserDetailsListResponseDTO,
} from "~/dto/userDetailsListResponse";
import {
  type UserDetailsResponseDTO,
  UserDetailsResponseValidator,
} from "~/dto/userDetailsResponse";
import type { UserPreferenceDTO } from "~/dto/userPreference";
import {
  type DateRange,
  dateToCalendarDate,
  pythonISOString,
} from "~/utils/datetime";

import {
  type ConversationFilters,
  getConversationFilterRequestParams,
  useConversationFilters,
} from "./useConversationFilters";
import useHashParam from "./useHashParam";
import { type PaginationOptions, usePagination } from "./usePagination";
import { useRemoteAction, useRemoteObject } from "./useRemoteData";
import { useRemoteQuery } from "./useRemoteQuery";
import { useUserPreference } from "./useUserPreference";

export type RawDataPaginationOptions = PaginationOptions & {
  /** ISO-8601 with timezone offset */
  timeRangeEnd?: string;
  /** ISO-8601 with timezone offset */
  timeRangeStart?: string;
};

export type RawDataPaginationInfo = MetadataDTO["pagination_info"] & {
  limit: number;
  offset: number;
  /** ISO-8601 with timezone offset */
  timeRangeEnd?: string;
  /** ISO-8601 with timezone offset */
  timeRangeStart?: string;
  total: number;
};

/* the narrative name also gets added to each record we display in a table */
export interface RawDataSampleDTO extends RawDataDTO {
  narrative: string;
}

/* raw data is (currently) immutable so there's no need to sync with the backend
 * once the initial fetching has completed */
const rawDataCacheOpts = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const useRawDataPagination = (
  params: URLSearchParams,
  opts: RawDataPaginationOptions,
) => {
  usePagination(opts, params);

  if ("timeRangeEnd" in opts) {
    params.set("end_range", String(opts.timeRangeEnd));
  }

  if ("timeRangeStart" in opts) {
    params.set("start_range", String(opts.timeRangeStart));
  }
};

const useRawData = (
  conversationId: string,
  filters: ConversationFilters | null,
  isCoordination = false,
  paginationOptions: RawDataPaginationOptions = {},
) => {
  const params = getConversationFilterRequestParams(filters ?? undefined);

  useRawDataPagination(params, paginationOptions);

  const apiEndpoint = useMemo(() => {
    if (!conversationId) {
      return undefined;
    }

    if (isCoordination) {
      params.set("coordination_only", "true");
    }

    return `/api/v2/conversations/${conversationId}/raw_data?${params}`;
  }, [conversationId, isCoordination, params]);
  const {
    data: rawData,
    error,
    isLoading,
  } = useRemoteObject(apiEndpoint, {
    cacheOpts: rawDataCacheOpts,
    schemaValidator: RawDataListWithEnvelope,
  });
  const { data: records, metadata = { pagination_info: { total: 0 } } } =
    rawData ?? {};
  const { availableDataSources, availableDateRange, data } = useMemo(() => {
    if (!records) {
      return {
        availableDataSources: [],
        availableDateRange: undefined,
        data: undefined,
      };
    }

    const dateRange = extent(
      records,
      (record) => new Date(record.published_at),
    );

    return {
      availableDataSources: Array.from(
        new Set(records.map((datum) => datum.class)),
      ),
      availableDateRange:
        dateRange[0] !== undefined && dateRange[1] !== undefined
          ? dateRange
          : undefined,
      data: records,
    };
  }, [records]);

  return {
    availableDataSources,
    availableDateRange,
    data,
    error,
    isLoading,
    metadata,
  };
};

const useRawDataQuery = (
  conversationId: string,
  filters: ConversationFilters | null,
  opts: RawDataPaginationOptions = {},
) => {
  const params = getConversationFilterRequestParams(filters ?? undefined);

  const { limit, offset, order } = opts;

  // graphQL requires limit, offset, and order_by passed in as variables instead of URL Params
  useRawDataPagination(params, opts);
  params.delete("limit");
  params.delete("offset");
  params.delete("order_by");

  const {
    data: rawData,
    error,
    isLoading,
  } = useRemoteQuery<RawDataGraphResponseDTO>(
    `/api/v2/graphql?conversation_id=${conversationId}&${params}`,
    getRawDataQuery,
    {
      limit,
      offset,
      orderBy: order?.map((o) => `${snakeCase(o.column)}:${o.direction}`),
    },
  );

  const { posts: records, metadata = { pagination_info: { total: 0 } } } =
    rawData ?? {};

  const { availableDataSources, availableDateRange, data } = useMemo(() => {
    if (!records) {
      return {
        availableDataSources: [],
        availableDateRange: undefined,
        data: undefined,
      };
    }

    const dateRange = extent(
      records,
      (record) => new Date(record.publishedAt ?? ""),
    );

    return {
      availableDataSources: Array.from(
        new Set(records.map((datum) => datum.type)),
      ),
      availableDateRange:
        dateRange[0] !== undefined && dateRange[1] !== undefined
          ? dateRange
          : undefined,
      data: records,
    };
  }, [records]);

  return {
    availableDataSources,
    availableDateRange,
    data,
    error,
    isLoading,
    metadata,
  };
};

const useExpandedRowDetails = (rid: string, conversationId?: string) => {
  const endpoint = conversationId
    ? `/api/v2/posts/${rid}/details?conversation_id=${conversationId}`
    : undefined;

  return useRemoteObject<RawDetailsResponseDTO>(endpoint, {
    cacheOpts: rawDataCacheOpts,
    schemaValidator: RawDetailsResponseValidator,
  });
};

const useRawDataCreatorDetails = ({
  conversationId,
  rid,
}: {
  conversationId?: string;
  rid: string | undefined;
}) => {
  const apiEndpoint = rid
    ? `/api/v2/accounts/${rid}/details?${
        conversationId ? `conversation_id=${conversationId}` : ``
      }`
    : undefined;
  const remoteObject = useRemoteObject<UserDetailsResponseDTO>(apiEndpoint, {
    schemaValidator: UserDetailsResponseValidator,
    cacheOpts: {
      dedupingInterval: 30 * 1000, // 30 seconds
    },
  });

  return {
    ...remoteObject,
    data: remoteObject.data?.data,
  };
};

const useRawDataCreatorDetailsBatch = ({
  conversationId,
  rids,
}: {
  conversationId?: string;
  rids: string[] | undefined;
}) => {
  const apiEndpoint =
    rids && rids.length > 1
      ? `/api/v2/accounts/details_list?account_ids=${`${encodeURIComponent(
          JSON.stringify(rids),
        )}`}${conversationId ? `&conversation_id=${conversationId}` : ``}`
      : undefined;
  const remoteObject = useRemoteObject<UserDetailsListResponseDTO>(
    apiEndpoint,
    {
      schemaValidator: UserDetailsListResponseValidator,
      cacheOpts: {
        dedupingInterval: 30 * 1000, // 30 seconds
      },
    },
  );

  return {
    ...remoteObject,
    data: remoteObject.data?.data,
  };
};

function isRawDataPaginationInfo(obj: unknown): obj is RawDataPaginationInfo {
  return (
    obj !== null && typeof obj === "object" && "limit" in obj && "offset" in obj
  );
}

function isRawDataTableOrdering(
  value: unknown,
): value is UserPreferenceDTO["raw_data_table_ordering"] {
  return (
    value !== null &&
    typeof value === "object" &&
    "column_fields" in value &&
    typeof value.column_fields === "object" &&
    value.column_fields !== null &&
    "type" in value.column_fields &&
    "creator" in value.column_fields &&
    "engagementScore" in value.column_fields &&
    "firstNgram" in value.column_fields &&
    "sourceUrl" in value.column_fields &&
    "entities" in value.column_fields &&
    "threatsOfViolence" in value.column_fields &&
    "publishedAt" in value.column_fields &&
    "post_content_fields" in value &&
    typeof value.post_content_fields === "object" &&
    value.post_content_fields !== null &&
    "results_per_page" in value
  );
}

const rawDataWithFirstNgram = (data: RawDataGraphDTO[] | undefined) =>
  data?.map((r) => ({
    ...r,
    narrative: r.firstNgram ?? "",
  }));

const useConversationRawData = (conversationId: string) => {
  const filters = useConversationFilters();
  const params = getConversationFilterRequestParams(filters);
  const initialDateRange: DateRange = useMemo(
    () => ({
      start: dateToCalendarDate(filters.start_range),
      end: dateToCalendarDate(filters.end_range),
    }),
    [filters.start_range, filters.end_range],
  );

  const { rawDataTableOrdering, updateRawDataTableOrdering } =
    useUserPreference();

  const [paginationOptions, setPaginationOptions] =
    useState<RawDataPaginationOptions>({
      limit: rawDataTableOrdering?.results_per_page || 20,
      offset: 0,
    });

  const [currentPage, setCurrentPage] = useState<RawDataGraphDTO[]>();
  const paginationInfo = useRef<RawDataPaginationInfo>({
    limit: paginationOptions.limit ?? -1,
    offset: paginationOptions.offset ?? 0,
    total: 0,
  });
  const rawData = useRawDataQuery(
    conversationId,
    useConversationFilters(),
    paginationOptions,
  );
  const { data, isLoading, metadata } = rawData;
  // TODO: if this action is ever executed then the frontend will have a
  // complete copy of raw data for this conversation, at which point we don't
  // have to make api requests for individual pages of records.
  // provide a flag to consumers to tell them that this is a full dataset and
  // to use the table library's own sorting/filtering/pagination/etc
  const getAllRawDataAction = useRemoteAction<void, RawDataListDTO>(
    `/api/v2/conversations/${conversationId}/raw_data?${params}`,
    {
      schemaValidator: RawDataListValidator,
      verb: "GET",
    },
  );
  const onPageChange = useCallback((paginationEvent: PageChangeData) => {
    setPaginationOptions((current) => ({
      ...current,
      offset: paginationEvent.startRow,
    }));
  }, []);
  const getAllRawData = useCallback(async () => {
    const allRawData = await getAllRawDataAction.execute();

    if (!allRawData) {
      throw new Error(
        "an error occurred while exporting raw data.  Please try again later.",
      );
    }

    return allRawData.data;
  }, [getAllRawDataAction]);
  const onCustomSort = useCallback((sortEvent: CustomTableSortEvent) => {
    setPaginationOptions((current) => {
      if (sortEvent.sorted) {
        return {
          ...current,
          offset: 0, // reset current page
          order: [
            {
              column: sortEvent.field,
              direction: sortEvent.sorted,
            },
          ],
        };
      }

      const { order, ...unsorted } = current;

      return unsorted;
    });
  }, []);
  const onCustomFilter = useCallback((filterEvent: CustomTableFilterEvent) => {
    setPaginationOptions((current) => {
      const { columnSearch, ...unfiltered } = current;
      const nextFilters =
        columnSearch?.filter((c) => c.column !== filterEvent.field) ?? [];

      if (filterEvent.query?.length) {
        return {
          ...unfiltered,
          columnSearch: [
            ...nextFilters,
            { column: filterEvent.field, query: filterEvent.query },
          ],
          offset: 0, // reset current page
        };
      } else if (nextFilters.length) {
        return {
          ...unfiltered,
          columnSearch: nextFilters,
        };
      } else {
        return unfiltered;
      }
    });
  }, []);
  const onGlobalSearch = useCallback(
    (isAcrossColumns: boolean, searchString: string) => {
      setPaginationOptions((current) => {
        const { globalSearch, columnSearch, ...next } = current;
        const nonBodyColumnSearch = columnSearch?.filter(
          (col) => col.column !== "body",
        );

        return searchString
          ? isAcrossColumns
            ? {
                ...next,
                globalSearch: searchString,
                columnSearch: nonBodyColumnSearch,
                offset: 0, // reset current page
              }
            : {
                ...next,
                offset: 0,
                columnSearch: [
                  ...(nonBodyColumnSearch || []),
                  { column: "body", query: searchString },
                ],
              }
          : {
              ...next,
              columnSearch: nonBodyColumnSearch,
              offset: 0, // reset current page
            };
      });
    },
    [],
  );
  const onDateRangeChange = useCallback((nextDateRange: DateRange) => {
    setPaginationOptions((current) => {
      return {
        ...current,
        offset: 0, // reset current page
        timeRangeEnd: pythonISOString(nextDateRange.end),
        timeRangeStart: pythonISOString(nextDateRange.start),
      };
    });
  }, []);

  useEffect(() => {
    if (data && metadata && !isLoading) {
      setCurrentPage(rawDataWithFirstNgram(data));

      if (isRawDataPaginationInfo(metadata.pagination_info)) {
        paginationInfo.current = metadata.pagination_info;
      }
    }
  }, [data, isLoading, metadata]);

  const onSettingModalSubmit = useCallback(
    (
      columnFields: Record<
        string,
        UserPreferenceDTO["raw_data_table_ordering"]["column_fields"]["type"]
      >,
      postContentFields: Record<string, boolean>,
      resultsPerPage: number,
    ) => {
      const rawDataOrdering = {
        column_fields: columnFields,
        post_content_fields: postContentFields,
        results_per_page: resultsPerPage,
      };
      if (isRawDataTableOrdering(rawDataOrdering)) {
        setPaginationOptions((current) => ({
          ...current,
          limit: resultsPerPage,
        }));
        updateRawDataTableOrdering(rawDataOrdering);
      }
    },
    [updateRawDataTableOrdering],
  );

  const hashParamValue = useHashParam("posts-search");
  useEffect(() => {
    onGlobalSearch?.(true, hashParamValue ?? "");
  }, [hashParamValue, onGlobalSearch]);

  return {
    ...rawData,
    data: currentPage,
    getAllRawData,
    globalSearch: hashParamValue,
    initialDateRange,
    onCustomFilter,
    onCustomSort,
    onDateRangeChange,
    onGlobalSearch,
    onPageChange,
    paginationInfo: paginationInfo.current,
    paginationOptions,
    rawDataTableOrdering,
    onSettingModalSubmit,
  };
};

const useConversationThreatsOfViolence = (
  conversationId: string,
  count = 10,
  filters?: ConversationFilters,
) => {
  const params = getConversationFilterRequestParams(filters);
  return useRemoteObject(
    `/api/v2/conversations/${conversationId}/raw_data?${params}&limit=${count}&order_by=threats_of_violence:asc`,
    {
      cacheOpts: rawDataCacheOpts,
      schemaValidator: RawDataListWithEnvelope,
    },
  );
};

const useNotablePosts = (
  conversationId: string,
  type: string,
  count = 10,
  filters?: ConversationFilters,
) => {
  const params = getConversationFilterRequestParams(filters);
  const remoteObject = useRemoteObject(
    `/api/v2/insights/notable_posts?${params}&conversation_id=${conversationId}&type=${type}&top_n=${count}`,
    {
      cacheOpts: rawDataCacheOpts,
      schemaValidator: RawDataListWithEnvelope,
    },
  );

  return {
    ...remoteObject,
    data: {
      // FIXME: bandaid: the knowledge-graph query powering this endpoint will
      // return multiple copies of the same raw-data record due to a bad join;
      // remove this when that has been addressed
      data:
        Object.values<RawDataDTO>(
          remoteObject.data?.data.reduce(
            (acc, el) => ({
              ...acc,
              [el.rid]: el,
            }),
            {},
          ) ?? {},
        ) ?? [],
      metadata: remoteObject.data?.metadata ?? {},
    },
  };
};

export {
  useConversationRawData,
  useConversationThreatsOfViolence,
  useExpandedRowDetails,
  useNotablePosts,
  useRawData,
  useRawDataQuery,
  useRawDataCreatorDetails,
  useRawDataCreatorDetailsBatch,
};

const getRawDataQuery = gql`
  query getRawData($limit: Int, $offset: Int, $orderBy: [String!]) {
    paginatedPosts(
      paginationFilters: { limit: $limit, offset: $offset, orderBy: $orderBy }
    ) {
      items {
        accountId
        additionalProperties
        body
        creator
        displayName
        engagementScore
        entities
        firstNgram
        ngrams
        narrative
        id
        images
        publishedAt
        screenName
        sourceUrl
        threatsOfViolence
        type
        urls
        userType
      }
      metadata {
        limit
        offset
        total
        orderBy
      }
    }
  }
`;
