import { styled } from "@linaria/react";
import { type StyledComponent, type FunctionComponent } from "react";

import { type UserDetailsDTO } from "~/dto";
import { text } from "~/styles/typography";

import AccountAvatar from "./AccountAvatar";
import { UserExternalLinkComponent } from "./ConnectionPreview";
import { Heading } from "./Section";
import { ExternalLink } from "./library/ExternalLink";
import { Truncated } from "./text";

type UserPopupOverviewProps = UserDetailsDTO &
  StyledComponent & {
    useExternalLink?: boolean;
  };

const ProfileInfo = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-areas:
    "profile-image screen-name"
    "profile-image display-name"
    "x bio";
  grid-column-gap: var(--spacing-md);

  img {
    width: 48px;
    height: 48px;
    grid-row: 1 / 4;
    grid-column: 1;
    border-radius: 50%;
  }

  [data-auto-header] {
    grid-area: screen-name;
  }
`;

const UserDisplayName = styled(Truncated)`
  grid-row: 2;
  grid-column: 2;
  ${text.xs.bold};
`;

const UserScreenName = styled(Heading)`
  grid-row: 1;
  grid-column: 2;
  font-weight: var(--font-weight-bold);
  ${text.sm.regular};

  a {
    color: var(--color-link);
  }
`;

const UserBio = styled.div`
  grid-row: 3;
  grid-column: 2;
  ${text.xs.regular};
  overflow: hidden;
`;

const UserPopupOverview: FunctionComponent<UserPopupOverviewProps> = (
  props,
) => {
  const {
    avatar_url,
    bio,
    class: cls,
    display_name,
    id,
    screen_name = "",
    url,
    useExternalLink,
    ...otherProps
  } = props;
  const titleId = `profile-details-${cls}-${id}`;

  return (
    <ProfileInfo {...otherProps}>
      <AccountAvatar url={avatar_url} />
      <div data-slot="user-names">
        <UserScreenName id={titleId}>
          <Truncated href={url} text={screen_name ?? ""}>
            {id && useExternalLink ? (
              <UserExternalLinkComponent
                screen_name={screen_name}
                user_rid={id}
              />
            ) : url ? (
              <ExternalLink href={url}>{screen_name}</ExternalLink>
            ) : (
              screen_name
            )}
          </Truncated>
        </UserScreenName>
        <UserDisplayName aria-label="User Display Name">
          {display_name ?? ""}
        </UserDisplayName>
      </div>
      {bio && (
        <UserBio aria-label="User Bio">
          <Truncated>{bio}</Truncated>
        </UserBio>
      )}
    </ProfileInfo>
  );
};

export default UserPopupOverview;
