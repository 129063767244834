import { styled } from "@linaria/react";

import { Heading } from "./Section";

const PageTitle = styled(Heading)`
  color: var(--color-page-title);
  font-size: var(--font-size-page-title);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-page-title);
`;

export default PageTitle;
