import { styled } from "@linaria/react";

import { type Platform, platformGroups } from "~/constants";
import { type AlertCriterionDTO, type RiskCategoryMapDTO } from "~/dto";

import Card from "../Card";

const volumeThresholdDirections = ["increase", "decrease", "either"] as const;
type VolumeThresholdDirection = (typeof volumeThresholdDirections)[number];

const getHumanReadableTriggerConditionFor = (data: AlertCriterionDTO) => {
  const { criterion } = data;

  if (!criterion) {
    return undefined;
  }

  // TODO: this is horrible and english-only
  return [
    `${criterion.threshold}${criterion.unit === "percent" ? "%" : ""}`,
    criterion.direction === "either"
      ? "increase or decrease"
      : criterion.direction,
  ].join(" ");
};

const getHumanReadableChangeFor = (data: AlertCriterionDTO) => {
  const { criterion } = data;

  if (!criterion?.actual) {
    return undefined;
  }

  return `${Number(criterion.actual).toFixed(2)}${
    criterion.unit === "percent" ? "%" : ""
  }`;
};

const groupLabels: Record<keyof typeof platformGroups, string> = Object.keys(
  platformGroups,
).reduce(
  (acc, el) => ({
    ...acc,
    [el]: el === "onlineDiscussion" ? "online discussion" : el,
  }),
  {} as Record<keyof typeof platformGroups, string>,
);

const AlertCard = styled(Card)`
  border-radius: var(--border-radius-card);
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3xl);
  height: 300px;
  overflow: hidden;
`;

const orderedTopicCategories: (keyof RiskCategoryMapDTO)[] = [
  "Extremism or Conspiratorial",
  "State Associated - China",
  "State Associated - Russia",
  "State Associated - Other",
  "Combined Risky",
  "Mainstream",
  "Persistent Threat Actor",
  "U.S. Government",
  "Pink Slime",
  "Market Mover",
  "Default",
];

/** for the time being, we are only allowing facebook data for the Trident Seafoods organization
so, we must remove the facebook platform from alerting cards for orgs that do not have facebook data */
function removeFacebook(platformGroups: Record<string, Platform[]>) {
  return Object.fromEntries(
    Object.entries(platformGroups).map(([group, platforms]) => [
      group,
      platforms.filter((platform) => platform !== "facebook"),
    ]),
  );
}

export {
  type VolumeThresholdDirection,
  getHumanReadableChangeFor,
  getHumanReadableTriggerConditionFor,
  groupLabels,
  orderedTopicCategories,
  AlertCard,
  removeFacebook,
};
