import { type DataSource, type Platform } from "~/constants";

const SYNCHRONOUS_POSTING = [
  "SYNCHRONOUS_URL_SHARE",
  "SYNCHRONOUS_HASHTAG_SHARE",
  "SYNCHRONOUS_REPOST",
] as const;

const REPLICATION = ["COPYPASTA", "SYNCHRONOUS_REPOST"] as const;

const HASHTAG_AMPLIFICATION = [
  "SYNCHRONOUS_HASHTAG_SHARE",
  "HASHTAG_SEQUENCE_SHARE",
] as const;

const SOCK_PUPPETS = [
  "SYNCHRONOUS_ACCOUNT_CREATION",
  "SIMILAR_SCREEN_NAMES",
  "SAME_PROFILE_URL",
] as const;

export const EVIDENCES = [
  ...SYNCHRONOUS_POSTING,
  ...REPLICATION,
  ...HASHTAG_AMPLIFICATION,
  ...SOCK_PUPPETS,
] as const;
export type Evidence = (typeof EVIDENCES)[number];

export const EVIDENCES_MAP: Record<Evidence, string> = {
  COPYPASTA: "Copypasta",
  HASHTAG_SEQUENCE_SHARE: "Hashtag Sequence",
  SYNCHRONOUS_REPOST: "Sync Reposts",
  SYNCHRONOUS_URL_SHARE: "Sync URLs",
  SYNCHRONOUS_HASHTAG_SHARE: "Sync Hashtags",
  SAME_PROFILE_URL: "Same Profile URL",
  SYNCHRONOUS_ACCOUNT_CREATION: "Sync Account Creation",
  SIMILAR_SCREEN_NAMES: "Similar Screen Names",
};

export type CoordinationTypeKeys =
  | "synchronousPosting"
  | "replication"
  | "hashtagAmplification"
  | "sockPuppets";

export const COORDINATION_TYPE_EVIDENCES_MAP: Record<
  CoordinationTypeKeys,
  Evidence[]
> = {
  synchronousPosting: [...SYNCHRONOUS_POSTING],
  replication: [...REPLICATION],
  hashtagAmplification: [...HASHTAG_AMPLIFICATION],
  sockPuppets: [...SOCK_PUPPETS],
};

export const coordinationTypeNames: Record<CoordinationTypeKeys, string> = {
  synchronousPosting: "Synchronous Posting",
  replication: "Replication",
  hashtagAmplification: "Hashtag Amplification",
  sockPuppets: "Sock Puppets",
};

export const PLATFORM_COLOR: Partial<Record<Platform | DataSource, string>> = {
  gab: "var(--color-gab)",
  GabPost: "var(--color-gab)",
  gettr: "var(--color-gettr)",
  GettrPost: "var(--color-gettr)",
  twitter: "var(--color-twitter)",
  Tweet: "var(--color-twitter)",
  TwitterPost: "var(--color-twitter)",
};

export const MAX_WEIGHT = Object.keys(EVIDENCES_MAP).length;
export const MIN_CLUSTER_RADIUS = 50;
export const PLATFORM_SPACING = 150;

export const CPA_CHART_MARGIN = {
  top: 12,
  left: 50,
  right: 50,
  bottom: 12,
};
