import { styled } from "@linaria/react";
import { scaleThreshold } from "@visx/scale";
import type { FunctionComponent } from "react";

import type { NrmsConversationThemeMetricsDTO } from "~/dto/nrmsConversationThemeMetrics";
import { text } from "~/styles/typography";

import { getSpectrumPalette } from "../charts/utils";
import Tooltip from "../library/Tooltip";

import { EvergreenThemeLabels } from "./constants";

type NarrativeSummaryScoreProps = Pick<
  NrmsConversationThemeMetricsDTO,
  "theme_name" | "volume_percentage"
>;

const ScoreWrapper = styled.div<{ bgColor: string; textColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 42px;
  width: 42px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  appearance: none;
  ${text.md.regular};

  &:hover,
  &:focus {
    filter: brightness(90%);
  }
`;

// Set the color palette based on the score:
// higher scores correspond to darker shades.
const MAX_SCORE = 10;

const spectrumPalette = getSpectrumPalette(MAX_SCORE).reverse();
const bgColorScale = scaleThreshold<number, string>()
  .domain([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
  .range(spectrumPalette);
const textColorScale = scaleThreshold<number, string>()
  .domain([5, 10])
  .range(["var(--color-black)", "var(--color-white)"]);

const NarrativeSummaryScore: FunctionComponent<NarrativeSummaryScoreProps> = ({
  theme_name,
  volume_percentage,
}) => {
  if (!volume_percentage) return null;

  const themeLabel = EvergreenThemeLabels[theme_name];
  // Calculate the score based on the volume_percentage by multiplying by 10.
  const volumeScore = parseFloat((volume_percentage * 10).toFixed(1));

  return (
    <Tooltip
      contents={`The narrative score for the ${themeLabel} topic is ${volumeScore}.`}
    >
      <ScoreWrapper
        aria-label="Narrative Summary Score"
        aria-valuemax={10.0}
        aria-valuemin={0.0}
        aria-valuenow={volumeScore}
        aria-valuetext={`The narrative score for the ${themeLabel} topic is ${volumeScore}.`}
        bgColor={bgColorScale(volumeScore)}
        role="meter"
        textColor={textColorScale(volumeScore)}
      >
        {volumeScore}
      </ScoreWrapper>
    </Tooltip>
  );
};

export default NarrativeSummaryScore;
