import { styled } from "@linaria/react";
import { type StyledComponent, type FunctionComponent } from "react";

import { type ConversationDTO, type ProjectDTO } from "~/dto";

import ConversationTile from "./ConversationTile";
import ProjectTile from "./ProjectTile";
import { PickerView } from "./useProjectPicker";

type PickerListProps = StyledComponent & {
  collapsed: boolean;
  conversations?: ConversationDTO[];
  labelId: string;
  onSelectProject: (projectId: string) => void;
  projects?: ProjectDTO[];
  selectedConversation?: ConversationDTO;
  selectedProject?: ProjectDTO;
  selectedView: PickerView;
};

const StyledOrderedList = styled.ol`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xl);
  overflow-y: auto;
  scrollbar-width: thin;
`;

const PickerList: FunctionComponent<PickerListProps> = (props) => {
  const {
    collapsed,
    conversations,
    labelId,
    onSelectProject,
    projects,
    selectedConversation,
    selectedProject,
    selectedView,
    ...otherProps
  } = props;

  return (
    <StyledOrderedList
      aria-labelledby={labelId}
      data-collapsed={collapsed}
      {...otherProps}
    >
      {selectedView === PickerView.Projects &&
        projects?.map((p, idx) => (
          <ProjectTile
            key={p.id ?? ""}
            collapsed={collapsed}
            deleteable={projects.length > 1}
            index={idx}
            isSelected={selectedConversation?.project_id === p.id}
            onSelectProject={onSelectProject}
            project={p}
            selectedConversation={selectedConversation}
          />
        ))}
      {selectedView === PickerView.Conversations &&
        !!selectedProject &&
        conversations?.map((c, idx) => (
          <ConversationTile
            key={c.id ?? ""}
            collapsed={collapsed}
            conversation={c}
            index={idx}
            isSelected={selectedConversation?.id === c.id}
            project={selectedProject}
          />
        ))}
    </StyledOrderedList>
  );
};

export default PickerList;
