import { parse } from "tldts";

/**
 * When given a full URL, it returns the primary domain name like google.com.
 * When given a valid primary domain name, it returns a lower-cased primary domain name.
 */
function getPrimaryDomainName(url?: string | null) {
  if (!url) {
    return "";
  }

  try {
    return parse(url)?.domain ?? "";
  } catch (ex) {
    //The parser will raise an exception when the input is already a primary domain name.
    //We should validate, then return the original primary domain name, but in lower case.
    const REGEX = new RegExp(
      /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/,
    );
    let loweredURL = url.toLowerCase();

    if (REGEX.test(loweredURL) == true) {
      //when wwww.google.com is provided, it should return google.com
      if (loweredURL.startsWith("www.")) {
        loweredURL = loweredURL.substring(4);
      }

      return loweredURL;
    }

    return "";
  }
}

export { getPrimaryDomainName };
