import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  type PageComponent,
  Suspense,
  useId,
} from "react";
import { useOutlet } from "react-router-dom";

import { StackedLayout } from "~/components/Layout";
import PageTitle from "~/components/PageTitle";
import Section, { Heading } from "~/components/Section";
import {
  LinkButton,
  ButtonKind,
  ButtonSize,
} from "~/components/library/Button";
import Icon from "~/components/library/Icon";
import LoadingIndicator from "~/components/library/LoadingIndicator/LoadingIndicator";
import { Ellipsized, LineClamp } from "~/components/text";
import { type ConversationDTO } from "~/dto/conversation";
import { useNrmsConversations } from "~/hooks/useNrmsConversations";
import { useScaledConversations } from "~/hooks/useScaledConversations";
import { display, text } from "~/styles/typography";

import NarrativeActivity from "./nrms/NarrativeActivity";

type ConversationCardProps = {
  conversation: ConversationDTO;
};

const CenteredStackedLayout = styled(StackedLayout)`
  align-items: center;
`;

const WelcomeText = styled(PageTitle)`
  margin-top: calc(var(--spacing-md) * 18);
  margin-bottom: var(--spacing-3xl);
  ${text.lg.bold};
`;

const SecondWelcomeText = styled.div`
  ${text.lg.regular};
`;

const GetStarted: PageComponent = () => (
  <CenteredStackedLayout>
    <WelcomeText>Welcome to Artemis</WelcomeText>
    <SecondWelcomeText>
      To get started, select (or create) a project from the Project list.
    </SecondWelcomeText>
  </CenteredStackedLayout>
);

const PlatformIntelligenceSection = styled(Section)`
  flex-grow: 1;
  align-self: start;
  padding: var(--spacing-xl) var(--spacing-2xl);
`;

const Title = styled(Heading)`
  ${display.xs.regular}
  color: var(--text-color-primary);
  margin-bottom: var(--spacing-xl);
`;

const ConversationList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: var(--spacing-xl);
`;

const ConversationName = styled(Ellipsized)`
  display: block;
  text-overflow: clip;
`;

const ConversationCardContainer = styled.li`
  /* does not exist in figma sync yet */
  --color-utility-accent-600: rgb(180, 229, 124);

  background-color: var(--background-color-primary_alt);
  border: 1px solid var(--border-color-secondary);
  border-radius: var(--border-radius-md);
  display: flex;
  flex-direction: column;

  [slot="name"] {
    ${text.md.bold};
    color: var(--text-color-secondary);
    padding: var(--spacing-lg) var(--spacing-xl);
    border-bottom: 1px solid var(--border-color-secondary);
  }

  img {
    width: 100%;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }

  [data-status] {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-xl);
    padding-block: var(--spacing-3xl);
    text-align: center;
    ${display.xxs.regular};

    [data-icon] {
      font-size: 48px;
    }

    &[data-status="running"] [data-icon] {
      color: var(--color-utility-accent-600);
    }

    &[data-status="failed"] [data-icon] {
      color: var(--foreground-color-warning-secondary);
    }
  }

  [slot="content"] {
    padding: var(--spacing-xl);
    padding-bottom: 0;
    flex-grow: 1;
    ${text.sm.regular}
  }

  [slot="actions"] {
    padding: var(--spacing-xl);
    align-self: center;
  }
`;

const RunningConversationView: FunctionComponent = () => {
  return (
    <div data-status="running">
      <span>Conversation is refreshing</span>
      <Icon family="untitled" name="refresh-cw-02" />
      <span>Check back soon</span>
    </div>
  );
};

const FailedConversationView: FunctionComponent = () => {
  return (
    <div data-status="failed">
      <span>Conversation refresh failed</span>
      <Icon family="untitled" name="alert-triangle" />
      <span>
        We&apos;re looking into it!
        <br />
        Check back soon
      </span>
    </div>
  );
};

const SuccessConversationView: FunctionComponent<ConversationCardProps> = (
  props,
) => {
  return (
    <>
      {props.conversation.image_url && (
        <img alt="" src={props.conversation.image_url} />
      )}
      <div slot="content">
        <LineClamp lines={3}>{props.conversation.description}</LineClamp>
      </div>
      <div slot="actions">
        <LinkButton
          data-kind={ButtonKind.Primary}
          data-size={ButtonSize.md}
          href={`/conversations/${props.conversation.id}`}
        >
          View
        </LinkButton>
      </div>
    </>
  );
};

const ConversationCard: FunctionComponent<ConversationCardProps> = (props) => {
  const { conversation } = props;

  const conversationView =
    conversation.status === "SUCCESS" ? (
      <SuccessConversationView conversation={conversation} />
    ) : conversation.status === "RUNNING" ||
      conversation.status === "PENDING" ? (
      <RunningConversationView />
    ) : (
      <FailedConversationView />
    );

  const labelId = useId();

  return (
    <ConversationCardContainer aria-labelledby={labelId}>
      <span id={labelId} slot="name">
        <ConversationName>{conversation.name}</ConversationName>
      </span>
      {conversationView}
    </ConversationCardContainer>
  );
};

const PlatformIntelligence: FunctionComponent<{
  conversations: ConversationDTO[];
}> = (props) => {
  const { conversations } = props;

  return (
    <PlatformIntelligenceSection>
      <Title>Platform Intelligence</Title>
      <ConversationList>
        {conversations.map((conversation) => (
          <ConversationCard key={conversation.id} conversation={conversation} />
        ))}
      </ConversationList>
    </PlatformIntelligenceSection>
  );
};

const Welcome: PageComponent = () => {
  const outlet = useOutlet();
  const {
    isLoading: isScaledConversationLoading,
    data: scaledConversationData,
  } = useScaledConversations();
  const { isLoading: isNrmsConversationLoading, data: nrmsConversationData } =
    useNrmsConversations();

  if (outlet) {
    // we still want to handle sub-routes that have been code-split, but since
    // we already have the outlet content, we can render it directly
    return <Suspense fallback={<LoadingIndicator />}>{outlet}</Suspense>;
  }

  if (isScaledConversationLoading || isNrmsConversationLoading) {
    return <LoadingIndicator />;
  }

  if (nrmsConversationData.length > 0) {
    return <NarrativeActivity data={nrmsConversationData} />;
  }

  if (scaledConversationData.length > 0) {
    return <PlatformIntelligence conversations={scaledConversationData} />;
  }

  return <GetStarted />;
};

export default Welcome;
