import { useContext, useMemo, type FunctionComponent } from "react";

import {
  useInfluencerFollowerCounts,
  useInfluencerMentionsCounts,
  useInfluencerRepliedToCounts,
  useInfluencerSharedCounts,
} from "~/hooks/useActorKeyInsights";
import { useConversationFilters } from "~/hooks/useConversationFilters";
import { RegraphContext } from "~/pages/Graph/components/ControlPanel";

import {
  type ActorKeyInsightsProps,
  ActorKeyInsightsSubTabs,
  getColumnData,
  getSortedData,
  InfoText,
} from "./ActorKeyInsights";
import {
  ActorTable,
  NoDataPlaceholder,
  PaddedLoadingIndicator,
} from "./NetworkKeyInsights";
import Icon from "./library/Icon";
import { TabItem, TabKind, TabSize } from "./library/Tabs";

const FollowerTab: FunctionComponent<ActorKeyInsightsProps> = (props) => {
  const { conversationId } = props;
  const filters = useConversationFilters();

  const { data: followerData, isLoading } = useInfluencerFollowerCounts(
    conversationId,
    filters,
  );
  const regraph = useContext(RegraphContext);

  const { columnData, sortedData } = useMemo(() => {
    const columnData = getColumnData(
      conversationId,
      "Followers",
      regraph?.regraphObject,
    );
    const sortedData = getSortedData(followerData);
    return {
      columnData,
      sortedData,
    };
  }, [conversationId, followerData, regraph?.regraphObject]);

  return (
    <>
      <InfoText>
        <Icon family="untitled" name="info-circle" />
        Number of followers these accounts have
      </InfoText>
      <ActorTable
        disableFilter
        id="influencer-followers-table"
        tableColumns={columnData}
        tableData={sortedData}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && followerData?.data.length === 0 && <NoDataPlaceholder />}
    </>
  );
};

const SharedTab: FunctionComponent<ActorKeyInsightsProps> = (props) => {
  const { conversationId } = props;
  const filters = useConversationFilters();

  const { data: sharedData, isLoading } = useInfluencerSharedCounts(
    conversationId,
    filters,
  );
  const regraph = useContext(RegraphContext);

  const { columnData, sortedData } = useMemo(() => {
    const columnData = getColumnData(
      conversationId,
      "Shared",
      regraph?.regraphObject,
    );
    const sortedData = getSortedData(sharedData);
    return {
      columnData,
      sortedData,
    };
  }, [conversationId, regraph?.regraphObject, sharedData]);

  return (
    <>
      <InfoText>
        <Icon family="untitled" name="info-circle" />
        Number of times others shared these accounts' posts in the conversation
      </InfoText>
      <ActorTable
        disableFilter
        id="influencer-shares-table"
        tableColumns={columnData}
        tableData={sortedData}
        virtual={false}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && sharedData?.data.length === 0 && <NoDataPlaceholder />}
    </>
  );
};

const RepliedTab: FunctionComponent<ActorKeyInsightsProps> = (props) => {
  const { conversationId } = props;
  const filters = useConversationFilters();

  const { data: repliedData, isLoading } = useInfluencerRepliedToCounts(
    conversationId,
    filters,
  );
  const regraph = useContext(RegraphContext);

  const { columnData, sortedData } = useMemo(() => {
    const columnData = getColumnData(
      conversationId,
      "Replied to",
      regraph?.regraphObject,
    );
    const sortedData = getSortedData(repliedData);
    return {
      columnData,
      sortedData,
    };
  }, [conversationId, regraph?.regraphObject, repliedData]);

  return (
    <>
      <InfoText>
        <Icon family="untitled" name="info-circle" />
        Number of times others replied to these accounts' posts in the
        conversation
      </InfoText>
      <ActorTable
        disableFilter
        id="influencer-replies-table"
        tableColumns={columnData}
        tableData={sortedData}
        virtual={false}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && repliedData?.data.length === 0 && <NoDataPlaceholder />}
    </>
  );
};

const MentionedTab: FunctionComponent<ActorKeyInsightsProps> = (props) => {
  const { conversationId } = props;
  const filters = useConversationFilters();

  const { data: mentionedData, isLoading } = useInfluencerMentionsCounts(
    conversationId,
    filters,
  );
  const regraph = useContext(RegraphContext);

  const { columnData, sortedData } = useMemo(() => {
    const columnData = getColumnData(
      conversationId,
      "Mentioned",
      regraph?.regraphObject,
    );
    const sortedData = getSortedData(mentionedData);
    return {
      columnData,
      sortedData,
    };
  }, [conversationId, mentionedData, regraph?.regraphObject]);

  return (
    <>
      <InfoText>
        <Icon family="untitled" name="info-circle" />
        Number of times others mentioned these accounts in the conversation
      </InfoText>
      <ActorTable
        disableFilter
        id="influencer-mentions-table"
        tableColumns={columnData}
        tableData={sortedData}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && mentionedData?.data.length === 0 && <NoDataPlaceholder />}
    </>
  );
};

const InfluencerKeyInsights: FunctionComponent<ActorKeyInsightsProps> = (
  props,
) => {
  const { conversationId } = props;

  return (
    <ActorKeyInsightsSubTabs data-kind={TabKind.Inner} data-size={TabSize.sm}>
      <TabItem id="influencer-followers-tab" title="Followers">
        <FollowerTab conversationId={conversationId} />
      </TabItem>
      <TabItem id="influencer-shares-tab" title="Shared">
        <SharedTab conversationId={conversationId} />
      </TabItem>
      <TabItem id="infleuncer-replies-tab" title="Replied to">
        <RepliedTab conversationId={conversationId} />
      </TabItem>
      <TabItem id="influencer-mentions-tab" title="Mentions">
        <MentionedTab conversationId={conversationId} />
      </TabItem>
    </ActorKeyInsightsSubTabs>
  );
};

export default InfluencerKeyInsights;
