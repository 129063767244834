import { isEqualDay, startOfWeek } from "@internationalized/date";
import { cx } from "@linaria/core";
import { type StyledComponent } from "react";
import {
  type DatePickerProps as BaseDatePickerProps,
  type DateRangePickerProps as BaseDateRangePickerProps,
  type TimeFieldProps as BaseTimeFieldProps,
  type DateValue,
  type TimeValue,
  DatePicker as BaseDatePicker,
  DateRangePicker as BaseDateRangePicker,
  TimeField as BaseTimeField,
  Label,
  DateInput,
  DateSegment,
  FieldError,
  Popover,
  Dialog,
  Calendar,
  RangeCalendar,
  CalendarGrid,
  CalendarCell,
  Text,
} from "react-aria-components";

import { FormSize } from "./Form";
import IconButton, { ButtonKind, ButtonSize } from "./IconButton";
/* NOTE: linaria's babel plugin really, really doesn't like React Aria's internationalization stuff
   so all styling needs to be done in calendar.styles.ts instead and imported here. */
import {
  CalendarHeading,
  InputGroup,
  calendar,
  calendarCellSingle,
  calendarCellRange,
  calendarGrid,
  dateInput,
  timeFieldInput,
  dateSegment,
  fieldRoot,
} from "./calendar.styles";
import {
  fieldLabel,
  fieldDescription,
  fieldError,
  Required,
} from "./formStyles";

export type DatePickerProps<T extends DateValue> = Omit<
  BaseDatePickerProps<T>,
  | "children"
  | "className"
  | "style"
  | "isDisabled"
  | "isReadOnly"
  | "isRequired"
> &
  StyledComponent & {
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;

    label?: string;
    description?: string;
    error?: string;
  };

export type DateRangePickerProps<T extends DateValue> = Omit<
  BaseDateRangePickerProps<T>,
  | "children"
  | "className"
  | "style"
  | "isDisabled"
  | "isReadOnly"
  | "isRequired"
> &
  StyledComponent & {
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;

    label?: string;
    description?: string;
    error?: string;
  };

export type TimeFieldProps<T extends TimeValue> = Omit<
  BaseTimeFieldProps<T>,
  | "children"
  | "className"
  | "style"
  | "isDisabled"
  | "isReadOnly"
  | "isRequired"
> &
  StyledComponent & {
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;

    label?: string;
    description?: string;
    error?: string;
  };

function isStartOfWeek<T extends DateValue>(date: T) {
  return isEqualDay(date, startOfWeek(date, "en-US"));
}

function isEndOfWeek<T extends DateValue>(date: T) {
  const endOfWeek = startOfWeek(date, "en-US").add({ days: 6 });
  return isEqualDay(date, endOfWeek);
}

function DatePicker<T extends DateValue>(props: DatePickerProps<T>) {
  const { label, description, error, disabled, readOnly, required, ...rest } =
    props;

  return (
    <BaseDatePicker
      {...rest}
      className={cx(fieldRoot, props.className)}
      data-required={required || undefined}
      data-size={FormSize.md}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
    >
      {label && (
        <Label className={fieldLabel}>
          {label}
          <Required />
        </Label>
      )}
      <InputGroup>
        <DateInput className={dateInput}>
          {(segment) => (
            <DateSegment className={dateSegment} segment={segment} />
          )}
        </DateInput>
        <IconButton
          aria-label="Open calendar"
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.md}
          icon="calendar"
        />
      </InputGroup>
      {description && (
        <Text className={fieldDescription} slot="description">
          {description}
        </Text>
      )}
      <FieldError className={fieldError}>{error}</FieldError>

      <Popover>
        <Dialog>
          <Calendar className={calendar}>
            <header>
              <IconButton
                aria-label="Previous month"
                data-kind={ButtonKind.Tertiary}
                data-size={ButtonSize.sm}
                icon="chevron-left"
                slot="previous"
              />
              <CalendarHeading />
              <IconButton
                aria-label="Next month"
                data-kind={ButtonKind.Tertiary}
                data-size={ButtonSize.sm}
                icon="chevron-right"
                slot="next"
              />
            </header>
            <CalendarGrid className={calendarGrid}>
              {(date) => (
                <CalendarCell className={calendarCellSingle} date={date} />
              )}
            </CalendarGrid>
          </Calendar>
        </Dialog>
      </Popover>
    </BaseDatePicker>
  );
}

function DateRangePicker<T extends DateValue>(props: DateRangePickerProps<T>) {
  const { label, description, error, disabled, readOnly, required, ...rest } =
    props;

  return (
    <BaseDateRangePicker
      {...rest}
      className={cx(fieldRoot, props.className)}
      data-required={required || undefined}
      data-size={FormSize.md}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
    >
      {label && (
        <Label className={fieldLabel}>
          {label}
          <Required />
        </Label>
      )}
      <InputGroup>
        <DateInput className={dateInput} slot="start">
          {(segment) => (
            <DateSegment className={dateSegment} segment={segment} />
          )}
        </DateInput>
        <span aria-hidden="true">&ndash;</span>
        <DateInput className={dateInput} slot="end">
          {(segment) => (
            <DateSegment className={dateSegment} segment={segment} />
          )}
        </DateInput>
        <IconButton
          aria-label="Open calendar"
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.md}
          icon="calendar"
        />
      </InputGroup>
      {description && (
        <Text className={fieldDescription} slot="description">
          {description}
        </Text>
      )}
      <FieldError className={fieldError}>{error}</FieldError>

      <Popover>
        <Dialog>
          <RangeCalendar className={calendar}>
            <header>
              <IconButton
                aria-label="Previous month"
                data-kind={ButtonKind.Tertiary}
                data-size={ButtonSize.sm}
                icon="chevron-left"
                slot="previous"
              />
              <CalendarHeading />
              <IconButton
                aria-label="Next month"
                data-kind={ButtonKind.Tertiary}
                data-size={ButtonSize.sm}
                icon="chevron-right"
                slot="next"
              />
            </header>
            <CalendarGrid className={calendarGrid}>
              {(date) => (
                <CalendarCell
                  className={calendarCellRange}
                  data-week-end={isEndOfWeek(date) || undefined}
                  data-week-start={isStartOfWeek(date) || undefined}
                  date={date}
                />
              )}
            </CalendarGrid>
          </RangeCalendar>
        </Dialog>
      </Popover>
    </BaseDateRangePicker>
  );
}

function TimeField<T extends TimeValue>(props: TimeFieldProps<T>) {
  const { label, description, error, disabled, readOnly, required, ...rest } =
    props;

  return (
    <BaseTimeField
      {...rest}
      className={cx(fieldRoot, props.className)}
      data-required={required || undefined}
      data-size={FormSize.md}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
    >
      {label && (
        <Label className={fieldLabel}>
          {label}
          <Required />
        </Label>
      )}
      <DateInput className={cx(dateInput, timeFieldInput)}>
        {(segment) => <DateSegment className={dateSegment} segment={segment} />}
      </DateInput>
      {description && (
        <Text className={fieldDescription} slot="description">
          {description}
        </Text>
      )}
      <FieldError className={fieldError}>{error}</FieldError>
    </BaseTimeField>
  );
}

export { DatePicker, DateRangePicker, TimeField };
