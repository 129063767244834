import { useCallback, useEffect, useRef } from "react";
import useSwr, {
  type Key as SWRKey,
  type KeyedMutator,
  type SWRConfiguration,
} from "swr";
import { type ZodSchema } from "zod";

import { useSession } from "../useSession";

import {
  type Collectible,
  useRemoteCollection,
  type RemoteCollectionOpts,
} from "./useRemoteCollection";
import {
  type GetFetcherFactory,
  type UpdateBuilderFactory,
  type UpdateFetcherFactory,
  defaultGetFetcherFactory,
  defaultUpdateBuilderFactory,
  defaultUpdateFetcherFactory,
} from "./utils";

interface RemoteObjectOpts<GetShape, UpdateShape, KeyShape> {
  /** https://swr.vercel.app/docs/options cacheOpts is the SWR options object */
  cacheOpts?: SWRConfiguration<GetShape>;
  /** a 'fetcher' is a thing that makes a network request */
  getFetcher?: GetFetcherFactory<GetShape, KeyShape>;
  /** a schema validator ensures we're processing the data we expect to process */
  schemaValidator: ZodSchema<GetShape>;
  /** an 'update builder' is a thing which applies an update to the existing data */
  updateBuilder?: false | UpdateBuilderFactory<GetShape, UpdateShape>;
  /** a remote resource may have different read and write endpoints/verbs/etc */
  updateFetcher?: UpdateFetcherFactory<UpdateShape, KeyShape>;
}

export function useRemoteObject<
  GetShape,
  UpdateShape = Partial<GetShape>,
  KeyShape extends SWRKey = RequestInfo,
>(
  apiEndpointPath: KeyShape | null | undefined,
  {
    cacheOpts = {},
    getFetcher,
    schemaValidator,
    updateBuilder = defaultUpdateBuilderFactory,
    updateFetcher,
  }: RemoteObjectOpts<GetShape, UpdateShape, KeyShape>,
) {
  const { authToken, userClaims } = useSession();
  const { organization_id } = userClaims ?? {};
  const fetcher = getFetcher
    ? getFetcher(authToken, schemaValidator)
    : defaultGetFetcherFactory(authToken, schemaValidator);
  const {
    data: validatedData,
    error,
    mutate,
    isLoading,
  } = useSwr<GetShape>(apiEndpointPath, fetcher, cacheOpts);
  const refreshRef = useRef<KeyedMutator<GetShape>>(mutate);
  const orgRef = useRef<string | undefined>(organization_id);
  const update = useCallback(
    async (payload: UpdateShape) => {
      if (!validatedData || !apiEndpointPath) {
        return;
      }

      /* step 1: optimistic local update */
      if (updateBuilder !== false) {
        mutate(await updateBuilder()(validatedData, payload), false);
      }

      /* step 2: make network request and revalidate */
      if (updateFetcher) {
        await updateFetcher(authToken, payload)(apiEndpointPath);
      } else {
        await defaultUpdateFetcherFactory(
          authToken,
          payload,
        )(String(apiEndpointPath));
      }

      mutate();
    },
    [
      apiEndpointPath,
      authToken,
      mutate,
      updateBuilder,
      updateFetcher,
      validatedData,
    ],
  );

  /* when we detect a change to the effective organization of a user, refresh
   * our data */
  useEffect(() => {
    if (organization_id && organization_id !== orgRef.current) {
      orgRef.current = organization_id;
      refreshRef.current?.(undefined);
    }
  }, [apiEndpointPath, organization_id]);

  return {
    data: validatedData,
    error,
    refresh: mutate,
    update,
    isLoading,
  };
}

/*
 * convenience hook.  scopes `useRemoteCollection` to a single object so that we
 * can leverage swr cache magic
 */
export function useRemoteObjectFromCollection<
  GetShape extends Collectible,
  UpdateOneShape = Partial<Omit<GetShape, "uuid">>,
  CreateOneShape = Partial<GetShape>,
>(
  apiEndpointPath: string | undefined,
  id: string | undefined,
  opts: RemoteCollectionOpts<GetShape, UpdateOneShape, CreateOneShape>,
) {
  const { data, error, isLoading, refresh, remove, update } =
    useRemoteCollection<GetShape, UpdateOneShape, CreateOneShape>(
      apiEndpointPath,
      opts,
    );
  const removeOne = useCallback(() => {
    if (id) {
      return remove(id);
    }
  }, [id, remove]);
  const updateOne = useCallback(
    (payload: UpdateOneShape) => {
      if (id) {
        return update(id, payload);
      }
    },
    [id, update],
  );

  return {
    data: data.find(({ id: objId }) => objId === id),
    error,
    isLoading,
    refresh,
    remove: removeOne,
    update: updateOne,
  };
}
