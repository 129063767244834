import { useCallback, useMemo, useState } from "react";

import {
  getCSSValue,
  getForegroundForBackground,
  getSpectrumPalette,
} from "~/components/charts/utils";
import { orderedTopicCategories } from "~/components/conversationExplorer/utils";

import { infoByNodeType } from "../constants";
import {
  isNodeType,
  type ColorMap,
  type LegendItem,
  type NodeType,
} from "../types";

interface ColorSet {
  main: string;
  text: string;
}

// TODO: remove these once these colors are added to css
const colorUtilityPurple900 = "#3E1C96";
const colorUtilityTeal500 = "#15B79E";

function getCoordinationColors(maxCoordinationWeight: number | undefined) {
  const colorsList = maxCoordinationWeight
    ? getSpectrumPalette(maxCoordinationWeight)
    : [];

  return colorsList.map((color) => ({
    main: getCSSValue(color),
    text: getCSSValue(getForegroundForBackground(color)),
  }));
}

function assignColors<T extends string>(
  ids: (T | undefined)[],
  getLabel: (item: T) => string,
  colors: ColorSet[],
) {
  const adjustedIds = ids.slice(0, colors.length);
  const mapById = new Map<T, LegendItem>();
  adjustedIds.forEach((id, i) => {
    if (!id) {
      return;
    }
    const color = colors[i];
    const newItem: LegendItem = { ...color, id, label: getLabel(id) };
    mapById.set(id, newItem);
  });

  return mapById;
}

function convertItemsToNodeTracker(items: NodeType[]): NodeTrackerType {
  return [
    items[0] ?? undefined,
    items[1] ?? undefined,
    items[2] ?? undefined,
    items[3] ?? undefined,
    items[4] ?? undefined,
  ];
}

function addToNodeTracker(
  node: NodeType,
  nodeTracker: NodeTrackerType,
): NodeTrackerType {
  if (nodeTracker.includes(node)) return nodeTracker;

  const index = nodeTracker.findIndex((n) => n === undefined);
  if (index !== -1) {
    const newNodeTracker = [...nodeTracker];
    newNodeTracker[index] = node;
    if (isNodeTracker(newNodeTracker)) {
      return newNodeTracker;
    }
  }

  return nodeTracker;
}

function deleteFromNodeTracker(
  node: NodeType,
  nodeTracker: NodeTrackerType,
): NodeTrackerType {
  if (!nodeTracker.includes(node)) return nodeTracker;

  const index = nodeTracker.findIndex((n) => n === node);
  if (index !== -1) {
    const newNodeTracker = [...nodeTracker];
    newNodeTracker[index] = undefined;
    if (isNodeTracker(newNodeTracker)) {
      return newNodeTracker;
    }
  }

  return nodeTracker;
}

interface UseLegendProps {
  selectedNode: NodeType[];
  maxCoordinationWeight: number | undefined;
}

type NodeTrackerType = [
  NodeType | undefined,
  NodeType | undefined,
  NodeType | undefined,
  NodeType | undefined,
  NodeType | undefined,
];

function isNodeTracker(nodes: unknown[]): nodes is NodeTrackerType {
  return (
    nodes.length === 5 &&
    nodes.every((node) => node === undefined || isNodeType(node))
  );
}

export interface LegendData {
  colorData: ColorMap;
  addNode: (node: NodeType) => void;
  deleteAllNode: () => void;
  deleteNode: (node: NodeType) => void;
  replaceNodes: (nodes: NodeType[]) => void;
}

export const useLegend = (props: UseLegendProps): LegendData => {
  const { selectedNode, maxCoordinationWeight } = props;

  const [nodeTracker, setNodeTracker] = useState<NodeTrackerType>(
    convertItemsToNodeTracker(selectedNode),
  );

  const addNode = useCallback((node: NodeType) => {
    setNodeTracker((prevNodeTracker) =>
      addToNodeTracker(node, prevNodeTracker),
    );
  }, []);

  const deleteNode = useCallback((node: NodeType) => {
    setNodeTracker((prevNodeTracker) =>
      deleteFromNodeTracker(node, prevNodeTracker),
    );
  }, []);

  const deleteAllNode = useCallback(() => {
    setNodeTracker([undefined, undefined, undefined, undefined, undefined]);
  }, []);

  const replaceNodes = useCallback((nodes: NodeType[]) => {
    setNodeTracker((prevNodeTracker) => {
      const newNodeTracker = Array.from(
        { length: 5 },
        (_, index) => nodes[index] ?? undefined,
      );
      return isNodeTracker(newNodeTracker) ? newNodeTracker : prevNodeTracker;
    });
  }, []);

  const nodeColors = useMemo(() => {
    return [
      getCSSValue("--color-utility-blue-500"),
      getCSSValue("--color-utility-warning-400"),
      colorUtilityPurple900,
      colorUtilityTeal500,
      getCSSValue("--color-utility-orange-500"),
      getCSSValue("--color-utility-pink-300"),
      getCSSValue("--color-utility-fuchsia-700"),
      getCSSValue("--color-utility-blue-light-300"),
      getCSSValue("--color-utility-gray-blue-300"),
      getCSSValue("--color-utility-success-200"),
      getCSSValue("--color-utility-error-300"),
      getCSSValue("--color-utility-error-700"),
      getCSSValue("--color-utility-pink-500"),
      getCSSValue("--color-utility-moss-300"),
    ].map((color) => ({
      main: color,
      text: getCSSValue(getForegroundForBackground(color)),
    }));
  }, []);

  const riskColors = useMemo(() => {
    return [
      colorUtilityPurple900,
      getCSSValue("--color-utility-fuchsia-700"),
      getCSSValue("--color-utility-error-700"),
      getCSSValue("--color-utility-orange-500"),
      getCSSValue("--color-utility-warning-400"),
      colorUtilityTeal500,
      getCSSValue("--color-utility-blue-500"),
      getCSSValue("--color-utility-blue-light-300"),
      getCSSValue("--color-utility-pink-300"),
      getCSSValue("--color-utility-purple-300"),
      getCSSValue("--color-utility-gray-300"),
    ].map((color) => ({
      main: color,
      text: getCSSValue(getForegroundForBackground(color)),
    }));
  }, []);

  const colorData = useMemo(() => {
    const coordinationEdgeColors = getCoordinationColors(maxCoordinationWeight);

    return {
      coordination: assignColors(
        coordinationEdgeColors.map((_, index) => (index + 1).toString()),
        (edgeWeight) => {
          const numWeight = Number(edgeWeight);
          return `${numWeight} ${numWeight === 1 ? "Evidence" : "Evidences"}`;
        },
        coordinationEdgeColors,
      ),
      narratives: assignColors(
        orderedTopicCategories,
        (category) => category,
        riskColors,
      ),
      nodes: assignColors(
        nodeTracker,
        (item: NodeType) => infoByNodeType[item].label,
        nodeColors,
      ),
    };
  }, [maxCoordinationWeight, nodeColors, nodeTracker, riskColors]);

  return {
    colorData,
    addNode,
    deleteAllNode,
    deleteNode,
    replaceNodes,
  };
};
