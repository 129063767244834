import { styled } from "@linaria/react";
import {
  type ReactNode,
  type StyledComponent,
  type FunctionComponent,
  useId,
} from "react";
import {
  type RadioProps as BaseRadioProps,
  type RadioGroupProps as BaseRadioGroupProps,
  Radio as BaseRadio,
  RadioGroup as BaseRadioGroup,
  Label,
  Text,
  FieldError,
} from "react-aria-components";

import { FormSize, useFormSize } from "./Form";
import {
  fieldDescription,
  fieldError,
  fieldLabel,
  itemDescription,
  itemLabel,
  Required,
} from "./formStyles";

export type RadioGroupProps = Omit<
  BaseRadioGroupProps,
  | "isDisabled"
  | "isRequired"
  | "isReadOnly"
  | "children"
  | "className"
  | "style"
  | "value"
> &
  StyledComponent & {
    children: ReactNode;
    disabled?: boolean;
    required?: boolean;
    readOnly?: boolean;

    label?: string;
    description?: string;
    error?: string;
    size?: FormSize;

    value: BaseRadioGroupProps["value"] | null;
  };

export type RadioProps = Omit<
  BaseRadioProps,
  "className" | "style" | "children" | "isDisabled"
> &
  StyledComponent & {
    children?: ReactNode;
    disabled?: boolean;

    description?: string;
  };

const Indicator = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--outer-size);
  width: var(--outer-size);
  border-radius: var(--border-radius-full);

  &::after {
    height: var(--inner-size);
    width: var(--inner-size);
    border-radius: var(--border-radius-full);
    content: "";
    display: block;
    visibility: hidden;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--radio-group-option-spacing);
`;

// @ts-expect-error linaria's TS definition thinks Radio doesn't take a className prop
const RadioRoot = styled(BaseRadio)`
  display: flex;
  align-items: flex-start;
  gap: var(--radio-option-horizontal-spacing);

  ${Indicator} {
    background-color: var(--background-color-primary);
    border: 1px solid var(--border-color-primary);
  }

  &[data-has-label] ${Indicator} {
    top: 2px;
  }

  [data-slot="text"] {
    display: flex;
    flex-direction: column;
    gap: var(--radio-option-vertical-spacing);
  }

  &:not([data-has-label]) [data-slot="text"] {
    display: none;
  }

  &[data-focused] {
    ${Indicator} {
      box-shadow: var(--ring-gray);
    }
  }

  &[data-selected] {
    ${Indicator} {
      background-color: var(--background-color-brand-solid);
      border-color: var(--border-color-brand-solid);

      &::after {
        background-color: var(--foreground-color-white);
        visibility: visible;
      }
    }

    &[data-focused] {
      ${Indicator} {
        box-shadow: var(--ring-brand);
      }
    }
  }

  &[data-disabled] {
    ${Indicator} {
      background-color: var(--background-color-disabled_subtle);
      border-color: var(--border-color-disabled);

      &::after {
        background-color: var(--foreground-color-disabled_subtle);
      }
    }
  }
`;

const RadioGroupRoot = styled(BaseRadioGroup)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  &[data-size="${FormSize.sm}"] {
    --outer-size: 16px;
    --inner-size: 6px;
    --radio-option-horizontal-spacing: var(--spacing-md);
    --radio-option-vertical-spacing: 0;
  }

  &[data-size="${FormSize.md}"] {
    --outer-size: 20px;
    --inner-size: 8px;
    --radio-option-horizontal-spacing: var(--spacing-lg);
    --radio-option-vertical-spacing: var(--spacing-xxs);
  }

  &[data-orientation="horizontal"] ${Options} {
    flex-direction: row;
    gap: var(--radio-group-option-spacing, var(--spacing-md));
  }
`;

export const Radio: FunctionComponent<RadioProps> = (props) => {
  const { children, disabled, description, ...rest } = props;

  const labelId = useId();
  const descriptionId = useId();

  const describedBy =
    [description && descriptionId, props["aria-describedby"]]
      .filter(Boolean)
      .join(" ") || undefined;

  const isDefaultLayout = Boolean(description) || typeof children === "string";

  return (
    <RadioRoot
      {...rest}
      aria-describedby={describedBy}
      aria-labelledby={isDefaultLayout && children ? labelId : undefined}
      data-has-label={Boolean(children) || undefined}
      isDisabled={disabled}
    >
      <Indicator data-slot="indicator" />
      <div data-slot="text">
        {isDefaultLayout ? (
          <>
            {children && (
              <span className={itemLabel} id={labelId}>
                {children}
              </span>
            )}
            {description && (
              <span className={itemDescription} id={descriptionId}>
                {description}
              </span>
            )}
          </>
        ) : (
          children
        )}
      </div>
    </RadioRoot>
  );
};

export const RadioGroup: FunctionComponent<RadioGroupProps> = (props) => {
  const {
    children,
    disabled,
    required,
    readOnly,
    label,
    description,
    error,
    size: _size,
    value,
    ...rest
  } = props;

  const { size } = useFormSize(props);

  return (
    <RadioGroupRoot
      {...rest}
      data-required={required || undefined}
      data-size={size}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
      // @ts-expect-error a null value means the component is controlled but doesn't have a selected value, fixed in https://react-spectrum.adobe.com/releases/2024-05-01.html
      value={value}
    >
      {label && (
        <Label className={fieldLabel}>
          {label}
          <Required />
        </Label>
      )}
      <Options slot="optionList">{children}</Options>
      {description && (
        <Text className={fieldDescription} slot="description">
          {description}
        </Text>
      )}
      {error && <FieldError className={fieldError}>{error}</FieldError>}
    </RadioGroupRoot>
  );
};
