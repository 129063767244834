import {
  type FormContents,
  type FormSubmitEvent,
  type FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useDomainInsights } from "~/hooks/useInsights";
import { getPrimaryDomainName } from "~/utils/domainUtils";

import ErrorMessageContainer from "../ErrorMessageContainer";
import ModalDialog, {
  DialogHeadingContainer,
  DialogTitle,
  XButton,
} from "../ModalDialog";

import InsightsDialogButtonPanel from "./InsightsDialogButtonPanel";
import InsightsForm from "./InsightsForm";
import {
  type AddDomainInsightsFormContents,
  type DomainInsightsDialogProps,
} from "./types";
import { StyledCard, insightFromDomainForm } from "./utils";

const useDomainInsightsDialog = (props: DomainInsightsDialogProps) => {
  const { insight, isOpen, onSuccess, uid, mode } = props;
  const formId = `domain-insights-dialog-form`;
  const dialogTitleA11y = `${mode} Domain Insights`;
  const [error, setError] = useState<string | undefined>();
  const dialogTitle = getPrimaryDomainName(insight?.link);
  const { create, refresh, remove, update } = useDomainInsights(
    isOpen ? uid : undefined,
  );
  const onSubmit = useCallback(
    async (e: FormSubmitEvent) => {
      e.preventDefault();
      const submitButton = e.nativeEvent.submitter;

      submitButton.setAttribute("disabled", "disabled");

      try {
        setError(undefined);

        const form =
          e.currentTarget as FormContents<AddDomainInsightsFormContents>;
        const formData = insightFromDomainForm(form, insight);

        if (Object.keys(formData.additional_properties).length === 0) {
          throw new Error("At least one value must be provided.");
        } else if (
          Object.keys(formData.additional_properties).length === 1 &&
          !!formData.additional_properties.name
        ) {
          throw new Error(
            "At least one value other than Outlet must be provided.",
          );
        }

        if (insight?.analyzable_id) {
          await update(formData);
        } else {
          await create({
            insight: formData,
            link: form.elements.link.value,
          });
        }

        onSuccess();
      } catch (ex) {
        setError(String(ex));
      }

      submitButton.removeAttribute("disabled");
    },
    [insight, create, onSuccess, update],
  );
  const onDelete = useCallback(async () => {
    if (!insight?.analyzable_id) {
      return;
    }

    try {
      setError(undefined);

      await remove(insight);

      onSuccess();
    } catch (ex) {
      setError(String(ex));
    }
  }, [insight, onSuccess, remove]);

  useEffect(() => {
    setError(undefined);
  }, [isOpen]);

  return {
    dialogTitle,
    dialogTitleA11y,
    error,
    formId,
    onDelete,
    onSubmit,
    refresh,
    uid: uid ?? insight?.link,
  };
};

const DomainInsightsDialog: FunctionComponent<DomainInsightsDialogProps> = (
  props,
) => {
  const { insight, isOpen, mode, onCancel } = props;
  const {
    dialogTitle,
    dialogTitleA11y,
    error,
    formId,
    onDelete,
    onSubmit,
    refresh,
    uid,
  } = useDomainInsightsDialog(props);

  return (
    <ModalDialog isOpen={isOpen}>
      <StyledCard>
        <DialogHeadingContainer>
          <DialogTitle>
            <span data-at-only>{dialogTitleA11y}</span>
            {dialogTitle}
          </DialogTitle>
          <XButton onPress={onCancel} />
        </DialogHeadingContainer>
        <InsightsForm
          aria-label={dialogTitleA11y}
          formId={formId}
          insight={insight}
          onSubmit={onSubmit}
          uid={uid}
        />
        <ErrorMessageContainer>{error}</ErrorMessageContainer>
        <InsightsDialogButtonPanel
          formId={formId}
          insight={insight}
          mode={mode}
          onCancel={onCancel}
          onDelete={onDelete}
          refresh={refresh}
        />
      </StyledCard>
    </ModalDialog>
  );
};

export default DomainInsightsDialog;
