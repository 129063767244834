import { styled } from "@linaria/react";

import Table from "./Table";

const DialogTable = styled(Table)`
  background-color: var(--color-white);
  width: 100%;

  thead tr {
    border: none;
    padding: 0;
  }

  thead tr th button {
    background-color: var(--color-white);
    color: var(--color-dialog-table-column-name);
    padding: 0 0 var(--spacing-xs) 0;
  }

  tr {
    background-color: var(--color-white);
    display: grid;
    padding: 0;
  }

  tbody tr td {
    display: block;
    word-break: break-all;
    color: var(--text-color-primary);
    padding: var(--spacing-md) 0;
  }

  tbody tr {
    &:not(:empty) {
      border-top: var(--border-standard);
    }

    /* remove hover effect */
    &:hover {
      background-color: transparent;
    }
    &:nth-child(even):hover {
    }
  }

  a {
    white-space: normal;
    word-break: break-all;
  }
`;

export { DialogTable };
