import { styled } from "@linaria/react";
import { type FormContents } from "react";

import { type InsightDTO } from "~/dto";

import Card from "../Card";
import { getListBoxValue } from "../library/ListBox";

import {
  type AccountInsightsFormContents,
  type CreateAccountInsightsFormContents,
  type CreateDomainInsightsFormContents,
  type CreateInsightsFormSharedContents,
  type DomainInsightsFormContents,
} from "./types";

const StyledCard = styled(Card)`
  width: 500px;
`;

const insightFromDomainForm = (
  form: FormContents<DomainInsightsFormContents>,
  insight?: InsightDTO,
  buttonAction?: string | null,
) => {
  const existingInsightProperties = insight?.additional_properties;
  const insightProperties = (
    [...form.elements] as (HTMLInputElement | HTMLSelectElement)[]
  )
    .filter(
      /* - buttons are included in the elements list even if they're not
       *   descendants of the <form>
       * - language/subcategory can have multiple selected and are handled separately
       * - link is a property of the request but is not itself insight data */
      (el) =>
        el.name !== "link" &&
        el.name !== "language" &&
        el.name !== "subcategory" &&
        ["input", "select"].includes(el.tagName.toLowerCase()),
    )
    .reduce(
      (acc, el) => {
        const id = el.name || el.id;

        return el.value?.length
          ? { ...acc, [id]: String(el.value).trim() }
          : id in acc
          ? { ...acc, [id]: null }
          : acc;
      },
      { ...existingInsightProperties },
    );

  insightProperties.language = getListBoxValue(form.elements.language);
  insightProperties.subcategory = getListBoxValue(form.elements.subcategory);

  const payload: InsightDTO = {
    ...insight,
    source: insight?.source ?? "manual",
    analyzable_type: "domain",
    additional_properties: insightProperties,
  };

  if (buttonAction === "Approve") {
    payload.status = "approved";
  } else if (buttonAction === "Decline") {
    payload.status = "rejected";
  } else {
    /* creating/editing */
    payload.status = "pending";
  }

  return payload;
};

const insightFromAccountForm = (
  form: FormContents<AccountInsightsFormContents>,
  insight?: InsightDTO,
  buttonAction?: string | null,
) => {
  const existingInsightProperties = insight?.additional_properties;
  const insightProperties = (
    [...form.elements] as (HTMLInputElement | HTMLSelectElement)[]
  )
    .filter(
      /* - buttons are included in the elements list even if they're not
       *   descendants of the <form>
       * - language/subcategory can have multiple selected and are handled separately */
      (el) =>
        el.name !== "language" &&
        el.name !== "subcategory" &&
        ["input", "select"].includes(el.tagName.toLowerCase()),
    )
    .reduce(
      (acc, el) => {
        const id = el.name || el.id;

        return el.value?.length
          ? { ...acc, [id]: String(el.value).trim() }
          : id in acc
          ? { ...acc, [id]: null }
          : acc;
      },
      { ...existingInsightProperties },
    );

  insightProperties.language = getListBoxValue(form.elements.language);
  insightProperties.subcategory = getListBoxValue(form.elements.subcategory);

  const payload: InsightDTO = {
    ...insight,
    source: insight?.source ?? "manual",
    analyzable_type: "account",
    additional_properties: insightProperties,
  };

  if (buttonAction === "Approve") {
    payload.status = "approved";
  } else if (buttonAction === "Decline") {
    payload.status = "rejected";
  } else {
    /* creating/editing */
    payload.status = "pending";
  }

  return payload;
};

const isDomainFormContents = (
  f: FormContents<CreateInsightsFormSharedContents>,
): f is FormContents<CreateDomainInsightsFormContents> => {
  return f?.elements.type.value === "domain";
};

const isAccountFormContents = (
  f: FormContents<CreateInsightsFormSharedContents>,
): f is FormContents<CreateAccountInsightsFormContents> => {
  return f?.elements.type.value === "account";
};

export {
  StyledCard,
  insightFromAccountForm,
  insightFromDomainForm,
  isAccountFormContents,
  isDomainFormContents,
};
