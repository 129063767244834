import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import CenteredLayout from "~/components/CenteredLayout";
import { ProfileDialogContextProvider } from "~/components/ProfileDialogContext";
import Section, { Heading } from "~/components/Section";
import Icon from "~/components/library/Icon";
import ThemeInModule from "~/components/nrms/ThemeInModule";
import type { NrmsConversationThemeMetricsDTO } from "~/dto/nrmsConversationThemeMetrics";
import { display, text } from "~/styles/typography";

interface NarrativeActivityProps {
  data: NrmsConversationThemeMetricsDTO[];
}

const SectionWrapper = styled(Section)`
  width: 100%;
  height: 100%;
  padding: var(--spacing-xl) var(--spacing-2xl);
`;

const SectionTitle = styled(Heading)`
  ${display.xs.regular}
  color: var(--text-color-primary);
  margin-bottom: var(--spacing-xl);
`;

const ModuleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-lg);

  > article {
    flex-basis: 100%;

    @media (min-width: 768px) {
      flex-basis: calc(50% - var(--spacing-lg));
    }

    @media (min-width: 1024px) {
      flex-basis: calc(33.33% - var(--spacing-lg));
    }
  }
`;

const NoResultMessage = styled(CenteredLayout)`
  flex-direction: column;
  gap: var(--spacing-lg);
  margin-top: var(--spacing-6xl);

  [data-icon] {
    width: 48px;
    height: 48px;
    color: var(--color-utility-gray-500);
  }

  > span {
    ${text.lg.regular};
  }
`;

const NarrativeActivity: FunctionComponent<NarrativeActivityProps> = ({
  data,
}) => {
  return (
    <ProfileDialogContextProvider>
      <SectionWrapper>
        <SectionTitle>Topic Radar</SectionTitle>
        {data ? (
          <ModuleWrapper>
            {data.map(({ id, ...dataProps }) => (
              <ThemeInModule key={id} {...dataProps} />
            ))}
          </ModuleWrapper>
        ) : (
          <NoResultMessage>
            <Icon family="untitled" name="x-circle" />
            <span>No Narrative Activity Theme Found</span>
          </NoResultMessage>
        )}
      </SectionWrapper>
    </ProfileDialogContextProvider>
  );
};

export default NarrativeActivity;
