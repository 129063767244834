import {
  type ForwardedRef,
  type FunctionComponent,
  type HTMLAttributes,
  createContext,
  forwardRef,
  useContext,
} from "react";

type SectionProps = Omit<HTMLAttributes<HTMLElement>, "as"> & {
  as?: "article" | "aside" | "nav" | "section";
};

const DocumentLevelContext = createContext<number>(1);
const { Provider } = DocumentLevelContext;

const useDocumentLevel = () => {
  return useContext(DocumentLevelContext);
};

export type HeadingElementName = Extract<
  keyof HTMLElementTagNameMap,
  "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div"
>;

const getHeadingElementName: (level: number) => HeadingElementName = (
  level,
) => {
  /* use a switch statement instead of `if (level > 0 && level <= 6)` to ensure type-safety */
  switch (level) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
      return `h${level}`;
    default:
      /* spec doesn't define anything beyond `<h6>` */
      return "div";
  }
};

const Heading: FunctionComponent<SectionProps> = (props) => {
  const documentLevel = useDocumentLevel();
  const ElName = getHeadingElementName(documentLevel);

  return <ElName data-auto-header {...props} />;
};

function Section(
  { as: ElementName = "section", ...otherProps }: SectionProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const documentLevel = useDocumentLevel();

  return (
    <Provider value={documentLevel + 1}>
      <ElementName ref={ref} {...otherProps} />
    </Provider>
  );
}

export { Heading, Provider, useDocumentLevel };

export default forwardRef(Section);
