import { scaleQuantize } from "@visx/scale";
import { type RefObject, useEffect, useState } from "react";

import {
  type LayoutWordMapper,
  type WordData,
} from "~/components/charts/Wordcloud";
import {
  SPECTRUM_COLOR_COUNT,
  getSpectrumPalette,
} from "~/components/charts/utils";

export function convertValueByText(words: WordData[]) {
  return words.reduce<Record<string, number>>((acc, curr) => {
    return {
      ...acc,
      [curr.text]: curr.count,
    };
  }, {});
}

export function getFillMapper(words: WordData[]): LayoutWordMapper {
  const colorScale = scaleQuantize({
    domain: [
      Math.min(...words.map((w) => w.count)),
      Math.max(...words.map((w) => w.count)),
    ],
    range: getSpectrumPalette(SPECTRUM_COLOR_COUNT).reverse(),
  });

  const valueByText = convertValueByText(words);

  return (word) => colorScale(valueByText[`${word.text}`]);
}

export function useIsVisible<T extends HTMLElement>(
  ref: RefObject<T>,
  opts: IntersectionObserverInit & { disabled?: boolean },
) {
  const { disabled, root, rootMargin, threshold } = opts;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!ref.current || disabled) {
      return;
    }

    /* eslint-disable-next-line compat/compat
    -- we 100% do not care about KaiOS but there's a browserslist bug which somehow prevents us
     * from getting it out of our compat tables */
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { root, rootMargin, threshold },
    );

    observer.observe(ref.current);

    return function cleanup() {
      observer.disconnect();
    };
  }, [ref, disabled, root, rootMargin, threshold]);

  return { isVisible };
}
