import { styled } from "@linaria/react";
import {
  type ReactNode,
  type StyledComponent,
  type FunctionComponent,
  useId,
} from "react";
import {
  type CheckboxProps as BaseCheckboxProps,
  type CheckboxGroupProps as BaseCheckboxGroupProps,
  Checkbox as BaseCheckbox,
  CheckboxGroup as BaseCheckboxGroup,
  Label,
  Text,
  FieldError,
} from "react-aria-components";

import { FormSize, FormContextProvider, useFormSize } from "./Form";
import Icon from "./Icon";
import {
  fieldDescription,
  fieldError,
  fieldLabel,
  itemDescription,
  itemError,
  itemLabel,
  Required,
} from "./formStyles";

export type CheckboxProps = Omit<
  BaseCheckboxProps,
  | "className"
  | "style"
  | "children"
  | "defaultSelected"
  | "isSelected"
  | "isDisabled"
  | "isRequired"
  | "isReadOnly"
> &
  StyledComponent & {
    children?: ReactNode;
    defaultChecked?: boolean;
    checked?: boolean;
    disabled?: boolean;
    required?: boolean;
    readOnly?: boolean;

    error?: string;
    description?: string;
    size?: FormSize;
  };

export type CheckboxGroupProps = Omit<
  BaseCheckboxGroupProps,
  | "className"
  | "style"
  | "children"
  | "isDisabled"
  | "isReadOnly"
  | "isRequired"
> &
  StyledComponent & {
    children: ReactNode;
    disabled?: boolean;
    required?: boolean;
    readOnly?: boolean;

    label?: string;
    error?: string;
    description?: string;
    size?: FormSize;
  };

const CheckboxWrapper = styled.div`
  display: inline-flex;
  position: relative;
  flex-shrink: 0;

  [data-icon] {
    visibility: hidden;
  }
`;

const CheckboxRoot = styled(BaseCheckbox)`
  display: flex;
  align-items: flex-start;

  ${CheckboxWrapper} {
    background-color: var(--background-color-primary);
    border: 1px solid var(--border-color-primary);
  }

  &:has([data-slot="text"]:not(:empty)) ${CheckboxWrapper} {
    top: 2px;
  }

  [data-slot="text"] {
    display: flex;
    flex-direction: column;
  }

  [data-slot="text"]:empty {
    display: none;
  }

  &[data-size="${FormSize.sm}"] {
    gap: var(--spacing-md);

    ${CheckboxWrapper} {
      font-size: 12px;
      padding: 1px;
      border-radius: var(--spacing-xs);
    }
  }

  &[data-size="${FormSize.md}"] {
    gap: var(--spacing-lg);

    [data-slot="text"] {
      gap: var(--spacing-xxs);
    }

    ${CheckboxWrapper} {
      font-size: 14px;
      padding: 2px;
      border-radius: var(--spacing-sm);
    }
  }

  &[data-focused] {
    ${CheckboxWrapper} {
      box-shadow: var(--ring-gray);
    }
  }

  &[data-selected],
  &[data-indeterminate] {
    ${CheckboxWrapper} {
      background-color: var(--background-color-brand-solid);
      color: var(--foreground-color-white);
      border-color: var(--border-color-brand-solid);

      [data-icon] {
        visibility: visible;
      }
    }

    &[data-focused] {
      ${CheckboxWrapper} {
        box-shadow: var(--ring-brand);
      }
    }
  }

  &[data-disabled] {
    ${CheckboxWrapper} {
      background-color: var(--background-color-disabled_subtle);
      border-color: var(--border-color-disabled);

      [data-icon] {
        color: var(--foreground-color-disabled_subtle);
      }
    }
  }
`;

export const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const {
    size: _size,
    children,
    error,
    description,
    defaultChecked,
    checked,
    disabled,
    required,
    readOnly,
    ...rest
  } = props;

  const size = useFormSize(props).size;

  const labelId = useId();
  const descriptionId = useId();
  const errorId = useId();

  const describedBy =
    [description && descriptionId, error && errorId, props["aria-describedby"]]
      .filter(Boolean)
      .join(" ") || undefined;

  const isDefaultLayout =
    Boolean(error) || Boolean(description) || typeof children === "string";

  return (
    <CheckboxRoot
      {...rest}
      aria-describedby={describedBy}
      aria-labelledby={
        isDefaultLayout && children ? labelId : props["aria-labelledby"]
      }
      data-size={size}
      defaultSelected={defaultChecked}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
      isSelected={checked}
    >
      {({ isIndeterminate }) => (
        <>
          <CheckboxWrapper>
            <Icon
              family="untitled"
              name={isIndeterminate ? "minus" : "check"}
            />
          </CheckboxWrapper>
          <div data-slot="text">
            {isDefaultLayout ? (
              <>
                {children && (
                  <span className={itemLabel} id={labelId}>
                    {children}
                  </span>
                )}
                {description && (
                  <span className={itemDescription} id={descriptionId}>
                    {description}
                  </span>
                )}
                {error && (
                  <span className={itemError} id={errorId}>
                    {error}
                  </span>
                )}
              </>
            ) : (
              children
            )}
          </div>
        </>
      )}
    </CheckboxRoot>
  );
};

const CheckboxGroupRoot = styled(BaseCheckboxGroup)`
  .${fieldLabel} {
    margin-bottom: var(--spacing-sm);
  }
`;

export const CheckboxGroup: FunctionComponent<CheckboxGroupProps> = (props) => {
  const {
    disabled,
    readOnly,
    required,
    label,
    description,
    error,
    size: _size,
    children,
    ...rest
  } = props;

  const size = useFormSize(props).size;

  return (
    <CheckboxGroupRoot
      {...rest}
      data-required={required || undefined}
      data-size={size}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
    >
      {label && (
        <Label className={fieldLabel}>
          {label}
          <Required />
        </Label>
      )}
      <FormContextProvider size={size}>{children}</FormContextProvider>
      {description && (
        <Text className={fieldDescription} slot="description">
          {description}
        </Text>
      )}
      {error && <FieldError className={fieldError}>{error}</FieldError>}
    </CheckboxGroupRoot>
  );
};
