import { styled } from "@linaria/react";
import { type CSSProperties, type ReactNode } from "react";
import {
  type ListBoxItemProps,
  type SectionProps as BaseSectionProps,
  type SelectProps as BaseSelectProps,
  Select as BaseSelect,
  Section,
  Header,
  Label,
  Button,
  SelectValue,
  Popover,
  ListBox,
  ListBoxItem,
  Text,
  FieldError,
  Collection,
} from "react-aria-components";

import { text } from "~/styles/typography";

import { FormSize } from "./Form";
import Icon from "./Icon";
import {
  fieldDescription,
  fieldError,
  fieldLabel,
  Required,
} from "./formStyles";

export type SelectProps<T extends object> = Omit<
  BaseSelectProps<T>,
  "isDisabled" | "isRequired" | "children" | "className" | "style"
> & {
  className?: string;
  style?: CSSProperties;
  children: ReactNode | ((item: T) => ReactNode);
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  items?: Iterable<T>;

  label?: string;
  description?: string;
  error?: string;
  preElement?: ReactNode;
};

export type OptionProps<T> = Omit<ListBoxItemProps<T>, "children"> & {
  children: ReactNode;
  description?: string;
  preElement?: ReactNode;
};

export type SectionProps<T> = BaseSectionProps<T> & {
  label?: string;
};

const OpenIcon = styled(Icon)`
  color: var(--foreground-color-quaternary);
  font-size: 20px;
`;

const SelectedValue = styled(SelectValue)`
  flex-grow: 1;
  overflow: hidden;
  text-align: left;

  &[data-placeholder] {
    ${text.md.regular}
    color: var(--text-color-placeholder);
  }
`;

const SelectTrigger = styled(Button)`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  padding: 10px 14px;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color-primary);
  background-color: var(--background-color-primary);
  box-shadow: var(--shadow-xs);

  [data-pre-element]:has(> [data-icon]) {
    font-size: 20px;
    line-height: 1;
    color: var(--foreground-color-quaternary);
  }

  &:has(> ${SelectedValue}:not([data-placeholder])) {
    [data-pre-element] {
      display: none;
    }
  }

  &[data-disabled]:not([data-readonly]) {
    background-color: var(--background-color-disabled_subtle);
    border-color: var(--border-color-disabled);
    color: var(--text-color-disabled);
  }
`;

const SelectRoot = styled(BaseSelect)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  &[data-focused],
  &[data-open] {
    ${SelectTrigger} {
      border-color: var(--border-color-brand);
      box-shadow: var(--ring-brand-shadow-xs);
    }
  }
`;

const SelectMenu = styled(Popover)`
  background-color: var(--background-color-primary);
  min-width: var(--trigger-width);
  border: 1px solid var(--border-color-secondary);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-lg);

  [data-focused] {
    outline: none;
  }

  &[data-placement="top"] {
    --origin: translateY(8px);
  }
  &[data-placement="bottom"] {
    --origin: translateY(-8px);
  }
  &[data-entering] {
    animation: 0.2s popover-slide;
  }
  &[data-exiting] {
    animation: 0.2s ease-in reverse popover-slide;
  }
  @keyframes popover-slide {
    0% {
      transform: var(--origin);
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const SelectList = styled(ListBox)`
  max-height: var(--spacing-80);
  overflow-y: auto;
  padding: var(--spacing-xs) var(--spacing-sm);
`;

const SelectedIcon = styled(Icon)``;

const SelectOption = styled(ListBoxItem)`
  padding: 10px var(--spacing-md);
  border-radius: var(--border-radius-sm);

  &:focus,
  &:focus-visible,
  &[data-focused],
  &[data-focus-visible] {
    outline: none;
  }

  &[data-focused],
  &[data-pressed] {
    background-color: var(--background-color-active);
  }

  &[data-selected] ${SelectedIcon} {
    display: block;
  }

  &[data-disabled] {
    background-color: var(--background-color-disabled_subtle);
  }
`;

const SelectOptionContent = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  cursor: default;

  [data-icon] {
    font-size: 20px;
    color: var(--foreground-color-quaternary);
  }

  ${SelectedIcon} {
    display: none;
  }
`;

const SelectOptionText = styled.div`
  display: contents;
  ${text.md.medium}
  color: var(--text-color-primary);
  flex-grow: 1;

  [slot="label"] {
    white-space: nowrap;
  }

  [slot="description"] {
    ${text.md.regular}
    color: var(--text-color-tertiary);
  }

  ${SelectOption}[data-disabled] & {
    color: var(--text-color-disabled);

    [slot="description"] {
      color: var(--text-color-disabled);
    }
  }
`;

const SectionRoot = styled(Section)`
  header {
    ${text.sm.medium}
    color: var(--text-color-tertiary);
    padding: var(--spacing-xl) var(--spacing-md) 0;
  }
`;

export function Select<T extends object>(props: SelectProps<T>) {
  const {
    children,
    items,
    disabled,
    required,
    readOnly,
    label,
    description,
    error,
    preElement,

    // RAC only uses a truthy check to determine if the default placeholder should be used, so we need a zero-width space to make it truthy
    placeholder = "\u200B",
    ...rest
  } = props;

  return (
    <SelectRoot
      {...rest}
      data-required={required || undefined}
      data-size={FormSize.md}
      isDisabled={disabled || readOnly}
      isRequired={required}
      placeholder={placeholder}
    >
      {label && (
        <Label className={fieldLabel}>
          {label}
          <Required />
        </Label>
      )}
      <SelectTrigger data-readonly={readOnly || undefined}>
        {preElement != null && <span data-pre-element>{preElement}</span>}
        <SelectedValue />
        <OpenIcon family="untitled" name="chevron-down" />
      </SelectTrigger>
      {description && (
        <Text className={fieldDescription} slot="description">
          {description}
        </Text>
      )}
      <FieldError className={fieldError}>{error}</FieldError>
      <SelectMenu offset={4} placement="bottom">
        <SelectList items={items}>{children}</SelectList>
      </SelectMenu>
    </SelectRoot>
  );
}

export function Option<T extends object>(props: OptionProps<T>) {
  const { children, description, preElement, textValue, ...rest } = props;
  const isDefaultLayout =
    preElement != null || description != null || typeof children === "string";

  return (
    <SelectOption
      textValue={
        textValue ?? (typeof children === "string" ? children : undefined)
      }
      {...rest}
    >
      <SelectOptionContent>
        {preElement}
        <SelectOptionText>
          {isDefaultLayout ? (
            <>
              <Text slot="label">{children}</Text>
              {description && <Text slot="description">{description}</Text>}
            </>
          ) : (
            children
          )}
        </SelectOptionText>
        <SelectedIcon family="untitled" name="check" />
      </SelectOptionContent>
    </SelectOption>
  );
}

export function OptionGroup<T extends object>(props: SectionProps<T>) {
  const { label, children, items, ...rest } = props;
  return (
    <SectionRoot {...rest}>
      <Header>{label}</Header>
      {typeof children === "function" ? (
        <Collection items={items}>{children}</Collection>
      ) : (
        children
      )}
    </SectionRoot>
  );
}
