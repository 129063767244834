import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useSwr, { type KeyedMutator, type SWRConfiguration } from "swr";
import type { ZodSchema } from "zod";

import { type MetadataDTO } from "~/dto";

import { useSession } from "../useSession";

import {
  type CreateBuilderFactory,
  type CreateFetcherFactory,
  type GetFetcherFactory,
  type RemoveFetcherFactory,
  type UpdateBuilderFactory,
  type UpdateFetcherFactory,
  defaultCreateBuilderFactory,
  defaultCreateFetcherFactory,
  defaultGetFetcherFactory,
  defaultRemoveFetcherFactory,
  defaultUpdateBuilderFactory,
  defaultUpdateFetcherFactory,
} from "./utils";

export interface Collectible {
  /* we assume all objects in a collection have a field named `id` which is
   * some sort of unique key.  it's optional since create requests won't
   * generally have one provided */
  id?: string;
}

export type WithId<T> = Omit<T, "id"> & {
  /* we're using this datatype to only represent api endpoint responses, in
   * which all objects of a collection are guaranteed to have an id */
  id: string;
};

export interface RemoteCollectionOpts<
  GetShape,
  UpdateOneShape,
  CreateOneShape,
> {
  cacheOpts?: SWRConfiguration<WithId<GetShape>[]>;
  createBuilder?: false | CreateBuilderFactory<GetShape, CreateOneShape>;
  createFetcher?: CreateFetcherFactory<CreateOneShape>;
  getFetcher?: GetFetcherFactory<WithId<GetShape>[]>;
  removeFetcher?: RemoveFetcherFactory;
  schemaValidator: ZodSchema;
  updateBuilder?:
    | false
    | UpdateBuilderFactory<WithId<GetShape>, UpdateOneShape>;
  updateFetcher?: UpdateFetcherFactory<UpdateOneShape>;
}

export function useRemoteCollection<
  GetShape extends Collectible,
  UpdateOneShape = Partial<GetShape>,
  CreateOneShape = Partial<GetShape>,
>(
  apiEndpointPath: string | undefined,
  {
    cacheOpts = {},
    createBuilder = defaultCreateBuilderFactory,
    createFetcher = defaultCreateFetcherFactory,
    getFetcher = defaultGetFetcherFactory,
    schemaValidator,
    removeFetcher = defaultRemoveFetcherFactory,
    updateBuilder = defaultUpdateBuilderFactory,
    updateFetcher = defaultUpdateFetcherFactory,
  }: RemoteCollectionOpts<GetShape, UpdateOneShape, CreateOneShape>,
) {
  const { authToken, userClaims } = useSession();
  const { organization_id } = userClaims ?? {};
  const { data, error, mutate, isLoading } = useSwr<WithId<GetShape>[]>(
    apiEndpointPath,
    getFetcher(authToken, schemaValidator),
    cacheOpts,
  );

  const { validatedData, validatedMetadata } = useMemo(() => {
    if (data && "data" in data && "metadata" in data) {
      return {
        validatedData: (data?.data as WithId<GetShape>[]) ?? [],
        validatedMetadata: (data?.metadata as MetadataDTO) ?? {},
      };
    } else {
      return {
        validatedData: data ?? [],
      };
    }
  }, [data]);

  const [createError, setCreateError] = useState<Error | undefined>(undefined);
  const refreshRef = useRef<KeyedMutator<WithId<GetShape>[]>>(mutate);
  const orgRef = useRef<string | undefined>(organization_id);

  const create = useCallback(
    async (payload: CreateOneShape) => {
      if (!validatedData || !apiEndpointPath) return;

      // Resets createError when the user attempts to execute create method.
      // Without this, a successful retry would still show an error message in the UI.
      setCreateError(undefined);

      if (createBuilder === false) {
        /* optimistic updates are disabled, so await all the things so
         * control is not returned to the consumer of this hook until after
         * the request has succeeded or failed */
        await createFetcher(authToken, payload)(apiEndpointPath);
        await mutate();
      } else {
        /* don't use `await` here since we want to exit this function ASAP so
         * React renders our optimistic update. but we also need to wait
         * until the optimistic update is applied before refreshing our
         * cache, so we need a promise chain */
        return mutate(
          [...validatedData, createBuilder()(payload) as WithId<GetShape>],
          false,
        )
          .then(async () => {
            await createFetcher(authToken, payload)(apiEndpointPath);
            mutate();
          })
          .catch((err) => {
            mutate(validatedData);
            setCreateError(err);
            throw err;
          });
      }
    },
    [
      apiEndpointPath,
      authToken,
      createBuilder,
      createFetcher,
      mutate,
      validatedData,
    ],
  );
  const remove = useCallback(
    async (uuid?: string) => {
      if (!validatedData || !uuid) {
        return;
      }

      /* step 1: optimistic local update */
      const idx = validatedData.findIndex((item) => item.id === uuid);

      if (idx === -1) {
        /* ?? */
        throw new Error(`can't find record to delete`);
      }

      mutate(
        [...validatedData.slice(0, idx), ...validatedData.slice(idx + 1)],
        false,
      );

      /* step 2: make network request and revalidate */
      await removeFetcher(authToken, uuid)(`${apiEndpointPath}/${uuid}`);
      mutate();
    },
    [apiEndpointPath, authToken, mutate, removeFetcher, validatedData],
  );
  const update = useCallback(
    async (uuid: string, payload: UpdateOneShape) => {
      if (!validatedData) {
        return;
      }

      /* step 1: optimistic local update */
      if (updateBuilder !== false) {
        const idx = validatedData.findIndex((item) => item.id === uuid);

        if (idx !== -1) {
          mutate(
            [
              ...validatedData.slice(0, idx),
              updateBuilder()(validatedData[idx], payload),
              ...validatedData.slice(idx + 1),
            ],
            false,
          );
        }
      }

      /* step 2: make network request and revalidate */
      await updateFetcher(authToken, payload)(`${apiEndpointPath}/${uuid}`);
      mutate();
    },
    [
      apiEndpointPath,
      authToken,
      mutate,
      updateBuilder,
      updateFetcher,
      validatedData,
    ],
  );

  /* when we detect a change to the effective organization of a user, refresh
   * our data */
  useEffect(() => {
    if (organization_id && organization_id !== orgRef.current) {
      orgRef.current = organization_id;
      refreshRef.current?.([]);
    }
  }, [organization_id]);

  return {
    create,
    createError,
    data: validatedData,
    error: createError ?? error,
    metadata: validatedMetadata,
    refresh: mutate,
    remove,
    update,
    isLoading,
  };
}
