import {
  StrippedUserListValidator,
  type StrippedUserDTO,
} from "~/dto/strippedUser";
import { UserListValidator, type UserDTO } from "~/dto/user";

import { useCurrentUser } from "./useCurrentUser";
import {
  useRemoteCollection,
  useRemoteObjectFromCollection,
} from "./useRemoteData";

const useUsers = () => {
  return useRemoteCollection<UserDTO>("/api/v2/user", {
    schemaValidator: UserListValidator,
  });
};

const useStrippedUsers = () => {
  return useRemoteCollection<StrippedUserDTO>("/api/v2/user/strippedUsers", {
    schemaValidator: StrippedUserListValidator,
  });
};

const useSingleUser = (id?: string) =>
  useRemoteObjectFromCollection("/api/v2/user", id, {
    schemaValidator: UserListValidator,
  });

const useOrganizationUsers = () => {
  const users = useUsers();
  const { currentUser } = useCurrentUser();
  const usersInOrg = users.data.filter(
    (recipient) => recipient.organization_id == currentUser?.organization_id,
  );
  return {
    ...users,
    data: usersInOrg,
  };
};

export { useSingleUser, useUsers, useStrippedUsers, useOrganizationUsers };
