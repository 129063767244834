import { styled } from "@linaria/react";
import {
  useId,
  type ReactElement,
  type ReactNode,
  type StyledComponent,
  type FunctionComponent,
} from "react";

import { text } from "~/styles/typography";

import Card from "./Card";
import { FormContentsContainer } from "./FormComponents";
import {
  DialogTitle,
  DialogHeadingContainer,
  XButton,
  DialogBottomContainer,
} from "./ModalDialog";
import Icon from "./library/Icon";

interface ConfirmationFormProps extends StyledComponent {
  cancelButton: ReactElement;
  confirmButton: ReactElement;
  errorMessage?: string;
  id?: string;
  message: ReactNode;
  title: string;
}

const Message = styled.div`
  max-width: 400px;
  ${text.sm.regular};
`;

const StyledIcon = styled(Icon)`
  color: var(--color-warning-500);
  margin-right: var(--spacing-md);
`;

const ErrorMessageContainer = styled.div`
  color: var(--color-error-600);
`;

const FlexedHeader = styled(DialogHeadingContainer)`
  display: flex;
  align-items: center;

  h1 {
    padding-left: 0;
    flex-grow: 1;
  }
`;

const FormContainer = styled(FormContentsContainer)`
  padding-bottom: var(--spacing-xl);
`;

// TODO: this should not require ReactElements as props; it should require
// cancelButtonText and confirmButtonText plus their respective callbacks.  we
// want all confirmation forms to have the same controls
const ConfirmationForm: FunctionComponent<ConfirmationFormProps> = (props) => {
  const { cancelButton, confirmButton, errorMessage, id, message, title } =
    props;
  let elId = useId();

  elId = id ?? elId;

  return (
    <Card>
      <FlexedHeader>
        <StyledIcon family="untitled" name="alert-triangle" />
        <DialogTitle id={`${elId}-title`}>{title}</DialogTitle>
        <XButton onPress={cancelButton.props.onClick} />
      </FlexedHeader>
      <FormContainer>
        <Message id={`${elId}-message`}>{message}</Message>
      </FormContainer>
      <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
      <DialogBottomContainer>
        {cancelButton}
        {confirmButton}
      </DialogBottomContainer>
    </Card>
  );
};

export default ConfirmationForm;
