import { type StyledComponent, type FunctionComponent } from "react";

import {
  Container,
  Path,
  StyledLoadingIndicator,
} from "./LoadingIndicator.styles";

export interface Props extends StyledComponent {
  /**
   * Any color value
   */
  color?: string;
  containerHeight?: number | string;
  /**
   * Duration of animation in milliseconds
   */
  durationInMs?: number | string;
  width?: string;
}

export const LoadingIndicator: FunctionComponent<Props> = (props) => {
  const {
    color = "var(--color-accent-500)",
    containerHeight,
    durationInMs,
    width = "5em",
    ...otherProps
  } = props;

  let durationValue = "1500ms";

  if (typeof durationInMs === "string") {
    durationValue = durationInMs;
  } else if (typeof durationInMs === "number") {
    durationValue = durationInMs + "ms";
  }

  return (
    <Container aria-hidden="true" {...otherProps}>
      <StyledLoadingIndicator
        style={{
          "--color": color,
          "--duration-value": durationValue,
          "--width": width,
          height: containerHeight,
        }}
        viewBox="0 10 100 20"
      >
        <title>Loading</title>

        <Path d="m 12.5,20 15,0 0,0 -15,0 z" />
        <Path d="m 32.5,20 15,0 0,0 -15,0 z" />
        <Path d="m 52.5,20 15,0 0,0 -15,0 z" />
        <Path d="m 72.5,20 15,0 0,0 -15,0 z" />
      </StyledLoadingIndicator>
    </Container>
  );
};

export default LoadingIndicator;
