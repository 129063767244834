import {
  init as initSentry,
  reactRouterV6Instrumentation,
  withProfiler,
  withSentryReactRouterV6Routing,
  BrowserTracing,
} from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  Routes,
} from "react-router-dom";

export const isErrorReportingEnabled =
  process.env.NODE_ENV === "production" &&
  process.env.ARTEMIS_ENV !== "development" &&
  Boolean(process.env.SENTRY_DSN);

function init() {
  if (!isErrorReportingEnabled) return;

  const hostname = document.location.hostname.split(".")[0];
  const isProdEnv = hostname === "stage" || hostname.startsWith("prod");
  const environment = isProdEnv
    ? hostname /* "stage", "production", or customer-specific subdomains */
    : process.env.ARTEMIS_ENV ?? "dev";

  initSentry({
    dsn: process.env.SENTRY_DSN,
    environment,
    beforeBreadcrumb: (breadcrumb) =>
      breadcrumb.category !== "console" ? breadcrumb : null,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate:
      hostname === "prod"
        ? 0.25 // Capture performance data for 25% of transactions in prod env
        : 1, // ...or for everyone in stage env so we can catch issues early
  });
}

const getRoutesWithSentry = () => withSentryReactRouterV6Routing(Routes);

export { init, withProfiler, getRoutesWithSentry };
