import { styled } from "@linaria/react";
import { type HTMLAttributes, type FunctionComponent } from "react";

import { type UserDTO } from "~/dto";
import { text } from "~/styles/typography";

const UserInitials = styled.div`
  border-radius: 50%;
  border: none;
  padding: var(--spacing-xs);
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
  color: var(--color-gray-900);
  background-color: var(--color-white);
  ${text.sm.regular};
`;

interface UserInitialsCardProps extends HTMLAttributes<HTMLDivElement> {
  currentUser: UserDTO;
}

const UserInitialsCard: FunctionComponent<UserInitialsCardProps> = (props) => {
  const { currentUser, ...otherProps } = props;

  return (
    <UserInitials {...otherProps}>
      {currentUser?.first_name[0]}
      {currentUser?.last_name[0]}
    </UserInitials>
  );
};

export default UserInitialsCard;
