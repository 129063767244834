import { styled } from "@linaria/react";
import { type HTMLAttributes, forwardRef } from "react";

import { dataSourceFilledImages } from "~/constants";
import { type UserDetailsDTO } from "~/dto/userDetails";
import { text } from "~/styles/typography";

import {
  URLExternalLinkComponent,
  type ExternalLinkComponentProps,
} from "./ConnectionPreview";
import UserPopupOverview from "./UserPopupOverview";

type UserProfileBlockProps = HTMLAttributes<HTMLDivElement> & {
  userDetails?: UserDetailsDTO;
  urlExternalLink?: ExternalLinkComponentProps;
};

const UserContentBlockContainer = styled.div`
  border: 1px solid var(--border-color-primary);
  border-radius: var(--border-radius-sm);
  position: relative;
  padding: var(--spacing-md);
  ${text.md.medium};

  > img {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    border-radius: 0 var(--border-radius-sm);
  }

  > button {
    position: absolute;
    top: 0;
    right: 0;
    height: fit-content;

    img {
      width: 32px;
      height: 32px;
      border-radius: 0 var(--border-radius-sm);
    }
  }

  > :first-child {
    [data-auto-header="true"] {
      text-align: start;

      & + span {
        text-align: start;
      }
    }
  }
`;

const UserContentBlock = forwardRef<HTMLDivElement, UserProfileBlockProps>(
  (props, ref) => {
    const { children, userDetails, urlExternalLink, ...otherProps } = props;
    const cls = userDetails?.class ?? userDetails?.post_class;
    const dataSourceImage = cls
      ? dataSourceFilledImages[cls as keyof typeof dataSourceFilledImages]
      : null;

    return (
      <UserContentBlockContainer ref={ref} {...otherProps}>
        {userDetails && (
          <>
            <UserPopupOverview
              avatar_url={userDetails.avatar_url}
              display_name={userDetails.display_name}
              id={userDetails.rid ?? userDetails.id}
              screen_name={userDetails.screen_name}
              url={userDetails.url}
              useExternalLink
            />
            {dataSourceImage &&
              (urlExternalLink ? (
                <URLExternalLinkComponent {...urlExternalLink}>
                  <img alt="User platform" src={dataSourceImage} />
                </URLExternalLinkComponent>
              ) : (
                <img alt="User platform" src={dataSourceImage} />
              ))}
          </>
        )}
        {children}
      </UserContentBlockContainer>
    );
  },
);

export default UserContentBlock;
