import {
  type ActorKeyInsightsResponseDTO,
  ActorKeyInsightsResponseValidator,
} from "~/dto/actorKeyInsightsResponse";

import {
  type ConversationFilters,
  getConversationFilterRequestParams,
} from "./useConversationFilters";
import { useRemoteObject } from "./useRemoteData";

const useActorPostCounts = (
  conversationId: string | undefined,
  filters: ConversationFilters | null,
  by_platform?: boolean,
) => {
  const params =
    filters !== null ? getConversationFilterRequestParams(filters) : "";
  const apiEndpoint = conversationId
    ? by_platform
      ? `/api/v2/conversations/${conversationId}/account_post_counts?by_platform=true&${params}`
      : `/api/v2/conversations/${conversationId}/account_post_counts?${params}`
    : undefined;

  return useRemoteObject<ActorKeyInsightsResponseDTO>(apiEndpoint, {
    schemaValidator: ActorKeyInsightsResponseValidator,
  });
};

const useInfluencerFollowerCounts = (
  conversationId: string | undefined,
  filters: ConversationFilters | null,
  by_platform?: boolean,
) => {
  const params =
    filters !== null ? getConversationFilterRequestParams(filters) : "";
  const apiEndpoint = conversationId
    ? by_platform
      ? `/api/v2/conversations/${conversationId}/account_follow_counts?actor_type=influencer&by_platform=true&${params}`
      : `/api/v2/conversations/${conversationId}/account_follow_counts?actor_type=influencer&${params}`
    : undefined;
  return useRemoteObject<ActorKeyInsightsResponseDTO>(apiEndpoint, {
    schemaValidator: ActorKeyInsightsResponseValidator,
  });
};

const useInfluencerSharedCounts = (
  conversationId: string | undefined,
  filters: ConversationFilters,
  by_platform?: boolean,
) => {
  const params = getConversationFilterRequestParams(filters);
  const apiEndpoint = conversationId
    ? by_platform
      ? `/api/v2/conversations/${conversationId}/account_shared_counts?actor_type=influencer&by_platform=true&${params}`
      : `/api/v2/conversations/${conversationId}/account_shared_counts?actor_type=influencer&${params}`
    : undefined;

  return useRemoteObject<ActorKeyInsightsResponseDTO>(apiEndpoint, {
    schemaValidator: ActorKeyInsightsResponseValidator,
  });
};

const useInfluencerRepliedToCounts = (
  conversationId: string | undefined,
  filters: ConversationFilters,
  by_platform?: boolean,
) => {
  const params = getConversationFilterRequestParams(filters);
  const apiEndpoint = conversationId
    ? by_platform
      ? `/api/v2/conversations/${conversationId}/account_reply_counts?actor_type=influencer&by_platform=true&${params}`
      : `/api/v2/conversations/${conversationId}/account_reply_counts?actor_type=influencer&${params}`
    : undefined;

  return useRemoteObject<ActorKeyInsightsResponseDTO>(apiEndpoint, {
    schemaValidator: ActorKeyInsightsResponseValidator,
  });
};

const useInfluencerMentionsCounts = (
  conversationId: string | undefined,
  filters: ConversationFilters,
  by_platform?: boolean,
) => {
  const params = getConversationFilterRequestParams(filters);
  const apiEndpoint = conversationId
    ? by_platform
      ? `/api/v2/conversations/${conversationId}/account_mention_counts?actor_type=influencer&by_platform=true&${params}`
      : `/api/v2/conversations/${conversationId}/account_mention_counts?actor_type=influencer&${params}`
    : undefined;

  return useRemoteObject<ActorKeyInsightsResponseDTO>(apiEndpoint, {
    schemaValidator: ActorKeyInsightsResponseValidator,
  });
};

const useAmplifierFollowingCounts = (
  conversationId: string | undefined,
  filters: ConversationFilters | null,
  by_platform?: boolean,
) => {
  const params =
    filters !== null ? getConversationFilterRequestParams(filters) : "";
  const apiEndpoint = conversationId
    ? by_platform
      ? `/api/v2/conversations/${conversationId}/account_follow_counts?actor_type=amplifier&by_platform=true&${params}`
      : `/api/v2/conversations/${conversationId}/account_follow_counts?actor_type=amplifier&${params}`
    : undefined;

  return useRemoteObject<ActorKeyInsightsResponseDTO>(apiEndpoint, {
    schemaValidator: ActorKeyInsightsResponseValidator,
  });
};

const useAmplifierSharingCounts = (
  conversationId: string | undefined,
  filters: ConversationFilters,
  by_platform?: boolean,
) => {
  const params = getConversationFilterRequestParams(filters);
  const apiEndpoint = conversationId
    ? by_platform
      ? `/api/v2/conversations/${conversationId}/account_shared_counts?actor_type=amplifier&by_platform=true&${params}`
      : `/api/v2/conversations/${conversationId}/account_shared_counts?actor_type=amplifier&${params}`
    : undefined;

  return useRemoteObject<ActorKeyInsightsResponseDTO>(apiEndpoint, {
    schemaValidator: ActorKeyInsightsResponseValidator,
  });
};

const useAmplifierReplyingCounts = (
  conversationId: string | undefined,
  filters: ConversationFilters,
  by_platform?: boolean,
) => {
  const params = getConversationFilterRequestParams(filters);
  const apiEndpoint = conversationId
    ? by_platform
      ? `/api/v2/conversations/${conversationId}/account_reply_counts?actor_type=amplifier&by_platform=true&${params}`
      : `/api/v2/conversations/${conversationId}/account_reply_counts?actor_type=amplifier&${params}`
    : undefined;

  return useRemoteObject<ActorKeyInsightsResponseDTO>(apiEndpoint, {
    schemaValidator: ActorKeyInsightsResponseValidator,
  });
};

const useAmplifierMentioningCounts = (
  conversationId: string | undefined,
  filters: ConversationFilters,
  by_platform?: boolean,
) => {
  const params = getConversationFilterRequestParams(filters);
  const apiEndpoint = conversationId
    ? by_platform
      ? `/api/v2/conversations/${conversationId}/account_mention_counts?actor_type=amplifier&by_platform=true&${params}`
      : `/api/v2/conversations/${conversationId}/account_mention_counts?actor_type=amplifier&${params}`
    : undefined;

  return useRemoteObject<ActorKeyInsightsResponseDTO>(apiEndpoint, {
    schemaValidator: ActorKeyInsightsResponseValidator,
  });
};

export {
  useActorPostCounts,
  useInfluencerFollowerCounts,
  useInfluencerSharedCounts,
  useInfluencerRepliedToCounts,
  useInfluencerMentionsCounts,
  useAmplifierFollowingCounts,
  useAmplifierSharingCounts,
  useAmplifierReplyingCounts,
  useAmplifierMentioningCounts,
};
