import { styled } from "@linaria/react";
import { type HTMLAttributes, type FunctionComponent } from "react";

const StyledCard = styled.div`
  background-color: var(--background-color-primary);
  box-shadow: var(--shadow-lg);
  border-radius: var(--border-radius-md);
  overflow: hidden;
`;

const Card: FunctionComponent<HTMLAttributes<HTMLElement>> = (props) => {
  return <StyledCard {...props} />;
};

export default Card;
