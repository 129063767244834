import { styled } from "@linaria/react";
import {
  type FormContents,
  type FormSubmitEvent,
  type FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";

import { platformForUser } from "~/constants";
import { useAccountInsights } from "~/hooks/useInsights";
import { useUserData } from "~/hooks/useUserNodePopUp";

import ErrorMessageContainer from "../ErrorMessageContainer";
import ModalDialog, {
  DialogHeadingContainer,
  DialogTitle,
  XButton,
} from "../ModalDialog";
import UserPopupOverview from "../UserPopupOverview";

import InsightsDialogButtonPanel from "./InsightsDialogButtonPanel";
import InsightsForm from "./InsightsForm";
import {
  type AccountInsightsDialogProps,
  type AddAccountInsightsFormContents,
} from "./types";
import { StyledCard, insightFromAccountForm } from "./utils";

const StyledUserOverview = styled(UserPopupOverview)`
  margin: var(--spacing-xl);
`;

const AccountProfileHeader = styled(DialogHeadingContainer)`
  align-items: start;
`;

function classIsKnownPlatform(x: unknown): x is keyof typeof platformForUser {
  return (
    typeof x === "string" && Object.keys(platformForUser).indexOf(x) !== -1
  );
}

const useAccountInsightsDialog = (props: AccountInsightsDialogProps) => {
  const { insight, isOpen, mode, onSuccess, uid } = props;
  const dialogTitleA11y = `${mode} Account Insights`;
  const formId = "account-insights-dialog-form";
  /* don't set a rid (which kicks off a network request) until this dialog is open */
  const userDataRid = isOpen ? insight?.analyzable_id ?? "" : "";
  const { data: userData = {} } = useUserData({
    rid: userDataRid,
  });
  const { create, refresh, remove, update } = useAccountInsights(
    isOpen ? uid?.id : undefined,
  );
  const [error, setError] = useState<string | undefined>();
  const onSubmit = useCallback(
    async (e: FormSubmitEvent) => {
      e.preventDefault();

      const submitButton = e.nativeEvent.submitter;

      submitButton.setAttribute("disabled", "disabled");

      try {
        setError(undefined);

        const form =
          e.currentTarget as FormContents<AddAccountInsightsFormContents>;
        const formData = insightFromAccountForm(form, insight);

        if (!Object.keys(formData.additional_properties).length) {
          throw new Error("At least one value must be provided.");
        } else if (
          Object.keys(formData.additional_properties).length === 1 &&
          !!formData.additional_properties.name
        ) {
          throw new Error(
            "At least one value other than Outlet must be provided.",
          );
        }

        if (insight?.analyzable_id) {
          await update(formData);
        } else {
          const platform = uid?.platform
            ? uid.platform
            : classIsKnownPlatform(uid.class)
            ? platformForUser[uid.class]
            : undefined;

          await create({
            insight: formData,
            link: uid?.url,
            platform,
            screen_name: uid?.screen_name,
            analyzable_id: uid?.id,
          });
        }

        onSuccess();
      } catch (ex) {
        setError(String(ex));
      }

      submitButton.removeAttribute("disabled");
    },
    [insight, create, onSuccess, uid, update],
  );
  const onDelete = useCallback(async () => {
    if (!insight?.analyzable_id) {
      return;
    }

    try {
      setError(undefined);

      await remove(insight);

      onSuccess();
    } catch (ex) {
      setError(String(ex));
    }
  }, [insight, onSuccess, remove]);

  useEffect(() => {
    setError(undefined);
  }, [isOpen]);

  return {
    dialogTitleA11y,
    error,
    formId,
    onDelete,
    onSubmit,
    refresh,
    uid: uid ?? userData,
  };
};

const AccountInsightsDialog: FunctionComponent<AccountInsightsDialogProps> = (
  props,
) => {
  const { insight, isOpen, onCancel, mode } = props;

  const { dialogTitleA11y, error, formId, onDelete, onSubmit, refresh, uid } =
    useAccountInsightsDialog(props);

  return (
    <ModalDialog isOpen={isOpen}>
      <StyledCard>
        <AccountProfileHeader>
          <StyledUserOverview {...uid} bio={undefined} />
          <DialogTitle data-at-only>{dialogTitleA11y}</DialogTitle>
          <XButton onPress={onCancel} />
        </AccountProfileHeader>
        <InsightsForm
          aria-label={dialogTitleA11y}
          formId={formId}
          insight={insight}
          onSubmit={onSubmit}
          uid={uid}
        />
        <ErrorMessageContainer>{error}</ErrorMessageContainer>
        <InsightsDialogButtonPanel
          formId={formId}
          insight={insight}
          mode={mode}
          onCancel={onCancel}
          onDelete={onDelete}
          refresh={refresh}
        />
      </StyledCard>
    </ModalDialog>
  );
};

export default AccountInsightsDialog;
