import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import DomainKeyInsights from "./DomainKeyInsights";
import URLKeyInsights from "./URLKeyInsights";
import Tabs, { TabItem, TabKind, TabSize } from "./library/Tabs";

const FixedHeightTabs = styled(Tabs)`
  min-height: 0;

  && > [role="tabpanel"] {
    min-height: 0;
    padding: var(--spacing-3xl) 0;
    display: flex;
    flex-direction: column;

    /* FIXME: tab parent stacked container layout sets all direct children to flex-grow:1 */
    > * {
      flex-grow: 0;
    }

    > :not([aria-hidden="true"]),
    > :not([aria-hidden="true"]) > table {
      min-height: 0; /* flex child with overflow container needs this */
      display: flex;
      flex-direction: column;

      table {
        min-height: 0; /* here too */
      }
    }
  }
`;

const SiteKeyInsights: FunctionComponent<{
  conversationId: string;
  excludeTwitter: boolean;
}> = (props) => {
  const { conversationId, excludeTwitter } = props;

  return (
    <FixedHeightTabs data-kind={TabKind.Outer} data-size={TabSize.sm}>
      <TabItem id="URL-insights" title="URLs">
        <URLKeyInsights
          conversationId={conversationId}
          excludeTwitter={excludeTwitter}
        />
      </TabItem>
      <TabItem id="domain-insights" title="Domains">
        <DomainKeyInsights
          conversationId={conversationId}
          excludeTwitter={excludeTwitter}
        />
      </TabItem>
    </FixedHeightTabs>
  );
};

export default SiteKeyInsights;
