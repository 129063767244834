import { styled } from "@linaria/react";
import {
  type FallbackRender,
  ErrorBoundary as SentryErrorBoundary,
} from "@sentry/react";
import {
  type FunctionComponent,
  type ReactNode,
  useEffect,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

import { useCurrentUser } from "~/hooks/useCurrentUser";
import { useSession } from "~/hooks/useSession";
import { text } from "~/styles/typography";
import { getLoginRedirect } from "~/utils/nav";

import DismissableMessage, { NotifyType } from "./DismissableMessage";
import Header from "./Header";
import ModalDialog from "./ModalDialog";
import Outlet from "./Outlet";

const Main = styled.main`
  display: flex;
  flex-grow: 1;
  align-items: start;
  align-self: stretch;
  overflow: hidden;
`;

const ErrorMessage = styled.span`
  ${text.sm.regular};
  line-height: 18px;
  max-width: 400px;
`;

const renderError: FallbackRender = ({ error, resetError }) => (
  <ModalDialog isOpen>
    <DismissableMessage onClose={resetError} type={NotifyType.Error}>
      <ErrorMessage>{error.message}</ErrorMessage>
    </DismissableMessage>
  </ModalDialog>
);

const ErrorBoundary: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => (
  <SentryErrorBoundary fallback={renderError}>{children}</SentryErrorBoundary>
);

const App = () => {
  const { authToken } = useSession();
  const { currentUser } = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [mounted, setMounted] = useState<boolean>(!!authToken && !!currentUser);

  useEffect(() => {
    if (!authToken && !currentUser) {
      /* don't render anything without first checking to see if we have an auth token and a
       * user and therefore an active session */
      navigate(getLoginRedirect(location), { replace: true });
      return;
    } else if (authToken && currentUser) {
      setMounted(true);
    }
  }, [authToken, currentUser, navigate, location]);

  if (!mounted) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Helmet>
        <title>AG Artemis</title>
        <meta content="AG Artemis application" name="description" />
      </Helmet>
      <Header />
      <Main>
        <Outlet />
      </Main>
    </ErrorBoundary>
  );
};

export default App;
