import { css } from "@linaria/core";
import { useId } from "react";
import {
  DropIndicator,
  useDragAndDrop,
  GridList,
  GridListItem,
  type GridListProps,
  type GridListItemProps,
} from "react-aria-components";
import type { Key } from "react-stately";

import IconButton, { ButtonKind, ButtonSize } from "./library/IconButton";

type ReorderableItem<T extends object = object> = T & {
  id: string;
  label: string;
};

type ReorderableListProps<T extends object = object> = GridListProps<T> & {
  /* not sure why react-aria-components doesn't provide this in TS, it's in the
   * documentation for ListBox */
  className?: string;
  enableDrag?: boolean;
  items: ReorderableItem<T>[];
  onReorder: (what: Key, where: Key, how: "before" | "after") => void;
};

type ListItemProps = GridListItemProps & {
  /* not sure why react-aria-components doesn't provide this in TS, it's in the
   * documentation for ListBox */
  className?: string;
  label: string;
};

/* drop indicators can't leverage styled components since react-aria renders them
 * into a portal where our selectors can't reach them, so we use a className
 * directly */
const dropIndicatorBar = css`
  width: 100%;
  height: 4px;
  background: var(--color-utility-brand-500);
`;

export const ReorderableItem = ({ children, ...props }: ListItemProps) => {
  const { label, ...otherProps } = props;

  return (
    <GridListItem textValue={label} {...otherProps}>
      {({ isDisabled }) => (
        <>
          <IconButton
            aria-label="Reorder this item"
            data-kind={ButtonKind.Tertiary}
            data-size={ButtonSize.sm}
            disabled={isDisabled}
            icon="paragraph-spacing"
            slot="drag"
            type="button"
          />
          {children}
        </>
      )}
    </GridListItem>
  );
};

export const ReorderableList = <T extends object>(
  props: ReorderableListProps<T>,
) => {
  const {
    "aria-label": ariaLabel,
    items,
    onReorder: afterReorder,
    ...otherProps
  } = props;
  const id = useId();
  /* disallow dragging when only one item is present by providing all item keys
   * to `disabledKeys` */
  const disabledKeys = items.length === 1 ? items.map((el) => el.id) : [];
  const dnd = useDragAndDrop({
    acceptedDragTypes: [id],
    getItems: (keys) =>
      [...keys].map((key) => ({
        [id]: String(items.find((item) => item.id === key)?.id),
      })),
    onReorder: (e) => {
      if (e.target.dropPosition === "on") {
        return;
      }

      afterReorder([...e.keys.keys()][0], e.target.key, e.target.dropPosition);
    },
    renderDropIndicator: (target) => (
      /* drop indicators can't leverage styled components since react-aria
       * renders them into a portal where our selectors can't reach them, so we
       * use a className directly */
      <DropIndicator className={dropIndicatorBar} target={target} />
    ),
  });

  return (
    <GridList
      aria-label={ariaLabel}
      disabledKeys={disabledKeys}
      dragAndDropHooks={dnd.dragAndDropHooks}
      items={items}
      selectionMode="none"
      {...otherProps}
    />
  );
};

export default ReorderableList;
