import { styled } from "@linaria/react";
import { useCallback, type VoidFunctionComponent } from "react";
import {
  toast,
  ToastContainer,
  Slide,
  type ToastOptions,
} from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Icon from "~/components/library/Icon";
import { text } from "~/styles/typography";

export enum AlertIntent {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Danger = "danger",
  Error = "error",
}

export const alertIntentIcons: Record<AlertIntent, UntitledIcon> = {
  [AlertIntent.Info]: "info-square",
  [AlertIntent.Success]: "alert-circle",
  [AlertIntent.Warning]: "alert-triangle",
  [AlertIntent.Danger]: "alert-octagon",
  [AlertIntent.Error]: "alert-hexagon",
} as const;

const NotificationContainer = styled(ToastContainer)`
  --toastify-color-progress-success: var(--foreground-color-success-secondary);
  --toastify-color-progress-info: var(--foreground-color-brand-secondary);
  --toastify-color-progress-warning: var(--foreground-color-warning-secondary);
  --toastify-color-progress-error: var(--foreground-color-error-secondary);
  --toastify-font-family: ${text.xs.regular.fontFamily};
  /* remap both light and dark colors; themes are set by us, not by toastify */
  --toastify-text-color-light: var(--text-color-primary);
  --toastify-text-color-dark: var(--text-color-primary);
  --toastify-toast-background: var(--background-color-primary);
  --toastify-toast-width: 320px;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 128px;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);

  & > div {
    padding: var(--spacing-xl);

    & svg {
      width: 20px;
      height: 20px;
    }

    & button svg {
      color: var(--foreground-color-quinary);
    }

    & [role="alert"] {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: var(--spacing-lg);
    }

    & .Toastify__close-button {
      opacity: 1;
    }

    &.Toastify__toast--success [role="alert"] svg {
      color: var(--foreground-color-success-primary);
    }

    &.Toastify__toast--info [role="alert"] svg {
      color: var(--foreground-color-brand-primary);
    }

    &.Toastify__toast--warning [role="alert"] svg {
      color: var(--foreground-color-warning-primary);
    }

    &.Toastify__toast--error [role="alert"] svg {
      color: var(--foreground-color-error-primary);
    }
  }

  @media (prefers-reduced-motion) {
    & > div {
      animation: none;
    }
  }
`;

const Notifications: VoidFunctionComponent = () => {
  return (
    <NotificationContainer
      autoClose={5000}
      className="toastify"
      position="top-right"
    />
  );
};

const defaultNotificationOptions: ToastOptions = {
  transition: Slide,
};

const useNotify = () => {
  const showSuccessToast = useCallback((message: string) => {
    toast.success(message, {
      ...defaultNotificationOptions,
      icon: () => (
        <Icon family="untitled" name={alertIntentIcons[AlertIntent.Success]} />
      ),
      toastId: `toast-${Date.now()}`,
    });
  }, []);
  const showErrorToast = useCallback((message: string) => {
    toast.error(message, {
      ...defaultNotificationOptions,
      icon: () => (
        <Icon family="untitled" name={alertIntentIcons[AlertIntent.Error]} />
      ),
      toastId: `toast-${Date.now()}`,
    });
  }, []);
  const showWarningToast = useCallback((message: string) => {
    toast.warning(message, {
      ...defaultNotificationOptions,
      icon: () => (
        <Icon family="untitled" name={alertIntentIcons[AlertIntent.Warning]} />
      ),
      toastId: `toast-${Date.now()}`,
    });
  }, []);
  const showInfoToast = useCallback((message: string) => {
    toast.info(message, {
      ...defaultNotificationOptions,
      icon: () => (
        <Icon family="untitled" name={alertIntentIcons[AlertIntent.Info]} />
      ),
      toastId: `toast-${Date.now()}`,
    });
  }, []);

  return {
    showErrorToast,
    showInfoToast,
    showSuccessToast,
    showWarningToast,
  };
};

export { Notifications, useNotify };
