import { useMemo } from "react";

import {
  CoURLExtraDataResponseValidator,
  type CoURLExtraDataResponseDTO,
} from "~/dto/coURLExtraDataResponse";

import { useRawDataCreatorDetails } from "./useRawData";
import { useRemoteObject } from "./useRemoteData";

const useUserData = ({
  conversationId,
  rid,
}: {
  conversationId?: string;
  rid: string;
}) => useRawDataCreatorDetails({ conversationId, rid });

const useUserSharedUrls = ({
  conversationId,
  rid,
}: {
  conversationId?: string;
  rid: string;
}) => {
  const apiEndpoint = useMemo(() => {
    if (!conversationId) {
      return undefined;
    }

    const params = new URLSearchParams({
      conversation_id: conversationId,
    });

    return `/api/v2/accounts/${rid}/urls?${params}`;
  }, [conversationId, rid]);
  const remoteObject = useRemoteObject<CoURLExtraDataResponseDTO>(apiEndpoint, {
    schemaValidator: CoURLExtraDataResponseValidator,
  });
  return {
    ...remoteObject,
    data: remoteObject.data?.data[0],
  };
};

export { useUserData, useUserSharedUrls };
