import { ResponseError } from "~/hooks/useRemoteData/utils";

/**
 * Extracts HTTP status code from error messages, matching two few different patterns:
 * 1. '[...] status: 400 [...]'
 * 2. '[...] 400'
 */
function getPasswordChangeErrorCode(
  passwordChangeError?: Error,
): number | null {
  if (!passwordChangeError) {
    return null;
  }
  const regexMatch = passwordChangeError.message.match(/status: (\d{3})/);
  const errorCodeStr =
    regexMatch && regexMatch?.length > 1
      ? regexMatch?.[1]
      : passwordChangeError.message.split(" ").pop();
  const errorCode = Number(errorCodeStr);
  return isNaN(errorCode) ? null : errorCode;
}

export interface PasswordErrors {
  statusCode?: number;
  reason?: string;
  tips?: string[];
}

/**
 * Determines the cause of a password change error based on the HTTP status code and length of the password
 */
function getPasswordChangeErrors(
  passwordChangeError: Error | undefined,
  passwordLength: number,
): PasswordErrors {
  const passwordErrors: {
    reason?: string;
    tips?: string[];
  } = {};
  let errorStatusCode = null;
  let errorStatusDefinition = null;

  if (passwordChangeError instanceof ResponseError) {
    errorStatusCode = passwordChangeError?.status;
    errorStatusDefinition = passwordChangeError?.statusDefintion;
  } else if (passwordChangeError instanceof Error) {
    errorStatusCode = getPasswordChangeErrorCode(passwordChangeError);
  }

  if (!errorStatusCode) {
    return passwordErrors;
  } else if (errorStatusCode === 400) {
    if (passwordLength < 8) {
      passwordErrors.reason =
        "Your password change was unsuccessful because your password was too short. Your password must be between 8 and 64 characters long.";
    } else if (passwordLength > 64) {
      passwordErrors.reason =
        "Your password change was unsuccessful because your password was too long. Your password must be between 8 and 64 characters long.";
    } else {
      passwordErrors.reason =
        "Your password change was unsuccessful because your password did not meet the password strength requirements. Tips for a strong password:";
      passwordErrors.tips = [
        "Use 8 - 64 characters",
        "No common passwords",
        "Use a mix of letters, numbers and symbols",
      ];
    }
  } else if (errorStatusCode === 409) {
    // @TODO: Replace the string value with enum.
    // For additional details, see comment starting at line 49 in ../hooks/useRemoteData/utils.ts.
    if (errorStatusDefinition === "DUPLICATE_USER") {
      passwordErrors.reason =
        "This user already exists. Please check the details and try again with a different email.";
    } else {
      passwordErrors.reason =
        "Something went wrong on our end. Please try again later.";
    }
  } else if (errorStatusCode === 401) {
    passwordErrors.reason =
      "Please enter your current password again. The password you have entered does not match our records.";
  } else {
    passwordErrors.reason = "Something went wrong. Please try again later.";
  }

  return {
    ...passwordErrors,
    statusCode: errorStatusCode,
  };
}

export { getPasswordChangeErrors };
