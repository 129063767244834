import { styled } from "@linaria/react";
import { type PageComponent } from "react";
import { Helmet } from "react-helmet";

import Header from "~/components/Header";
import ResetPasswordForm from "~/components/ResetPasswordForm";

export interface ResetPasswordFormElements {
  resetPasswordNew: HTMLInputElement;
  resetPasswordConfirm: HTMLInputElement;
}

const CenteredFixedTopLayout = styled.main`
  display: flex;
  padding-top: 33vh;
  justify-content: center;
  align-items: center;

  > :first-child {
    width: 400px;
    padding: 0 var(--spacing-2xl) var(--spacing-2xl);
  }
`;

const ResetPasswordPage: PageComponent = () => {
  return (
    <>
      <Helmet>
        <title>AG Artemis: Password reset required</title>
        <meta content="AG Artemis password reset page" name="description" />
      </Helmet>
      <Header />
      <CenteredFixedTopLayout>
        <ResetPasswordForm />
      </CenteredFixedTopLayout>
    </>
  );
};

export default ResetPasswordPage;
