import { styled } from "@linaria/react";
import { useCallback, type FunctionComponent, useMemo } from "react";

import Button, { ButtonKind, ButtonSize } from "~/components/library/Button";
import Icon from "~/components/library/Icon";
import { Tag, TagGroup } from "~/components/library/Tags";
import { text } from "~/styles/typography";

import { edgeStrings, infoByEdgeType, infoByNodeType } from "../constants";
import { isNodeType } from "../types";

import { useGraphContext } from "./GraphContext";

const Container = styled.div`
  display: flex;
  gap: var(--spacing-lg);
  flex-direction: column;

  [data-slot="header"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      ${text.xs.semibold}
    }
  }

  [data-slot="display"] {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-md);
  }
`;

const StyledTagGroup = styled(TagGroup)`
  [data-slot="tagList"] {
    gap: var(--spacing-md);
  }
`;

const StyledTag = styled(Tag)`
  padding: var(--spacing-xxs) var(--spacing-xs) var(--spacing-xxs)
    var(--spacing-md);

  &[data-slot="node"] {
    background-color: var(--background-color-brand-primary);
    border: 1px solid var(--color-utility-brand-200);
    color: var(--color-utility-brand-700);

    > div {
      [data-slot="content"] {
        background-color: var(--background-color-brand-primary);
        padding: 0;
      }

      button {
        &[data-hovered="true"] {
          background-color: var(--color-utility-brand-100);
        }

        color: var(--color-utility-brand-400);
      }
    }
  }

  &[data-slot="edge"] {
    background-color: var(--color-utility-success-50);
    border: 1px solid var(--color-utility-success-200);
    color: var(--color-utility-success-700);

    > div {
      [data-slot="content"] {
        background-color: var(--color-utility-success-50);
        padding: 0;
      }

      button {
        &[data-hovered="true"] {
          background-color: var(--color-utility-success-100);
        }

        color: var(--color-utility-success-400);
      }
    }
  }

  > div {
    [data-slot="content"] {
      align-items: center;
      display: flex;
      gap: var(--spacing-xs);
      width: 100%;
      ${text.sm.medium}
    }
  }
`;

const ClearAllButton = styled(Button)`
  > span {
    display: flex;
    align-items: center;
  }
`;

const SelectedDisplay: FunctionComponent = () => {
  const {
    graphState,
    layoutState: { resetLayout },
  } = useGraphContext();
  const {
    colorGroupBy: [, setColorGroupBy],
    cluster: [, setSelectedGroup],
    edge: [edges, setEdges],
    node: [nodes, setNodes],
    numOfMinEdge: [, setNumOfMinEdge],
  } = graphState;
  const { deleteAllNode, deleteNode } = useGraphContext().legendData;

  const handleClearAllPress = useCallback(() => {
    setColorGroupBy("");
    setNodes("");
    setEdges("");
    setSelectedGroup("");
    deleteAllNode();
    setNumOfMinEdge("0");
  }, [
    deleteAllNode,
    setColorGroupBy,
    setEdges,
    setNodes,
    setNumOfMinEdge,
    setSelectedGroup,
  ]);

  const tagItems = useMemo(() => {
    const nodess = nodes.map((node) => {
      return {
        id: node,
        value: node,
        label: infoByNodeType[node].label,
        iconName: infoByNodeType[node].iconName,
      };
    });

    const edgess = edges.map((edge) => {
      return {
        id: edge,
        value: edge,
        label: infoByEdgeType[edge].text,
        iconName: null,
      };
    });

    return [...nodess, ...edgess];
  }, [edges, nodes]);

  const removeNodeEdge = useCallback(
    (keys: Set<string | number>) => {
      const key = String([...keys][0]);
      if (!key) return;
      if (isNodeType(key)) {
        setNodes([...nodes].filter((node) => node !== key));
        const edgesOfKey = Object.keys(edgeStrings[key]);
        setEdges(edges.filter((edge) => !edgesOfKey.includes(edge)));
        deleteNode(key);
      } else {
        setEdges([...edges].filter((edge) => edge !== key));
      }
      resetLayout();
    },
    [deleteNode, edges, nodes, resetLayout, setEdges, setNodes],
  );

  return (
    <Container>
      <div data-slot="header">
        <span>Active Selections</span>
        <ClearAllButton
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.sm}
          onPress={handleClearAllPress}
          preIcon="x-close"
        >
          Clear All
        </ClearAllButton>
      </div>
      <div data-slot="display">
        <StyledTagGroup
          aria-label="remove nodes and edges"
          items={tagItems}
          onRemove={removeNodeEdge}
        >
          {({ value, label, iconName }) => (
            <StyledTag
              key={value}
              data-slot={iconName ? "node" : "edge"}
              textValue={label}
            >
              {iconName && <Icon family="untitled" name={iconName} />}
              {label}
            </StyledTag>
          )}
        </StyledTagGroup>
      </div>
    </Container>
  );
};

export default SelectedDisplay;
