import type { InsightRiskResponseDTO } from "~/dto";
import { InsightRiskResponseValidator } from "~/dto/insightRiskResponse";

import { useRemoteObject } from "./useRemoteData";

const useConversationInsights = (conversationId: string) => {
  const apiEndpoint = `/api/v2/conversations/${conversationId}/insights`;

  const { data, error, isLoading } = useRemoteObject<InsightRiskResponseDTO>(
    apiEndpoint,
    {
      schemaValidator: InsightRiskResponseValidator,
    },
  );

  return {
    data: data?.data ?? [],
    isLoading,
    error,
  };
};

export { useConversationInsights };
