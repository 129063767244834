import { styled } from "@linaria/react";
import { useControlledState } from "@react-stately/utils";
import {
  type ReactNode,
  type StyledComponent,
  type FunctionComponent,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useField } from "react-aria";
import {
  type ListBoxProps as BaseListBoxProps,
  type ListBoxItemProps,
  type SectionProps as BaseSectionProps,
  type TextProps,
  type Key,
  ListBox as BaseListBox,
  ListBoxItem,
  Section,
  Collection,
  Header,
  Text,
} from "react-aria-components";

import { text } from "~/styles/typography";

import { FormSize, FormContextProvider, useFormSize } from "./Form";
import Icon from "./Icon";
import SearchField from "./SearchField";
import {
  fieldDescription,
  fieldError,
  fieldLabel,
  itemDescription,
  itemLabel,
} from "./formStyles";

export { type Selection } from "react-aria-components";

export type ListBoxProps<T> = Omit<BaseListBoxProps<T>, "className" | "style"> &
  StyledComponent & {
    label?: string;
    description?: string;
    error?: string;
    size?: FormSize;
    name?: string;
  };

export type FilterableListBoxProps<T> = Omit<ListBoxProps<T>, "children"> & {
  children: (item: T) => ReactNode;
  items: Iterable<T>;
  isItemIncluded: (item: T, searchTerm: string) => boolean;
};

export type ListBoxOptionProps = Omit<
  ListBoxItemProps,
  "children" | "className" | "style"
> &
  StyledComponent & {
    children: ReactNode;
    description?: string;
    "aria-describedby"?: string;
  };

export type ListBoxOptionGroupProps<T> = BaseSectionProps<T> & {
  label?: string;
};

type ListBoxContextShape = {
  register: (key: Key | undefined) => void;
};
const ListBoxContext = createContext<ListBoxContextShape | undefined>(
  undefined,
);

const ListBoxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

const CheckboxWrapper = styled.div`
  display: inline-flex;
  position: relative;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-primary);
  top: 2px;
  flex-shrink: 0;

  [data-icon] {
    visibility: hidden;
  }
`;

const ListBoxOptionRoot = styled(ListBoxItem)`
  display: flex;
  align-items: flex-start;

  [data-slot="text"] {
    display: flex;
    flex-direction: column;
  }

  &[data-size="${FormSize.sm}"] {
    gap: var(--spacing-md);

    ${CheckboxWrapper} {
      font-size: 12px;
      padding: 1px;
      border-radius: var(--spacing-xs);
    }
  }

  &[data-size="${FormSize.md}"] {
    gap: var(--spacing-lg);

    [data-slot="text"] {
      gap: var(--spacing-xxs);
    }

    ${CheckboxWrapper} {
      font-size: 14px;
      padding: 2px;
      border-radius: var(--spacing-sm);
    }
  }

  &:focus {
    outline: none;
  }

  &[data-focused] {
    ${CheckboxWrapper} {
      box-shadow: var(--ring-gray);
    }
  }

  &[data-selected] {
    ${CheckboxWrapper} {
      background-color: var(--background-color-brand-solid);
      color: var(--foreground-color-white);
      border-color: var(--border-color-brand-solid);

      [data-icon] {
        visibility: visible;
      }
    }

    &[data-focused] {
      ${CheckboxWrapper} {
        box-shadow: var(--ring-brand);
      }
    }
  }

  &[data-disabled] {
    ${CheckboxWrapper} {
      background-color: var(--background-color-disabled_subtle);
      border-color: var(--border-color-disabled);

      [data-icon] {
        color: var(--foreground-color-disabled_subtle);
      }
    }
  }
`;

const OptionGroupRoot = styled(Section)`
  header {
    ${text.md.regular}
    color: var(--text-color-secondary);
    padding: var(--spacing-xl) var(--spacing-md) 0;
  }
`;

const HiddenFields = styled.div`
  display: none;
`;

export function ListBox<T extends object>(props: ListBoxProps<T>) {
  const {
    className,
    style,
    label,
    description,
    error,
    size: _size,
    ...rest
  } = props;
  const isInvalid = Boolean(error);
  const { labelProps, fieldProps, descriptionProps, errorMessageProps } =
    useField({
      ...rest,
      labelElementType: "span",
      label,
      isInvalid,
      errorMessage: error,
    });

  const { size } = useFormSize(props);

  return (
    <ListBoxGroup className={className} data-size={size} style={style}>
      <FormContextProvider size={size}>
        {label && (
          <span className={fieldLabel} data-slot="label" {...labelProps}>
            {label}
          </span>
        )}
        <ListBoxInner {...rest} {...fieldProps} data-slot="listbox" />
        {description && (
          <Text
            {...descriptionProps}
            className={fieldDescription}
            data-slot="description"
          >
            {description}
          </Text>
        )}
        {isInvalid && (
          <Text
            {...errorMessageProps}
            className={fieldError}
            data-slot="errorMessage"
          >
            {error}
          </Text>
        )}
      </FormContextProvider>
    </ListBoxGroup>
  );
}

export function FilterableListBox<T extends object>(
  props: FilterableListBoxProps<T>,
) {
  const {
    className,
    style,
    label,
    description,
    error,
    size: _size,
    items,
    isItemIncluded,
    ...rest
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const isInvalid = Boolean(error);
  const { labelProps, fieldProps, descriptionProps, errorMessageProps } =
    useField({
      ...rest,
      labelElementType: "span",
      label,
      isInvalid,
      errorMessage: error,
    });

  const filteredItems = useMemo(
    () =>
      searchTerm
        ? [...items].filter((item) => isItemIncluded(item, searchTerm))
        : items,
    [items, searchTerm, isItemIncluded],
  );

  const { size } = useFormSize(props);

  return (
    <ListBoxGroup className={className} data-size={size} style={style}>
      <FormContextProvider size={size}>
        {label && (
          <span className={fieldLabel} data-slot="label" {...labelProps}>
            {label}
          </span>
        )}
        <SearchField
          aria-controls={fieldProps.id}
          onSearchChange={setSearchTerm}
        />
        <ListBoxInner
          {...rest}
          {...fieldProps}
          data-slot="listbox"
          items={filteredItems}
        />
        {description && (
          <Text
            {...descriptionProps}
            className={fieldDescription}
            data-slot="description"
          >
            {description}
          </Text>
        )}
        {isInvalid && (
          <Text
            {...errorMessageProps}
            className={fieldError}
            data-slot="errorMessage"
          >
            {error}
          </Text>
        )}
      </FormContextProvider>
    </ListBoxGroup>
  );
}

type ListBoxInnerProps<T extends object> = BaseListBoxProps<T> & {
  name?: string;
};

// TODO: Refactor this to use react-aria-components collection utilities when they are released https://github.com/adobe/react-spectrum/issues/5954
/* Ideally, this component would be able to somehow read the selected keys from
react-aria-component ListBox's internal state--either by injecting that state
directly or by reading it from Context--but neither of those methods are
currently exposed for "Collection" components.

The current workaround keeps a second copy of the selected keys in state, and
updates the hidden input fields when the selection changes. In order to know
which keys correspond to the "all" selection, we need to require that all leaf
nodes call back to this ancestor component once their key has been determined
by react-aria-components. */
function ListBoxInner<T extends object>(props: ListBoxInnerProps<T>) {
  const {
    name,
    selectedKeys: controlledSelectedKeys,
    defaultSelectedKeys = [],
    onSelectionChange,
    ...rest
  } = props;

  const { keys, register } = useListBoxKeys();
  const [selection, setSelectedKeys] = useControlledState(
    controlledSelectedKeys,
    defaultSelectedKeys,
    onSelectionChange,
  );

  const selectedKeys = useMemo(() => {
    return selection === "all" ? [...keys] : [...selection];
  }, [selection, keys]);

  return (
    <>
      <ListBoxContext.Provider value={{ register }}>
        <BaseListBox
          {...rest}
          onSelectionChange={setSelectedKeys}
          selectedKeys={selection}
        />
      </ListBoxContext.Provider>
      {name && (
        <HiddenFields>
          {selectedKeys.map((key) => (
            <input key={key} name={name} readOnly type="hidden" value={key} />
          ))}
        </HiddenFields>
      )}
    </>
  );
}

function useListBoxKeys() {
  const [keys, setKeys] = useState(new Set<Key>());

  const register = useCallback((key: Key | undefined) => {
    if (key == null) {
      return;
    }

    setKeys((prevKeys) => new Set(prevKeys).add(key));
  }, []);

  return { keys, register };
}

function useListBoxOption(key: Key | undefined) {
  const context = useContext(ListBoxContext);
  if (!context) {
    throw new Error("ListBoxOption must be used within a ListBox");
  }
  const { register } = context;

  useLayoutEffect(() => {
    register(key);
  }, [key, register]);
}

export const ListBoxOptionLabel: FunctionComponent<TextProps> = (props) => (
  <Text slot="label" {...props} />
);

export const ListBoxOptionDescription: FunctionComponent<TextProps> = (
  props,
) => <Text slot="description" {...props} />;

export const ListBoxOption: FunctionComponent<ListBoxOptionProps> = (props) => {
  const { children, description, ...rest } = props;
  const isDefaultLayout = description != null || typeof children === "string";

  const { size } = useFormSize({});
  useListBoxOption(props.id);

  return (
    <ListBoxOptionRoot
      textValue={typeof children === "string" ? children : undefined}
      {...rest}
      data-size={size}
    >
      <CheckboxWrapper>
        <Icon family="untitled" name="check" />
      </CheckboxWrapper>
      <div data-slot="text">
        {isDefaultLayout ? (
          <>
            {children && (
              <ListBoxOptionLabel className={itemLabel}>
                {children}
              </ListBoxOptionLabel>
            )}
            {description && (
              <ListBoxOptionDescription className={itemDescription}>
                {description}
              </ListBoxOptionDescription>
            )}
          </>
        ) : (
          children
        )}
      </div>
    </ListBoxOptionRoot>
  );
};

export function ListBoxOptionGroup<T extends object>(
  props: ListBoxOptionGroupProps<T>,
) {
  const { label, children, items, ...rest } = props;

  return (
    <OptionGroupRoot {...rest}>
      <Header>{label}</Header>
      {typeof children === "function" ? (
        <Collection items={items}>{children}</Collection>
      ) : (
        children
      )}
    </OptionGroupRoot>
  );
}

function isSingleNode(x: unknown): x is HTMLInputElement {
  return (
    x !== null &&
    typeof x === "object" &&
    "tagName" in x &&
    x.tagName === "INPUT"
  );
}

export function getListBoxValue(
  nodeList: NodeListOf<HTMLInputElement> | HTMLInputElement,
): string[] {
  if (!nodeList) {
    return [];
  }

  /* HTMLFormControlsCollection.<foo> can be either a single element or a list of elements :/ */
  const nodes = isSingleNode(nodeList) ? [nodeList] : Array.from(nodeList);

  return nodes.map((el) => el.value);
}
