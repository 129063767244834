import { styled } from "@linaria/react";
import { sum } from "d3-array";
import { type FunctionComponent, type RefObject } from "react";

import { type TopicInsightCategory } from "~/constants";
import {
  type RiskCategoryMapDTO,
  type TopicInsightDTO,
  type TopicListDTO,
} from "~/dto";

import { LoadingIndicator } from "./library";

type TopicsOverviewProps = {
  isLoading: boolean;
  svgRef: RefObject<HTMLDivElement>;
};

const TopicsOverviewContainer = styled.div`
  position: relative;
`;

const BubbleChartContainer = styled.div`
  padding: var(--spacing-md) var(--spacing-md) var(--spacing-xs)
    var(--spacing-md);
`;

const ChartLoadingIndicator = styled(LoadingIndicator)`
  position: absolute;
  inset: 0;
`;

export function getColors(risk_map: RiskCategoryMapDTO) {
  const colors: Record<TopicInsightCategory, { fill: string; text: string }> = {
    [risk_map["Categories"][0]]: {
      fill: "var(--color-chart-category-110)",
      text: "var(--color-white)",
    },
    [risk_map["Categories"][1]]: {
      fill: "var(--color-chart-category-100)",
      text: "var(--color-white)",
    },
    [risk_map["Categories"][2]]: {
      fill: "var(--color-chart-category-90)",
      text: "var(--color-white)",
    },
    [risk_map["Categories"][3]]: {
      fill: "var(--color-chart-category-80)",
      text: "var(--color-black)",
    },
    [risk_map["Categories"][4]]: {
      fill: "var(--color-chart-category-70)",
      text: "var(--color-black)",
    },
    [risk_map["Categories"][5]]: {
      fill: "var(--color-chart-category-60)",
      text: "var(--color-black)",
    },
    [risk_map["Categories"][6]]: {
      fill: "var(--color-chart-category-50)",
      text: "var(--color-black)",
    },
    [risk_map["Categories"][7]]: {
      fill: "var(--color-chart-category-40)",
      text: "var(--color-black)",
    },
    [risk_map["Categories"][8]]: {
      fill: "var(--color-chart-category-30)",
      text: "var(--color-black)",
    },
    [risk_map["Categories"][9]]: {
      fill: "var(--color-chart-category-20)",
      text: "var(--color-black)",
    },
    [risk_map["Categories"][10]]: {
      fill: "var(--color-chart-category-10)",
      text: "var(--color-black)",
    },
  };
  return colors;
}

export interface TopicInsights {
  topicRid: string;
  ngrams: string[];
  postCount: number;
  counts: number[];
  subcategories: string[];
  countries_of_origin: string[];
  ratios: number[];
  maxRatio: number;
  subcategory: string;
  country_of_origin: string;
  postDates: string[];
  insights: string[];
}

const SUBCATEGORY_THRESHOLD_1 = 0.05;
const SUBCATEGORY_THRESHOLD_2 = 0.15;

/*
 * This method is called when there is a tie between multiple subcategories. It sets the topic subcategory, and country when possible, for
 * topics that have a tie between multiple subcategories. We can only set the country
 */
function getSubcategoryForTie(
  topicObj: TopicInsights,
  risk_map: RiskCategoryMapDTO,
) {
  const keys = Object.keys(risk_map);
  const ratio = topicObj.maxRatio;
  const tied_insights: string[] = [];
  //Collect all subcategories that are tied
  for (let i = 0; i < topicObj.subcategories.length; i++) {
    if (ratio === topicObj.ratios[i]) {
      tied_insights.push(topicObj.insights[i]);
    }
  }
  if (tied_insights.includes(keys[0])) {
    topicObj.subcategory = keys[0];
  } else if (tied_insights.includes(keys[1])) {
    topicObj.subcategory = keys[1];
    topicObj.country_of_origin = "China";
  } else if (tied_insights.includes(keys[2])) {
    topicObj.subcategory = keys[2];
    topicObj.country_of_origin = "Russia";
  } else if (tied_insights.includes(keys[3])) {
    topicObj.subcategory = keys[3];
  } else if (tied_insights.includes(keys[4])) {
    topicObj.subcategory = keys[4];
  } else if (tied_insights.includes(keys[5])) {
    topicObj.subcategory = keys[5];
  }
}

/* Returns whether or not the topic is considered to be part of the Combined Risky insight or the Default insight. */
function checkCombinedRisky(ratios: number[]) {
  if (sum(ratios) > SUBCATEGORY_THRESHOLD_2) {
    return "Combined Risky";
  }
  return "Default";
}

/*
 * Given a list of topics, topicsList, of type TopicListDTO, and another list of topics, filteredTopics, of type TopicInsightDTO,
 * this method builds and returns an array of type TopicInsights, which contains information used to calculate topic insights for
 * each topic in the conversation
 */
export function getFinalTopicArray(
  topicsList: TopicListDTO,
  risk_map: RiskCategoryMapDTO | undefined,
  filteredTopics: TopicInsightDTO[],
): TopicInsights[] {
  const finalTopicArray: TopicInsights[] = [];
  if (filteredTopics && risk_map) {
    for (let i = 0; i < filteredTopics.length; i++) {
      const rid = filteredTopics[i].topic;
      const topic = topicsList.topics.find((t) => t.rid === rid);
      if (!topic) {
        throw new Error(
          "There is no record for this topic in the topic list. Something has gone wrong!",
        );
      }
      const obj: TopicInsights = {
        topicRid: rid,
        ngrams: topic.ngrams,
        postCount: topic.post_dates.length,
        counts: filteredTopics[i].count,
        subcategories: filteredTopics[i].subcategory,
        countries_of_origin: filteredTopics[i].country_of_origin,
        ratios: filteredTopics[i].count.map((c) => c / topic.post_dates.length),
        maxRatio: Math.max(
          ...filteredTopics[i].count.map((c) => c / topic.post_dates.length),
        ),
        subcategory: "",
        country_of_origin: "",
        postDates: topic.post_dates,
        insights: filteredTopics[i].risk_category,
      };
      finalTopicArray.push(obj);
      const index = obj.ratios.indexOf(obj.maxRatio);
      const lastIndex = obj.ratios.lastIndexOf(obj.maxRatio);
      //if there's a tie on the max ratio, use the category that's higher on the risk scale
      if (index !== lastIndex) {
        if (obj.maxRatio > SUBCATEGORY_THRESHOLD_1) {
          getSubcategoryForTie(obj, risk_map);
        } else {
          obj.subcategory = checkCombinedRisky(obj.ratios);
        }
      } else {
        if (obj.maxRatio > SUBCATEGORY_THRESHOLD_1) {
          obj.subcategory = obj.insights[index];
          obj.country_of_origin = obj.countries_of_origin[index];
        } else {
          obj.subcategory = checkCombinedRisky(obj.ratios);
        }
      }
    }
  }
  return finalTopicArray;
}

const TopicsOverview: FunctionComponent<TopicsOverviewProps> = (props) => {
  const { svgRef, isLoading } = props;

  return (
    <TopicsOverviewContainer>
      <BubbleChartContainer
        ref={svgRef}
        aria-label="Topics bubble chart"
        role="img"
      />
      {isLoading && <ChartLoadingIndicator />}
    </TopicsOverviewContainer>
  );
};

export default TopicsOverview;
