import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import { type PasswordErrors } from "~/utils/passwordErrors";

const ErrorContainer = styled.div`
  display: inline-block;
  margin-left: var(--spacing-xs);
`;

const PasswordTips = styled.ul`
  padding-left: var(--spacing-xl);
  padding-top: var(--spacing-xs);
`;

const PasswordTip = styled.li`
  list-style-type: disc;
`;

interface PasswordChangeErrorProps {
  passwordErrors: PasswordErrors;
}

const PasswordChangeError: FunctionComponent<PasswordChangeErrorProps> = ({
  passwordErrors,
}) => (
  <ErrorContainer>
    {passwordErrors.reason}
    {passwordErrors.tips && (
      <PasswordTips>
        {passwordErrors.tips.map((tipText) => (
          <PasswordTip key={tipText}>{tipText}</PasswordTip>
        ))}
      </PasswordTips>
    )}
  </ErrorContainer>
);

export default PasswordChangeError;
