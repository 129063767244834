import { styled } from "@linaria/react";
import {
  type FormSubmitEvent,
  type FunctionComponent,
  type FormContents,
  useCallback,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  type ChangePasswordRequestDTO,
  type ResetPasswordRequestDTO,
  type ResetPasswordResponseDTO,
} from "~/dto";
import { ResetPasswordResponseValidator } from "~/dto/resetPasswordResponse";
import { useNotify } from "~/hooks/useNotify";
import { useRemoteAction } from "~/hooks/useRemoteData";
import {
  unauthenticatedActionFetcherFactory,
  voidResponseActionFetcherFactory,
} from "~/hooks/useRemoteData/utils";
import { text } from "~/styles/typography";
import { logEvent } from "~/utils/analytics";
import {
  type PasswordErrors,
  getPasswordChangeErrors,
} from "~/utils/passwordErrors";

import Card from "./Card";
import DismissableMessage, { NotifyType } from "./DismissableMessage";
import {
  FormContentsContainer,
  FormTitle,
  ButtonContainer,
} from "./FormComponents";
import ModalDialog from "./ModalDialog";
import PasswordChangeError from "./PasswordChangeError";
import Button, { ButtonKind, ButtonSize } from "./library/Button";
import Form from "./library/Form";
import Input from "./library/Input";

export interface ResetPasswordFormElements {
  resetPasswordNew: HTMLInputElement;
  resetPasswordConfirm: HTMLInputElement;
}

const PasswordRulesContainer = styled.div`
  max-width: 320px;
  ${text.xs.regular};
  margin-top: var(--spacing-xs);
`;

const StyledInput = styled(Input)`
  margin-top: var(--spacing-3xl);
`;

function usePasswordChange() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { showSuccessToast } = useNotify();
  const onSuccess = useCallback(async () => {
    showSuccessToast("Your password has been successfully set");
    navigate("/");
  }, [navigate, showSuccessToast]);
  const { execute } = useRemoteAction<ChangePasswordRequestDTO, void>(
    "/api/v2/user/changePassword",
    {
      actionFetcher: voidResponseActionFetcherFactory,
      schemaValidator: undefined,
      verb: "PUT",
    },
  );

  return {
    execute,
    onSuccess,
    token: searchParams.get("token"),
  };
}

function usePasswordReset() {
  const navigate = useNavigate();
  const { showSuccessToast } = useNotify();
  const [searchParams] = useSearchParams();
  const onSuccess = useCallback(async () => {
    showSuccessToast("Your password has been successfully reset");
    navigate("/login");
  }, [navigate, showSuccessToast]);
  const { execute } = useRemoteAction<
    ResetPasswordRequestDTO,
    ResetPasswordResponseDTO
  >("/api/v2/user/resetPassword", {
    schemaValidator: ResetPasswordResponseValidator,
    actionFetcher: unauthenticatedActionFetcherFactory,
    verb: "POST",
  });

  return {
    execute,
    onSuccess,
    token: searchParams.get("token"),
  };
}

const useResetPasswordForm = () => {
  const {
    execute: doReset,
    token,
    onSuccess: onResetSuccess,
  } = usePasswordReset();
  const { execute: doChange, onSuccess: onChangeSuccess } = usePasswordChange();
  const [showError, setShowError] = useState<boolean>(false);
  const [passwordErrors, setPasswordErrors] = useState<PasswordErrors>({});
  const onCloseError = useCallback(() => {
    setShowError(false);
  }, [setShowError]);
  const onFormSubmit = useCallback(
    async (e: FormSubmitEvent) => {
      e.preventDefault();

      const form = e.currentTarget as FormContents<ResetPasswordFormElements>;
      const newPassword = form.elements.resetPasswordNew.value;
      const confirmPassword = form.elements.resetPasswordConfirm.value;
      try {
        if (newPassword === confirmPassword) {
          if (token) {
            await doReset({
              password: newPassword,
              token,
            });
            logEvent("password reset");
            onResetSuccess();
          } else {
            await doChange({ password: newPassword });
            logEvent("new-user initial password set");
            onChangeSuccess();
          }
        } else {
          setPasswordErrors({
            reason: "Your passwords do not match. Please try again.",
          });
          setShowError(true);
        }
      } catch (err: any) {
        const passwordErrors = getPasswordChangeErrors(err, newPassword.length);
        // 401 means something different for the reset password endpoint than for other password endpoints
        if (passwordErrors?.statusCode === 401) {
          passwordErrors.reason = "Your password reset link has expired.";
        }
        setPasswordErrors(passwordErrors);
        setShowError(true);
      }
    },
    [doChange, doReset, onChangeSuccess, onResetSuccess, token],
  );

  return {
    onCloseError,
    onFormSubmit,
    passwordErrors,
    showError,
  };
};

const ResetPasswordForm: FunctionComponent = () => {
  const { onCloseError, onFormSubmit, passwordErrors, showError } =
    useResetPasswordForm();

  return (
    <Card>
      <FormTitle>Reset Password</FormTitle>
      <Form onSubmit={onFormSubmit}>
        <FormContentsContainer>
          <Input
            autoComplete="new-password"
            autoFocus
            id="resetPasswordNew"
            label="New password"
            required
            type="password"
          />
          <PasswordRulesContainer>
            Your password must be between 8 and 64 characters and cannot be in a
            list of common passwords.
          </PasswordRulesContainer>
          <StyledInput
            autoComplete="new-password"
            id="resetPasswordConfirm"
            label="Confirm new password"
            required
            type="password"
          />
          <ButtonContainer>
            <Button
              data-kind={ButtonKind.Primary}
              data-size={ButtonSize.md}
              type="submit"
            >
              Save
            </Button>
          </ButtonContainer>
        </FormContentsContainer>

        <ModalDialog isOpen={showError}>
          <DismissableMessage onClose={onCloseError} type={NotifyType.Error}>
            <PasswordChangeError passwordErrors={passwordErrors} />
          </DismissableMessage>
        </ModalDialog>
      </Form>
    </Card>
  );
};

export default ResetPasswordForm;
