import { styled } from "@linaria/react";
import {
  type ReactNode,
  type StyledComponent,
  type FunctionComponent,
  type LegacyRef,
} from "react";
import {
  type TagGroupProps as ReactAriaTagGroupProps,
  type TagListProps,
  type TagProps as ReactAriaTagProps,
  TagGroup as ReactAriaTagGroup,
  TagList,
  Tag as ReactAriaTag,
  Button,
  Text,
  Label,
} from "react-aria-components";

import { text } from "~/styles/typography";

import Icon from "./Icon";

export type { Key } from "react-aria-components";

export enum TagSize {
  sm = "sm",
  md = "md",
  lg = "lg",
}

export type TagGroupProps<T extends object> = Omit<
  ReactAriaTagGroupProps,
  | "children"
  // selecting tags is not currently supported
  | "selectionBehavior"
  | "selectionMode"
  | "selectedKeys"
  | "defaultSelectedKeys"
  | "disallowEmptySelection"
> &
  Pick<TagListProps<T>, "items" | "children" | "renderEmptyState"> & {
    label?: string;
    description?: string;
    error?: string;
    "data-size"?: TagSize;
  };

type BaseTagProps = Omit<
  ReactAriaTagProps,
  keyof StyledComponent | "children" | "textValue"
> &
  StyledComponent;

type ImplicitTextValueTagProps = BaseTagProps & {
  children: string;
  textValue?: string;
};

type ExplicitTextValueTagProps = BaseTagProps & {
  children: ReactNode;
  textValue: string;
};

export type TagProps = (
  | ImplicitTextValueTagProps
  | ExplicitTextValueTagProps
) & {
  contentRef?: LegacyRef<HTMLDivElement> | undefined;
};

const TagGroupRoot = styled(ReactAriaTagGroup)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  [data-slot="label"] {
    ${text.sm.medium}
    color: var(--text-color-secondary);
  }

  [slot="description"],
  [slot="errorMessage"] {
    ${text.sm.regular}
  }

  [slot="description"] {
    color: var(--text-color-tertiary);
  }
  [slot="errorMessage"] {
    color: var(--text-color-error-primary);
  }

  [data-slot="tagList"] {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
  }
`;

const RemoveButton = styled(Button)`
  background: none;
  border-radius: 3px;
  color: var(--foreground-color-quinary);
  padding: var(--tag-remove-button-padding, var(--spacing-xxs));
  font-size: var(--tag-remove-button-icon-size, 10px);

  & > svg {
    display: block;
  }

  &[data-hovered] {
    background-color: var(--background-color-primary_hover);
    color: var(--foreground-color-quinary_hover);
  }
`;

const TagRoot = styled(ReactAriaTag)`
  display: flex;
  align-items: center;
  gap: 3px;
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-primary);
  border-radius: var(--border-radius-sm);
  color: var(--text-color-secondary);
  padding: var(--tag-padding-top, var(--tag-padding-vertical, 3px))
    var(--tag-padding-right, var(--tag-padding-horizontal, var(--spacing-md)))
    var(--tag-padding-bottom, var(--tag-padding-vertical, 3px))
    var(--tag-padding-left, var(--tag-padding-horizontal, var(--spacing-md)));

  [data-slot="content"] {
    display: flex;
    align-items: center;
    gap: var(--tag-content-spacing, var(--spacing-xs));
  }

  ${TagGroupRoot}[data-size=${TagSize.sm}] & {
    ${text.xs.medium}
    --tag-padding-vertical: 3px;
    --tag-padding-horizontal: var(--spacing-md);
    --tag-content-spacing: var(--spacing-xs);
    --tag-remove-button-padding: var(--spacing-xxs);
    --tag-remove-button-icon-size: 10px;

    &[data-allows-removing] {
      --tag-padding-right: var(--spacing-xs);
    }
  }

  ${TagGroupRoot}[data-size=${TagSize.md}] & {
    ${text.sm.medium}
    --tag-padding-vertical: var(--spacing-xxs);
    --tag-padding-horizontal: 9px;
    --tag-content-spacing: 5px;
    --tag-remove-button-padding: var(--spacing-xxs);
    --tag-remove-button-icon-size: 12px;

    &[data-allows-removing] {
      --tag-padding-right: var(--spacing-xs);
    }
  }

  ${TagGroupRoot}[data-size=${TagSize.lg}] & {
    ${text.sm.medium}
    --tag-padding-vertical: var(--spacing-xs);
    --tag-padding-horizontal: 10px;
    --tag-content-spacing: var(--spacing-sm);
    --tag-remove-button-padding: 3px;
    --tag-remove-button-icon-size: 14px;

    &[data-allows-removing] {
      --tag-padding-right: var(--spacing-sm);
    }
  }
`;

export function TagGroup<T extends object = object>(props: TagGroupProps<T>) {
  const {
    label,
    description,
    error,
    items,
    children,
    renderEmptyState,
    "data-size": size = TagSize.md,
    ...rest
  } = props;

  return (
    <TagGroupRoot {...rest} data-size={size}>
      {label && <Label data-slot="label">{label}</Label>}
      <TagList
        data-slot="tagList"
        items={items}
        renderEmptyState={renderEmptyState}
      >
        {children}
      </TagList>
      {description && <Text slot="description">{description}</Text>}
      {error && <Text slot="errorMessage">{error}</Text>}
    </TagGroupRoot>
  );
}

export const Tag: FunctionComponent<TagProps> = (props) => {
  const { children, contentRef, ...rest } = props;
  const textValue = typeof children === "string" ? children : undefined;

  return (
    <TagRoot textValue={textValue} {...rest}>
      {({ allowsRemoving }) => (
        <>
          <div ref={contentRef} data-slot="content">
            {children}
          </div>
          {allowsRemoving && (
            <RemoveButton slot="remove">
              <Icon family="untitled" name="x-close" strokeWidth={4} />
            </RemoveButton>
          )}
        </>
      )}
    </TagRoot>
  );
};
