import { type CoURLExtraDataDTO } from "~/dto";

export function booleanToEnglish(value: boolean) {
  return value ? "Yes" : "No";
}

interface URLTableData {
  url: string;
  date: string;
}

export function getTableRowData(nodeData: CoURLExtraDataDTO | undefined) {
  const rowData =
    nodeData?.urls?.reduce((accumulator: URLTableData[], el, idx) => {
      if (nodeData.post_dates[idx] && nodeData.urls[idx]) {
        accumulator.push({
          url: el,
          date: new Date(nodeData.post_dates[idx]).toISOString().split("T")[0],
        });
      }

      return accumulator;
    }, []) ?? [];

  return rowData;
}
