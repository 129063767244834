import { styled } from "@linaria/react";
import { type FunctionComponent, type ReactNode } from "react";

import Card from "~/components/Card";
import { DialogXButton } from "~/components/Dialog";
import { DialogHeadingContainer } from "~/components/ModalDialog";
import { Heading } from "~/components/Section";
import Icon from "~/components/library/Icon";
import { text } from "~/styles/typography";

export const NoDataContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorContainer = styled(NoDataContainer)`
  color: var(--color-text-error);
`;

export const MatchIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
`;

export const MatchIndicator = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--background-color-quaternary);

  &[data-match="true"] {
    background-color: var(--background-color-brand-solid);
  }
`;

const PopUpContainer = styled(Card)`
  width: 360px;
  max-height: 686px;
  display: flex;
  flex-direction: column;

  .dialog-heading {
    padding-left: var(--spacing-sm);
  }

  ${DialogXButton} {
    padding: 0;
  }

  &[aria-busy="true"] {
    padding-top: var(--spacing-2xl);
  }
`;

const StyledHeading = styled(DialogHeadingContainer)`
  padding-left: var(--spacing-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: grab;

  > div {
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
  }

  [data-icon] {
    color: var(--color-white);
  }
`;

const HeadingText = styled(Heading)`
  display: flex;
  color: var(--color-gray-100);
  padding: var(--spacing-xs);
  ${text.xs.regular};
  line-height: 1.5;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  background-color: var(--color-gray-100);
  padding: var(--spacing-lg);
`;

interface PopUpBaseProps {
  children: ReactNode;
  heading: string;
  onDismiss?: () => void;
}

export const PopUpBase: FunctionComponent<PopUpBaseProps> = (props) => {
  return (
    <PopUpContainer>
      <StyledHeading className="dialog-heading">
        <div>
          <Icon family="untitled" name="dataflow-03" />
          <HeadingText>{props.heading}</HeadingText>
        </div>
        <DialogXButton onPress={props.onDismiss} />
      </StyledHeading>
      <Content>{props.children}</Content>
    </PopUpContainer>
  );
};
