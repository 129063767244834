import { useCallback, useMemo } from "react";

import { type CreateInsightRequestDTO } from "~/dto/createInsightRequest";
import { type InsightDTO } from "~/dto/insight";
import {
  type InsightConversationListDTO,
  InsightConversationListValidator,
} from "~/dto/insightConversationList";
import { type InsightListDTO, InsightListValidator } from "~/dto/insightList";
import {
  type InsightOptionsDTO,
  InsightOptionsValidator,
} from "~/dto/insightOptions";
import {
  type RiskCategoryMapResponseDTO,
  RiskCategoryMapResponseValidator,
} from "~/dto/riskCategoryMapResponse";
import {
  type TopicInsightResponseDTO,
  TopicInsightResponseValidator,
} from "~/dto/topicInsightResponse";

import {
  getConversationFilterRequestParams,
  useConversationFilters,
} from "./useConversationFilters";
import { useRemoteAction, useRemoteObject } from "./useRemoteData";
import { type AuthToken } from "./useRemoteData/utils";
import { useSession } from "./useSession";
import { useStrippedUsers } from "./useUsers";

/* analyst-insights api responses do not extend Collectible and so we can't use
 * `useRemoteCollection` to manipulate them.  but we do need to be able to
 * create, edit, and delete insights so we have to jump through a couple of small
 * hoops in order to provide some CRUD helpers to consumers */

const insightsUpdaterFactory =
  (authToken: AuthToken, payload: InsightDTO) =>
  async (_input: RequestInfo, init?: RequestInit) => {
    if (!authToken) {
      throw new Error("no auth token found");
    }

    return window
      .fetch("/api/v2/insights", {
        ...(init ?? {}),
        body: JSON.stringify(payload),
        headers: {
          ...(init?.headers ?? {}),
          "X-CSRF-TOKEN": authToken,
          "Content-Type": "application/json",
        },
        method: "PATCH",
      })
      .then((res) => res.json())
      .catch(() => {
        throw new Error(
          "An error occurred while updating this insights.  Please try again later.",
        );
      }) as Promise<never>;
  };

const insightsCreatorFactory =
  (authToken: AuthToken, payload: CreateInsightRequestDTO) =>
  async (_input: RequestInfo, init?: RequestInit) => {
    if (!authToken) {
      throw new Error("no auth token found");
    }

    const response = await window.fetch("/api/v2/insights", {
      ...(init ?? {}),
      body: JSON.stringify(payload),
      headers: {
        ...(init?.headers ?? {}),
        "X-CSRF-TOKEN": authToken,
        "Content-Type": "application/json",
      },
      method: "POST",
    });
    let result;
    try {
      result = await (response.json() as Promise<InsightListDTO>);
    } catch (ex) {
      throw new Error(
        "An error occurred while creating this insight.  Please try again later.",
      );
    }

    if (response.status > 299) {
      throw new Error(
        result.metadata?.errors?.[0] ??
          `unexpected response code ${response.status}`,
      );
    }
  };

const getCreateUpdateInsightsMethods = (
  authToken: AuthToken,
  cb?: () => void,
) => {
  return {
    create: async (payload: CreateInsightRequestDTO) => {
      await insightsCreatorFactory(authToken, payload)("/api/v2/insights");
      cb?.();
    },
    remove: async (payload: InsightDTO) => {
      await insightsUpdaterFactory(authToken, {
        ...payload,
        status: "deleted",
      })("/api/v2/insights");
      cb?.();
    },
    update: async (payload: InsightDTO) => {
      await insightsUpdaterFactory(authToken, payload)("/api/v2/insights");
      cb?.();
    },
  };
};

const useAccountInsights = (accountId?: string) => {
  const { authToken } = useSession();
  const { refresh: refreshAllInsights } = useRemoteObject<InsightListDTO>(
    "/api/v2/insights",
    {
      cacheOpts: {
        revalidateOnMount: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 0,
      },
      schemaValidator: InsightListValidator,
    },
  );
  const apiEndpoint = accountId
    ? `/api/v2/insights?account_id=${encodeURIComponent(accountId)}`
    : undefined;

  const remoteObject = useRemoteObject<InsightListDTO, InsightDTO>(
    apiEndpoint,
    {
      cacheOpts: {
        dedupingInterval: 1000 * 60 * 1,
      },
      schemaValidator: InsightListValidator,
      updateFetcher: insightsUpdaterFactory,
    },
  );
  const { refresh: refreshAccountInsights } = remoteObject;
  const onRequestComplete = useCallback(() => {
    refreshAccountInsights();
    /* if we delete the last insight belonging to a particular user
     * account or domain then we want anyone listening to the full list to be
     * notified too in case they need to remove a table row */
    refreshAllInsights();
  }, [refreshAllInsights, refreshAccountInsights]);

  const extraMethods = useMemo(
    () => getCreateUpdateInsightsMethods(authToken, onRequestComplete),
    [authToken, onRequestComplete],
  );

  return {
    ...remoteObject,
    ...extraMethods,
  };
};

const useDomainInsights = (domain?: string | null) => {
  const { authToken } = useSession();
  // const { refresh: refreshAllInsights } = useInsightsRequests();
  const { refresh: refreshAllInsights } = useRemoteObject<InsightListDTO>(
    "/api/v2/insights",
    {
      cacheOpts: {
        revalidateOnMount: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        refreshWhenHidden: false,
        refreshInterval: 0,
      },
      schemaValidator: InsightListValidator,
    },
  );
  const apiEndpoint = domain
    ? `/api/v2/insights?domain_url=${encodeURIComponent(domain)}`
    : undefined;
  const remoteObject = useRemoteObject<InsightListDTO, InsightDTO>(
    apiEndpoint,
    {
      schemaValidator: InsightListValidator,
      updateFetcher: insightsUpdaterFactory,
    },
  );
  const { refresh: refreshDomainInsights } = remoteObject;
  const onRequestComplete = useCallback(() => {
    refreshDomainInsights();
    /* if we delete the last insight belonging to a particular user
     * account or domain then we want anyone listening to the full list to be
     * notified too in case they need to remove a table row */
    refreshAllInsights();
  }, [refreshAllInsights, refreshDomainInsights]);
  const extraMethods = useMemo(
    () => getCreateUpdateInsightsMethods(authToken, onRequestComplete),
    [authToken, onRequestComplete],
  );

  return {
    ...remoteObject,
    ...extraMethods,
  };
};

const useInsightCreator = () => {
  const { authToken } = useSession();
  const { refresh } = useRemoteObject<InsightListDTO>("/api/v2/insights", {
    schemaValidator: InsightListValidator,
  });
  const extraMethods = useMemo(
    () => getCreateUpdateInsightsMethods(authToken, refresh),
    [authToken, refresh],
  );

  return {
    ...extraMethods,
    refresh,
  };
};

const useTopicInsights = (conversation_id: string) => {
  const filters = useConversationFilters();
  const params = getConversationFilterRequestParams(filters);
  const apiEndpoint = conversation_id
    ? `/api/v2/insights/topics?conversation_id=${conversation_id}&${params}`
    : undefined;

  return useRemoteObject<TopicInsightResponseDTO>(apiEndpoint, {
    schemaValidator: TopicInsightResponseValidator,
  });
};

function useInsightsRequests() {
  const {
    data: insights,
    error: insightsError,
    isLoading: isLoadingInsights,
    refresh,
  } = useRemoteObject<InsightListDTO>("/api/v2/insights", {
    schemaValidator: InsightListValidator,
  });
  const {
    data: users,
    error: usersError,
    isLoading: isLoadingUsers,
  } = useStrippedUsers();

  const userNamesById = useMemo(() => {
    return Object.fromEntries(
      users.map((user) => [
        user.id,
        [user.first_name, user.last_name].filter(Boolean).join(" "),
      ]),
    );
  }, [users]);

  const { pending, archived } = useMemo(() => {
    return [...(insights?.data ?? [])]
      .sort(
        (a, b) =>
          new Date(b?.edited_at ?? 0).getTime() -
          new Date(a?.edited_at ?? 0).getTime(),
      )
      .reduce<{ archived: InsightDTO[]; pending: InsightDTO[] }>(
        (acc, el) => {
          if (el.status === "pending") {
            acc.pending.push(el);
          } else {
            acc.archived.push(el);
          }

          return acc;
        },
        {
          archived: [],
          pending: [],
        },
      );
  }, [insights]);

  return {
    archivedChanges: archived,
    error: insightsError ?? usersError,
    isLoading: isLoadingInsights || isLoadingUsers,
    pendingChanges: pending,
    refresh,
    tableData: insights?.data,
    userNamesById,
  };
}

const useInsightOptions = () => {
  return useRemoteObject<InsightOptionsDTO>("/api/v2/insights/options", {
    schemaValidator: InsightOptionsValidator,
  });
};

const useProfileLocations = (analyzableId: string | null | undefined) => {
  const apiEndpoint = analyzableId
    ? `/api/v2/insights/conversations?id=${encodeURIComponent(analyzableId)}`
    : undefined;

  return useRemoteObject<InsightConversationListDTO>(apiEndpoint, {
    schemaValidator: InsightConversationListValidator,
  });
};

const useRiskCategories = () => {
  const remoteObject = useRemoteObject<RiskCategoryMapResponseDTO>(
    `/api/v2/insights/risk_categories_map`,
    {
      cacheOpts: { dedupingInterval: 1000 * 60 * 60 },
      schemaValidator: RiskCategoryMapResponseValidator,
    },
  );
  return remoteObject;
};

type InsightRepair = {
  insightType: string;
  oldValue: string;
  newValue?: string;
};

const useRepairInsight = () => {
  const baseUrl = "/api/v2/insights/repair";
  /* we'll want to refresh our list of insights after repairing */
  const insightsObject = useRemoteObject<InsightListDTO>("/api/v2/insights", {
    schemaValidator: InsightListValidator,
  });
  /* we'll also want to refresh our list of insight options after
   * repairing */
  const insightOptionsObject = useInsightOptions();
  const remoteAction = useRemoteAction<InsightRepair, void>(baseUrl, {
    actionFetcher:
      (authToken: AuthToken, payload: InsightRepair) =>
      async (_request, init) => {
        if (!authToken) {
          throw new Error("no auth token found");
        }

        const params = new URLSearchParams();

        params.set("type", payload.insightType);
        params.set("oldValue", payload.oldValue);

        if (payload.newValue) {
          params.set("newValue", payload.newValue);
        }

        const response = await window.fetch(`${baseUrl}?${params}`, {
          ...init,
          headers: {
            ...init?.headers,
            "X-CSRF-TOKEN": authToken,
          },
          method: "PATCH",
        });
        const returnValue = {
          metadata: undefined,
          status: response.status,
        };

        try {
          const result = await response.json();

          returnValue.metadata = result.metadata;
        } catch (ex) {}

        if (response.status >= 400) {
          return Promise.reject(returnValue);
        }

        return Promise.resolve() as Promise<void>;
      },
    schemaValidator: undefined,
  });

  return {
    ...remoteAction,
    isLoading: insightsObject.isLoading || insightOptionsObject.isLoading,
    execute: async (payload: InsightRepair) => {
      return remoteAction.execute(payload).then(() => {
        insightsObject.refresh();
        insightOptionsObject.refresh();
      });
    },
  };
};

export {
  useAccountInsights,
  useInsightOptions,
  useDomainInsights,
  useInsightCreator,
  useInsightsRequests,
  useTopicInsights,
  useProfileLocations,
  useRepairInsight,
  useRiskCategories,
};
