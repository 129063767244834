const getCookie = (key: string) => {
  if (typeof document === "undefined") {
    return null;
  }

  const cookieJar = Object.fromEntries(
    document.cookie.split(";").map((c) =>
      c
        .split("=")
        /* normalize cookie names (at idx 0) but not values (idx 1) */
        .map((t, idx) => (!idx ? t.trim().toLowerCase() : t.trim())),
    ),
  );
  const k = key.toLowerCase();

  return cookieJar[k] ? String(cookieJar[k]) : null;
};

const clearCookie = (key: string) => {
  /* document.cookie has a special setter which only applies to any cookies
   * enumerated in the value string assigned to it */
  document.cookie = `${key}=`;
};

export { clearCookie, getCookie };
