import { styled } from "@linaria/react";
import {
  type HTMLAttributes,
  useRef,
  type FunctionComponent,
  useEffect,
} from "react";

import { text } from "~/styles/typography";

import { XButton } from "./ModalDialog";
import Portal from "./Portal";
import { Provider as DocumentLevelProvider } from "./Section";

interface ModalDialogProps extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
}

const Underlay = styled.div`
  position: absolute;
  margin: auto;
  z-index: var(--z-index-dialog);
`;

const DialogHeading = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DialogContents = styled.div`
  background-color: var(--color-gray-200);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  overflow-y: auto;
  padding: var(--spacing-lg);
`;

const InfoBox = styled.div`
  background-color: var(--color-white);
  border-radius: var(--border-radius-dialog);
  padding: var(--gutter-250) var(--spacing-4xl);
  margin-top: var(--gutter-250);
`;

const DialogXButton = styled(XButton)`
  margin: 0;
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    ${text.xl.regular};
  }
`;

const Dialog: FunctionComponent<ModalDialogProps> = (props) => {
  const { children, isOpen, ...otherProps } = props;

  const underlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document
      .querySelector("#dialog")
      ?.setAttribute("aria-hidden", `${!isOpen}`);
    if (isOpen && !underlayRef.current?.contains(document.activeElement)) {
      underlayRef.current?.focus();
    }
  }, [isOpen, children]);

  return (
    /* a document's heading levels get reset inside of modal dialogs */
    <Portal to="#dialog">
      <DocumentLevelProvider value={1}>
        {isOpen && (
          <Underlay
            ref={underlayRef}
            role="dialog"
            tabIndex={-1}
            {...otherProps}
          >
            {children}
          </Underlay>
        )}
      </DocumentLevelProvider>
    </Portal>
  );
};

export { DialogXButton, DialogHeading, DialogContents, InfoBox };

export default Dialog;
