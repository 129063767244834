import { styled } from "@linaria/react";
import type { FormContents, FormSubmitEvent, FunctionComponent } from "react";
import { useCallback, useState } from "react";

import type { ForgotPasswordRequestDTO } from "~/dto/forgotPasswordRequest";
import { useRemoteAction } from "~/hooks/useRemoteData";
import { unauthenticatedActionFetcherFactory } from "~/hooks/useRemoteData/utils";
import { text } from "~/styles/typography";
import { logEvent } from "~/utils/analytics";

import Card from "./Card";
import {
  ButtonContainer,
  FormTitle,
  FormContentsContainer,
} from "./FormComponents";
import A from "./library/A";
import Button, { ButtonKind, ButtonSize } from "./library/Button";
import Form from "./library/Form";
import Input from "./library/Input";

const StyledLink = styled(A)`
  ${text.sm.regular};
  text-align: center;
  text-decoration: none;
  padding-bottom: var(--spacing-md);
`;

const StyledText = styled.div`
  ${text.sm.regular};
  align-items: center;
`;

const StyledInput = styled(Input)`
  margin-top: var(--spacing-3xl);
`;

const SuccessText = styled.p`
  ${text.sm.regular};
  text-align: center;
`;

const ErrorText = styled.p`
  ${text.sm.regular};
  text-align: center;
  color: var(--text-color-error-primary);
`;

interface ForgotPasswordFormElements {
  emailAddress: HTMLInputElement;
}

const ForgotPasswordForm: FunctionComponent = () => {
  const { execute } = useRemoteAction<ForgotPasswordRequestDTO, void>(
    "/api/v2/user/forgotPassword",
    {
      schemaValidator: undefined,
      actionFetcher: unauthenticatedActionFetcherFactory,
    },
  );
  const [feedbackMessage, setFeedbackMessage] = useState("\u200B");
  const [error, setError] = useState<boolean>(false);
  const onSubmit = useCallback(
    async (e: FormSubmitEvent) => {
      setFeedbackMessage("\u200B");
      setError(false);
      e.preventDefault();
      const form = e.currentTarget as FormContents<ForgotPasswordFormElements>;
      const requestedEmail = form.elements.emailAddress.value;
      try {
        await execute({
          email: requestedEmail,
        });
        logEvent("password reset request");
        setFeedbackMessage("Your request has been received.");
      } catch (ex) {
        setError(true);
        setFeedbackMessage(
          "Something went wrong. Please verify your email address and try again.",
        );
      }
    },
    [execute],
  );

  return (
    <Card>
      <FormTitle>Forgot Your Password?</FormTitle>
      <Form onSubmit={onSubmit}>
        <FormContentsContainer>
          <StyledText>
            Please enter your email address associated with Artemis. If you are
            in our system, an email will be sent with instructions to reset your
            password.
          </StyledText>
          <StyledInput
            autoFocus
            id="emailAddress"
            label="Email Address"
            placeholder="artemis-user@example.com"
            required
            type="email"
          />
          {error ? (
            <ErrorText>{feedbackMessage}</ErrorText>
          ) : (
            <SuccessText>{feedbackMessage}</SuccessText>
          )}
          <ButtonContainer>
            <Button
              data-kind={ButtonKind.Primary}
              data-size={ButtonSize.md}
              type="submit"
            >
              Send
            </Button>
            <StyledLink href="/login">Back to Login</StyledLink>
          </ButtonContainer>
        </FormContentsContainer>
      </Form>
    </Card>
  );
  /*
    <StackedForm onSubmit={onSubmit}>
      <FormContentsContainer>
        <StyledText>
          Please enter your email address associated with Alethea Group. If you
          are in our system, an email will be sent with instructions to reset
          your password.
        </StyledText>
        <StyledInput
          id="emailAddress"
          label="Email Address"
          placeholder="artemis-user@example.com"
          required
          type="email"
        />
        {error ? (
          <ErrorText>{feedbackMessage}</ErrorText>
        ) : (
          <SuccessText>{feedbackMessage}</SuccessText>
        )}
        <ButtonContainer>
          <Button
            data-kind={ButtonKind.Primary}
            data-size={ButtonSize.md}
            type="submit"
          >
            Send
          </Button>
          <StyledLink href="/login">Back to Login</StyledLink>
        </ButtonContainer>
      </FormContentsContainer>
    </StackedForm>
    ); */
};

export default ForgotPasswordForm;
