import { styled } from "@linaria/react";
import { useMemo, type FunctionComponent } from "react";

import Accordion from "~/components/Accordion";
import Section from "~/components/Section";
import { text } from "~/styles/typography";

import {
  EVIDENCES_MAP,
  type Evidence,
  COORDINATION_TYPE_EVIDENCES_MAP,
  coordinationTypeNames,
} from "../constants";

import { MatchIndicator, MatchIndicatorContainer } from "./SharedComponents";

const EvidencesContainer = styled(Section)`
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border: var(--border-group);
  border-color: var(--color-gray-200);
  border-radius: var(--border-radius-default);
  padding: var(--spacing-lg);
  gap: var(--spacing-md);

  > div {
    border: none;
    border-radius: none;

    > button {
      padding: 0;
    }

    > button[data-expanded="true"] {
      border-bottom: none;
    }

    > div[data-expanded="true"] > div {
      padding: 0 0 0 var(--spacing-md);
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    ${text.md.semibold};
    ${text.xs.regular};
  }

  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-700);
  }
`;

interface EvidenceInfoProps {
  label: string;
  availableEvidences: Evidence[];
  evidenceMatches: Record<Evidence, boolean>;
}

const EvidenceInfo: FunctionComponent<EvidenceInfoProps> = (props) => {
  const { label, availableEvidences, evidenceMatches } = props;

  const evidenceDetected = useMemo(() => {
    return availableEvidences.some((evidence) => evidenceMatches[evidence]);
  }, [availableEvidences, evidenceMatches]);

  return (
    <Accordion
      beforeTitleElement={
        <MatchIndicatorContainer>
          <span data-at-only>{evidenceDetected ? "found" : "not found"}</span>
          <MatchIndicator data-match={evidenceDetected} />
        </MatchIndicatorContainer>
      }
      title={label}
    >
      <InfoContainer>
        <ul>
          {availableEvidences.map((evidence) => (
            <li key={evidence}>
              <span>{EVIDENCES_MAP[evidence]}</span>
              <MatchIndicatorContainer>
                <span data-at-only>
                  {!!evidenceMatches[evidence] ? "found" : "not found"}
                </span>
                <MatchIndicator data-match={evidenceMatches[evidence]} />
              </MatchIndicatorContainer>
            </li>
          ))}
        </ul>
      </InfoContainer>
    </Accordion>
  );
};

interface EvidencesProps {
  evidenceMatches: Record<Evidence, boolean>;
}

export const Evidences: FunctionComponent<EvidencesProps> = (props) => {
  const { evidenceMatches } = props;

  return (
    <EvidencesContainer>
      <EvidenceInfo
        availableEvidences={COORDINATION_TYPE_EVIDENCES_MAP.synchronousPosting}
        evidenceMatches={evidenceMatches}
        label={coordinationTypeNames.synchronousPosting}
      />
      <EvidenceInfo
        availableEvidences={COORDINATION_TYPE_EVIDENCES_MAP.replication}
        evidenceMatches={evidenceMatches}
        label={coordinationTypeNames.replication}
      />
      <EvidenceInfo
        availableEvidences={
          COORDINATION_TYPE_EVIDENCES_MAP.hashtagAmplification
        }
        evidenceMatches={evidenceMatches}
        label={coordinationTypeNames.hashtagAmplification}
      />
      <EvidenceInfo
        availableEvidences={COORDINATION_TYPE_EVIDENCES_MAP.sockPuppets}
        evidenceMatches={evidenceMatches}
        label={coordinationTypeNames.sockPuppets}
      />
    </EvidencesContainer>
  );
};
