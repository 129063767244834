import { styled } from "@linaria/react";

export const ScrollableLayout = styled.div`
  flex-grow: 1;
  align-self: stretch;
  overflow-y: auto;
`;

export const SinglePaneLayout = styled.div`
  flex-grow: 1;
  align-self: stretch;
  display: flex;
`;

export const StackedLayout = styled.div`
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const TwoPaneContainer = styled.div`
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  grid-gap: 0;
`;
