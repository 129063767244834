import type { FunctionComponent } from "react";

import { infoByNodeType } from "../../constants";
import type { QueryHashtag } from "../../types";

import BaseProfile, { InfoDisplay, type InfoProps } from "./BaseProfile";

export interface HashtagProfileProps {
  conversationId: string;
  type: "hashtags";
  onDismiss?: () => void;
  data: QueryHashtag;
}

function useHashtagProfile(props: HashtagProfileProps) {
  const { data, onDismiss } = props;

  const formattedData: InfoProps = {
    text: data.text ?? "",
  };

  return {
    data: formattedData,
    onDismiss,
  };
}

export const HashtagProfile: FunctionComponent<HashtagProfileProps> = (
  props,
) => {
  const { data, onDismiss } = useHashtagProfile(props);

  return (
    <BaseProfile
      heading="Hashtag"
      iconName={infoByNodeType.hashtags.iconName}
      onDismiss={onDismiss}
    >
      <InfoDisplay data={data} />
    </BaseProfile>
  );
};
