import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import { text } from "~/styles/typography";

import { FormSize } from "./Form";

export const fieldLabel = css`
  color: var(--text-color-secondary);

  [data-size="${FormSize.sm}"] & {
    ${text.sm.medium}
  }

  [data-size="${FormSize.md}"] & {
    ${text.sm.medium}
  }
`;

export const fieldDescription = css`
  color: var(--text-color-tertiary);

  [data-size="${FormSize.sm}"] & {
    ${text.sm.regular}
  }

  [data-size="${FormSize.md}"] & {
    ${text.sm.regular}
  }
`;

export const fieldError = css`
  color: var(--text-color-error-primary);

  &:empty {
    display: none;
  }

  [data-size="${FormSize.sm}"] & {
    ${text.sm.regular}
  }

  [data-size="${FormSize.md}"] & {
    ${text.sm.regular}
  }
`;

export const itemLabel = css`
  color: var(--text-color-secondary);

  &:empty {
    display: none;
  }

  [data-size="${FormSize.sm}"] & {
    ${text.sm.medium}
  }

  [data-size="${FormSize.md}"] & {
    ${text.md.medium}
  }
`;

export const itemDescription = css`
  color: var(--text-color-tertiary);

  &:empty {
    display: none;
  }

  [data-size="${FormSize.sm}"] & {
    ${text.sm.regular}
  }

  [data-size="${FormSize.md}"] & {
    ${text.md.regular}
  }
`;

export const itemError = css`
  color: var(--text-color-error-primary);

  &:empty {
    display: none;
  }

  [data-size="${FormSize.sm}"] & {
    ${text.sm.regular}
  }

  [data-size="${FormSize.md}"] & {
    ${text.md.regular}
  }
`;

const RequiredIndicator = styled.span`
  color: var(--text-color-error-primary);
  margin-left: var(--spacing-xxs);
  position: relative;
  top: -0.05em;
  display: none;

  [data-required] & {
    display: inline;
  }
`;

export const Required: FunctionComponent = () => (
  <RequiredIndicator aria-hidden>*</RequiredIndicator>
);
