import { styled } from "@linaria/react";
import {
  useCallback,
  useMemo,
  type Dispatch,
  type SetStateAction,
  type FunctionComponent,
} from "react";
import type { PressEvent } from "react-aria";
import { type Index, type Items, type Link, type Node } from "regraph";

import { useProfileDialogContext } from "~/components/ProfileDialogContext";
import IconButton, {
  ButtonKind,
  ButtonSize,
} from "~/components/library/IconButton";

import { infoByEdgeType, infoByNodeType } from "../../constants";
import { isEdge, type NodeType, type QueryNodes } from "../../types";
import { setGraphDataFromRegraphObject } from "../../utils";

import BaseProfile from "./BaseProfile";

export interface EdgeProfileProps {
  conversationId: string;
  type: "genericEdge";
  onDismiss: () => void;
  link: Link<QueryNodes>;
  setSelection: Dispatch<SetStateAction<Index<boolean | Node | Link>>>;
  data: Items<QueryNodes>;
}

const StyledBaseProfile = styled(BaseProfile)`
  #profile-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

function useEdgeProfile(props: EdgeProfileProps) {
  const {
    conversationId,
    data: allItems,
    link,
    onDismiss,
    setSelection,
  } = props;
  const { setGraphData } = useProfileDialogContext();

  const data = useMemo(() => {
    const node1 = allItems[link.id1];
    const node2 = allItems[link.id2];
    if (!link.data || !link.data.type) return undefined;
    if (isEdge(link.data.type)) {
      const edgeInfo = infoByEdgeType[link.data.type];
      const actionNodeId = (
        edgeInfo.actionNodeType === node1.data?.type ? node1 : node2
      ).data?.id;
      const receiverNodeId = (
        edgeInfo.receiverNodeType === node1.data?.type ? node1 : node2
      ).data?.id;

      return {
        actionNodeId,
        receiverNodeId,
        edgeInfo,
      };
    }
  }, [allItems, link.data, link.id1, link.id2]);

  const handleIconClick = useCallback(
    (e: PressEvent) => {
      const id = e.target.getAttribute("data-for");
      if (!id) return;
      const clickedObject = allItems[id];
      setGraphDataFromRegraphObject(
        allItems,
        conversationId,
        clickedObject,
        onDismiss,
        setGraphData,
        setSelection,
      );
      setSelection({
        [id]: allItems[id],
      });
    },
    [conversationId, onDismiss, allItems, setGraphData, setSelection],
  );

  return {
    data,
    handleIconClick,
    onDismiss,
  };
}

export const EdgeProfile: FunctionComponent<EdgeProfileProps> = (props) => {
  const { data, handleIconClick, onDismiss } = useEdgeProfile(props);

  return (
    <StyledBaseProfile heading="Edge" onDismiss={onDismiss}>
      <IconButton
        aria-label={`open ${data?.edgeInfo.actionNodeType} node dialog`}
        data-for={data?.actionNodeId}
        data-kind={ButtonKind.Tertiary}
        data-size={ButtonSize.md}
        data-type={data?.edgeInfo.actionNodeType}
        icon={
          infoByNodeType[data?.edgeInfo.actionNodeType as NodeType].iconName
        }
        onPress={handleIconClick}
        type="button"
      />
      <span>{data?.edgeInfo.text}</span>
      <IconButton
        aria-label={`open ${data?.edgeInfo.receiverNodeType} node dialog`}
        data-for={data?.receiverNodeId}
        data-kind={ButtonKind.Tertiary}
        data-size={ButtonSize.md}
        data-type={data?.edgeInfo.receiverNodeType}
        icon={
          infoByNodeType[data?.edgeInfo.receiverNodeType as NodeType].iconName
        }
        onPress={handleIconClick}
        type="button"
      />
    </StyledBaseProfile>
  );
};
