import { styled } from "@linaria/react";
import { type CSSProperties, type FunctionComponent } from "react";
import {
  FieldError,
  Group,
  Input,
  Label,
  SearchField as BaseSearchField,
  type SearchFieldProps as BaseSearchFieldProps,
  Text,
} from "react-aria-components";

import { text } from "~/styles/typography";

import { FormSize } from "./Form";
import Icon from "./Icon";
import IconButton, { ButtonKind, ButtonSize } from "./IconButton";
import {
  fieldDescription,
  fieldError,
  fieldLabel,
  Required,
} from "./formStyles";

export type SearchFieldProps = Omit<
  BaseSearchFieldProps,
  | "style"
  | "className"
  | "children"
  | "isDisabled"
  | "isRequired"
  | "isReadOnly"
  | "onChange"
  | "onSubmit"
> & {
  className?: string;
  style?: CSSProperties;

  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;

  label?: string;
  description?: string;
  error?: string;
  placeholder?: string;

  onSearchChange?: BaseSearchFieldProps["onChange"];
  onSearch?: BaseSearchFieldProps["onSubmit"];
};

const InputGroup = styled(Group)`
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color-primary);
  border-radius: var(--border-radius-md);
  gap: var(--spacing-md);
  overflow: hidden;
  padding: var(--spacing-xs) var(--spacing-lg);
  background-color: var(--background-color-primary);

  &[data-focus-within] {
    box-shadow: var(--ring-brand-shadow-xs);
  }

  & > input {
    ${text.md.regular}
    flex-grow: 1;
    background: none;
    color: var(--text-color-primary);
    min-width: 0;
    padding-block: var(--spacing-sm);

    &:focus {
      outline: none;
    }
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  & > *:not(input) {
    flex-shrink: 0;
  }

  & > [data-icon]:not(button [data-icon]) {
    color: var(--foreground-color-quaternary);
  }

  & > button {
    margin-right: calc((var(--spacing-lg) - var(--spacing-xs)) * -1);
  }
`;

const SearchFieldRoot = styled(BaseSearchField)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  &[data-empty] button {
    display: none;
  }

  &[data-disabled] {
    ${InputGroup} {
      background-color: var(--background-color-disabled_subtle);
      border-color: var(--border-color-disabled);
    }

    input {
      color: var(--text-color-disabled);
    }
  }
`;

const SearchField: FunctionComponent<SearchFieldProps> = (props) => {
  const {
    label,
    description,
    error,
    disabled,
    placeholder,
    required,
    readOnly,
    onSearchChange,
    onSearch,
    ...rest
  } = props;

  const ariaLabel = rest["aria-label"]
    ? rest["aria-label"]
    : label
    ? undefined
    : "Search all data";

  return (
    <SearchFieldRoot
      {...rest}
      aria-label={ariaLabel}
      data-required={required || undefined}
      data-size={FormSize.md}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
      onChange={onSearchChange}
      onSubmit={onSearch}
    >
      {label && (
        <Label className={fieldLabel}>
          {label}
          <Required />
        </Label>
      )}
      <InputGroup>
        <Icon family="untitled" name="search-md" />
        <Input placeholder={placeholder} />
        {/* @ts-expect-error SearchField provides the button's aria-label via context, we don't want to override it. */}
        <IconButton
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.sm}
          icon="x"
        />
      </InputGroup>
      {description && (
        <Text className={fieldDescription} slot="description">
          {description}
        </Text>
      )}
      <FieldError className={fieldError}>{error}</FieldError>
    </SearchFieldRoot>
  );
};

export default SearchField;
