import {
  type ContextComponent,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";

import { getCSSValue } from "~/components/charts/utils";

// import { useCurrentUser } from "./useCurrentUser";
// import { useUserPreference } from "./useUserPreference";

interface ThemeContextShape {
  getThemeColor: (arg0: string) => string | number | undefined;
  setThemeName: (arg0: string) => void;
  themeName?: string;
  themes: Record<string, string>;
}

/** @deprecated */
export const legacyThemes = {
  "(Default)": "",
  "Theme A": "viz-a",
  "Theme B": "viz-b",
} as const;

/* Record<value, icon name> */
const themes = {
  light: "light_mode",
  dark: "dark_mode",
} as const;

const ThemeContext = createContext<ThemeContextShape>({
  getThemeColor: () => undefined,
  setThemeName() {},
  themes,
});

const defaultTheme =
  // typeof window !== "undefined"
  //   ? window.matchMedia("(prefers-color-scheme: dark)").matches
  //     ? "dark"
  //     : "light"
  //   : "light";
  "light";

function applyThemeAttr(theme?: string | undefined | null) {
  if (theme) {
    document.querySelector("html")?.setAttribute("data-theme", theme);
  } else {
    document.querySelector("html")?.removeAttribute("data-theme");
  }
}

const ThemeProvider: ContextComponent = ({ children }) => {
  // const { currentUser } = useCurrentUser();
  // const { theme, updateTheme } = useUserPreference();
  const selectedTheme =
    //theme ||
    document.querySelector("html")?.getAttribute("data-theme") ?? defaultTheme;
  const setThemeName = useCallback(
    (/*nextTheme: string*/) => {
      /* we always want to honor a user selection regardless of login status */
      // applyThemeAttr(nextTheme);
      // if (currentUser) {
      //   /* attempt to store the preference remotely */
      //   updateTheme(nextTheme);
      // }
    },
    [
      /*currentUser, updateTheme*/
    ],
  );
  const getThemeColor = useCallback(
    (customProperty: string) => getCSSValue(customProperty),
    /* eslint-disable-next-line react-hooks/exhaustive-deps
       -- we want to recalculate these when the theme changes */
    [selectedTheme],
  );

  useEffect(() => {
    applyThemeAttr(selectedTheme);
  }, [selectedTheme]);

  return (
    <ThemeContext.Provider
      value={{
        getThemeColor,
        themeName: selectedTheme,
        setThemeName,
        themes,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  return useContext(ThemeContext);
};

export { ThemeProvider, useTheme };
