import { type FunctionComponent, useCallback, useState } from "react";

import { type ProjectDTO } from "~/dto/project";
import { useOneProject } from "~/hooks/useProjects";
import { isError } from "~/utils/error";

import ConfirmationForm from "../ConfirmationForm";
import ModalDialog from "../ModalDialog";
import Button, { ButtonKind, ButtonSize } from "../library/Button";

type DeleteProjectDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  project?: ProjectDTO;
};

const DeleteProjectForm: FunctionComponent<DeleteProjectDialogProps> = (
  props,
) => {
  const { isOpen, onClose, project } = props;
  const [errorMessage, setErrorMessage] = useState<string>();
  const { remove } = useOneProject(project?.id);
  const onConfirm = useCallback(async () => {
    setErrorMessage(undefined);

    try {
      await remove();
      onClose();
    } catch (ex) {
      if (isError(ex)) {
        setErrorMessage(ex.message);
      }
    }
  }, [onClose, remove]);

  if (!project || !isOpen) {
    return null;
  }

  return (
    <ConfirmationForm
      cancelButton={
        <Button
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.md}
          onPress={onClose}
          type="button"
        >
          Cancel
        </Button>
      }
      confirmButton={
        <Button
          data-kind={ButtonKind.Primary}
          data-size={ButtonSize.md}
          id="remove-project-button"
          onPress={onConfirm}
          type="button"
        >
          Remove Project
        </Button>
      }
      errorMessage={errorMessage}
      message={`Are you sure you want to remove the project "${project.name}"? You will lose access to any conversations in this project.`}
      title="Remove Project"
    />
  );
};

const DeleteProjectDialog: FunctionComponent<DeleteProjectDialogProps> = (
  props,
) => {
  const { isOpen, onClose, project } = props;

  if (!project || !isOpen) {
    return null;
  }

  return (
    <ModalDialog isOpen={isOpen}>
      {isOpen ? (
        <DeleteProjectForm
          isOpen={isOpen}
          onClose={onClose}
          project={project}
        />
      ) : null}
    </ModalDialog>
  );
};

export default DeleteProjectDialog;
