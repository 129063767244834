import { type FunctionComponent, type ReactNode } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
  children: ReactNode;
  to: string;
}

const Portal: FunctionComponent<PortalProps> = (props) => {
  const { children, to } = props;
  const el = document.querySelector(to);

  if (!el) {
    throw new Error(`invalid portal selector ${to}`);
  } else if (!children) {
    return null;
  }

  return createPortal(
    /* eslint-disable-next-line react/jsx-no-useless-fragment --
     * we need a fragment here since `children` could be an array */
    <>{children}</>,
    el,
  );
};

export default Portal;
