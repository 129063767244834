import { scalePower } from "@visx/scale";
import type { Dispatch, SetStateAction } from "react";
import {
  type Chart,
  type Index,
  type Items,
  type Link,
  type Node,
} from "regraph";

import type { GraphPopupProps } from "~/components/GraphPopup";
import { getCSSValue } from "~/components/charts/utils";
import {
  type DataSource,
  postClass_to_platform_mapping,
  type Platform,
} from "~/constants";
import untitledIcon from "~/images/icons.svg?raw";
import { extent } from "~/utils/arrayUtils";

import { infoByNodeType, platformInfo } from "./constants";
import {
  type QueryAccount,
  type QueryDomain,
  type QueryData,
  type QueryPost,
  type GraphNode,
  type IndexNode,
  type IndexLink,
  type QueryURLs,
  type EdgeData,
  type Topics,
  type ClusteringNodeKey,
  type QueryHashtag,
  isAccountCoordinationEvidenceAccounts,
  type QueryNodes,
  isAccountNode,
  isPostNode,
  isDomainNode,
  isUrlNode,
  isHashtagNode,
  isLink,
  type GraphState,
  type Preset,
  type NodeType,
  type AccountLinks,
  type ColorMap,
  type NodesByEdgeCount,
  type ProcessedData,
} from "./types";

const TOPIC_SUMMARY_LENGTH_CHARS = 70;

function convertSVGToUrl(svgString: string) {
  return `data:image/svg+xml;base64,${btoa(svgString)}`;
}
function generateIconSvgDataUrl(name: UntitledIcon, textColor: string) {
  const div = document.createElement("div");
  div.innerHTML = untitledIcon;

  const iconContent =
    div
      .querySelector(`#untitled-icon-${name}`)
      ?.innerHTML.replace(/stroke="[^"]*"/g, `stroke="${textColor}"`) ?? "";
  const iconSVGString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">${iconContent}</svg>`;
  return `data:image/svg+xml;base64,${btoa(iconSVGString)}`;
}

export function convertToNode<T extends QueryNodes>(
  data: T[],
  typeKey: NodeType,
  colorMap: ColorMap,
  nodeConnections: Map<string, string[]>,
  extendData: (item: T) => Partial<T> = () => ({}),
  extendNode: (item: T) => Partial<Node<T>> = () => ({}),
) {
  const { nodes } = colorMap;
  const color = nodes.get(typeKey)?.main;
  const textColor = nodes.get(typeKey)?.text;

  const image = generateIconSvgDataUrl(
    infoByNodeType[typeKey].iconName,
    textColor ?? getCSSValue("--text-color-primary"),
  );

  const nodesWithMultipleConnections: IndexNode = {};
  const nodesWithSingleConnection: IndexNode = {};
  const nodesWithoutConnections: IndexNode = {};

  data.forEach((datum) => {
    const connections = nodeConnections.get(datum.id) || [];
    let processedNodes;

    if (connections.length > 1) {
      processedNodes = nodesWithMultipleConnections;
    } else if (connections.length === 1) {
      const partnerId = connections[0];
      const partnerConnections = nodeConnections.get(partnerId) || [];
      if (partnerConnections.length === 1) {
        processedNodes = nodesWithSingleConnection;
      } else {
        processedNodes = nodesWithMultipleConnections;
      }
    } else {
      processedNodes = nodesWithoutConnections;
    }

    processedNodes[datum.id] = {
      data: {
        ...datum,
        ...extendData(datum),
        type: typeKey,
      },
      color,
      image,
      ...extendNode(datum),
    };
  });

  return {
    nodesWithoutConnections,
    nodesWithSingleConnection,
    nodesWithMultipleConnections,
  };
}

export function convertPostsToNodes(
  posts: QueryPost[],
  colorMap: ColorMap,
  nodeConnections: Map<string, string[]>,
) {
  return convertToNode(posts, "posts", colorMap, nodeConnections, (post) => ({
    platform: postClass_to_platform_mapping[post.type as DataSource],
  }));
}

export function convertAccountsToNodes(
  accounts: QueryAccount[],
  colorMap: ColorMap,
  preset: Preset,
  nodeConnections: Map<string, string[]>,
) {
  return convertToNode(
    accounts,
    "accounts",
    colorMap,
    nodeConnections,
    (account) => ({
      platform: account.platform,
    }),
    () => {
      if (preset === "coordination") {
        return {
          border: {
            color: getCSSValue("--background-color-primary"),
            width: 10,
          },
          color: getCSSValue("--color-utility-gray-600"),
          halos: [
            {
              color: getCSSValue("--color-utility-gray-400"),
              radius: 30,
              width: 20,
            },
          ],
          image: undefined,
        };
      }
      return {};
    },
  );
}

export function convertDomainsToNodes(
  domains: QueryDomain[],
  colorMap: ColorMap,
  nodeConnections: Map<string, string[]>,
) {
  return convertToNode(
    domains,
    "domains",
    colorMap,
    nodeConnections,
    (domain) => ({
      body: domain.name,
    }),
  );
}

export function convertURLsToNodes(
  urls: QueryURLs[],
  colorMap: ColorMap,
  nodeConnections: Map<string, string[]>,
) {
  return convertToNode(urls, "links", colorMap, nodeConnections);
}

export function convertHashtagsToNodes(
  hashtags: QueryHashtag[],
  colorMap: ColorMap,
  nodeConnections: Map<string, string[]>,
) {
  const uniqueTextsArray: QueryHashtag[] = [
    ...new Set(hashtags.map((hashtag) => hashtag.text)),
  ].map((t) => ({
    id: t,
    postId: "", //converting an edge to a node, the node does not need a postId
    text: t,
  }));

  return convertToNode(
    uniqueTextsArray,
    "hashtags",
    colorMap,
    nodeConnections,
    (hashtag) => ({
      text: `#${hashtag.text}`,
    }),
  );
}

export function getAccountsAndEdgesFromLinks(links: QueryURLs[]) {
  const uniqueAccountIds = new Set<string>();
  const uniqueAccounts: QueryAccount[] = [];
  const accountLinks: AccountLinks[] = [];

  links.forEach((link) => {
    if (link.accountEdges) {
      link.accountEdges.forEach((account) => {
        if (!uniqueAccountIds.has(account.id)) {
          uniqueAccountIds.add(account.id);
          uniqueAccounts.push(account);
        }
        accountLinks.push({
          accountId: account.id,
          linkId: link.id,
        });
      });
    }
  });

  return {
    accounts: uniqueAccounts,
    accountLinks,
  };
}

export function convertURLSharingDataToRegraph(
  queryData: QueryData,
  colorMap: ColorMap,
): ProcessedData | undefined {
  const { links } = queryData;
  if (!links) return undefined;
  const { accounts, accountLinks } = getAccountsAndEdgesFromLinks(links);

  const { nodeConnections, processedEdges } = processEdges(
    colorMap,
    { accountLinks },
    ["accountLinks"],
    "network",
  );

  const accountNodes = convertAccountsToNodes(
    accounts,
    colorMap,
    "network",
    nodeConnections,
  );
  const linkNodes = convertURLsToNodes(links, colorMap, nodeConnections);

  return {
    processedEdges,
    nodesWithMultipleConnections: Object.assign(
      accountNodes.nodesWithMultipleConnections,
      linkNodes.nodesWithMultipleConnections,
    ),
    nodesWithSingleConnection: Object.assign(
      accountNodes.nodesWithSingleConnection,
      linkNodes.nodesWithSingleConnection,
    ),
    nodesWithoutConnections: Object.assign(
      accountNodes.nodesWithoutConnections,
      linkNodes.nodesWithoutConnections,
    ),
  };
}

export function processEdges(
  colorMap: ColorMap,
  queryData: QueryData,
  edges: (keyof EdgeData)[],
  preset: Preset,
) {
  const isCoordinationPreset = preset === "coordination";
  const processedEdges = {};
  const nodeConnections: Map<string, string[]> = new Map();

  edges.forEach((edgeKey) => {
    queryData[edgeKey]?.forEach((el) => {
      const [n1, n2] = Object.values(el).map(String);
      const linkInfo: Link<GraphNode> = {
        id1: n1,
        id2: n2,
        data: {
          id: `edge-${n1}-${n2}`,
          type: edgeKey,
        },
        width: 5,
      };

      if (isAccountCoordinationEvidenceAccounts(el) && isCoordinationPreset) {
        if (linkInfo.data) {
          linkInfo.data.body = `${el.weight} evidences`;
        }
        linkInfo.color = colorMap.coordination.get(String(el.weight))?.main;
      }

      Object.assign(processedEdges, {
        [`edge-${n1}-${n2}`]: linkInfo,
      });

      if (!nodeConnections.has(n1)) nodeConnections.set(n1, []);
      if (!nodeConnections.has(n2)) nodeConnections.set(n2, []);
      nodeConnections.get(n1)?.push(n2);
      nodeConnections.get(n2)?.push(n1);
    });
  });

  return {
    nodeConnections,
    processedEdges,
  };
}

export function processNodes(
  colorMap: ColorMap,
  nodeConnections: Map<string, string[]>,
  preset: Preset,
  queryData: QueryData,
) {
  const processedNodes: Omit<ProcessedData, "processedEdges"> = {
    nodesWithMultipleConnections: {},
    nodesWithSingleConnection: {},
    nodesWithoutConnections: {},
  };

  const nodeResults = [
    queryData.posts
      ? convertPostsToNodes(queryData.posts, colorMap, nodeConnections)
      : null,
    queryData.accounts
      ? convertAccountsToNodes(
          queryData.accounts,
          colorMap,
          preset,
          nodeConnections,
        )
      : null,
    queryData.domains
      ? convertDomainsToNodes(queryData.domains, colorMap, nodeConnections)
      : null,
    queryData.links
      ? convertURLsToNodes(queryData.links, colorMap, nodeConnections)
      : null,
    queryData.hashtags
      ? convertHashtagsToNodes(queryData.hashtags, colorMap, nodeConnections)
      : null,
  ];

  nodeResults.forEach((result) => {
    if (result) {
      Object.assign(
        processedNodes.nodesWithMultipleConnections,
        result.nodesWithMultipleConnections,
      );
      Object.assign(
        processedNodes.nodesWithoutConnections,
        result.nodesWithoutConnections,
      );
      Object.assign(
        processedNodes.nodesWithSingleConnection,
        result.nodesWithSingleConnection,
      );
    }
  });

  return processedNodes;
}

export function convertDataToRegraphLinks(
  colorMap: ColorMap,
  queryData: QueryData,
  edges: (keyof EdgeData)[],
  preset: Preset,
) {
  const isCoordinationPreset = preset === "coordination";

  return edges.reduce<IndexLink>(
    (acc, edgeKey) =>
      Object.assign(
        acc,
        queryData[edgeKey]?.reduce<IndexLink>((acc, el) => {
          const [n1, n2] = Object.values(el);
          const linkInfo: Link<GraphNode> = {
            id1: n1,
            id2: n2,
            data: {
              id: `edge-${n1}-${n2}`,
              type: edgeKey,
            },
            width: 5,
          };

          if (
            isAccountCoordinationEvidenceAccounts(el) &&
            isCoordinationPreset
          ) {
            if (linkInfo.data) {
              linkInfo.data.body = `${el.weight} evidences`;
            }
            linkInfo.color = colorMap.coordination.get(String(el.weight))?.main;
          }

          return Object.assign(acc, {
            [`edge-${n1}-${n2}`]: linkInfo,
          });
        }, {}),
      ),
    {},
  );
}

function getTopicStyleProps(
  colorGroupBy: string[],
  colorMapNarrative: ColorMap["narratives"],
  id: string,
  title: string,
  selectedPreset: Preset[],
  subcategoryByTopicId: Record<string, string> | undefined,
) {
  const subcategory = subcategoryByTopicId
    ? subcategoryByTopicId[id] ?? "Default"
    : "Default";
  const displayedLabel =
    title.length > TOPIC_SUMMARY_LENGTH_CHARS
      ? `${title.slice(0, TOPIC_SUMMARY_LENGTH_CHARS)}...`
      : title;
  const showTopicColors =
    colorGroupBy[0] === "threatLevel" && selectedPreset[0] === "narrative";
  return {
    color: showTopicColors
      ? colorMapNarrative.get(subcategory)?.main
      : getCSSValue("--color-utility-gray-300"),
    label: {
      text: displayedLabel,
      color: showTopicColors
        ? colorMapNarrative.get(subcategory)?.text
        : getCSSValue("--text-color-primary"),
      backgroundColor: "transparent",
      fontSize: 14,
    },
  };
}

export function convertTopicToNodes(
  colorMap: ColorMap,
  colorGroupBy: string[],
  queryData: QueryData,
  selectedPreset: Preset[],
  topicData: TopicData | undefined,
): IndexNode {
  const { topics } = queryData;
  if (!topics || !topicData) return {};

  /* some topics have ALOT of post counts, to the point that 
  it looks a bit huge compared to other topics. This aims to scale them down.
  The formula is copied from BubbleChart.tsx
  */
  const [minPostCount, maxPostCount] = topics.length
    ? extent(topics, (t) => t.postCount)
    : [1, 1];
  const scalingExponent = 0.6;

  const powScale = scalePower({
    exponent: scalingExponent,
    domain: [minPostCount, maxPostCount],
    range: [1, 5],
  });

  const { ngramsByTopicId, subcategoryByTopicId, titleByTopicId } = topicData;
  return topics.reduce<IndexNode>(
    (acc, t) =>
      Object.assign(acc, {
        [t.id]: {
          data: {
            ...t,
            type: "topics",
          },
          size: powScale(t.postCount),
          ...getTopicStyleProps(
            colorGroupBy,
            colorMap.narratives,
            t.id,
            titleByTopicId[t.id] ?? ngramsByTopicId[t.id][0] ?? "",
            selectedPreset,
            subcategoryByTopicId,
          ),
        },
      }),
    {},
  );
}

export interface RegraphClusterData {
  topicData?: TopicData;
  coordinationData?: CoordinationData;
}

export function getClusterData(
  queryData: QueryData | undefined,
  subcategoryByTopicId: Record<string, string> | undefined,
): RegraphClusterData {
  if (!queryData) {
    return {};
  }
  const { topics, posts, accounts } = queryData;

  return {
    topicData: topics ? getTopicData(subcategoryByTopicId, topics) : undefined,
    coordinationData:
      posts || accounts ? getCoordinationData(posts, accounts) : undefined,
  };
}

interface CoordinationData {
  coordinationClusterIds: string[];
}

function getCoordinationData(posts?: QueryPost[], accounts?: QueryAccount[]) {
  const coordinationClusterIds = new Set<string>();

  posts?.forEach((post) => {
    if (post.coordinationClusterId) {
      coordinationClusterIds.add(post.coordinationClusterId);
    }
  });

  accounts?.forEach((account) => {
    if (account.coordinationClusterId) {
      coordinationClusterIds.add(account.coordinationClusterId);
    }
  });

  return {
    coordinationClusterIds: Array.from(coordinationClusterIds),
  };
}

interface TopicData {
  ngramsByTopicId: Record<string, string[]>;
  subcategoryByTopicId: Record<string, string> | undefined;
  titleByTopicId: Record<string, string | null>;
}

export function getTopicData(
  subcategoryByTopicId: Record<string, string> | undefined,
  topics: Topics[],
): TopicData {
  const dataByTopicId = topics.reduce(
    (acc, topic) =>
      Object.assign(acc, {
        ngrams: Object.assign(acc.ngrams, { [topic.id]: topic.ngrams }),
        titles: Object.assign(acc.titles, { [topic.id]: topic.title }),
      }),
    { ngrams: {}, titles: {} } as {
      ngrams: Record<string, string[]>;
      titles: Record<string, string | null>;
    },
  );

  return {
    ngramsByTopicId: dataByTopicId.ngrams,
    subcategoryByTopicId,
    titleByTopicId: dataByTopicId.titles,
  };
}

/* Styling Combos */

function styleNarrativesCombo(
  colorMapNarrative: ColorMap["narratives"],
  combo: Pick<GraphNode, ClusteringNodeKey>,
  graphState: GraphState,
  id: string,
  openCombos: Record<string, boolean>,
  nodes: Index<Node<GraphNode>>,
  setStyle: (style: Chart.ComboStyle) => void,
  topicData: RegraphClusterData["topicData"],
) {
  if (!combo.topicId || !topicData) {
    return;
  }
  const {
    colorGroupBy: [colorGroupBy],
    preset: [selectedPreset],
  } = graphState;
  const { ngramsByTopicId, subcategoryByTopicId, titleByTopicId } = topicData;

  const { color, label } = getTopicStyleProps(
    colorGroupBy,
    colorMapNarrative,
    combo.topicId ?? "",
    titleByTopicId[combo.topicId] ??
      ngramsByTopicId[combo.topicId]?.join(", ") ??
      "",
    selectedPreset,
    subcategoryByTopicId,
  );

  setStyle({
    open: !!openCombos[id],
    label,
    color,
    size: Math.sqrt(Object.keys(nodes).length),
  });
}

function getPlatformSVGToUrl(svg: string) {
  const viewBoxRegex = /viewBox="([^"]+)"/;
  const viewBoxMatch = svg.match(viewBoxRegex);

  let viewBox = "0 0 41 41"; //default viewbox, confirmed with all platform's svg
  if (viewBoxMatch && viewBoxMatch[1]) {
    viewBox = viewBoxMatch[1];
  }

  const circularMask = `
  <mask id="circleMask">
    <circle cx="50%" cy="50%" r="50%" fill="white" />
  </mask>
`;

  const wrappedSvg = `
<svg width="100%" height="100%" viewBox="${viewBox}" xmlns="http://www.w3.org/2000/svg">
  ${circularMask}
  <g mask="url(#circleMask)">
    ${svg}
  </g>
</svg>
`;

  return convertSVGToUrl(wrappedSvg);
}

function styleCoordinationCombos(
  combo: Pick<GraphNode, ClusteringNodeKey>,
  setStyle: (style: Chart.ComboStyle) => void,
) {
  if (!combo.coordinationClusterId) return;

  setStyle({
    label: {
      text: undefined,
    },
    border: {
      color: getCSSValue("--color-utility-gray-700"),
      lineStyle: "dashed",
    },
    color: getCSSValue("--background-color-secondary"),
  });
}

function stylePlatformCombos(
  combo: Pick<GraphNode, ClusteringNodeKey>,
  setStyle: (style: Chart.ComboStyle) => void,
) {
  if (!combo.platform) return;
  const info = platformInfo[combo.platform as Platform];
  setStyle({
    glyphs: [
      {
        position: "nw",
        image: info ? getPlatformSVGToUrl(info.svg) : undefined,
        size: 3,
      },
    ],
    label: {
      text: undefined,
    },
    border: {
      color: info ? info.color : getCSSValue("--border-color-primary"),
    },
    color: getCSSValue("--background-color-secondary"),
  });
}

export function styleCombos(
  colorMap: ColorMap,
  combo: Pick<GraphNode, ClusteringNodeKey>,
  clusterData: RegraphClusterData,
  graphState: GraphState,
  id: string,
  nodes: Index<Node<GraphNode>>,
  openCombos: Record<string, boolean>,
  selectedGroup: ClusteringNodeKey[],
  setStyle: (style: Chart.ComboStyle) => void,
) {
  const keys = Object.keys(combo) as ClusteringNodeKey[];
  const comboType = selectedGroup.find((group) => keys.includes(group));
  if (!comboType) return;

  switch (comboType) {
    case "topicId":
      styleNarrativesCombo(
        colorMap.narratives,
        combo,
        graphState,
        id,
        openCombos,
        nodes,
        setStyle,
        clusterData.topicData,
      );
      break;
    case "coordinationClusterId":
      styleCoordinationCombos(combo, setStyle);
      break;
    case "platform":
      stylePlatformCombos(combo, setStyle);
      break;
  }
}

/* Popup */
export function setGraphDataFromRegraphObject(
  allItems: Items<QueryNodes>,
  conversationId: string,
  item: Link<QueryNodes> | Node<QueryNodes>,
  onDismiss: () => void,
  setGraphData: (props: GraphPopupProps) => void,
  setSelection: Dispatch<SetStateAction<Index<boolean | Node | Link>>>,
) {
  if (!item.data) return;

  if (isAccountNode(item)) {
    setGraphData({
      type: "accounts",
      accountId: item.data.id,
      conversationId,
      onDismiss,
    });
  } else if (isPostNode(item)) {
    setGraphData({
      conversationId,
      type: "posts",
      onDismiss,
      data: item.data,
    });
  } else if (isDomainNode(item)) {
    setGraphData({
      conversationId,
      type: "domains",
      onDismiss,
      data: item.data,
    });
  } else if (isUrlNode(item)) {
    setGraphData({
      conversationId,
      type: "links",
      onDismiss,
      id: item.data?.url ?? "",
      isRegraph: true,
    });
  } else if (isHashtagNode(item)) {
    setGraphData({
      conversationId,
      type: "hashtags",
      onDismiss,
      data: item.data,
    });
  } else if (isLink(item)) {
    if (item.data.type === "accountCoordinationEvidenceAccounts") {
      const [lowerId, higherId] = [item.id1, item.id2].sort();
      const clusterId =
        allItems[lowerId].data?.coordinationClusterId ??
        allItems[higherId].data?.coordinationClusterId;
      if (!clusterId) return;
      setGraphData({
        type: "coordinationEdge",
        conversationId,
        sourceUserRid: lowerId,
        targetUserRid: higherId,
        onDismiss,
        clusterId,
        regraphObject: {
          data: allItems,
          setSelection,
        },
      });
    } else
      setGraphData({
        conversationId,
        type: "genericEdge",
        onDismiss,
        link: item,
        setSelection,
        data: allItems,
      });
  }
}

export function getFilteredNodes(
  nodes: IndexNode,
  nodesByEdgeCount: NodesByEdgeCount | undefined,
  minEdge: number,
) {
  if (minEdge === 0 || !nodesByEdgeCount) {
    return nodes;
  }

  const filteredNodes: IndexNode = {};
  Object.keys(nodesByEdgeCount).forEach((key) => {
    const edgeCount = Number(key);
    if (edgeCount >= minEdge) {
      Object.assign(filteredNodes, nodesByEdgeCount[edgeCount]);
    }
  });

  return filteredNodes;
}
