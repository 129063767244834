import { type FunctionComponent, useMemo, useContext } from "react";

import {
  useAmplifierFollowingCounts,
  useAmplifierMentioningCounts,
  useAmplifierReplyingCounts,
  useAmplifierSharingCounts,
} from "~/hooks/useActorKeyInsights";
import { useConversationFilters } from "~/hooks/useConversationFilters";
import { RegraphContext } from "~/pages/Graph/components/ControlPanel";

import {
  type ActorKeyInsightsProps,
  ActorKeyInsightsSubTabs,
  getColumnData,
  getSortedData,
  InfoText,
} from "./ActorKeyInsights";
import {
  ActorTable,
  NoDataPlaceholder,
  PaddedLoadingIndicator,
} from "./NetworkKeyInsights";
import Icon from "./library/Icon";
import { TabItem, TabKind, TabSize } from "./library/Tabs";

const FollowingTab: FunctionComponent<ActorKeyInsightsProps> = (props) => {
  const { conversationId } = props;
  const filters = useConversationFilters();

  const { data: followingData, isLoading } = useAmplifierFollowingCounts(
    conversationId,
    filters,
  );
  const regraph = useContext(RegraphContext);

  const { columnData, sortedData } = useMemo(() => {
    const columnData = getColumnData(
      conversationId,
      "Following",
      regraph?.regraphObject,
    );
    const sortedData = getSortedData(followingData);
    return {
      columnData,
      sortedData,
    };
  }, [conversationId, followingData, regraph?.regraphObject]);

  return (
    <>
      <InfoText>
        <Icon family="untitled" name="info-circle" />
        Number of accounts these accounts follow
      </InfoText>
      <ActorTable
        disableFilter
        id="amplifier-following-table"
        tableColumns={columnData}
        tableData={sortedData}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && followingData?.data?.length === 0 && <NoDataPlaceholder />}
    </>
  );
};

const SharingTab: FunctionComponent<ActorKeyInsightsProps> = (props) => {
  const { conversationId } = props;
  const filters = useConversationFilters();

  const { data: sharingData, isLoading } = useAmplifierSharingCounts(
    conversationId,
    filters,
  );
  const regraph = useContext(RegraphContext);

  const { columnData, sortedData } = useMemo(() => {
    const columnData = getColumnData(
      conversationId,
      "Sharing",
      regraph?.regraphObject,
    );
    const sortedData = getSortedData(sharingData);
    return {
      columnData,
      sortedData,
    };
  }, [conversationId, regraph?.regraphObject, sharingData]);

  return (
    <>
      <InfoText>
        <Icon family="untitled" name="info-circle" />
        Number of times these accounts shared others' posts in the conversation
      </InfoText>
      <ActorTable
        disableFilter
        id="amplifier-sharing-table"
        tableColumns={columnData}
        tableData={sortedData}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && sharingData?.data.length === 0 && <NoDataPlaceholder />}
    </>
  );
};

const ReplyingTab: FunctionComponent<ActorKeyInsightsProps> = (props) => {
  const { conversationId } = props;
  const filters = useConversationFilters();

  const { data: replyingData, isLoading } = useAmplifierReplyingCounts(
    conversationId,
    filters,
  );
  const regraph = useContext(RegraphContext);

  const { columnData, sortedData } = useMemo(() => {
    const columnData = getColumnData(
      conversationId,
      "Replying",
      regraph?.regraphObject,
    );
    const sortedData = getSortedData(replyingData);
    return {
      columnData,
      sortedData,
    };
  }, [conversationId, regraph?.regraphObject, replyingData]);

  return (
    <>
      <InfoText>
        <Icon family="untitled" name="info-circle" />
        Number of times these accounts replied to others' posts in the
        conversation
      </InfoText>
      <ActorTable
        disableFilter
        id="amplifier-replying-table"
        tableColumns={columnData}
        tableData={sortedData}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && replyingData?.data.length === 0 && <NoDataPlaceholder />}
    </>
  );
};

const MentioningTab: FunctionComponent<ActorKeyInsightsProps> = (props) => {
  const { conversationId } = props;
  const filters = useConversationFilters();

  const { data: mentioningData, isLoading } = useAmplifierMentioningCounts(
    conversationId,
    filters,
  );
  const regraph = useContext(RegraphContext);

  const { columnData, sortedData } = useMemo(() => {
    const columnData = getColumnData(
      conversationId,
      "Mentioning",
      regraph?.regraphObject,
    );
    const sortedData = getSortedData(mentioningData);
    return {
      columnData,
      sortedData,
    };
  }, [conversationId, mentioningData, regraph?.regraphObject]);

  return (
    <>
      <InfoText>
        <Icon family="untitled" name="info-circle" />
        Number of times these accounts mentioned other accounts in the
        conversation
      </InfoText>
      <ActorTable
        disableFilter
        id="amplifier-mentioning-table"
        tableColumns={columnData}
        tableData={sortedData}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && mentioningData?.data?.length === 0 && (
        <NoDataPlaceholder />
      )}
    </>
  );
};

const AmplifierKeyInsights: FunctionComponent<ActorKeyInsightsProps> = (
  props,
) => {
  const { conversationId } = props;

  return (
    <ActorKeyInsightsSubTabs data-kind={TabKind.Inner} data-size={TabSize.sm}>
      <TabItem id="amplifier-following-tab" title="Following">
        <FollowingTab conversationId={conversationId} />
      </TabItem>
      <TabItem id="amplifier-sharing-tab" title="Sharing">
        <SharingTab conversationId={conversationId} />
      </TabItem>
      <TabItem id="amplifier-replying-tab" title="Replying">
        <ReplyingTab conversationId={conversationId} />
      </TabItem>
      <TabItem id="amplifier-mentioning-tab" title="Mentioning">
        <MentioningTab conversationId={conversationId} />
      </TabItem>
    </ActorKeyInsightsSubTabs>
  );
};

export default AmplifierKeyInsights;
