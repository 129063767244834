import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import { text } from "~/styles/typography";

import ActorKeyInsights from "./ActorKeyInsights";
import SiteKeyInsights from "./SiteKeyInsights";
import Table from "./Table";
import { LoadingIndicator } from "./library";

export interface KeyInsightsProps {
  conversationId: string;
}

const Container = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 9fr 10fr; /* gives a little more visual balance to the actor sub-tabs */
  gap: var(--spacing-xl);
  border: var(--border-standard);
  border-radius: var(--border-radius-default);
  box-shadow: var(--box-shadow-key-insights);
  padding: var(--spacing-xl);
`;

const Title = styled.div`
  ${text.xl.regular};
`;

export const KeyInsightsTable = styled(Table)`
  border-radius: 3px;

  tr {
    grid-template-columns: 1fr max-content;
  }

  thead {
    tr {
      border-radius: 3px;
      /* need overflow: hidden for the border radius to show up */
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      th {
        color: var(--background-color-primary);
        ${text.sm.regular};
        padding: 0;

        button {
          background-color: transparent;
          color: var(--text-color-primary);
          ${text.xs.semibold};
          padding: var(--spacing-md) var(--spacing-lg);
        }
      }
    }
  }

  tbody {
    overflow-y: auto;
    scrollbar-width: thin;

    tr:last-child {
      border-radius: 3px;
      /* need overflow: hidden for the border radius to show up */
      overflow: hidden;
    }

    tr {
      td {
        ${text.sm.regular};
        padding: var(--spacing-md) var(--spacing-lg);
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        button {
          white-space: normal;
          word-break: break-all;
          text-align: left;
        }
      }

      td:last-child {
        text-align: right;
        padding-right: var(--spacing-3xl);
      }
    }
  }
`;

export const ActorTable = styled(KeyInsightsTable)`
  scrollbar-width: thin;
`;

export const PaddedLoadingIndicator = styled(LoadingIndicator)`
  margin-top: var(--spacing-3xl);
`;
const NoDataPlaceholderContainer = styled.div`
  margin-top: var(--spacing-3xl);
  display: flex;
  justify-content: center;
`;

export const NoDataPlaceholder = () => (
  <NoDataPlaceholderContainer aria-hidden="true">
    No data found
  </NoDataPlaceholderContainer>
);

const NetworkKeyInsights: FunctionComponent<{
  conversationId: string;
  excludeTwitter: boolean;
}> = (props) => {
  return (
    <Container>
      <Title>Key Insights</Title>
      <SiteKeyInsights
        conversationId={props.conversationId}
        excludeTwitter={props.excludeTwitter}
      />
      <ActorKeyInsights conversationId={props.conversationId} />
    </Container>
  );
};

export default NetworkKeyInsights;
