import { styled } from "@linaria/react";
import { useControlledState } from "@react-stately/utils";
import {
  type ComponentPropsWithoutRef,
  type FunctionComponent,
  useCallback,
} from "react";

import { text } from "~/styles/typography";

import Button, { ButtonKind, ButtonSize } from "./Button";

export enum PaginationKind {
  Default = "default",
  ButtonGroup = "button-group",
}

export type PageChangeData = {
  /** The page number that was selected, 0-indexed */
  page: number;
  /** The start row of the selected page, 0-indexed */
  startRow: number;
  /** The end row of the selected page, 0-indexed */
  endRow: number;
};

type HTMLNavProps = Omit<ComponentPropsWithoutRef<"nav">, "children">;

type BasePaginationProps = {
  /** Whether all pagination controls are disabled */
  disabled?: boolean;
  "data-kind"?: PaginationKind;
  as?: "div" | "nav";
};

type ControlledPaginationProps = BasePaginationProps & {
  /** The current selected page number (controlled) */
  currentPage?: number;
  initialPage?: never;
};

type UncontrolledPaginationProps = BasePaginationProps & {
  /** The initial page number (uncontrolled) */
  initialPage?: number;
  currentPage?: never;
};

export type PaginationProps = HTMLNavProps &
  (ControlledPaginationProps | UncontrolledPaginationProps) & {
    /** The total number of pages */
    pageCount: number;
    onPageChange?: (page: number) => void;
  };

export type TablePaginationProps = Omit<HTMLNavProps, "aria-controls"> &
  (ControlledPaginationProps | UncontrolledPaginationProps) & {
    /** The ID of the table that the pagination controls */
    "aria-controls": string;
    /** The total number of rows in the data set */
    rowCount: number;
    /** The number of rows per page */
    rowsPerPage: number;
    /** Called when the page changes. The `startRow` and `endRow` properties form
     * a half-open range [start, end) i.e. suitable to be passed to `Array.slice` */
    onPageChange?: (event: PageChangeData) => void;
  };

type UsePaginationItemsProps = {
  disabled?: boolean;
  pageCount: number;
  currentPage?: number;
  initialPage?: number;
  onPageChange?: (page: number) => void;
};

type PaginationItem = {
  type: "next" | "previous" | "page" | "start-ellipsis" | "end-ellipsis";
  page: number | null;
  onClick?: () => void;
  isCurrent: boolean;
  disabled: boolean;
};

const PaginationRoot = styled.nav`
  border-top: 1px solid var(--border-color-secondary);
  padding: var(--spacing-lg) var(--spacing-3xl) var(--spacing-xl);

  &[data-kind=${PaginationKind.ButtonGroup}] {
    display: flex;
    justify-content: center;
  }
`;

const PaginationButton = styled(Button)`
  height: 40px;
  width: 40px;

  &[aria-current] {
    background-color: var(--background-color-tertiary);
  }
`;

const PaginationEllipsis = styled.span`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color-tertiary);
  ${text.sm.medium}

  &[data-disabled] {
    color: var(--foreground-color-disabled);
  }
`;

const PaginationList = styled.ul`
  display: grid;
  align-items: center;

  [data-kind=${PaginationKind.Default}] & {
    grid-template-columns: 1fr repeat(var(--item-count, 1), auto) 1fr;
    column-gap: var(--spacing-xxs);

    [data-slot="previous"] {
      justify-self: start;
    }
    [data-slot="next"] {
      justify-self: end;
    }

    ${PaginationButton} {
      border-radius: var(--border-radius-full);
    }
  }

  [data-kind=${PaginationKind.ButtonGroup}] & {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-xs);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--border-color-primary);

    [data-slot="previous"],
    [data-slot="next"] {
      & > button {
        height: 40px;
      }
    }

    ${PaginationButton},
    [data-slot="previous"] > button,
    [data-slot="next"] > button {
      border-radius: 0;
    }

    [data-slot="previous"] > button {
      border-top-left-radius: var(--border-radius-md);
      border-bottom-left-radius: var(--border-radius-md);
    }
    [data-slot="next"] > button {
      border-top-right-radius: var(--border-radius-md);
      border-bottom-right-radius: var(--border-radius-md);
    }

    > li:not([data-slot="next"]) {
      border-right: 1px solid var(--border-color-primary);
    }
  }
`;

// build a closed range [start, end]
const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

// the minimum number of pages at the start and end of the pagination
const BOUNDARY_PAGE_COUNT = 2;
// the minimum number of pages on either side of the current page
const SIBLING_PAGE_COUNT = 1;

function usePaginationItems(props: UsePaginationItemsProps): {
  previous: PaginationItem;
  next: PaginationItem;
  items: PaginationItem[];
} {
  const {
    disabled = false,
    pageCount,
    currentPage: controlledCurrentPage,
    initialPage = 1,
    onPageChange,
  } = props;
  const [currentPage, setCurrentPage] = useControlledState(
    controlledCurrentPage,
    initialPage,
    onPageChange,
  );

  const startPages = range(1, Math.min(BOUNDARY_PAGE_COUNT, pageCount));
  const endPages = range(
    Math.max(pageCount - BOUNDARY_PAGE_COUNT + 1, BOUNDARY_PAGE_COUNT + 1),
    pageCount,
  );

  const siblingsStartIndex = Math.max(
    Math.min(
      // Natural start
      currentPage - SIBLING_PAGE_COUNT,
      // avoid overlap with end pages when currentPage is high
      pageCount - BOUNDARY_PAGE_COUNT - SIBLING_PAGE_COUNT * 2 - 1,
    ),
    // avoid overlap with start pages when currentPage is low
    BOUNDARY_PAGE_COUNT + 2,
  );
  const siblingsEndIndex = Math.min(
    Math.max(
      // Natural end
      currentPage + SIBLING_PAGE_COUNT,
      // avoid overlap with start pages when currentPage is low
      BOUNDARY_PAGE_COUNT + 2 + 2 * SIBLING_PAGE_COUNT,
    ),
    // avoid overlap with end pages when currentPage is high
    endPages.length > 0 ? endPages[0] - 2 : pageCount - 1,
  );
  const siblingPages = range(siblingsStartIndex, siblingsEndIndex);

  const itemList = [
    ...startPages,

    // ellipsis before siblings OR the single page between start pages and siblings
    ...(siblingsStartIndex > BOUNDARY_PAGE_COUNT + 2
      ? ["start-ellipsis" as const]
      : BOUNDARY_PAGE_COUNT + 1 < pageCount - BOUNDARY_PAGE_COUNT
      ? [BOUNDARY_PAGE_COUNT + 1]
      : []),

    ...siblingPages,

    // ellipsis after siblings OR the single page between siblings and end pages
    ...(siblingsEndIndex < pageCount - BOUNDARY_PAGE_COUNT - 1
      ? ["end-ellipsis" as const]
      : pageCount - BOUNDARY_PAGE_COUNT > BOUNDARY_PAGE_COUNT
      ? [pageCount - BOUNDARY_PAGE_COUNT]
      : []),

    ...endPages,
  ];

  const items: PaginationItem[] = itemList.map((item) => {
    return typeof item === "string"
      ? {
          type: item,
          page: null,
          isCurrent: false,
          disabled: true,
        }
      : {
          type: "page",
          page: item,
          onClick: () => setCurrentPage(item),
          isCurrent: item === currentPage,
          disabled,
        };
  });

  return {
    previous: {
      type: "previous",
      page: currentPage - 1,
      onClick: () => setCurrentPage(currentPage - 1),
      isCurrent: false,
      disabled: disabled || currentPage <= 1,
    },
    next: {
      type: "next",
      page: currentPage + 1,
      onClick: () => setCurrentPage(currentPage + 1),
      isCurrent: false,
      disabled: disabled || currentPage >= pageCount,
    },
    items,
  };
}

/**
 * A pagination component that allows users to navigate through a series of pages.
 * The component can be controlled using `currentPage` or uncontrolled using `initialPage`.
 * Page numbers are 1-indexed. Pagination items are generated based on the `pageCount` prop.
 */
export const Pagination: FunctionComponent<PaginationProps> = (props) => {
  const {
    "data-kind": kind = PaginationKind.Default,
    disabled,
    pageCount,
    currentPage,
    initialPage,
    onPageChange,
    ...navProps
  } = props;

  const { previous, next, items } = usePaginationItems(props);

  return (
    <PaginationRoot aria-label="pagination" {...navProps} data-kind={kind}>
      <PaginationList style={{ "--item-count": items.length }}>
        <li data-slot="previous">
          <Button
            aria-label="go to previous page"
            data-kind={
              kind === PaginationKind.Default
                ? ButtonKind.Secondary
                : ButtonKind.Tertiary
            }
            data-size={ButtonSize.sm}
            disabled={previous.disabled}
            onPress={previous.onClick}
            preIcon="arrow-left"
            type="button"
          >
            Previous
          </Button>
        </li>

        {items.map((item) => {
          return item.page == null ? (
            <li key={item.type}>
              <PaginationEllipsis data-disabled={disabled || undefined}>
                …
              </PaginationEllipsis>
            </li>
          ) : (
            <li key={item.page}>
              <PaginationButton
                aria-current={item.isCurrent ? "true" : undefined}
                aria-label={`${item.isCurrent ? "" : "go to "}page ${
                  item.page
                }`}
                data-kind={ButtonKind.Tertiary}
                data-size={ButtonSize.sm}
                disabled={item.disabled}
                onPress={item.onClick}
                type="button"
              >
                {item.page}
              </PaginationButton>
            </li>
          );
        })}

        <li data-slot="next">
          <Button
            aria-label="go to next page"
            data-kind={
              kind === PaginationKind.Default
                ? ButtonKind.Secondary
                : ButtonKind.Tertiary
            }
            data-size={ButtonSize.sm}
            disabled={next.disabled}
            onPress={next.onClick}
            postIcon="arrow-right"
            type="button"
          >
            Next
          </Button>
        </li>
      </PaginationList>
    </PaginationRoot>
  );
};

/**
 * Use TablePagination for paginating tabular data. The component calculates the
 * number of pages based on the `rowCount` and `rowsPerPage` props. The `onPageChange`
 * callback is called with the selected page number and the half-open range of rows
 * to display on that page. Page numbers are 0-indexed.
 */
export const TablePagination: FunctionComponent<TablePaginationProps> = (
  props,
) => {
  const {
    rowCount,
    rowsPerPage,
    onPageChange,
    currentPage: currentPageProp,
    initialPage: initialPageProp,
    ...paginationProps
  } = props;
  const pageCount =
    rowCount > 0 && rowsPerPage > 0 ? Math.ceil(rowCount / rowsPerPage) : 0;

  const handlePageChange = useCallback(
    (selectedPage: number) => {
      // convert 1-indexed page to 0-indexed page
      const page = selectedPage - 1;
      const startRow = page * rowsPerPage;
      const endRow = Math.min(startRow + rowsPerPage, rowCount);

      onPageChange?.({
        page,
        startRow,
        endRow,
      });
    },
    [onPageChange, rowsPerPage, rowCount],
  );

  // convert 0-indexed page to 1-indexed page
  const currentPage =
    typeof currentPageProp === "number" ? currentPageProp + 1 : undefined;
  const initialPage =
    typeof initialPageProp === "number" ? initialPageProp + 1 : undefined;

  return (
    // @ts-expect-error - only one of currentPage or initialPage will be defined
    <Pagination
      as="div"
      {...paginationProps}
      currentPage={currentPage}
      initialPage={initialPage}
      onPageChange={handlePageChange}
      pageCount={pageCount}
    />
  );
};
