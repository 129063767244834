export enum QueryErrorReason {
  NoInput,
  MissingQuote,
  LowercaseKeyword,
  UnbalancedKeyword,
  MissingParen,
  ConsecutiveKeywords,
  EmptyParens,
  SmartQuote,
}

export class QueryError extends Error {
  msg: QueryErrorReason;
  position: number;

  constructor(msg: QueryErrorReason, position: number) {
    super();

    this.msg = msg;
    this.position = position;
  }
}

/**
 * @throws a QueryError if `input` is not a valid query string
 */
export const validateQueryString = (input: string) => {
  let pos = 0;

  if (input.length === 0) {
    throw new QueryError(QueryErrorReason.NoInput, 0);
  }

  /* private function to get the next token in our language from the input text */
  function _nextToken() {
    let text = input.slice(pos);
    let match: RegExpMatchArray | null;

    /* consume, and ignore, any leading whitespace */
    if ((match = text.match(/^(\s+)/u)) !== null) {
      pos += match[1].length;
      text = input.slice(pos);

      /* if there's no more text after consuming this whitespace then we're done */
      if (input[pos] === undefined) {
        return undefined;
      }
    }

    const c = input[pos];

    if (c === "(" || c === ")") {
      /* paren literal, consume it and return it as the next token */
      return input[pos++];
    } else if (c === `"` || c === "'") {
      /* string literal, consume everything until the next quote and return it as
       * the next token */
      match = text.match(new RegExp(`^${c}([^${c}]*)${c}`, "g"));

      if (!match) {
        /* we didn't find the closing quote before the end of our input */
        throw new QueryError(QueryErrorReason.MissingQuote, pos);
      }

      pos += match[0].length;

      return match[0].slice(1, -1);
    } else if (c === `“`) {
      throw new QueryError(QueryErrorReason.SmartQuote, pos);
    }

    /* capture anything that's not leading whitespace and not a quote; that
     * means keywords (AND|OR|NOT) as well as bare-word search terms */
    match = text.match(/^([^\s\)\("']+)/iu);

    if (!match) {
      return;
    }

    pos += match[1].length;

    return match[1];
  }

  /* private function to get the next expression (a list of one or more tokens) from our token-stream */
  function _nextExpr() {
    const token = _nextToken();

    if (!token) {
      return undefined;
    }

    const tokenPos = pos - token.length;

    if (token.match(/^((and)|(or)|(not))$/iu)) {
      /* case-insensitive match of a keyword.  we can examine this token by itself without
       * needing any greater context.  make sure it's uppercase and throw an error if not */
      if (!token.match(/^\p{Lu}+$/u)) {
        throw new QueryError(QueryErrorReason.LowercaseKeyword, tokenPos);
      }

      /* make sure that whatever's on the right-hand side of a keyword is itself valid */
      const expr = _nextExpr();

      if (expr === undefined || expr === ")") {
        /* we can't have a keyword followed by nothing */
        throw new QueryError(QueryErrorReason.UnbalancedKeyword, tokenPos);
      } else if (expr === "kw") {
        /* can't have two keywords in a row */
        throw new QueryError(QueryErrorReason.ConsecutiveKeywords, tokenPos);
      }

      return "kw";
    } else if (token === ")") {
      throw new QueryError(QueryErrorReason.MissingParen, tokenPos);
    } else if (token === "(") {
      /* open-paren means the start of a grouping.  make sure that we have:
       * - one or more valid expressions; which are
       * - followed by a close-paren */
      let expr;
      const exprs: string[] = [];
      let peek: RegExpMatchArray | null;

      if (input[pos] === ")") {
        throw new QueryError(QueryErrorReason.EmptyParens, tokenPos);
      }

      while ((expr = _nextExpr()) !== undefined) {
        exprs.push(expr);

        /* peek to see if the next token would be a close paren */
        peek = input.slice(pos).match(/^(\s*)\)/u);

        if (peek) {
          pos += peek[1].length;

          break;
        }
      }

      if (expr === undefined) {
        /* we scanned the remaining input but never found our close-paren */
        throw new QueryError(QueryErrorReason.MissingParen, tokenPos);
      } else if (exprs.length === 0) {
        throw new QueryError(QueryErrorReason.EmptyParens, tokenPos);
      }

      /* consume the trailing close-paren */
      pos++;

      return "group";
    } else {
      return token;
    }
  }

  /* verify that all expressions are valid (we don't care what they are) */
  while (_nextExpr() !== undefined) {}
};
