import { lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route } from "react-router-dom";

import App from "~/components/App";

import AppProvider from "./AppProvider";
import { Notifications } from "./hooks/useNotify";
import Dashboard from "./pages/dashboard";
import ForgotPassword from "./pages/forgotPassword";
import Login from "./pages/login";
import ChangePasswordPage from "./pages/passwordChange";
import ResetPasswordPage from "./pages/resetPassword";
import Welcome from "./pages/welcome";
import * as Analytics from "./utils/analytics";
import * as ErrorReporting from "./utils/errorReporting";

import "./global.css";

const DataSourceManagement = lazy(() =>
  import("./components/DataSourceManagement").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const OrganizationManagement = lazy(() =>
  import("./components/OrganizationManagement").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const UserManagement = lazy(() =>
  import("./components/UserManagement").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const NotFound = lazy(() =>
  import("./pages/404").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const Settings = lazy(() =>
  import("./pages/Settings").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const Conversation = lazy(() =>
  import("./pages/conversation").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const ScaledConversation = lazy(() =>
  import("./pages/scaledConversation").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const ConversationExplorer = lazy(() =>
  import("./pages/conversationExplorer").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const EventAudit = lazy(() =>
  import("./pages/eventAudit").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const AlertAudit = lazy(() =>
  import("./pages/alertAudit").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const Insights = lazy(() =>
  import("./pages/insights").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const MyOrganization = lazy(() =>
  import("./pages/myOrganization").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const QueryAudit = lazy(() =>
  import("./pages/queryAudit").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const SystemStatus = lazy(() =>
  import("./pages/systemStatus").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);
const MfaRequired = lazy(() =>
  import("./pages/mfaRequired").catch(() => {
    window.location.reload();
    throw Promise.reject();
  }),
);

Analytics.init();
ErrorReporting.init();
const Routes = ErrorReporting.getRoutesWithSentry();

if (process.env.NODE_ENV !== "production") {
  import("../support/mock-server").then(({ makeServer }) => {
    makeServer({ environment: process.env.NODE_ENV });
    renderApp();
  });
} else {
  renderApp();
}

function renderApp() {
  /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    -- if document doesn't exist then we have bigger problems */
  createRoot(document.getElementById("app")!).render(
    <BrowserRouter>
      <AppProvider>
        <Notifications />
        <Routes>
          <Route element={<Login />} path="/login" />
          <Route element={<ForgotPassword />} path="/forgotPassword" />
          <Route element={<ResetPasswordPage />} path="/resetPassword/*" />
          <Route element={<ChangePasswordPage />} path="/changePassword" />
          <Route element={<MfaRequired />} path="/mfa-required" />
          <Route element={<App />}>
            <Route element={<MyOrganization />} path="/myOrganization">
              <Route element={<DataSourceManagement />} path="datasources" />
              <Route element={<Insights />} path="insights" />
              <Route
                element={<OrganizationManagement />}
                path="organizations"
              />
              <Route element={<UserManagement />} path="userManagement" />
              <Route element={<Settings />} path="settings" />
              <Route element={<SystemStatus />} path="systemStatus" />
              <Route element={<QueryAudit />} path="queryAudit" />
              <Route element={<EventAudit />} path="eventAudit" />
              <Route element={<AlertAudit />} path="alertAudit" />
            </Route>
            <Route element={<Dashboard />} path="/">
              <Route
                element={<ScaledConversation />}
                path="conversations/:conversationId"
              >
                <Route
                  element={<ConversationExplorer />}
                  path=":tab?/:subtab?"
                />
              </Route>
              <Route element={<Welcome />} path="projects/:projectName">
                <Route element={<Conversation />} path=":conversationId">
                  <Route
                    element={<ConversationExplorer />}
                    path=":tab?/:subtab?"
                  />
                </Route>
              </Route>
            </Route>
            <Route element={<NotFound />} path="*" />
          </Route>
        </Routes>
      </AppProvider>
    </BrowserRouter>,
  );
}
