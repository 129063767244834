import { color, lab } from "d3";

import { formatDate } from "~/utils/datetime";

export interface TimeSeriesDataPoint {
  date: string;
  value: number;
}

export interface TimeSeriesData<
  T extends TimeSeriesDataPoint = TimeSeriesDataPoint,
> {
  data: T[];
  label: string;
}

const nodeUsageOrder = [
  "var(--color-blue-500)",
  "var(--color-warning-500)",
  "var(--color-purple-900)",
  "var(--color-teal-500)",
  "var(--color-orange-500)",
  "var(--color-pink-300)",
  "var(--color-fuchsia-700)",
  "var(--color-blue-light-300)",
  "var(--color-gray-blue-300)",
  "var(--color-success-200)",
  "var(--color-error-700)",
  "var(--color-pink-500)",
  "var(--color-moss-300)",
];
export function getNodePalette(
  dataCount: number,
  overflowColor = "var(--background-color-disabled)",
) {
  const nodeColors = nodeUsageOrder.slice(0, dataCount);
  if (dataCount > nodeUsageOrder.length) {
    const overflowColors = new Array(
      dataCount - spectrumColorPreferenceOrder.length,
    ).fill(overflowColor);
    return nodeColors.concat(overflowColors);
  }
  return nodeColors;
}

const spectrumColorPreferenceOrder = [
  "var(--color-gradient-10)",
  "var(--color-gradient-2)",
  "var(--color-gradient-6)",
  "var(--color-gradient-12)",
  "var(--color-gradient-8)",
  "var(--color-gradient-4)",
  "var(--color-gradient-11)",
  "var(--color-gradient-9)",
  "var(--color-gradient-7)",
  "var(--color-gradient-5)",
  "var(--color-gradient-3)",
  "var(--color-gradient-1)",
];
export const SPECTRUM_COLOR_COUNT = spectrumColorPreferenceOrder.length;

export function getSpectrumPalette(
  dataCount: number,
  overflowColor = "var(--background-color-disabled)",
): string[] {
  const spectrumColors = spectrumColorPreferenceOrder
    .slice(0, dataCount)
    .sort((a, b) => b.localeCompare(a, "en", { numeric: true }));

  if (dataCount > spectrumColorPreferenceOrder.length) {
    const overflowColors = new Array(
      dataCount - spectrumColorPreferenceOrder.length,
    ).fill(overflowColor);
    return spectrumColors.concat(overflowColors);
  }

  return spectrumColors;
}

export function getForegroundForBackground(background: string) {
  // If background is a variable or var() function, color() will return null, and we need to get the actual value
  const backgroundColor = color(background) ?? color(getCSSValue(background));
  if (!backgroundColor) {
    // background is not a valid color, so we default to "dark"
    return "var(--background-color-quaternary)";
  }

  return lab(backgroundColor).l > 52
    ? "var(--text-color-primary)"
    : "var(--text-color-primary_on-brand)";
}

export function getCSSValue(variable: string) {
  const matchVar = variable.match(/var\(([^)]+)\)/);
  const cssVariable = matchVar ? matchVar[1].trim() : variable;
  return getComputedStyle(document.documentElement)
    .getPropertyValue(cssVariable)
    .trim();
}

export function fillMissingDateData(
  data: TimeSeriesData[],
  dateRanges: Date[],
): TimeSeriesData[] {
  return data.map((item) => {
    const valueByDate = item.data.reduce<Record<string, number>>(
      (acc, datum) => {
        const dateString = formatDate(new Date(datum.date));
        return dateString ? { ...acc, [dateString]: datum.value } : acc;
      },
      {},
    );

    return {
      label: item.label,
      data: dateRanges.map((date) => {
        const dateString = formatDate(date);
        return {
          date: dateString ?? "",
          value: dateString ? valueByDate[dateString] ?? 0 : 0,
        };
      }),
    };
  });
}
