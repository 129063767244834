import { useCallback, useEffect } from "react";

import { type UserPreferenceDTO } from "~/dto";
import {
  UserPreferenceListValidator,
  UserPreferenceValidator,
} from "~/dto/userPreference";

import { useRemoteObject } from "./useRemoteData";
import { type UpdateFetcherFactory } from "./useRemoteData/utils";
import { useUsers } from "./useUsers";

const useUserPreference = () => {
  const { data, update } = useRemoteObject<UserPreferenceDTO>(
    "/api/v2/preference/my",
    { schemaValidator: UserPreferenceValidator },
  );
  const updateTheme = useCallback(
    (theme: string) => {
      update({ theme });
    },
    [update],
  );
  const updateConversationLanguage = useCallback(
    (language: string) => {
      update({ conversation_language: language });
    },
    [update],
  );
  const setAlertsEnabled = useCallback(
    (getAlerts: boolean) => {
      update({ alerts_enabled: getAlerts });
    },
    [update],
  );
  const updateRawDataTableOrdering = useCallback(
    (rawDataTableOrdering: UserPreferenceDTO["raw_data_table_ordering"]) => {
      update({ raw_data_table_ordering: rawDataTableOrdering });
    },
    [update],
  );

  return {
    alertsEnabled: data?.alerts_enabled,
    theme: data?.theme,
    conversationLanguage: data?.conversation_language,
    rawDataTableOrdering: data?.raw_data_table_ordering,
    setAlertsEnabled,
    updateTheme,
    updateConversationLanguage,
    updateRawDataTableOrdering,
  };
};

const updateFetcher: UpdateFetcherFactory<Partial<UserPreferenceDTO>> =
  (authToken, payload) => async (_input: RequestInfo, init?: RequestInit) => {
    if (!authToken || !payload?.user_id) {
      throw new Error("invalid arguments passed to preferences update fetcher");
    }

    const { user_id, ...rest } = payload;

    const path = `/api/v2/preference?user_id=${payload.user_id}`;

    await window.fetch(path, {
      ...init,
      body: JSON.stringify(rest),
      headers: {
        ...init?.headers,
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": authToken,
      },
      method: "PUT",
    });

    return Promise.resolve() as Promise<never>;
  };

const useAllUserPreferences = () => {
  const { data: userData } = useUsers();
  /* we read a collection but write individual objects */
  const remoteObject = useRemoteObject<
    UserPreferenceDTO[],
    Partial<UserPreferenceDTO>
  >("/api/v2/preference", {
    schemaValidator: UserPreferenceListValidator,
    updateBuilder: false,
    updateFetcher,
  });
  const { refresh, update } = remoteObject;
  const updateOne = useCallback(
    async (userId: string, payload: Partial<UserPreferenceDTO>) => {
      await update({
        ...payload,
        user_id: userId,
      });
    },
    [update],
  );

  useEffect(() => {
    refresh();
  }, [refresh, userData]);

  return {
    ...remoteObject,
    update: updateOne,
  };
};

export { useAllUserPreferences, useUserPreference };
