import { type FunctionComponent } from "react";

import {
  type ClusterCoordination,
  type EdgeCoordination,
  type NodeCoordination,
} from "../types";

import { ClusterPopUp } from "./ClusterPopUp";
import { EdgePopUp } from "./EdgePopUp";
import { NodePopUp } from "./NodePopUp";

export type CoordinationPopUpProps =
  | NodeCoordination
  | EdgeCoordination
  | ClusterCoordination;

export const CoordinationPopUp: FunctionComponent<CoordinationPopUpProps> = (
  props,
) => {
  const { type } = props;

  switch (type) {
    case "node":
      return <NodePopUp {...props} />;
    case "edge":
      return <EdgePopUp {...props} />;
    case "cluster":
      return <ClusterPopUp {...props} />;
    default:
      return null;
  }
};
