import * as Sentry from "@sentry/browser";
import { request, type RequestDocument, type Variables } from "graphql-request";
import type { Key } from "swr";
import useSWR from "swr";

import { defaultLogger as logger } from "./useLogger";
import { useSession } from "./useSession";

export function useRemoteQuery<T>(
  url: Key,
  query: RequestDocument,
  variables: Variables,
) {
  const { authToken } = useSession();
  const requestHeaders = {
    "X-CSRF-TOKEN": authToken ?? "",
  };

  return useSWR<T>(
    !!url && !!query && !!variables ? [url, query, variables] : undefined,
    () =>
      request<T>({
        url: String(url),
        document: query,
        variables,
        requestHeaders,
      })
        .then((res) => res as T)
        .catch((err) => {
          Sentry.withScope((scope) => {
            scope.setContext("request", {
              method: "POST",
              url: String(url),
            });
            logger.error(err);
          });

          throw err;
        }),

    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
}
