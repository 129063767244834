import { styled } from "@linaria/react";
import { useCallback, useEffect, useId, useRef } from "react";
import type { Key, PressEvent } from "react-aria-components";

import ReorderableList, { ReorderableItem } from "~/components/ReorderableList";
import Button, { ButtonKind, ButtonSize } from "~/components/library/Button";
import IconButton from "~/components/library/IconButton";
import { text } from "~/styles/typography";

import { type ClusteringNodeKey, isClusteringNodeKey } from "../types";

import { useGraphContext } from "./GraphContext";

const clusterOptions: { id: ClusteringNodeKey; label: string }[] = [
  { id: "platform", label: "Platform" },
  { id: "topicId", label: "Topic" },
  {
    id: "coordinationClusterId",
    label: "Coordination",
  },
];

const Container = styled.div`
  margin: var(--spacing-2xl) 0;

  [slot="title"] {
    ${text.sm.medium};
  }
  [slot="description"] {
    ${text.xs.regular};
  }

  [data-button-icon] {
    display: block;
  }

  > [aria-label] {
    margin-bottom: var(--spacing-2xl);
  }
`;

const ClusterOptionButtonContainer = styled.div`
  margin-top: var(--spacing-2xl);
  display: flex;
  gap: var(--spacing-md);
`;

const ClusterOptionButton = styled(Button)`
  && {
    padding: var(--spacing-xxs) var(--spacing-md);
    ${text.sm.medium};
  }

  [data-icon] {
    width: 16px;
    height: 16px;
  }
`;

const StyledReorderableList = styled(ReorderableList)`
  margin-top: var(--spacing-2xl);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-md);
`;

const StyledReorderableItem = styled(ReorderableItem)`
  border: 1px solid var(--border-color-primary);
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-sm) var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  ${text.sm.medium};
  background: var(--background-color-primary);

  [slot="drag"][disabled] {
    width: 0;
    padding: 0;
    visibility: hidden;
    overflow: hidden;
  }

  button,
  svg {
    width: 20px;
    height: 20px;
    background: transparent;
  }

  svg {
    color: var(--color-utility-brand-400);
  }
`;

function existsAsClusterOptionsObj(
  x: unknown,
): x is (typeof clusterOptions)[number] {
  return x !== undefined;
}

export const ClusterByFilter = () => {
  const {
    graphState,
    layoutState: { resetLayout },
  } = useGraphContext();

  const {
    cluster: [clusters, setClusters],
  } = graphState;
  /* react-aria lists might optimize away some re-renders of their child items,
   * meaning there's a pretty good chance some of those child items will have
   * stale props attached to them no matter how smart we are with our usage of
   * `useCallback`.  put some stuff in a ref and pair it with a `useEffect` to
   * keep it in sync so that callbacks attached to listbox items are guaranteed
   * to be using fresh data */
  const clusterRef = useRef<ClusteringNodeKey[]>(clusters);
  const titleId = useId();
  const descId = useId();
  const onSelectCluster = useCallback(
    (e: PressEvent) => {
      const clusterType = e.target.getAttribute("data-for");

      if (!isClusteringNodeKey(clusterType)) {
        return;
      }

      setClusters([...clusters, clusterType]);
      resetLayout();
    },
    [clusters, resetLayout, setClusters],
  );
  const selectedClusterObjects = clusters
    .map((el) => clusterOptions.find((co) => co.id === el))
    .filter(existsAsClusterOptionsObj);
  const onRemoveCluster = useCallback(
    (e: PressEvent) => {
      const clusterType = e.target.getAttribute("data-for");

      if (!isClusteringNodeKey(clusterType)) {
        return;
      }

      setClusters(clusterRef.current.filter((el) => el !== clusterType));
      resetLayout();
    },
    [resetLayout, setClusters],
  );
  const onReorder = useCallback(
    (what: Key, where: Key, how: "before" | "after") => {
      if (!isClusteringNodeKey(what) || !isClusteringNodeKey(where)) {
        return;
      }
      setClusters((prev) => {
        const idx = prev.findIndex((el) => el === what);
        const next = [...prev.slice(0, idx), ...prev.slice(idx + 1)];
        let newIdx = next.findIndex((el) => el === where);

        if (how === "after") {
          newIdx++;
        }

        return [
          ...next.slice(0, newIdx),
          what,
          ...next.slice(newIdx),
        ].reverse();
      });
      resetLayout();
    },
    [resetLayout, setClusters],
  );
  const items = [...selectedClusterObjects].reverse();

  useEffect(() => {
    clusterRef.current = clusters;
  }, [clusters]);

  return (
    <Container>
      <div id={titleId} slot="title">
        Cluster By
      </div>
      <div id={descId} slot="description">
        Clustering is nested, with the top option being the outer layer of
        nesting
      </div>
      <StyledReorderableList<(typeof clusterOptions)[number]>
        aria-describedby={descId}
        aria-labelledby={titleId}
        enableDrag={clusters.length > 1}
        items={items}
        onReorder={onReorder}
      >
        {(item) => (
          <StyledReorderableItem key={item.id} label={item.label}>
            <span>{item.label}</span>
            <IconButton
              aria-label="Remove this cluster"
              data-for={item.id}
              data-kind={ButtonKind.Tertiary}
              data-size={ButtonSize.sm}
              icon="x-close"
              onPress={onRemoveCluster}
            />
          </StyledReorderableItem>
        )}
      </StyledReorderableList>
      <hr />
      <ClusterOptionButtonContainer>
        {clusterOptions
          .filter((item) => !clusters.includes(item.id))
          .map((item) => (
            <ClusterOptionButton
              key={item.id}
              data-for={item.id}
              data-kind={ButtonKind.Secondary}
              data-size={ButtonSize.sm}
              onPress={onSelectCluster}
              postIcon="plus"
            >
              {item.label}
            </ClusterOptionButton>
          ))}
      </ClusterOptionButtonContainer>
    </Container>
  );
};
