import { type GroupDTO, type OrganizationDTO } from "~/dto";
import { GroupListValidator } from "~/dto/group";
import { OrganizationListValidator } from "~/dto/organization";
import { type UserDTO, UserListValidator } from "~/dto/user";

import {
  useRemoteCollection,
  useRemoteObjectFromCollection,
} from "./useRemoteData";

const useOrganizationUsers = (organizationId: string) => {
  return useRemoteCollection<UserDTO>(
    `/api/v2/organization/${organizationId}/users`,
    { schemaValidator: UserListValidator },
  );
};

const useOrganizationGroups = (organizationId: string) => {
  return useRemoteCollection<GroupDTO>(
    `/api/v2/organization/${organizationId}/group`,
    { schemaValidator: GroupListValidator },
  );
};

const useOrganization = (organizationId: string) => {
  const { data } = useRemoteObjectFromCollection<OrganizationDTO>(
    `/api/v2/organization`,
    organizationId,
    {
      schemaValidator: OrganizationListValidator,
    },
  );

  return data;
};

const useOrganizations = () => {
  const { data } = useRemoteCollection<OrganizationDTO>(
    `/api/v2/organization`,
    {
      createBuilder: () => (payload: Partial<OrganizationDTO>) => ({
        ...payload,
        id: "",
      }),
      schemaValidator: OrganizationListValidator,
    },
  );
  return data;
};

export {
  useOrganization,
  useOrganizations,
  useOrganizationUsers,
  useOrganizationGroups,
};
