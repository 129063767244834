import { styled } from "@linaria/react";

import { text } from "~/styles/typography";

import { Heading } from "./Section";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: var(--spacing-xl);
`;

const FormContentsContainer = styled.div`
  padding: var(--spacing-xl);
  padding-bottom: var(--spacing-3xl);

  > * + * {
    margin-top: var(--spacing-3xl);
  }
`;

const FormTitle = styled(Heading)`
  ${text.lg.medium};
  padding-top: var(--spacing-4xl);
  display: flex;
  justify-content: center;
`;

export { ButtonContainer, FormContentsContainer, FormTitle };
