import { styled } from "@linaria/react";
import {
  type StyledComponent,
  type FunctionComponent,
  useCallback,
  useRef,
  useState,
  type KeyboardEvent,
} from "react";

import { type ConversationDTO, type ProjectDTO } from "~/dto";
import { text } from "~/styles/typography";

import { ButtonKind, ButtonSize } from "../library/Button";
import Icon from "../library/Icon";
import IconButton from "../library/IconButton";
import { TooltipBody } from "../library/Tooltip";

import EditProjectDialog from "./AddEditProjectDialog";
import DeleteProjectDialog from "./DeleteProjectDialog";

export type ProjectTileProps = StyledComponent & {
  collapsed: boolean;
  deleteable?: boolean;
  project: ProjectDTO;
  index: number;
  isSelected: boolean;
  onSelectProject: (projectId: string) => void;
  selectedConversation?: ConversationDTO;
};

const ProjectListItem = styled.li`
  border: 2px solid var(--border-color-secondary);
  border-radius: var(--border-radius-sm);
  position: relative;
  color: var(--text-color-primary);
  background-color: var(--background-color-primary);
  ${text.xs.medium};

  &[aria-current] {
    border: 2px solid var(--border-color-brand-solid);
  }

  [data-icon] {
    width: 16px;
    height: 16px;
  }

  > div[role="button"] {
    padding: var(--spacing-lg);
    display: grid;
    grid-template-areas:
      "title text"
      "count count";
    grid-template-columns: 1fr max-content;
    gap: var(--spacing-md);
    color: var(--text-color-primary);
    cursor: pointer;

    [slot="title"] {
      grid-area: title;
      ${text.xs.semibold};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    [slot="text"] {
      grid-area: text;
      display: flex;
      align-items: center;
      grid-gap: var(--spacing-md);
      ${text.xs.regular};
      justify-content: flex-end;
    }

    [slot="count"] {
      grid-area: count;
      justify-self: end;
    }

    [data-has-tooltip],
    [data-has-tooltip]:focus {
      /* react-aria-components tooltips must have a focusable trigger element
       * for keyboard-nav purposes, but we want that button to act as much like
       * the parent <a> as possible */
      outline: none;
      text-align: start;
      background: transparent;
      cursor: inherit;
    }
  }

  [slot="actions"] {
    /* can't nest buttons inside of other buttons so we absolutely position this */
    position: absolute;
    /* don't forget to take into account any button padding */
    left: var(--spacing-sm);
    bottom: var(--spacing-xs);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: var(--spacing-xs);
  }

  &[data-collapsed="true"] {
    > div[role="button"] {
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0;

      [slot="count"],
      [slot="text"] {
        display: none;
      }

      [slot="title"] {
        justify-self: stretch;
        text-align: center;
      }
    }

    [slot="actions"] {
      display: none;
    }
  }
`;

const useProjectTile = (props: ProjectTileProps) => {
  const {
    collapsed,
    deleteable,
    project,
    index,
    isSelected,
    onSelectProject,
    selectedConversation,
    ...otherProps
  } = props;
  const tooltipTriggerRef = useRef(null);
  const [showDeleteProjectDialog, setShowDeleteProjectDialog] =
    useState<boolean>(false);
  const [showEditProjectDialog, setShowEditProjectDialog] =
    useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const onClick = useCallback(() => {
    onSelectProject(project.id);
  }, [onSelectProject, project]);
  const onDelete = useCallback(() => {
    setShowDeleteProjectDialog(true);
  }, []);
  const onDismiss = useCallback(() => {
    setShowDeleteProjectDialog(false);
    setShowEditProjectDialog(false);
  }, []);
  const onEdit = useCallback(() => {
    setShowEditProjectDialog(true);
  }, []);
  const onMouseEnter = useCallback(() => {
    setShowTooltip(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);
  /** @see https://www.w3.org/WAI/content-assets/wai-aria-practices/patterns/button/examples/js/button.js */
  const onItemKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.code.toLowerCase() === "space") {
        e.preventDefault();
      } else if (e.code.toLowerCase() === "enter") {
        e.preventDefault();
        onSelectProject(project.id);
      }
    },
    [onSelectProject, project],
  );
  const onItemKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.code.toLowerCase() === "space") {
        e.preventDefault();
        onSelectProject(project.id);
      }
    },
    [onSelectProject, project],
  );
  const onTriggerKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.code.toLowerCase() === "space") {
      e.preventDefault();
      e.stopPropagation();
    } else if (e.code.toLowerCase() === "enter") {
      e.preventDefault();
      e.stopPropagation();
      setShowTooltip((prev) => !prev);
    }
  }, []);
  const onTriggerKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.code.toLowerCase() === "space") {
      e.preventDefault();
      e.stopPropagation();
      setShowTooltip((prev) => !prev);
    }
  }, []);

  return {
    collapsed,
    deleteable,
    project,
    index,
    isSelected,
    onClick,
    onDelete,
    onDismiss,
    onEdit,
    onItemKeyDown,
    onItemKeyUp,
    onItemMouseEnter: collapsed ? onMouseEnter : undefined,
    onItemMouseLeave: collapsed ? onMouseLeave : undefined,
    onTriggerKeyDown,
    onTriggerKeyUp,
    onTriggerMouseEnter: collapsed ? undefined : onMouseEnter,
    onTriggerMouseLeave: collapsed ? undefined : onMouseLeave,
    otherProps,
    selectedConversation,
    showDeleteProjectDialog,
    showEditProjectDialog,
    showTooltip,
    tooltipTriggerRef,
  };
};

const ProjectTile: FunctionComponent<ProjectTileProps> = (props) => {
  const {
    collapsed,
    deleteable,
    index,
    isSelected,
    onClick,
    onDelete,
    onDismiss,
    onEdit,
    onItemKeyDown,
    onItemKeyUp,
    onItemMouseEnter,
    onItemMouseLeave,
    onTriggerKeyDown,
    onTriggerKeyUp,
    onTriggerMouseEnter,
    onTriggerMouseLeave,
    otherProps,
    project,
    showDeleteProjectDialog,
    showEditProjectDialog,
    showTooltip,
    tooltipTriggerRef,
  } = useProjectTile(props);
  const elId = `single-prj-${project.id}`;
  const current = isSelected ? `page` : undefined;

  return (
    <ProjectListItem
      ref={collapsed ? tooltipTriggerRef : undefined}
      aria-current={current}
      data-collapsed={collapsed}
      {...otherProps}
    >
      <div
        aria-labelledby={elId}
        data-project={project.id}
        onClick={onClick}
        onKeyDown={onItemKeyDown}
        onKeyUp={onItemKeyUp}
        onMouseEnter={onItemMouseEnter}
        onMouseLeave={onItemMouseLeave}
        role="button"
        tabIndex={0}
      >
        <span slot="title">
          <span
            ref={tooltipTriggerRef}
            onClick={onClick}
            onKeyDownCapture={onTriggerKeyDown}
            onKeyUpCapture={onTriggerKeyUp}
            onMouseEnter={onTriggerMouseEnter}
            onMouseLeave={onTriggerMouseLeave}
            role="button"
            tabIndex={0}
          >
            {collapsed ? String(1 + index) : project.name}
          </span>
        </span>
        <span slot="text">
          <span>Open</span>
          <Icon family="untitled" name="folder" />
        </span>
        <div slot="count">{`Conversations: ${project.conversation_count}`}</div>
      </div>
      <div slot="actions">
        <IconButton
          aria-label="Edit project"
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.sm}
          icon="edit-02"
          onPress={onEdit}
          type="button"
        />
        <IconButton
          aria-label={
            deleteable ? "Delete project" : "Can't delete the only project"
          }
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.sm}
          disabled={!deleteable}
          icon="trash-01"
          onPress={onDelete}
          type="button"
        />
      </div>

      {showTooltip && (
        <TooltipBody contents={project.name} triggerRef={tooltipTriggerRef} />
      )}

      <EditProjectDialog
        isOpen={showEditProjectDialog}
        onClose={onDismiss}
        projectId={project.id}
      />

      <DeleteProjectDialog
        isOpen={showDeleteProjectDialog}
        onClose={onDismiss}
        project={project}
      />
    </ProjectListItem>
  );
};

export default ProjectTile;
