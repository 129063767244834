import { type RiskCategoryMapDTO } from "./dto";

/**  Record<backend platform name, human-readable platform name> */
export const platforms = {
  blog: "Blog",
  board: "Board",
  eightkun: "8kun",
  fourchan: "4chan",
  gab: "Gab",
  gettr: "Gettr",
  kiwifarms: "Kiwifarms",
  mewe: "MeWe",
  minds: "Minds",
  news: "News",
  parler: "Parler",
  poal: "Poal",
  reddit: "Reddit",
  telegram: "Telegram",
  truthsocial: "Truth Social",
  twitter: "Twitter",
  wimkin: "Wimkin",
  facebook: "Facebook",
} as const;

export type Platform = keyof typeof platforms;

export const readable_platform_to_backend_platform = Object.fromEntries(
  new Map(Object.entries(platforms).map(([k, v]) => [v, k])),
) as Record<string, Platform>;

export const platformGroups: Record<string, Platform[]> = {
  niche: [
    "fourchan",
    "eightkun",
    "gab",
    "gettr",
    "kiwifarms",
    "mewe",
    "minds",
    "parler",
    "poal",
    "telegram",
    "truthsocial",
    "wimkin",
  ],
  mainstream: ["reddit", "twitter", "facebook"],
  onlineDiscussion: ["news", "board", "blog"],
};

export const platform_to_postClass_mapping: Record<Platform, DataSource> = {
  blog: "BlogPost",
  board: "MessageBoardPost",
  eightkun: "EightKunPost",
  fourchan: "FourChanPost",
  gab: "GabPost",
  gettr: "GettrPost",
  kiwifarms: "KiwiFarmsPost",
  mewe: "MeWePost",
  minds: "MindsPost",
  news: "NewsPost",
  parler: "ParlerPost",
  poal: "PoalPost",
  reddit: "RedditPost",
  telegram: "TelegramPost",
  truthsocial: "TruthSocialPost",
  twitter: "TwitterPost",
  wimkin: "WimkinPost",
  facebook: "FacebookPost",
};

/** use this to select platform filters on a conversation */
export const platformFilters = Object.fromEntries(
  new Map(
    Object.entries(platform_to_postClass_mapping).map(([k, v]) => [v, k]),
  ),
) as Record<string, keyof typeof platform_to_postClass_mapping>;

export const postClass_to_platform_mapping = Object.fromEntries(
  new Map(
    Object.entries(platform_to_postClass_mapping).map(([k, v]) => [v, k]),
  ),
) as Record<string, keyof typeof platform_to_postClass_mapping>;

/** Record<record type, human-readable record type> */
export const dataSources = {
  BlogPost: "Blog post",
  EightKunPost: "8kun",
  FourChanPost: "4chan",
  GabPost: "Gab",
  GettrPost: "Gettr",
  KiwiFarmsPost: "Kiwi Farms",
  MessageBoardPost: "Message board post",
  MeWePost: "MeWe",
  MindsPost: "Minds",
  NewsPost: "News post",
  ParlerPost: "Parler",
  PoalPost: "Poal",
  RedditPost: "Reddit",
  TelegramPost: "Telegram",
  TruthSocialPost: "Truth Social",
  Tweet: "Twitter",
  TwitterPost: "Twitter",
  WimkinPost: "Wimkin",
  FacebookPost: "Facebook",
  BlogUser: "Blog User",
  EightKunUser: "8kun User",
  FourChanUser: "4chan User",
  GabUser: "Gab User",
  GettrUser: "Gettr User",
  KiwiFarmsUser: "KiwiFarms User",
  MessageBoardUser: "Message Board User",
  MeWeUser: "MeWe User",
  MindsUser: "Minds User",
  NewsUser: "News User",
  ParlerUser: "Parler User",
  PoalUser: "Poal User",
  RedditUser: "Reddit User",
  TelegramUser: "Telegram User",
  TruthSocialUser: "Truth Social User",
  TwitterUser: "Twitter User",
  WimkinUser: "Wimkin User",
  FacebookUser: "Facebook User",
  BlogAccount: "Blog Account",
  EightKunAccount: "8kun Account",
  FourChanAccount: "4chan Account",
  GabAccount: "Gab Account",
  GettrAccount: "Gettr Account",
  KiwiFarmsAccount: "KiwiFarms Account",
  MessageBoardAccount: "Message Board Account",
  MeWeAccount: "MeWe Account",
  MindsAccount: "Minds Account",
  NewsAccount: "News Account",
  ParlerAccount: "Parler Account",
  PoalAccount: "Poal Account",
  RedditAccount: "Reddit Account",
  TelegramAccount: "Telegram Account",
  TruthSocialAccount: "Truth Social Account",
  TwitterAccount: "Twitter Account",
  WimkinAccount: "Wimkin Account",
  FacebookAccount: "Facebook Account",
} as const;

export const platformForUser: Partial<
  Record<keyof typeof dataSources, keyof typeof platforms>
> = {
  BlogUser: "blog",
  EightKunUser: "eightkun",
  FourChanUser: "fourchan",
  GabUser: "gab",
  GettrUser: "gettr",
  KiwiFarmsUser: "kiwifarms",
  MessageBoardUser: "board",
  MeWeUser: "mewe",
  MindsUser: "minds",
  NewsUser: "news",
  ParlerUser: "parler",
  PoalUser: "poal",
  RedditUser: "reddit",
  TelegramUser: "telegram",
  TruthSocialUser: "truthsocial",
  TwitterUser: "twitter",
  WimkinUser: "wimkin",
  FacebookUser: "facebook",
} as const;

export const userForPlatform = Object.fromEntries(
  Object.entries(platformForUser).map(([user, platform]) => [platform, user]),
) as Record<keyof typeof platforms, keyof typeof dataSources>;

export type DataSource = keyof typeof dataSources;
export type DataSourceName = (typeof dataSources)[DataSource];
export const isDataSource = (value: unknown): value is DataSource => {
  if (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "symbol"
  ) {
    return value in dataSources;
  }
  return false;
};

export const dataSourceImages: Record<DataSource, string> = {
  BlogPost: "/assets/images/sources/blog.png",
  BlogUser: "/assets/images/sources/blog.png",
  BlogAccount: "/assets/images/sources/blog.png",
  EightKunPost: "/assets/images/sources/8kun.svg",
  EightKunUser: "/assets/images/sources/8kun.svg",
  EightKunAccount: "/assets/images/sources/8kun.svg",
  FacebookPost: "/assets/images/sources/facebook.png",
  FacebookUser: "/assets/images/sources/facebook.png",
  FacebookAccount: "/assets/images/sources/facebook.png",
  FourChanPost: "/assets/images/sources/4chan.svg",
  FourChanUser: "/assets/images/sources/4chan.svg",
  FourChanAccount: "/assets/images/sources/4chan.svg",
  GabPost: "/assets/images/sources/gab.svg",
  GabUser: "/assets/images/sources/gab.svg",
  GabAccount: "/assets/images/sources/gab.svg",
  GettrPost: "/assets/images/sources/gettr.svg",
  GettrUser: "/assets/images/sources/gettr.svg",
  GettrAccount: "/assets/images/sources/gettr.svg",
  KiwiFarmsPost: "/assets/images/sources/kiwifarms.png",
  KiwiFarmsUser: "/assets/images/sources/kiwifarms.png",
  KiwiFarmsAccount: "/assets/images/sources/kiwifarms.png",
  MessageBoardPost: "/assets/images/sources/messageboard.png",
  MessageBoardUser: "/assets/images/sources/messageboard.png",
  MessageBoardAccount: "/assets/images/sources/messageboard.png",
  MindsPost: "/assets/images/sources/minds.png",
  MindsUser: "/assets/images/sources/minds.png",
  MindsAccount: "/assets/images/sources/minds.png",
  NewsPost: "/assets/images/sources/news.png",
  NewsUser: "/assets/images/sources/news.png",
  NewsAccount: "/assets/images/sources/news.png",
  MeWePost: "/assets/images/sources/mewe.png",
  MeWeUser: "/assets/images/sources/mewe.png",
  MeWeAccount: "/assets/images/sources/mewe.png",
  ParlerPost: "/assets/images/sources/parler.svg",
  ParlerUser: "/assets/images/sources/parler.svg",
  ParlerAccount: "/assets/images/sources/parler.svg",
  PoalPost: "/assets/images/sources/poal.svg",
  PoalUser: "/assets/images/sources/poal.svg",
  PoalAccount: "/assets/images/sources/poal.svg",
  RedditPost: "/assets/images/sources/reddit.svg",
  RedditUser: "/assets/images/sources/reddit.svg",
  RedditAccount: "/assets/images/sources/reddit.svg",
  TelegramPost: "/assets/images/sources/telegram.svg",
  TelegramUser: "/assets/images/sources/telegram.svg",
  TelegramAccount: "/assets/images/sources/telegram.svg",
  TruthSocialPost: "/assets/images/sources/truthsocial.png",
  TruthSocialUser: "/assets/images/sources/truthsocial.png",
  TruthSocialAccount: "/assets/images/sources/truthsocial.png",
  Tweet: "/assets/images/sources/twitter.svg",
  TwitterPost: "/assets/images/sources/twitter.svg",
  TwitterUser: "/assets/images/sources/twitter.svg",
  TwitterAccount: "/assets/images/sources/twitter.svg",
  WimkinPost: "/assets/images/sources/wimkin.png",
  WimkinUser: "/assets/images/sources/wimkin.png",
  WimkinAccount: "/assets/images/sources/wimkin.png",
} as const;

// currently missing messageboard
export const dataSourceFilledImages: Partial<Record<DataSource, string>> = {
  BlogPost: "/assets/images/filled/blog.svg",
  BlogUser: "/assets/images/filled/blog.svg",
  BlogAccount: "/assets/images/filled/blog.svg",
  EightKunPost: "/assets/images/filled/8kun.svg",
  EightKunUser: "/assets/images/filled/8kun.svg",
  EightKunAccount: "/assets/images/filled/8kun.svg",
  FacebookPost: "/assets/images/filled/facebook.svg",
  FacebookUser: "/assets/images/filled/facebook.svg",
  FacebookAccount: "/assets/images/filled/facebook.svg",
  FourChanPost: "/assets/images/filled/4chan.svg",
  FourChanUser: "/assets/images/filled/4chan.svg",
  FourChanAccount: "/assets/images/filled/4chan.svg",
  GabPost: "/assets/images/filled/gab.svg",
  GabUser: "/assets/images/filled/gab.svg",
  GabAccount: "/assets/images/filled/gab.svg",
  GettrPost: "/assets/images/filled/gettr.svg",
  GettrUser: "/assets/images/filled/gettr.svg",
  GettrAccount: "/assets/images/filled/gettr.svg",
  KiwiFarmsPost: "/assets/images/filled/kiwifarms.svg",
  KiwiFarmsUser: "/assets/images/filled/kiwifarms.svg",
  KiwiFarmsAccount: "/assets/images/filled/kiwifarms.svg",
  MindsPost: "/assets/images/filled/minds.svg",
  MindsUser: "/assets/images/filled/minds.svg",
  MindsAccount: "/assets/images/filled/minds.svg",
  NewsPost: "/assets/images/filled/news.svg",
  NewsUser: "/assets/images/filled/news.svg",
  NewsAccount: "/assets/images/filled/news.svg",
  MeWePost: "/assets/images/filled/mewe.svg",
  MeWeUser: "/assets/images/filled/mewe.svg",
  MeWeAccount: "/assets/images/filled/mewe.svg",
  ParlerPost: "/assets/images/filled/parler.svg",
  ParlerUser: "/assets/images/filled/parler.svg",
  ParlerAccount: "/assets/images/filled/parler.svg",
  PoalPost: "/assets/images/filled/poal.svg",
  PoalUser: "/assets/images/filled/poal.svg",
  PoalAccount: "/assets/images/filled/poal.svg",
  RedditPost: "/assets/images/filled/reddit.svg",
  RedditUser: "/assets/images/filled/reddit.svg",
  RedditAccount: "/assets/images/filled/reddit.svg",
  TelegramPost: "/assets/images/filled/telegram.svg",
  TelegramUser: "/assets/images/filled/telegram.svg",
  TelegramAccount: "/assets/images/filled/telegram.svg",
  TruthSocialPost: "/assets/images/filled/truthsocial.svg",
  TruthSocialUser: "/assets/images/filled/truthsocial.svg",
  TruthSocialAccount: "/assets/images/filled/truthsocial.svg",
  Tweet: "/assets/images/filled/twitter.svg",
  TwitterPost: "/assets/images/filled/twitter.svg",
  TwitterUser: "/assets/images/filled/twitter.svg",
  TwitterAccount: "/assets/images/filled/twitter.svg",
  WimkinPost: "/assets/images/filled/wimkin.svg",
  WimkinUser: "/assets/images/filled/wimkin.svg",
  WimkinAccount: "/assets/images/filled/wimkin.svg",
} as const;

/** Record<(lowercase'd ISO language code), (language name in English)> */
export const languageNames = {
  aa: "Afar",
  ab: "Abkhazian",
  ae: "Avestan",
  af: "Afrikaans",
  ak: "Akan",
  am: "Amharic",
  an: "Aragonese",
  ar: "Arabic",
  as: "Assamese",
  av: "Avaric",
  ay: "Aymara",
  az: "Azerbaijani",
  ba: "Bashkir",
  be: "Belarusian",
  bg: "Bulgarian",
  bh: "Bihari languages",
  bi: "Bislama",
  bm: "Bambara",
  bn: "Bengali",
  bo: "Tibetan",
  br: "Breton",
  bs: "Bosnian",
  ca: "Catalan",
  ce: "Chechen",
  ch: "Chamorro",
  co: "Corsican",
  cr: "Cree",
  cs: "Czech",
  cu: "Church Slavic",
  cv: "Chuvash",
  cy: "Welsh",
  da: "Danish",
  de: "German",
  dv: "Maldivian",
  dz: "Dzongkha",
  ee: "Ewe",
  el: "Greek",
  en: "English",
  eo: "Esperanto",
  es: "Spanish",
  et: "Estonian",
  eu: "Basque",
  fa: "Persian",
  ff: "Fulah",
  fi: "Finnish",
  fj: "Fijian",
  fo: "Faroese",
  fr: "French",
  fy: "Western Frisian",
  ga: "Irish",
  gd: "Gaelic",
  gl: "Galician",
  gn: "Guarani",
  gu: "Gujarati",
  gv: "Manx",
  ha: "Hausa",
  he: "Hebrew",
  hi: "Hindi",
  ho: "Hiri Motu",
  hr: "Croatian",
  ht: "Haitian",
  hu: "Hungarian",
  hy: "Armenian",
  hz: "Herero",
  ia: "Interlingua",
  id: "Indonesian",
  ie: "Interlingue",
  ig: "Igbo",
  ii: "Sichuan Yi",
  ik: "Inupiaq",
  io: "Ido",
  is: "Icelandic",
  it: "Italian",
  iu: "Inuktitut",
  ja: "Japanese",
  jv: "Javanese",
  ka: "Georgian",
  kg: "Kongo",
  ki: "Kikuyu",
  kj: "Kuanyama",
  kk: "Kazakh",
  kl: "Kalaallisut",
  km: "Central Khmer",
  kn: "Kannada",
  ko: "Korean",
  kr: "Kanuri",
  ks: "Kashmiri",
  ku: "Kurdish",
  kv: "Komi",
  kw: "Cornish",
  ky: "Kirghiz",
  la: "Latin",
  lb: "Luxembourgish",
  lg: "Ganda",
  li: "Limburgan",
  ln: "Lingala",
  lo: "Lao",
  lt: "Lithuanian",
  lu: "Luba-Katanga",
  lv: "Latvian",
  mg: "Malagasy",
  mh: "Marshallese",
  mi: "Maori",
  mk: "Macedonian",
  ml: "Malayalam",
  mn: "Mongolian",
  mr: "Marathi",
  ms: "Malay",
  mt: "Maltese",
  my: "Burmese",
  na: "Nauru",
  nb: "Norwegian",
  nd: "North Ndebele",
  ne: "Nepali",
  ng: "Ndonga",
  nl: "Dutch",
  nn: "Norwegian",
  no: "Norwegian",
  nr: "South Ndebele",
  nv: "Navajo",
  ny: "Chichewa",
  oc: "Occitan",
  oj: "Ojibwa",
  om: "Oromo",
  or: "Oriya",
  os: "Ossetic",
  pa: "Panjabi",
  pi: "Pali",
  pl: "Polish",
  ps: "Pushto",
  pt: "Portuguese",
  qu: "Quechua",
  rm: "Romansh",
  rn: "Rundi",
  ro: "Romanian",
  ru: "Russian",
  rw: "Kinyarwanda",
  sa: "Sanskrit",
  sc: "Sardinian",
  sd: "Sindhi",
  se: "Northern Sami",
  sg: "Sango",
  si: "Sinhala",
  sk: "Slovak",
  sl: "Slovenian",
  sm: "Samoan",
  sn: "Shona",
  so: "Somali",
  sq: "Albanian",
  sr: "Serbian",
  ss: "Swati",
  st: "Sotho, Southern",
  su: "Sundanese",
  sv: "Swedish",
  sw: "Swahili",
  ta: "Tamil",
  te: "Telugu",
  tg: "Tajik",
  th: "Thai",
  ti: "Tigrinya",
  tk: "Turkmen",
  tl: "Tagalog",
  tn: "Tswana",
  to: "Tonga",
  tr: "Turkish",
  ts: "Tsonga",
  tt: "Tatar",
  tw: "Twi",
  ty: "Tahitian",
  ug: "Uighur",
  uk: "Ukrainian",
  ur: "Urdu",
  uz: "Uzbek",
  ve: "Venda",
  vi: "Vietnamese",
  vo: "Volapük",
  wa: "Walloon",
  wo: "Wolof",
  xh: "Xhosa",
  yi: "Yiddish",
  yo: "Yoruba",
  za: "Zhuang",
  zh: "Chinese",
  zu: "Zulu",
} as const;

export type LanguageCode = keyof typeof languageNames;

/** Record<(lowercase'd ISO country code), (country name)>
 * http://country.io/names.json */
export const countryNames = {
  bd: "Bangladesh",
  be: "Belgium",
  bf: "Burkina Faso",
  bg: "Bulgaria",
  ba: "Bosnia and Herzegovina",
  bb: "Barbados",
  wf: "Wallis and Futuna",
  bl: "Saint Barthelemy",
  bm: "Bermuda",
  bn: "Brunei",
  bo: "Bolivia",
  bh: "Bahrain",
  bi: "Burundi",
  bj: "Benin",
  bt: "Bhutan",
  jm: "Jamaica",
  bv: "Bouvet Island",
  bw: "Botswana",
  ws: "Samoa",
  bq: "Bonaire, Saint Eustatius and Saba",
  br: "Brazil",
  bs: "Bahamas",
  je: "Jersey",
  by: "Belarus",
  bz: "Belize",
  ru: "Russia",
  rw: "Rwanda",
  rs: "Serbia",
  tl: "East Timor",
  re: "Reunion",
  tm: "Turkmenistan",
  tj: "Tajikistan",
  ro: "Romania",
  tk: "Tokelau",
  gw: "Guinea-Bissau",
  gu: "Guam",
  gt: "Guatemala",
  gs: "South Georgia and the South Sandwich Islands",
  gr: "Greece",
  gq: "Equatorial Guinea",
  gp: "Guadeloupe",
  jp: "Japan",
  gy: "Guyana",
  gg: "Guernsey",
  gf: "French Guiana",
  ge: "Georgia",
  gd: "Grenada",
  gb: "United Kingdom",
  ga: "Gabon",
  sv: "El Salvador",
  gn: "Guinea",
  gm: "Gambia",
  gl: "Greenland",
  gi: "Gibraltar",
  gh: "Ghana",
  om: "Oman",
  tn: "Tunisia",
  jo: "Jordan",
  hr: "Croatia",
  ht: "Haiti",
  hu: "Hungary",
  hk: "Hong Kong",
  hn: "Honduras",
  hm: "Heard Island and McDonald Islands",
  ve: "Venezuela",
  pr: "Puerto Rico",
  ps: "Palestinian Territory",
  pw: "Palau",
  pt: "Portugal",
  sj: "Svalbard and Jan Mayen",
  py: "Paraguay",
  iq: "Iraq",
  pa: "Panama",
  pf: "French Polynesia",
  pg: "Papua New Guinea",
  pe: "Peru",
  pk: "Pakistan",
  ph: "Philippines",
  pn: "Pitcairn",
  pl: "Poland",
  pm: "Saint Pierre and Miquelon",
  zm: "Zambia",
  eh: "Western Sahara",
  ee: "Estonia",
  eg: "Egypt",
  za: "South Africa",
  ec: "Ecuador",
  it: "Italy",
  vn: "Vietnam",
  sb: "Solomon Islands",
  et: "Ethiopia",
  so: "Somalia",
  zw: "Zimbabwe",
  sa: "Saudi Arabia",
  es: "Spain",
  er: "Eritrea",
  me: "Montenegro",
  md: "Moldova",
  mg: "Madagascar",
  mf: "Saint Martin",
  ma: "Morocco",
  mc: "Monaco",
  uz: "Uzbekistan",
  mm: "Myanmar",
  ml: "Mali",
  mo: "Macao",
  mn: "Mongolia",
  mh: "Marshall Islands",
  mk: "Macedonia",
  mu: "Mauritius",
  mt: "Malta",
  mw: "Malawi",
  mv: "Maldives",
  mq: "Martinique",
  mp: "Northern Mariana Islands",
  ms: "Montserrat",
  mr: "Mauritania",
  im: "Isle of Man",
  ug: "Uganda",
  tz: "Tanzania",
  my: "Malaysia",
  mx: "Mexico",
  il: "Israel",
  fr: "France",
  io: "British Indian Ocean Territory",
  sh: "Saint Helena",
  fi: "Finland",
  fj: "Fiji",
  fk: "Falkland Islands",
  fm: "Micronesia",
  fo: "Faroe Islands",
  ni: "Nicaragua",
  nl: "Netherlands",
  no: "Norway",
  na: "Namibia",
  vu: "Vanuatu",
  nc: "New Caledonia",
  ne: "Niger",
  nf: "Norfolk Island",
  ng: "Nigeria",
  nz: "New Zealand",
  np: "Nepal",
  nr: "Nauru",
  nu: "Niue",
  ck: "Cook Islands",
  xk: "Kosovo",
  ci: "Ivory Coast",
  ch: "Switzerland",
  co: "Colombia",
  cn: "China",
  cm: "Cameroon",
  cl: "Chile",
  cc: "Cocos Islands",
  ca: "Canada",
  cg: "Republic of the Congo",
  cf: "Central African Republic",
  cd: "Democratic Republic of the Congo",
  cz: "Czech Republic",
  cy: "Cyprus",
  cx: "Christmas Island",
  cr: "Costa Rica",
  cw: "Curacao",
  cv: "Cape Verde",
  cu: "Cuba",
  sz: "Swaziland",
  sy: "Syria",
  sx: "Sint Maarten",
  kg: "Kyrgyzstan",
  ke: "Kenya",
  ss: "South Sudan",
  sr: "Suriname",
  ki: "Kiribati",
  kh: "Cambodia",
  kn: "Saint Kitts and Nevis",
  km: "Comoros",
  st: "Sao Tome and Principe",
  sk: "Slovakia",
  kr: "South Korea",
  si: "Slovenia",
  kp: "North Korea",
  kw: "Kuwait",
  sn: "Senegal",
  sm: "San Marino",
  sl: "Sierra Leone",
  sc: "Seychelles",
  kz: "Kazakhstan",
  ky: "Cayman Islands",
  sg: "Singapore",
  se: "Sweden",
  sd: "Sudan",
  do: "Dominican Republic",
  dm: "Dominica",
  dj: "Djibouti",
  dk: "Denmark",
  vg: "British Virgin Islands",
  de: "Germany",
  ye: "Yemen",
  dz: "Algeria",
  us: "United States",
  uy: "Uruguay",
  yt: "Mayotte",
  um: "United States Minor Outlying Islands",
  lb: "Lebanon",
  lc: "Saint Lucia",
  la: "Laos",
  tv: "Tuvalu",
  tw: "Taiwan",
  tt: "Trinidad and Tobago",
  tr: "Turkey",
  lk: "Sri Lanka",
  li: "Liechtenstein",
  lv: "Latvia",
  to: "Tonga",
  lt: "Lithuania",
  lu: "Luxembourg",
  lr: "Liberia",
  ls: "Lesotho",
  th: "Thailand",
  tf: "French Southern Territories",
  tg: "Togo",
  td: "Chad",
  tc: "Turks and Caicos Islands",
  ly: "Libya",
  va: "Vatican",
  vc: "Saint Vincent and the Grenadines",
  ae: "United Arab Emirates",
  ad: "Andorra",
  ag: "Antigua and Barbuda",
  af: "Afghanistan",
  ai: "Anguilla",
  vi: "U.S. Virgin Islands",
  is: "Iceland",
  ir: "Iran",
  am: "Armenia",
  al: "Albania",
  ao: "Angola",
  aq: "Antarctica",
  as: "American Samoa",
  ar: "Argentina",
  au: "Australia",
  at: "Austria",
  aw: "Aruba",
  in: "India",
  ax: "Aland Islands",
  az: "Azerbaijan",
  ie: "Ireland",
  id: "Indonesia",
  ua: "Ukraine",
  qa: "Qatar",
  mz: "Mozambique",
} as const;

export enum runType {
  STATIC_RUN = "STATIC_RUN",
  STATIC_MANUAL_RERUN = "STATIC_MANUAL_RERUN",
  SCHEDULED_INITIAL_RUN = "SCHEDULED_INITIAL_RUN",
  SCHEDULED_RUN = "SCHEDULED_RUN",
  SCHEDULED_MANUAL_RERUN = "SCHEDULED_MANUAL_RERUN",
}
export const conversationRunType: Record<keyof typeof runType, string> = {
  [runType.STATIC_RUN]: "Static Run",
  [runType.STATIC_MANUAL_RERUN]: "Static Manual Rerun",
  [runType.SCHEDULED_INITIAL_RUN]: "Scheduled Initial Run",
  [runType.SCHEDULED_RUN]: "Scheduled Run",
  [runType.SCHEDULED_MANUAL_RERUN]: "Scheduled Manual Rerun",
} as const;

export type CountryCode = keyof typeof countryNames;

export type TopicInsightCategories = RiskCategoryMapDTO["Categories"];
export type TopicInsightCategory = TopicInsightCategories[number];
export type RiskCategory = Exclude<keyof RiskCategoryMapDTO, "Categories">;
