import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { LoadingIndicator } from "~/components";
import { UserExternalLinkComponent } from "~/components/ConnectionPreview";
import Section from "~/components/Section";
import Icon from "~/components/library/Icon";
import { type CoordinationEdgeProfileDTO } from "~/dto";
import { useConversationFilters } from "~/hooks/useConversationFilters";
import { useEdgeProfile } from "~/hooks/useCoordination";
import type { RegraphObject } from "~/pages/Graph/types";
import { text } from "~/styles/typography";

import { EVIDENCES, type Evidence } from "../constants";
import { type EdgeCoordination } from "../types";

import { Evidences } from "./Evidences";
import { MatchIndicator, NoDataContainer, PopUpBase } from "./SharedComponents";

const IMG_SIZE = 50;

const EdgeUsersContainer = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const UsersInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    gap: var(--gutter-25);
    flex: 1;
    justify-content: center;

    span {
      ${text.xl.regular};
    }
  }

  > button {
    flex: 2;
    ${text.xs.regular};
    overflow: hidden;
    text-align: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  > button:nth-child(3) {
    text-align: right;
  }
`;

const AvatarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: ${IMG_SIZE}px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  > img {
    display: block;
    border-radius: var(--border-radius-full);
    width: ${IMG_SIZE}px;
  }

  [data-icon] {
    width: ${IMG_SIZE}px;
    height: ${IMG_SIZE}px;
  }
`;

const LineContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  padding-top: ${IMG_SIZE / 2}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VerticalLine = styled.div`
  border-right: 1px solid var(--foreground-color-quaternary);
  height: ${IMG_SIZE / 2}px;
`;

const HorizontalLine = styled.div`
  border-top: 1px solid var(--foreground-color-quaternary);
  width: 90%;
`;

const CircleDiv = styled.div`
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-full);
  background-color: var(--foreground-color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Letter = styled.span`
  ${text.xs.medium};
  color: var(--color-white);
`;

const CircleLetter: FunctionComponent<{ text: string }> = ({ text }) => {
  return (
    <CircleDiv>
      <Letter>{text}</Letter>
    </CircleDiv>
  );
};

interface CoordinationUsersProps {
  data: CoordinationEdgeProfileDTO;
  evidenceMatches: Record<Evidence, boolean>;
  clusterId: string;
  conversationId: string;
  regraphObject?: RegraphObject;
}

const EdgeAccounts: FunctionComponent<CoordinationUsersProps> = (props) => {
  const { data, evidenceMatches, clusterId, conversationId, regraphObject } =
    props;

  const { i: userA, j: userB } = data;

  const [imgAFailedToLoad, setImgAFailedToLoad] = useState<boolean>(false);
  const [imgBFailedToLoad, setImgBFailedToLoad] = useState<boolean>(false);

  const matchedEvidencesCount = EVIDENCES.filter((evidence) =>
    Object.keys(evidenceMatches).includes(evidence),
  ).length;

  useEffect(() => {
    setImgAFailedToLoad(!userA.avatar_url);
  }, [userA.avatar_url]);

  useEffect(() => {
    setImgBFailedToLoad(!userB.avatar_url);
  }, [userB.avatar_url]);

  const imageAError = useCallback(() => {
    setImgAFailedToLoad(true);
  }, []);

  const imageBError = useCallback(() => {
    setImgBFailedToLoad(true);
  }, []);

  return (
    <EdgeUsersContainer>
      <AvatarContainer>
        <ImageContainer>
          {!imgAFailedToLoad ? (
            <img
              alt="A Avatar"
              aria-hidden="true"
              onError={imageAError}
              src={userA.avatar_url}
            />
          ) : (
            <Icon
              aria-label="User A default image"
              family="untitled"
              name="user-01"
            />
          )}
          <CircleLetter text="A" />
        </ImageContainer>
        <LineContainer aria-hidden="true">
          <HorizontalLine />
          <VerticalLine />
        </LineContainer>
        <ImageContainer>
          <CircleLetter text="B" />
          {!imgBFailedToLoad ? (
            <img
              alt="B Avatar"
              aria-hidden="true"
              onError={imageBError}
              src={userB.avatar_url}
            />
          ) : (
            <Icon
              aria-label="User B default image"
              family="untitled"
              name="user-01"
            />
          )}
        </ImageContainer>
      </AvatarContainer>
      <UsersInfo aria-hidden="true">
        <UserExternalLinkComponent
          clusterId={clusterId}
          conversationId={conversationId}
          regraphObject={regraphObject}
          screen_name={userA.screen_name}
          url={userA.profile_url}
          user_rid={userA.rid}
        />
        <div>
          <span>{matchedEvidencesCount}</span>
          <MatchIndicator data-match="true" />
        </div>
        <UserExternalLinkComponent
          clusterId={clusterId}
          conversationId={conversationId}
          regraphObject={regraphObject}
          screen_name={userB.screen_name}
          url={userB.profile_url}
          user_rid={userB.rid}
        />
      </UsersInfo>
      <dl data-at-only>
        <dt>user A</dt>
        <dd>{userA.screen_name}</dd>
        <dt>user B</dt>
        <dd>{userB.screen_name}</dd>
        <dt>evidence count</dt>
        <dd>{matchedEvidencesCount}</dd>
      </dl>
    </EdgeUsersContainer>
  );
};

export const EdgePopUp: FunctionComponent<EdgeCoordination> = (props) => {
  const { conversationId, sourceUserRid, targetUserRid, clusterId, onDismiss } =
    props;
  const filters = useConversationFilters();
  const { regraphObject } = props;

  const { data: edgeData, isLoading } = useEdgeProfile(
    clusterId,
    conversationId,
    sourceUserRid,
    targetUserRid,
    filters,
  );

  const evidenceMatches = useMemo(() => {
    const matches = edgeData?.data?.coordinations.reduce(
      (map, evidence) => {
        map[evidence as Evidence] = EVIDENCES.includes(evidence as Evidence);
        return map;
      },
      {} as Record<Evidence, boolean>,
    );

    return (
      matches ??
      EVIDENCES.reduce(
        (map, evidence) => ({ ...map, [evidence]: false }),
        {} as Record<Evidence, boolean>,
      )
    );
  }, [edgeData?.data?.coordinations]);

  return (
    <PopUpBase heading="Coordination" onDismiss={onDismiss}>
      {isLoading ? (
        <LoadingIndicator />
      ) : edgeData?.data ? (
        <>
          <EdgeAccounts
            clusterId={clusterId}
            conversationId={conversationId}
            data={edgeData.data}
            evidenceMatches={evidenceMatches}
            regraphObject={regraphObject}
          />
          <Evidences evidenceMatches={evidenceMatches} />
        </>
      ) : (
        <NoDataContainer>No Data</NoDataContainer>
      )}
    </PopUpBase>
  );
};
