import { styled } from "@linaria/react";
import type { FunctionComponent } from "react";

import RegularField from "~/components/Field";
import { type TopicDTO } from "~/dto/topic";

import BaseProfile from "./BaseProfile";

const StyledBaseProfile = styled(BaseProfile)`
  #profile-content {
    padding: var(--spacing-lg);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    background-color: var(--background-color-primary);

    dl {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-lg);
    }

    button {
      align-self: start;
    }
  }
`;

export interface NarrativeProfileProps {
  conversationId: string;
  type: "narrative";
  onDismiss?: () => void;
  onShowPosts: () => void;
  ngrams: TopicDTO["ngrams"];
  title: TopicDTO["title"];
}

export const NarrativeProfile: FunctionComponent<NarrativeProfileProps> = (
  props,
) => {
  const { ngrams, onDismiss, title } = props;

  return (
    <StyledBaseProfile
      heading="Narrative"
      iconName="message-chat-circle"
      onDismiss={onDismiss}
    >
      <dl>
        <RegularField name="Cluster Title" value={title ?? ngrams[0]} />
        <RegularField
          name="Cluster Keywords and Entities"
          value={title ? ngrams.join(" / ") : ngrams.slice(1).join(" / ")}
        />
      </dl>
      {/* TODO: temporary remove until lazy loading is applied */}
      {/* <Button
        data-kind={ButtonKind.Secondary}
        data-size={ButtonSize.md}
        onPress={onShowPosts}
        type="button"
      >
        Show Posts
      </Button> */}
    </StyledBaseProfile>
  );
};
