import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useConversations } from "~/hooks/useConversation";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { useLocalStorage } from "~/hooks/useLocalStorage";
import { useProjects } from "~/hooks/useProjects";

type ProjectUrlParams = {
  conversationId?: string;
  projectName?: string;
};

export enum PickerView {
  Projects,
  Conversations,
}

const useProjectPicker = () => {
  const { conversationId, projectName } = useParams<ProjectUrlParams>();
  const { organizationId: currentOrgId } = useCurrentUser();
  const { data: projects } = useProjects();
  const [shouldPoll, setShouldPoll] = useState<boolean>(false);
  const [view, setView] = useState<PickerView>(
    projectName ? PickerView.Conversations : PickerView.Projects,
  );
  const [conversationSearchTerm, setConversationSearchTerm] =
    useState<string>("");
  const [projectSearchTerm, setProjectSearchTerm] = useState<string>("");
  /** name of the project whose conversations are rendered in the picker.
   * stored as a name and not an id so we can easily sync with the url.  may be
   * different from the name of the project currently in the url, due to the
   * user browsing the contents of different projects */
  const [selectedProjectName, setSelectedProjectName] = useState<
    string | undefined
  >(projectName);
  const selectedProject = projects?.find((p) => p.name === selectedProjectName);
  const { data: conversations } = useConversations(
    selectedProject?.id,
    shouldPoll,
  );
  const selectedConversation = conversations?.find(
    (c) => c.id === conversationId,
  );
  const [collapsed, setCollapsed] =
    useLocalStorage<boolean>("picker:collapsed");
  const [showAddProjectDialog, setShowAddProjectDialog] =
    useState<boolean>(false);
  const [showAddConversationDialog, setShowAddConversationDialog] =
    useState<boolean>(false);
  const visibleProjects = !!projectSearchTerm
    ? projects.filter((el) =>
        el.name.toLowerCase().includes(projectSearchTerm.toLowerCase()),
      )
    : projects;
  const visibleConversations = conversations
    ?.toSorted(
      (c1, c2) =>
        new Date(c1.created_at).getTime() - new Date(c2.created_at).getTime(),
    )
    .filter(
      (el) =>
        !conversationSearchTerm ||
        el.name.toLowerCase().includes(conversationSearchTerm.toLowerCase()),
    );
  const onSelectProject = useCallback(
    (projectId: string) => {
      setSelectedProjectName(projects.find((p) => p.id === projectId)?.name);
      setView(PickerView.Conversations);
      setConversationSearchTerm("");
    },
    [projects],
  );
  const onBack = useCallback(() => {
    setView(PickerView.Projects);
    setSelectedProjectName(projectName);
    setConversationSearchTerm("");
    setProjectSearchTerm("");
  }, [projectName]);
  const toggleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);
  const onAddProject = useCallback(() => {
    setShowAddProjectDialog(true);
  }, []);
  const onAddConversation = useCallback(() => {
    setShowAddConversationDialog(true);
  }, []);
  const onCloseDialog = useCallback(() => {
    setShowAddConversationDialog(false);
    setShowAddProjectDialog(false);
  }, []);
  const onSearch = useCallback(
    (value: string) => {
      if (view === PickerView.Projects) {
        setProjectSearchTerm(value);
      } else {
        setConversationSearchTerm(value);
      }
    },
    [view],
  );

  useEffect(() => {
    setShouldPoll(
      !!conversations?.some(
        (c) =>
          c.status === "PENDING" || c.status === "RUNNING" || c.threat_feed,
      ),
    );
  }, [conversations]);

  useEffect(() => {
    setSelectedProjectName(projectName);

    if (!projectName) {
      setView(PickerView.Projects);
    }
  }, [projectName, projects]);

  useEffect(() => {
    /* reset picker upon org change */
    return function cleanup() {
      setSelectedProjectName(undefined);
      setConversationSearchTerm("");
      setProjectSearchTerm("");
      setView(PickerView.Projects);
    };
  }, [currentOrgId]);

  return {
    collapsed,
    conversations,
    onAddConversation,
    onAddProject,
    onBack,
    onCloseDialog,
    onSearch,
    onSelectProject,
    projects: visibleProjects,
    searchTerm:
      view === PickerView.Conversations
        ? conversationSearchTerm
        : projectSearchTerm,
    selectedConversation,
    selectedProject,
    selectedView: view,
    showAddConversationDialog,
    showAddProjectDialog,
    toggleCollapse,
    visibleConversations,
  };
};

export { useProjectPicker };
