import { styled } from "@linaria/react";
import { type ComponentPropsWithoutRef, type FunctionComponent } from "react";

import untitledIcon from "~/images/icons.svg";

/* https://fonts.google.com/icons?icon.set=Material+Icons&selected=Material+Icons:add:&icon.style=Filled */
/** @deprecated */
type IconName = {
  "data-name": string;
  "data-symbol"?: never;
};

/* https://fonts.google.com/icons?icon.set=Material+Symbols */
/** @deprecated */
type SymbolName = {
  "data-symbol": string;
  "data-name"?: never;
};

type IconPropsV2 = {
  family?:
    | "material-symbols-rounded" /* https://fonts.google.com/icons?icon.style=Rounded */
    | "material-symbols-outlined" /* https://fonts.google.com/icons?icon.style=Outlined */
    | "material-icons" /* https://fonts.google.com/icons?icon.style=Rounded&icon.set=Material+Icons */;
  name: string;
};

type SpanIconProps = ComponentPropsWithoutRef<"span"> &
  (IconName | SymbolName | IconPropsV2);

type IconPropsUntitled = ComponentPropsWithoutRef<"svg"> & {
  family?: "untitled";
  name: UntitledIcon;
};

type IconProps =
  | SpanIconProps
  | (IconPropsUntitled & {
      title?: string;
    });

const IconSpan = styled.span`
  user-select: none;
`;

const IconSvg = styled.svg`
  width: 1em;
  height: 1em;
`;

function isIcon(props: unknown): props is IconName {
  return props !== null && typeof props === "object" && "data-name" in props;
}

function isSymbol(props: unknown): props is SymbolName {
  return props !== null && typeof props === "object" && "data-symbol" in props;
}

function isIconV2(props: unknown): props is IconPropsV2 {
  return (
    props !== null &&
    typeof props === "object" &&
    "name" in props &&
    (!("family" in props) || props.family !== "untitled")
  );
}

function isUntitledIcon(props: unknown): props is IconPropsUntitled {
  return (
    props !== null &&
    typeof props === "object" &&
    "name" in props &&
    "family" in props &&
    props.family === "untitled"
  );
}

const Icon: FunctionComponent<IconProps> = (props) => {
  if (isUntitledIcon(props)) {
    const { name, family, strokeWidth, style, title, ...otherProps } = props;

    return (
      <IconSvg
        aria-hidden="true"
        data-icon
        {...otherProps}
        style={
          strokeWidth == null
            ? style
            : { ...style, "--ag-icon-stroke-width": strokeWidth }
        }
      >
        {title ? <title>{title}</title> : null}
        <use href={`${untitledIcon}#untitled-icon-${name}`} />
      </IconSvg>
    );
  }

  const { className = undefined, ...otherProps } = props;
  const materialName = isIcon(props)
    ? props["data-name"]
    : isSymbol(props)
    ? props["data-symbol"]
    : isIconV2(props)
    ? props.name
    : undefined;
  const materialClass = isIcon(props)
    ? "material-icons"
    : isSymbol(props)
    ? "material-symbols-outlined"
    : "material-symbols-rounded";

  return (
    <IconSpan
      aria-hidden="true"
      className={`${materialClass} ${className ?? ""}`}
      data-icon
      {...otherProps}
    >
      {materialName}
    </IconSpan>
  );
};

export default Icon;
