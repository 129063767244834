import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  type ReactNode,
  type StyledComponent,
} from "react";

import { text } from "~/styles/typography";

type ErrorMessageContainerProps = StyledComponent & {
  children: ReactNode;
};

const Container = styled.div`
  ${text.xs.regular};
  line-height: 16px;
  color: var(--color-error-500);
`;

const ErrorMessageContainer: FunctionComponent<ErrorMessageContainerProps> = (
  props,
) => {
  const { children, ...otherProps } = props;

  return (
    <Container aria-live="polite" {...otherProps}>
      &#8203;
      {children}
    </Container>
  );
};

export default ErrorMessageContainer;
