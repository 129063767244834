import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import Card from "../Card";
import ModalDialog, {
  DialogBottomContainer,
  DialogContentsContainer,
  DialogHeadingContainer,
  DialogTitle,
  XButton,
} from "../ModalDialog";
import Button, { ButtonKind, ButtonSize } from "../library/Button";

import AddEditProjectForm from "./AddEditProjectForm";

interface AddEditProjectDialogProps {
  isOpen: boolean;
  onClose: () => void;
  projectId?: string;
}

const PaddedDialogContents = styled(DialogContentsContainer)`
  margin: var(--spacing-4xl) var(--spacing-xs) var(--spacing-lg);
`;

const StyledCard = styled(Card)`
  width: 560px;
`;

const AddEditProjectDialog: FunctionComponent<AddEditProjectDialogProps> = (
  props,
) => {
  const { isOpen, onClose, projectId } = props;
  const title = projectId ? "Edit Project" : "Add Project";
  const ctaText = projectId ? "Save Changes" : "Add";

  return (
    <ModalDialog isOpen={isOpen}>
      <StyledCard>
        <DialogHeadingContainer>
          <DialogTitle>{title}</DialogTitle>
          <XButton onPress={onClose} />
        </DialogHeadingContainer>
        <PaddedDialogContents>
          <AddEditProjectForm
            id="add-edit-project-form"
            onDismiss={onClose}
            projectId={projectId}
          />
        </PaddedDialogContents>
        <DialogBottomContainer>
          <Button
            data-kind={ButtonKind.Tertiary}
            data-size={ButtonSize.md}
            onPress={onClose}
            type="button"
          >
            Cancel
          </Button>
          <Button
            data-kind={ButtonKind.Primary}
            data-size={ButtonSize.md}
            form="add-edit-project-form"
            type="submit"
          >
            {ctaText}
          </Button>
        </DialogBottomContainer>
      </StyledCard>
    </ModalDialog>
  );
};

export default AddEditProjectDialog;
