import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  type LinkClickEvent,
  type StyledComponent,
  useCallback,
} from "react";

import { useCurrentUser } from "~/hooks/useCurrentUser";
import { useLogout } from "~/hooks/useLogout";
import { useTheme } from "~/hooks/useTheme";
import headerLogo from "~/images/alethea-logo.svg";
import { text } from "~/styles/typography";
import { logEvent } from "~/utils/analytics";
import { formatDateAGStandard } from "~/utils/datetime";

import { AlertBanner } from "./AlertBanner";
import UserInitialsCard from "./UserInitialsCard";
import UserOrganizationSwitcher from "./UserOrganizationSwitcher";
import A from "./library/A";
import Icon from "./library/Icon";
import Popover from "./library/Popover";
// import { Option, Select } from "./library/Select";

const StyledHeader = styled.header`
  border-bottom: 1px solid var(--color-green-light-200); /* FIXME: no semantic color available */
  padding: var(--spacing-lg) var(--spacing-xl);
  display: grid;
  grid-gap: var(--gutter-300);
  grid-template-columns: [first] max-content 1fr max-content max-content max-content max-content [last];
  align-items: center;
  background-color: var(--background-color-brand-section);
`;

// const StyledSelect = styled(Select)`
//   && svg {
//     color: var(--text-color-primary_on-brand);
//   }
// `;

const HeaderLink = styled(A)`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  ${text.xl.regular};

  &&,
  &&:hover {
    color: var(--text-color-primary_on-brand);
    text-decoration: none;
  }
`;

const HeaderImage = styled.div`
  width: calc(
    var(--font-size-text-lg) + 8px
  ); /* doesn't look good at intended size */
  height: calc(var(--font-size-text-lg) + 8px);
  mask-image: url(${headerLogo});
  mask-position: none;
  mask-repeat: no-repeat;
  mask-size: 100%;
  background: var(--color-white);
`;

const HeaderOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-3xl);

  > :first-child button {
    border: none;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    box-shadow: none;
  }

  > :last-child {
    width: 30px;
  }
`;

const ProfileMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacing-xl);
  ${text.md.regular};

  &,
  & a {
    color: var(--text-color-primary);
  }
`;

// const modeIcons: Record<string, UntitledIcon> = {
//   light_mode: "sun",
//   dark_mode: "moon-02",
// } as const;

const useHeader = () => {
  const { currentUser, isGlobalAdmin } = useCurrentUser();
  const { themes, themeName: currentTheme, setThemeName } = useTheme();
  const { execute: doLogout } = useLogout();
  const onLogOut = useCallback(
    (e: LinkClickEvent) => {
      e.preventDefault();
      logEvent("user initiated logout");
      doLogout();
    },
    [doLogout],
  );
  const onChangeTheme = useCallback(
    (e: string) => {
      setThemeName(e);
    },
    [setThemeName],
  );

  return {
    currentTheme,
    currentUser,
    isGlobalAdmin: isGlobalAdmin(),
    onChangeTheme,
    onLogOut,
    themes,
  };
};

const Header: FunctionComponent<StyledComponent> = (props) => {
  const {
    // currentTheme,
    currentUser,
    // isGlobalAdmin,
    // onChangeTheme,
    onLogOut,
    // themes,
  } = useHeader();

  return (
    <StyledHeader {...props}>
      <HeaderLink href="/">
        <HeaderImage />
        <span>Artemis</span>
      </HeaderLink>
      <div>
        <AlertBanner
          isOpen={currentUser !== undefined && !currentUser.mfa_enabled}
        >
          Multi-factor authentication is now required on Artemis accounts.
          Please visit your{" "}
          <A href="/myOrganization/settings">user-settings page</A> and enroll
          {currentUser
            ? ` before ${formatDateAGStandard(
                new Date(String(currentUser.mfa_enabled_at)),
              )}`
            : ""}
          .
        </AlertBanner>
      </div>
      <HeaderOptionsContainer>
        {/*isGlobalAdmin && (
          <StyledSelect
            aria-label="theme"
            onSelectionChange={onChangeTheme}
            selectedKey={currentTheme}
          >
            {Object.entries(themes).map(([k, v]) => (
              <Option key={k} id={k} textValue={v}>
                <Icon aria-label={v} family="untitled" name={modeIcons[v]} />
              </Option>
            ))}
          </StyledSelect>
          )*/}
        {currentUser && (
          <>
            <HeaderLink href="/myOrganization/settings" title="App Settings">
              <Icon family="untitled" name="settings-01" />
              <span data-at-only>App Settings</span>
            </HeaderLink>
            <Popover
              aria-label="User Menu"
              triggerText={<UserInitialsCard currentUser={currentUser} />}
            >
              <ProfileMenu>
                <UserOrganizationSwitcher />
                <A href="https://yellowlillies.atlassian.net/servicedesk/customer/portal/6">
                  Submit feedback
                </A>
                <A href="/login" onClick={onLogOut}>
                  Sign out
                </A>
              </ProfileMenu>
            </Popover>
          </>
        )}
      </HeaderOptionsContainer>
    </StyledHeader>
  );
};

export default Header;
