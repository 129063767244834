import {
  type ContextComponent,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSWRConfig } from "swr";

import {
  type LoginResponseDTO,
  type RefreshRequestDTO,
  type UserDTO,
} from "~/dto";
import { LoginResponseValidator } from "~/dto/loginResponse";
import { getCookie } from "~/utils/cookie";

import { useLogger } from "./useLogger";
import { type AuthToken } from "./useRemoteData/utils";

export type UserClaims =
  | {
      organization_id: string;
      roles: string[];
    }
  | null
  | undefined;

type NewSessionParams = {
  exp?: string;
  claims?: UserClaims;
  user?: UserDTO;
};

export type SessionContextShape = {
  authToken?: AuthToken;
  currentUser?: UserDTO;
  onEndSession: () => void;
  onNewSession: (params: NewSessionParams) => void;
  onRefreshSession: () => void;
  userClaims?: UserClaims;
};

const refreshActionFetcherFactory =
  <T = any, U = any>(payload: T, verb: RequestInit["method"]) =>
  async (input: RequestInfo, init?: RequestInit) => {
    const refreshToken = getCookie("csrf_refresh_token");
    if (!refreshToken) {
      throw new Error();
    }

    return window
      .fetch(input, {
        ...(init ?? {}),
        body: JSON.stringify(payload),
        headers: {
          ...(init?.headers ?? {}),
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": String(refreshToken),
        },
        method: verb,
      })
      .then((res) => res.json()) as Promise<U>;
  };

/**
 * essentially a clone of `useRemoteAction`, but copied-and-pasted to avoid
 * cycles in our module import graph (since useRemoteAction needs session info)
 */
const useSessionRefresh = () => {
  const logger = useLogger();
  const [data, setData] = useState<LoginResponseDTO | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [validatedData, setValidatedData] = useState<
    LoginResponseDTO | undefined
  >();
  const execute = useCallback(async (payload: RefreshRequestDTO) => {
    try {
      const response = await refreshActionFetcherFactory(
        payload,
        "POST",
      )("/api/v2/user/refresh");
      setData(response);
      return response;
    } catch (ex) {
      setError(String(ex));

      throw ex;
    }
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    const parseResult = LoginResponseValidator.safeParse(data);

    if (parseResult.success) {
      setValidatedData(parseResult.data);
    } else {
      logger.warn(
        `couldn't parse network payload for action at /api/v2/user/refresh:`,
        parseResult.error,
      );
    }
  }, [data, logger]);

  return {
    data: validatedData,
    error,
    execute,
  };
};

const SessionContext = createContext<SessionContextShape>({
  onEndSession() {},
  onNewSession() {},
  onRefreshSession() {},
});

const SessionProvider: ContextComponent = ({ children }) => {
  const [, setSessionStart] = useState<number>(Date.now());
  const [exp, setExp] = useState<number>();
  const authToken = getCookie("csrf_access_token");
  const { cache } = useSWRConfig();
  const [currentUser, setCurrentUser] = useState<UserDTO>();
  const [userClaims, setUserClaims] = useState<UserClaims>();
  const { execute: doRefreshSession } = useSessionRefresh();
  const onNewSession = useCallback((opts: NewSessionParams) => {
    setCurrentUser(opts.user);
    setUserClaims(opts.claims);

    if (opts.exp) {
      setExp(new Date(opts.exp).getTime());
    }
  }, []);
  const onRefreshSession = useCallback(() => {
    setSessionStart(Date.now());
  }, []);
  const onEndSession = useCallback(() => {
    setCurrentUser(undefined);
    setUserClaims(undefined);
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment
    -- see https://github.com/vercel/swr/issues/1887 */
    // @ts-ignore
    cache.clear();
  }, [cache]);

  /* refresh JWT cookies when they're close to expiring */
  useEffect(() => {
    if (!userClaims || exp === undefined) {
      return;
    }

    const handle = window.setTimeout(
      async () => {
        const result = await doRefreshSession({
          organization_id: userClaims?.organization_id,
        });

        if (result?.exp) {
          setExp(new Date(result.exp).getTime());
        }
      },
      /* 5 minutes before expiration */
      exp - Date.now() - 5 * 60 * 1000,
    );

    return function cleanup() {
      window.clearTimeout(handle);
    };
  }, [doRefreshSession, exp, userClaims]);

  return (
    <SessionContext.Provider
      value={{
        authToken,
        currentUser,
        onEndSession,
        onNewSession,
        onRefreshSession,
        userClaims,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

const useSession = () => useContext(SessionContext);

export { SessionContext, SessionProvider, useSession, useSessionRefresh };
