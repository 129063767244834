import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";
import { OverlayArrow as ReactAriaOverlayArrow } from "react-aria-components";

type OverlayArrowProps = {
  className?: string;
  "data-placement"?: "top" | "bottom";
};

export const OverlayArrowContainer = styled(
  ReactAriaOverlayArrow,
)<OverlayArrowProps>`
  svg {
    width: 17px;
    height: var(--arrow-height);
    position: relative;
  }

  &[data-placement^="top"] svg {
    top: var(--overlay-arrow-offset);
  }

  &[data-placement^="bottom"] svg {
    transform: rotate(180deg);
    bottom: var(--overlay-arrow-offset);
  }
`;

export const OverlayArrow: FunctionComponent<OverlayArrowProps> = (props) => (
  <OverlayArrowContainer {...props}>
    <svg viewBox="0 0 17 9" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5711 0.485289C15.462 0.485289 15.9081 1.56243 15.2782 2.1924L9.20711 8.26347C8.81658 8.654 8.18342 8.654 7.79289 8.26347L1.72183 2.1924C1.09187 1.56243 1.53803 0.485289 2.42894 0.485289L14.5711 0.485289Z" />
    </svg>
  </OverlayArrowContainer>
);
