import {
  type ContextComponent,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";

import { type GroupDTO, type UserDTO } from "~/dto";
import { CurrentUserValidator } from "~/dto/currentUser";
import { logEvent, identifyUser } from "~/utils/analytics";
import type { Feature } from "~/utils/features";
import type { Permissions } from "~/utils/permissions";

import { useLogout } from "./useLogout";
import { useRemoteObject } from "./useRemoteData";
import { useSession, useSessionRefresh } from "./useSession";

const checkRole = (role: string, roleList: string[]) => {
  if (!roleList.length) {
    return false;
  }

  try {
    return roleList.some((userRole) => JSON.parse(userRole)["name"] === role);
  } catch (e) {
    return false;
  }
};

const isOrgAdmin = (userGroups: string[], orgGroups: GroupDTO[]) => {
  const userGroupNames = userGroups?.map(
    (groupID: string) => orgGroups?.find(({ id }) => groupID === id)?.name,
  );
  return userGroupNames.includes("Admin");
};

export type CurrentUserContextShape = {
  currentUser?: Omit<
    UserDTO,
    "is_global_admin" | "is_analyst" | "is_senior_analyst"
  > /* legacy perm checks; should not be used */;
  hasPermission: (permission: Permissions) => boolean;
  hasFeature: (feature: Feature) => boolean;
  isOrgAdmin: (userGroups: string[], orgGroups: GroupDTO[]) => boolean;
  isGlobalAdmin: (userRoles?: string[]) => boolean;
  isSeniorAnalyst: (userRoles?: string[]) => boolean;
  isAnalyst: (userRoles?: string[]) => boolean;
  organizationId?: string;
  switchOrganization: (organization: string) => Promise<void>;
  SSOLogoutURL?: string;
};

const CurrentUserContext = createContext<CurrentUserContextShape>({
  hasPermission: () => false,
  hasFeature: () => false,
  isOrgAdmin: () => false,
  isGlobalAdmin: () => false,
  isSeniorAnalyst: () => false,
  isAnalyst: () => false,
  switchOrganization: () => Promise.reject(),
});

const CurrentUserProvider: ContextComponent = ({ children }) => {
  const {
    authToken,
    currentUser,
    onEndSession,
    onNewSession,
    onRefreshSession,
    userClaims,
  } = useSession();
  const { execute: doRefreshSession } = useSessionRefresh();
  const navigate = useNavigate();
  const { execute: doLogout } = useLogout();
  const { organization_id, roles } = userClaims ?? {};
  const { data, error } = useRemoteObject(
    authToken ? "/api/v2/user/current" : undefined,
    {
      schemaValidator: CurrentUserValidator,
    },
  );
  const hasPermission = useCallback(
    (perm: Permissions) => {
      return new Set<string>(currentUser?.permissions).has(perm);
    },
    [currentUser],
  );
  const hasFeature = useCallback(
    (feature: Feature) => {
      return new Set(data?.features ?? []).has(feature);
    },
    [data?.features],
  );
  const isGlobalAdmin = useCallback(
    (userRoles?: string[]) => {
      return checkRole("Global Admin", userRoles ?? roles ?? []);
    },
    [roles],
  );
  const isSeniorAnalyst = useCallback(
    (userRoles?: string[]) => {
      return checkRole("Senior Analyst", userRoles ?? roles ?? []);
    },
    [roles],
  );
  const isAnalyst = useCallback(
    (userRoles?: string[]) => {
      return checkRole("Analyst", userRoles ?? roles ?? []);
    },
    [roles],
  );

  const switchOrganization = useCallback(
    async (orgId: string) => {
      if (!isGlobalAdmin()) {
        return;
      }

      const response = await doRefreshSession({
        organization_id: orgId,
      });

      if (response?.organization_id && response?.roles) {
        onNewSession({
          claims: {
            organization_id: response.organization_id,
            roles: response.roles,
          },
          exp: response.exp,
          user: response.user,
        });
      }
    },
    [doRefreshSession, isGlobalAdmin, onNewSession],
  );

  useEffect(() => {
    if (!authToken) {
      return;
    }

    if (!currentUser && data) {
      /* existing and fresh cookie for already-logged-in session */
      logEvent("new session");
      onNewSession({
        claims: {
          organization_id: data.user.organization_id,
          roles: data.roles,
        },
        exp: data.exp,
        user: data.user,
      });
    } else if (currentUser && data && error) {
      /* token timeout */
      logEvent("token timeout");
      doLogout();
    } else if (!currentUser && !data && error) {
      /* existing but stale cookie */
      logEvent("stale auth cookie");
      doLogout();
      navigate("/login", { replace: true });
    }
  }, [
    authToken,
    currentUser,
    data,
    doLogout,
    error,
    navigate,
    onEndSession,
    onNewSession,
    onRefreshSession,
  ]);

  useEffect(() => {
    identifyUser(currentUser);
  }, [currentUser]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        hasPermission,
        hasFeature,
        isOrgAdmin,
        isGlobalAdmin,
        isAnalyst,
        isSeniorAnalyst,
        organizationId: organization_id,
        switchOrganization,
        SSOLogoutURL: data?.logout_url ?? "",
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

const useCurrentUser = () => useContext(CurrentUserContext);

export { CurrentUserContext, CurrentUserProvider, useCurrentUser };
