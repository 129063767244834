import useSwr, { type Fetcher, type SWRConfiguration } from "swr";

import { useLogger } from "./useLogger";

const localStorageFetcher: Fetcher<any, string> = (key) => {
  const rawData = localStorage.getItem(key);

  if (!rawData) {
    return null;
  }

  try {
    return JSON.parse(rawData ?? "");
  } catch (ex) {
    return null;
  }
};

const useLocalStorage = <T>(storageKey: string, opts?: SWRConfiguration<T>) => {
  const key = `artemis-${storageKey}`;
  const { data, mutate } = useSwr(key, localStorageFetcher, opts);
  const logger = useLogger();
  const setData = (nextValue: T) => {
    try {
      localStorage.setItem(key, JSON.stringify(nextValue));
      mutate(nextValue);
    } catch (ex: any) {
      logger.error(ex.message);
    }
  };

  return [data as T, setData] as const;
};

export { useLocalStorage };
