import { styled } from "@linaria/react";
import { type FunctionComponent, type ReactNode, useMemo } from "react";

import { type DataSource } from "~/constants";
import { type UserDetailsDTO } from "~/dto/userDetails";
import { useUserSharedUrls, useUserData } from "~/hooks/useUserNodePopUp";
import { text } from "~/styles/typography";

import Card from "./Card";
import { URLExternalLinkComponent } from "./ConnectionPreview";
import DataSourceIcon from "./DataSourceIcon";
import { DialogXButton, DialogContents } from "./Dialog";
import { DialogTable } from "./DialogTable";
import Field from "./Field";
import { DialogHeadingContainer } from "./ModalDialog";
import Section, { Heading } from "./Section";
import { type TableColumn } from "./Table";
import UserPopupOverview from "./UserPopupOverview";
import { booleanToEnglish, getTableRowData } from "./charts/ForceGraph/utils";
import { AccountInsights } from "./insights/InsightsPanel";
import { ExternalLink } from "./library/ExternalLink";
import LoadingIndicator from "./library/LoadingIndicator/LoadingIndicator";
import { Truncated, TruncatedWithoutPopover } from "./text";

const StyledDataSourceIcon = styled(DataSourceIcon)`
  height: 20px;
  border-radius: 20px;
`;

const EmbeddedUrl = styled.dl`
  word-wrap: break-word;
  a {
    color: var(--color-link);
  }
`;

const AdditionalDetailsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-row-gap: var(--spacing-md);
`;

const URLLabel = styled(Heading)`
  padding: var(--spacing-md);
  ${text.md.semibold};
  ${text.sm.regular};
`;

const AccountInfoSection = styled(Section)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;

const URLTableSection = styled(Section)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const Container = styled(Card)`
  width: 360px;
  max-height: 600px;
  display: flex;
  flex-direction: column;

  .dialog-heading {
    padding-left: var(--spacing-sm);
  }

  ${DialogXButton} {
    padding: 0;
  }

  ${DialogContents} {
    background-color: var(--background-color-profile);
    overflow-y: hidden;

    section {
      padding: var(--spacing-lg);
      background-color: var(--background-color-primary);
      border: 1px solid var(--border-color-primary);
      border-radius: var(--border-radius-xl);
    }

    ${URLTableSection} {
      padding: 0;
    }
  }

  &[aria-busy="true"] {
    padding-top: var(--spacing-2xl);
  }
`;

export const StyledDialogTable = styled(DialogTable)`
  th {
    padding: 0;
  }

  tr {
    ${text.xs.regular};
    gap: var(--spacing-md);
    grid-template-columns: 3fr 2fr;
  }

  thead {
    tr {
      padding: 0 var(--spacing-md) var(--spacing-md);
    }
    button {
      display: flex;
      ${text.xs.regular};
      justify-content: flex-end;
    }
  }

  thead button {
    display: flex;
    justify-content: flex-end;
  }

  tbody tr:not(.SkipRow):not(:nth-child(3)) {
    border-top: none;
  }

  tbody tr {
    td:first-child {
      overflow: hidden;
      padding-left: var(--gutter-250);
      word-break: normal;

      button {
        font-size: var(--font-size-700);
        text-align: left;
        text-decoration: none;
        white-space: nowrap;
        td {
          overflow: hidden;

          button {
            text-decoration: none;
            text-align: left;
          }
        }

        td:nth-child(2) {
          text-align: right;
          padding-right: var(--gutter-250);
        }
      }
    }

    td:last-child {
      padding-right: var(--gutter-250);
      text-align: right;
    }
  }
`;

//TO-DO: style this dynamically based on the source
//right now it's only styled for twitter
const SourceCircle = styled.div`
  background-color: var(--color-white);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 1px var(--color-twitter-icon);
`;

const NoDatDiv = styled.div`
  padding: var(--spacing-lg) var(--spacing-4xl);
  ${text.md.regular};
`;

export interface UserNodePopUpProps {
  user_rid: string;
  conversationId?: string;
  narrativeTopic?: string;
  onDismiss?: () => void;
  user_class?: string;
}

interface URLTableData {
  url: string;
  date: string;
}

const getColumnData: (
  conversationId: string | undefined,
) => TableColumn<URLTableData>[] = (conversationId: string | undefined) => [
  {
    field: "url",
    label: "",
    filterable: false,
    sortable: false,
    renderFn: ({ data }) => (
      <TruncatedWithoutPopover>
        <URLExternalLinkComponent
          conversationId={conversationId}
          url={data.url}
        />
      </TruncatedWithoutPopover>
    ),
  },
  {
    field: "date",
    label: "Date Shared",
    filterable: false,
  },
];

const lookupFieldName = (key: string) => {
  //Converting a backend property name to a preferred name.
  //If no preferred name found, return the original input.

  let result = key;

  const lookupTable: Record<string, string> = {
    follower_count: "Followers",
    favorite_count: "Favorites",
    friend_count: "Friends",
    listed_count: "Listed",
    status_count: "Statuses",
    status: "Statuses",
  };

  if (lookupTable.hasOwnProperty(key)) {
    result = lookupTable[key];
  }

  return result;
};

export const handleAdditionalContainerKeyNames = (key: string) => {
  let convertedKey = lookupFieldName(key);

  if (convertedKey === key) {
    convertedKey = convertedKey.replaceAll("_", " ");
  }

  return convertedKey;
};

interface BaseUserProfileProps {
  children?: ReactNode;
  userDetails: UserDetailsDTO;
  showPopUpData: boolean;
  created_at: string;
  location: string;
  additionalProperties: [string, boolean | number][];
  noData: boolean;
  onDismiss?: () => void;
}

export const BaseUserProfile: FunctionComponent<BaseUserProfileProps> = (
  props,
) => {
  const {
    userDetails,
    showPopUpData,
    onDismiss,
    created_at,
    location,
    additionalProperties,
    noData,
    children,
  } = props;

  const { embedded_profile_url } = userDetails;

  return (
    <Container aria-label="User Profile">
      <DialogHeadingContainer className="dialog-heading">
        <SourceCircle>
          <StyledDataSourceIcon
            isFilledImage
            name={userDetails.post_class as DataSource}
          />
        </SourceCircle>
        <DialogXButton onPress={onDismiss} />
      </DialogHeadingContainer>
      <DialogContents aria-busy={!showPopUpData}>
        {!showPopUpData ? (
          <LoadingIndicator containerHeight={200} width="8em" />
        ) : (
          <>
            <UserPopupOverview {...userDetails} />
            <AccountInsights userDetails={userDetails} />
            {(created_at || location || embedded_profile_url) && (
              <AccountInfoSection>
                <Heading data-at-only>Account Info</Heading>
                {embedded_profile_url && (
                  <EmbeddedUrl>
                    <Field
                      name="URL"
                      value={
                        <Truncated
                          href={embedded_profile_url}
                          text={embedded_profile_url}
                        >
                          <ExternalLink href={embedded_profile_url}>
                            {embedded_profile_url}
                          </ExternalLink>
                        </Truncated>
                      }
                    />
                  </EmbeddedUrl>
                )}
                {location && (
                  <Field
                    name="Location"
                    value={<Truncated>{location}</Truncated>}
                  />
                )}
                {created_at && (
                  <Field
                    name="Created"
                    value={<Truncated>{created_at}</Truncated>}
                  />
                )}
              </AccountInfoSection>
            )}
            {additionalProperties.length > 0 && (
              <Section>
                <AdditionalDetailsContainer>
                  {additionalProperties.map(([k, v]) => (
                    <Field
                      key={k}
                      name={handleAdditionalContainerKeyNames(k)}
                      value={
                        <Truncated>
                          {typeof v === "boolean"
                            ? booleanToEnglish(v)
                            : v.toLocaleString()}
                        </Truncated>
                      }
                    />
                  ))}
                </AdditionalDetailsContainer>
              </Section>
            )}
            {children}
            {noData && <NoDatDiv>No available data to display</NoDatDiv>}
          </>
        )}
      </DialogContents>
    </Container>
  );
};

const useUserNodePopUp = (props: UserNodePopUpProps) => {
  const { user_rid, conversationId } = props;
  const { data: nodeData } = useUserSharedUrls({
    conversationId,
    rid: user_rid,
  });
  const { data = {} } = useUserData({
    conversationId,
    rid: user_rid,
  });
  const rowData = getTableRowData(nodeData);
  const columnData = useMemo(
    () => getColumnData(conversationId),
    [conversationId],
  );
  const { additional_properties, class: cls, id, ...standardUserData } = data;
  const { created_at = "", location = "" } = standardUserData;
  const showPopUpData = Object.values(standardUserData)
    .filter(Boolean)
    .some((x) => x.length > 0);
  const additionalProperties = useMemo(
    () =>
      Object.entries(additional_properties ?? {})
        .filter(([, v]) => Boolean(v))
        .sort((a, b) => a[0].toLowerCase().localeCompare(b[0].toLowerCase())),
    [additional_properties],
  );
  const noData =
    !(created_at || location) &&
    !(additionalProperties.length > 0) &&
    !(rowData.length > 0);

  return {
    additionalProperties,
    created_at,
    location,
    noData,
    showPopUpData,
    data,
    columnData,
    rowData,
  };
};

const UserNodePopUp: FunctionComponent<UserNodePopUpProps> = (props) => {
  const { onDismiss } = props;

  const {
    additionalProperties,
    created_at,
    location,
    noData,
    showPopUpData,
    data,
    rowData,
    columnData,
  } = useUserNodePopUp(props);

  return (
    <BaseUserProfile
      additionalProperties={additionalProperties}
      created_at={created_at}
      location={location}
      noData={noData}
      onDismiss={onDismiss}
      showPopUpData={showPopUpData}
      userDetails={data}
    >
      {rowData.length > 0 && (
        <URLTableSection>
          <URLLabel id="account-profile-urls-table-title">URLs</URLLabel>
          <StyledDialogTable
            aria-labelledby="account-profile-urls-table-title"
            disableFilter
            id="account-profile-urls-table"
            initialSort={{ id: "date", desc: true }}
            tableColumns={columnData}
            tableData={rowData}
          />
        </URLTableSection>
      )}
    </BaseUserProfile>
  );
};

export default UserNodePopUp;
