import { css, cx } from "@linaria/core";
import { styled } from "@linaria/react";
/* NOTE: linaria's babel plugin really, really doesn't like React Aria's internationalization stuff
   so NO date- or calendar-specific components can be imported here. Instead, either target elements
   using data attributes or create classNames with linaria's css helper. */
import { Group, Heading } from "react-aria-components";

import { text } from "~/styles/typography";

export const fieldRoot = css`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;

export const InputGroup = styled(Group)`
  display: flex;
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color-primary);
  gap: var(--spacing-md);
  align-items: center;
  padding: var(--spacing-xxs) var(--spacing-lg);

  button {
    flex-shrink: 0;
    // nudge the button to the right so the spacing looks right for hover state
    margin-right: calc((var(--spacing-lg) - var(--spacing-xxs)) * -1);
  }

  .${fieldRoot}[data-open] & {
    box-shadow: var(--ring-gray-shadow-xs);
  }
`;

export const dateInput = css`
  display: flex;
  flex-grow: 1;
  gap: var(--spacing-xxs);
`;

export const timeFieldInput = css`
  border-radius: var(--border-radius-md);
  border: 1px solid var(--border-color-primary);
  // these don't line up with our spacing scale, so we just use fixed values
  padding: 10px 14px;
`;

export const dateSegment = css`
  ${text.md.regular}
  border-radius: var(--border-radius-xs);

  &[data-placeholder] {
    color: var(--text-color-placeholder);
  }

  &:focus {
    outline: none;
    background-color: var(--background-color-tertiary);
  }
`;

export const CalendarHeading = styled(Heading)`
  ${text.md.semibold}
  text-align: center;
`;

export const calendar = css`
  background-color: var(--background-color-primary);
  border: 1px solid var(--border-color-secondary);
  border-radius: var(--border-radius-xl);
  box-shadow: var(--shadow-xl);
  padding: var(--spacing-2xl) var(--spacing-3xl);

  header {
    display: flex;
    align-items: center;

    ${CalendarHeading} {
      flex-grow: 1;
    }
    button {
      flex-shrink: 0;
    }
  }
`;

export const calendarGrid = css`
  --grid-cell-size: 40px;

  thead {
    ${text.sm.medium}
    color: var(--text-color-secondary);
    height: var(--grid-cell-size);
  }
`;

const calendarCellRoot = css`
  --text-color: var(--text-color-secondary);
  --indicator-color: transparent;

  height: var(--grid-cell-size);
  width: var(--grid-cell-size);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  cursor: default;
  position: relative;
  z-index: 1;
  border-radius: var(--border-radius-full);
  ${text.sm.regular}

  &:focus {
    outline: none;
  }

  &[data-disabled],
  &[data-unavailable],
  &[data-outside-month] {
    --text-color: var(--text-color-disabled);
  }

  &[data-hovered],
  &[data-selected],
  &[data-focused] {
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: var(--border-radius-full);
      background-color: var(--indicator-color);
      z-index: -1;
    }
  }

  &[data-hovered],
  &[data-focused] {
    --text-color: var(--text-color-secondary_hover);
    --indicator-color: var(--background-color-tertiary);
  }
`;

const _calendarCellSingle = css`
  &[data-selected] {
    --text-color: var(--text-color-white);
    --indicator-color: var(--background-color-brand-solid);

    &[data-hovered] {
      --indicator-color: var(--background-color-brand-solid_hover);
    }
  }
`;

export const calendarCellSingle = cx(calendarCellRoot, _calendarCellSingle);

const _calendarCellRange = css`
  &[data-selected] {
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: var(--background-color-active);
      z-index: -1;
    }

    &[data-hovered] {
      --text-color: var(--text-color-secondary_hover);
      --indicator-color: var(--background-color-tertiary);
    }
  }

  &[data-selection-start]:not([data-outside-month]),
  &[data-selection-end]:not([data-outside-month]) {
    --text-color: var(--text-color-white);
    --indicator-color: var(--background-color-brand-solid);

    &[data-hovered] {
      --text-color: var(--text-color-white);
      --indicator-color: var(--background-color-brand-solid_hover);
    }
  }

  &[data-selection-start],
  &[data-week-start] {
    &::before {
      border-top-left-radius: var(--border-radius-full);
      border-bottom-left-radius: var(--border-radius-full);
    }
  }

  &[data-selection-end],
  &[data-week-end] {
    &::before {
      border-top-right-radius: var(--border-radius-full);
      border-bottom-right-radius: var(--border-radius-full);
    }
  }
`;

export const calendarCellRange = cx(calendarCellRoot, _calendarCellRange);
