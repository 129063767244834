import { useUserPreference } from "./useUserPreference";

const useConversationLanguage = () => {
  const languageOptions = [
    { id: "en", label: "English" },
    { id: "es", label: "Spanish" },
    { id: "zh", label: "Chinese" },
  ];

  const { conversationLanguage, updateConversationLanguage } =
    useUserPreference();

  return {
    languageOptions,
    setConversationLanguage: updateConversationLanguage,
    conversationLanguage,
  };
};

export { useConversationLanguage };
