import { type FunctionComponent, useState, useCallback } from "react";

import defaultAvatar from "~/images/default_avatar.png";

const AccountAvatar: FunctionComponent<{
  url: string | null | undefined;
}> = (props) => {
  const { url } = props;
  const [avatarFailedToLoad, setAvatarFailedToLoad] = useState<boolean>(false);
  const onError = useCallback(() => {
    setAvatarFailedToLoad(true);
  }, []);
  return (
    <img
      alt={!avatarFailedToLoad && url ? "User Avatar" : "Default Avatar"}
      aria-hidden="true"
      onError={onError}
      src={!avatarFailedToLoad && url ? url : defaultAvatar}
    />
  );
};

export default AccountAvatar;
