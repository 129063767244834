import { styled } from "@linaria/react";
import { type FunctionComponent, useMemo } from "react";

import { useSharedURLS } from "~/hooks/useCoUrl";
import { useConversationFilters } from "~/hooks/useConversationFilters";

import { URLExternalLinkComponent } from "./ConnectionPreview";
import {
  KeyInsightsTable,
  NoDataPlaceholder,
  PaddedLoadingIndicator,
} from "./NetworkKeyInsights";
import { type TableColumn } from "./Table";
import { LineClamp } from "./text";

interface URLKeyInsightsProps {
  conversationId: string;
  excludeTwitter: boolean;
}
interface URLInsightsTableData {
  url: string;
  value: number;
}

const URLCell = styled(LineClamp)`
  -webkit-line-clamp: 2;
`;

export function getColumnData(conversationId: string) {
  const columnData: TableColumn<URLInsightsTableData>[] = [
    {
      field: "url",
      label: "URLs",
      filterable: false,
      renderFn: ({ data }) => (
        <URLExternalLinkComponent
          conversationId={conversationId}
          url={data.url}
        >
          <URLCell>{data.url}</URLCell>
        </URLExternalLinkComponent>
      ),
    },
    {
      field: "value",
      label: "Posts",
      filterable: false,
    },
  ];
  return columnData;
}

const URLKeyInsights: FunctionComponent<URLKeyInsightsProps> = (props) => {
  const filters = useConversationFilters();
  const { data, isLoading } = useSharedURLS(props.conversationId, {
    filters,
    excludeTwitter: props.excludeTwitter,
  });
  const { columnData } = useMemo(() => {
    const columnData = getColumnData(props.conversationId);
    return {
      columnData,
    };
  }, [props.conversationId]);

  return (
    <>
      <KeyInsightsTable
        aria-label="URLs Shared"
        disableFilter
        id="url-key-insights-table"
        tableColumns={columnData}
        tableData={data?.data ?? []}
        virtual={false}
      />
      {isLoading && <PaddedLoadingIndicator />}
      {!isLoading && data?.data.length === 0 && <NoDataPlaceholder />}
    </>
  );
};

export default URLKeyInsights;
