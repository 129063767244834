import { styled } from "@linaria/react";
import {
  type FormContents,
  type FormSubmitEvent,
  type FunctionComponent,
  useCallback,
  useState,
} from "react";

import { type ProjectDTO } from "~/dto";
import { useLogger } from "~/hooks/useLogger";
import { useProjects } from "~/hooks/useProjects";

import Form, { type FormProps } from "../library/Form";
import Input from "../library/Input";

interface AddProjectFormProps extends FormProps {
  onDismiss: () => void;
  projectId?: ProjectDTO["id"];
}

interface ProjectFormElements {
  project_name: HTMLInputElement;
}

const ErrorMessageContainer = styled.div`
  color: var(--color-text-error);
`;

const StyledForm = styled(Form)`
  > * {
    margin-top: var(--spacing-xl);
  }
`;

const useProjectForm = (props: AddProjectFormProps) => {
  const { onDismiss, projectId, ...otherProps } = props;
  const logger = useLogger();
  const { create, data: projects, update } = useProjects();
  const [errorMessage, setErrorMessage] = useState<string>();
  const project = projects?.find((p) => p.id === projectId) ?? undefined;
  const onSubmit = useCallback(
    async (e: FormSubmitEvent) => {
      e.preventDefault();

      const form = e.currentTarget as FormContents<ProjectFormElements>;

      try {
        setErrorMessage(undefined);

        const newProjectName = form.elements.project_name.value;

        if (projects.find((p) => p.name === newProjectName)) {
          throw new Error("a project with that name already exists");
        }

        await (projectId
          ? update(projectId, {
              name: newProjectName,
            })
          : create({
              name: newProjectName,
            }));

        onDismiss();
      } catch (ex: any) {
        logger.error(ex.message);
        setErrorMessage(ex.message);
      }
    },
    [create, logger, onDismiss, projectId, projects, update],
  );

  return {
    errorMessage,
    onSubmit,
    otherProps,
    project,
  };
};

const AddProjectForm: FunctionComponent<AddProjectFormProps> = (props) => {
  const { errorMessage, onSubmit, otherProps, project } = useProjectForm(props);

  return (
    <StyledForm {...otherProps} onSubmit={onSubmit}>
      <Input
        defaultValue={project?.name ?? ""}
        id="project_name"
        label="Project Name"
        required
        type="text"
      />
      <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
    </StyledForm>
  );
};

export default AddProjectForm;
