import { type LogLevel, type LoggerFunction } from "./types";

const remoteLog: LoggerFunction = (level: LogLevel, ...msg: unknown[]) => {
  /* emit log messages as a list so that in the future we can batch them if we need to */
  // should probably store-and-retry here too
  const payload = new TextEncoder().encode(
    JSON.stringify([
      {
        level,
        msg,
      },
    ]),
  );

  /* we POST these messages to our own server, not the API server, in case an unreachable API
   * server is what's generating error messages */
  window.fetch("/logreporter", {
    body: payload,
    headers: {
      "Content-type": "application/json",
    },
    method: "POST",
  });
};

export { remoteLog };
