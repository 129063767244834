import { useMemo } from "react";

import { platforms } from "~/constants";
import { type TopicDTO } from "~/dto/topic";
import { type TopicListDTO, TopicListValidator } from "~/dto/topicList";

import {
  type ConversationFilters,
  getConversationFilterRequestParams,
} from "./useConversationFilters";
import { useDataSourceBreakdown } from "./usePlatformOverview";
import { useRemoteObject } from "./useRemoteData";

const useConversationTopics = (
  conversationId: string,
  filters?: ConversationFilters,
) => {
  const params = getConversationFilterRequestParams(filters);
  const apiEndpoint = conversationId
    ? `/api/v2/conversations/${conversationId}/topics?${params}`
    : undefined;
  /* the list of conversation narratives is not keyed by id so we can't use `useRemoteCollection` */
  const { data, isLoading } = useRemoteObject<TopicListDTO>(apiEndpoint, {
    schemaValidator: TopicListValidator,
  });

  return useMemo(() => {
    const topics: TopicDTO[] =
      data?.topics?.filter(
        (topic) => topic.ngrams[0] !== "No keyphrases found",
      ) ?? [];
    return {
      topics,
      isLoading,
    };
  }, [data?.topics, isLoading]);
};

function isPlatform(s: string): s is keyof typeof platforms {
  return s in platforms;
}

const useConversationPlatforms = (conversationId: string) => {
  const { data: { data: dataSources } = {}, isLoading } =
    useDataSourceBreakdown(conversationId);

  const platformsForConversation = useMemo(() => {
    if (!dataSources) {
      return [];
    }

    return dataSources
      .map((d) => d.platform)
      .filter(isPlatform)
      .map((platform) => {
        return {
          value: platform,
          label: platforms[platform],
        };
      });
  }, [dataSources]);

  return {
    platforms: platformsForConversation,
    isLoading,
  };
};

export { useConversationPlatforms, useConversationTopics };
