import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  type ReactNode,
  useCallback,
  useState,
  useMemo,
} from "react";

import { platforms } from "~/constants";
import { type InsightDTO, type UserDetailsDTO } from "~/dto";
import { useInsightOptions } from "~/hooks/useInsights";
import { text } from "~/styles/typography";

import Section, { Heading } from "../Section";
import Form from "../library/Form";
import Icon from "../library/Icon";
import Input from "../library/Input";
import { FilterableListBox, ListBoxOption } from "../library/ListBox";
import { Select, Option } from "../library/Select";

import {
  type AccountInsightsFormProps,
  type DomainInsightsFormProps,
  type InsightsFormProps,
  type InsightsType,
} from "./types";

const StyledHeading = styled(Heading)`
  ${text.lg.medium};
`;

const AccountInsightIdentificationSection = styled(Section)`
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: auto;
  gap: var(--spacing-xl);
`;

const MetadataSelect = styled(Select)`
  grid-column: 1;
`;

const MetadataSection = styled(AccountInsightIdentificationSection)`
  label {
    grid-column: 1;
  }
`;

const InsightsListBox = styled(FilterableListBox)`
  [data-slot="listbox"] {
    max-height: 215px;
    overflow-y: auto;
    scrollbar-width: thin;
  }
`;

const InsightsOption = styled(ListBoxOption)`
  [data-slot="text"] > span {
    font-weight: var(--font-weight-regular);
  }
`;

const StyledForm = styled(Form)`
  padding: var(--spacing-xl);
  display: grid;
  align-items: start;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: auto;
  gap: var(--spacing-xl);

  section:not(${MetadataSection}) {
    display: contents;
  }

  ${StyledHeading} {
    grid-column: 1 / span 2;
  }

  aside {
    grid-column: 1 / span 2;
    display: flex;
    align-items: center;
    gap: var(--spacing-md);
    ${text.sm.regular};

    [data-icon] {
      color: var(--color-warning-500);
    }
  }

  input {
    width: 100%;
  }
`;

const optionMatchesSearchTerm = (
  option: { label: string },
  searchTerm: string,
) => {
  return option.label.toLowerCase().includes(searchTerm.toLowerCase());
};

const SomeRequired: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => (
  <aside>
    <Icon family="untitled" name="alert-triangle" />
    <span>{children}</span>
  </aside>
);

type TypePickerProps = {
  onSetType: (t: InsightsType) => void;
  value: InsightsType | null;
};

const InsightTypePicker: FunctionComponent<TypePickerProps> = (props) => {
  const { onSetType, value } = props;
  const onChange = useCallback(
    (selected: string | number) => {
      onSetType(selected as InsightsType);
    },
    [onSetType],
  );

  return (
    <Select
      label="Type"
      name="type"
      onSelectionChange={onChange}
      placeholder="Select a type"
      required
      selectedKey={value}
    >
      <Option id="account">Account</Option>
      <Option id="domain">Domain</Option>
    </Select>
  );
};

type PlatformPickerProps = {
  platform?: string;
  readOnly?: boolean;
};
const AccountInsightPlatformPicker: FunctionComponent<PlatformPickerProps> = (
  props,
) => {
  const { platform, readOnly } = props;

  return (
    <MetadataSelect
      defaultSelectedKey={platform}
      label="Platform"
      name="platform"
      readOnly={readOnly}
      required
    >
      {Object.entries(platforms).map(([k, v]) => (
        <Option key={k} id={k}>
          {v}
        </Option>
      ))}
    </MetadataSelect>
  );
};

type AccountIdFormProps = {
  insight?: InsightDTO;
};

const AccountIdFormElements: FunctionComponent<AccountIdFormProps> = (
  props,
) => {
  const { insight } = props;
  const { link, screen_name, user_id } = insight ?? {};

  return (
    <>
      <Input
        autoComplete="off"
        defaultValue={user_id ?? ""}
        label="User ID"
        name="user_id"
        readOnly={Boolean(insight)}
        type="text"
      />
      <Input
        data-1p-ignore
        defaultValue={screen_name ?? ""}
        label="Handle"
        name="screen_name"
        placeholder="@example"
        readOnly={Boolean(insight)}
        type="text"
      />
      <Input
        defaultValue={link ?? ""}
        label="Link"
        name="link"
        placeholder="https://example.com/user"
        readOnly={Boolean(insight)}
        type="text"
      />
      <Input label="Name" name="name" type="text" />
    </>
  );
};

const AccountInsightsFormElements: FunctionComponent<
  AccountInsightsFormProps
> = (props) => {
  const { insight } = props;
  const { additional_properties } = insight ?? {};
  const {
    subcategory,
    country_of_origin,
    operating_country,
    state_association,
    language,
    name,
    region,
    role,
  } = additional_properties ?? {};
  const { data: insightOptions } = useInsightOptions();
  const { data: insightOptionsData } = insightOptions ?? {};
  const {
    country: availableCountries,
    region: availableRegions,
    role: availableRoles,
  } = insightOptionsData ?? {};

  const languageOptions = useMemo(
    () =>
      insightOptionsData?.language?.map((l) => ({
        key: l,
        label: l,
      })) ?? [],
    [insightOptionsData?.language],
  );
  const subcategoryOptions = useMemo(
    () =>
      insightOptionsData?.subcategory?.map((l) => ({
        key: l,
        label: l,
      })) ?? [],
    [insightOptionsData?.subcategory],
  );

  return (
    <>
      <Input
        data-1p-ignore
        defaultValue={name ?? ""}
        label="Outlet"
        name="name"
        placeholder="New York Times"
        type="text"
      />
      <Select
        defaultSelectedKey={country_of_origin ?? undefined}
        label="Country of Origin"
        name="country_of_origin"
      >
        <Option id="">&nbsp;</Option>
        {availableCountries?.map((l) => (
          <Option key={l} id={l}>
            {l}
          </Option>
        ))}
      </Select>
      <Select
        defaultSelectedKey={operating_country ?? undefined}
        label="Operating Country"
        name="operating_country"
      >
        <Option id="">&nbsp;</Option>
        {availableCountries?.map((l) => (
          <Option key={l} id={l}>
            {l}
          </Option>
        ))}
      </Select>
      <Select
        defaultSelectedKey={state_association ?? undefined}
        label="State Association"
        name="state_association"
      >
        <Option id="">&nbsp;</Option>
        {availableCountries?.map((l) => (
          <Option key={l} id={l}>
            {l}
          </Option>
        ))}
      </Select>
      <Select defaultSelectedKey={role ?? undefined} label="Role" name="role">
        <Option id="">&nbsp;</Option>
        {availableRoles?.map((r) => (
          <Option key={r} id={r}>
            {r}
          </Option>
        ))}
      </Select>
      <Select
        defaultSelectedKey={region ?? undefined}
        label="Region"
        name="region"
      >
        <Option id="">&nbsp;</Option>
        {availableRegions?.map((r) => (
          <Option key={r} id={r}>
            {r}
          </Option>
        ))}
      </Select>
      <InsightsListBox
        defaultSelectedKeys={language ?? []}
        isItemIncluded={optionMatchesSearchTerm}
        items={languageOptions}
        label="Language"
        name="language"
        selectionMode="multiple"
      >
        {(language) => (
          <InsightsOption key={language.key}>{language.label}</InsightsOption>
        )}
      </InsightsListBox>
      <InsightsListBox
        defaultSelectedKeys={subcategory ?? []}
        isItemIncluded={optionMatchesSearchTerm}
        items={subcategoryOptions}
        label="Subcategory"
        name="subcategory"
        selectionMode="multiple"
      >
        {(subcategory) => (
          <InsightsOption key={subcategory.key}>
            {subcategory.label}
          </InsightsOption>
        )}
      </InsightsListBox>
    </>
  );
};

const DomainInsightsFormElements: FunctionComponent<DomainInsightsFormProps> = (
  props,
) => {
  const { insight, uid } = props;
  const { additional_properties } = insight ?? {};
  const {
    subcategory,
    country_of_origin,
    operating_country,
    state_association,
    language,
    name,
  } = additional_properties ?? {};
  const { data: insightOptions } = useInsightOptions();
  const { data: insightOptionsData } = insightOptions ?? {};
  const { country: availableCountries } = insightOptionsData ?? {};

  const languageOptions = useMemo(
    () =>
      insightOptionsData?.language?.map((l) => ({
        key: l,
        label: l,
      })) ?? [],
    [insightOptionsData?.language],
  );
  const subcategoryOptions = useMemo(
    () =>
      insightOptionsData?.subcategory?.map((l) => ({
        key: l,
        label: l,
      })) ?? [],
    [insightOptionsData?.subcategory],
  );

  return (
    <>
      <Input
        autoComplete="off"
        defaultValue={uid ?? insight?.link ?? ""}
        label="FQDN"
        name="link"
        placeholder="www.example.com"
        required
        type={uid ? "hidden" : "text"}
      />
      <Input
        data-1p-ignore
        defaultValue={name ?? ""}
        label="Outlet"
        name="name"
        placeholder="New York Times"
        type="text"
      />
      <Select
        defaultSelectedKey={country_of_origin ?? undefined}
        label="Country of Origin"
        name="country_of_origin"
      >
        <Option id="">&nbsp;</Option>
        {availableCountries?.map((l) => (
          <Option key={l} id={l}>
            {l}
          </Option>
        ))}
      </Select>
      <Select
        defaultSelectedKey={operating_country ?? undefined}
        label="Operating Country"
        name="operating_country"
      >
        <Option id="">&nbsp;</Option>
        {availableCountries?.map((l) => (
          <Option key={l} id={l}>
            {l}
          </Option>
        ))}
      </Select>
      <Select
        defaultSelectedKey={state_association ?? undefined}
        label="State Association"
        name="state_association"
      >
        <Option id="">&nbsp;</Option>
        {availableCountries?.map((l) => (
          <Option key={l} id={l}>
            {l}
          </Option>
        ))}
      </Select>
      <InsightsListBox
        defaultSelectedKeys={language ?? []}
        isItemIncluded={optionMatchesSearchTerm}
        items={languageOptions}
        label="Language"
        name="language"
        selectionMode="multiple"
      >
        {(language) => (
          <InsightsOption key={language.key}>{language.label}</InsightsOption>
        )}
      </InsightsListBox>
      <InsightsListBox
        defaultSelectedKeys={subcategory ?? []}
        isItemIncluded={optionMatchesSearchTerm}
        items={subcategoryOptions}
        label="Subcategory"
        name="subcategory"
        selectionMode="multiple"
      >
        {(subcategory) => (
          <InsightsOption key={subcategory.key}>
            {subcategory.label}
          </InsightsOption>
        )}
      </InsightsListBox>
    </>
  );
};

function getFormType(insight: InsightDTO | undefined, uid?: unknown) {
  if (
    insight?.analyzable_type === "account" ||
    insight?.analyzable_type === "domain"
  ) {
    return insight.analyzable_type;
  }

  return uid ? (typeof uid === "string" ? "domain" : "account") : null;
}

const InsightsForm: FunctionComponent<InsightsFormProps> = (props) => {
  const { insight, formId, onSubmit, uid, ...rest } = props;
  const [formType, setFormType] = useState<InsightsType | null>(
    getFormType(insight, uid),
  );

  return (
    <StyledForm {...rest} id={formId} onSubmit={onSubmit}>
      {!uid && (
        <>
          <MetadataSection
            aria-live="polite" /* since we use JS to insert/remove fields */
          >
            <StyledHeading data-at-only>Insight Metadata</StyledHeading>
            <InsightTypePicker onSetType={setFormType} value={formType} />
            {formType === "account" && (
              <AccountInsightPlatformPicker
                platform={insight?.platform ?? undefined}
                readOnly={Boolean(insight)}
              />
            )}
          </MetadataSection>
          {formType === "account" ? (
            <AccountInsightIdentificationSection>
              <StyledHeading>Account Identification</StyledHeading>
              <SomeRequired>
                You must fill in at least 1 of these fields
              </SomeRequired>
              <AccountIdFormElements insight={insight} />
            </AccountInsightIdentificationSection>
          ) : formType === "domain" ? (
            <SomeRequired>
              You must fill in FQDN and at least 1 other field besides Outlet
            </SomeRequired>
          ) : null}
        </>
      )}
      <Section aria-live="polite" data-form-type={formType}>
        {formType === "domain" ? (
          <>
            <StyledHeading data-at-only>Domain Insights</StyledHeading>
            <DomainInsightsFormElements insight={insight} uid={uid as string} />
          </>
        ) : formType === "account" ? (
          <>
            <StyledHeading {...(uid ? { "data-at-only": true } : {})}>
              Account Insights
            </StyledHeading>
            <AccountInsightsFormElements
              insight={insight}
              uid={uid as UserDetailsDTO}
            />
          </>
        ) : null}
      </Section>
    </StyledForm>
  );
};

export default InsightsForm;
