import { styled } from "@linaria/react";
import type { PageComponent } from "react";
import { Helmet } from "react-helmet";

import Header from "~/components/Header";
import ResetPasswordForm from "~/components/ResetPasswordForm";

const StyledHeader = styled(Header)`
  grid-row: 1;
  grid-column: 1;
`;

const CenteredFixedTopLayout = styled.div`
  display: flex;
  padding-top: 33vh;
  justify-content: center;
  align-items: center;

  > :first-child {
    width: 400px;
    padding: 0 var(--spacing-2xl) var(--spacing-2xl);
  }
`;

const ChangePasswordPage: PageComponent = () => {
  return (
    <>
      <Helmet>
        <title>AG Artemis: Password change required</title>
        <meta content="AG Artemis password change page" name="description" />
      </Helmet>
      <StyledHeader />
      <CenteredFixedTopLayout>
        <ResetPasswordForm />
      </CenteredFixedTopLayout>
    </>
  );
};

export default ChangePasswordPage;
