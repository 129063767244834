import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  useCallback,
  useState,
  type FormSubmitEvent,
  useEffect,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";

import type { CheckSSORequestDTO, OrganizationSSODTO } from "~/dto";
import { OrganizationSSOValidator } from "~/dto/organizationSSO";
import { useLogin } from "~/hooks/useLogin";
import { useRemoteAction } from "~/hooks/useRemoteData";
import { unauthenticatedActionFetcherFactory } from "~/hooks/useRemoteData/utils";
import { text } from "~/styles/typography";

import Card from "./Card";
import {
  FormContentsContainer,
  FormTitle,
  ButtonContainer,
} from "./FormComponents";
import A from "./library/A";
import Button, { ButtonKind, ButtonSize } from "./library/Button";
import Form from "./library/Form";
import Icon from "./library/Icon";
import Input from "./library/Input";

interface LoginFormProps {
  title: string;
}

export function useSSOStatus() {
  const { data, error, execute } = useRemoteAction<
    CheckSSORequestDTO,
    OrganizationSSODTO
  >("/api/v2/user/check_sso", {
    actionFetcher: unauthenticatedActionFetcherFactory,
    schemaValidator: OrganizationSSOValidator,
  });

  const getSSOLink = useCallback(
    async (email: string) => {
      if (email) {
        const response = await execute({ email: email });
        return response?.sso_login_link;
      }
    },
    [execute],
  );

  return {
    data,
    error,
    getSSOLink,
  };
}

const getErrorMessage = (
  error: undefined | string,
  MFAStatus: undefined | string,
  SSOError: undefined | string,
) => {
  let message = "";

  // Login error has higher precedence
  if (error) {
    return "Invalid login credentials. Please try again.";
  }

  //mfa_0 means DUO is unhealthy/unreachable (refer to /backend MFAStatus for more details)
  if (MFAStatus === "mfa_0") {
    message = "An issue occurred on our end. Please retry later.";
  }

  if (SSOError) {
    message = "Something went wrong. Please try again.";
  }

  return message;
};

interface LoginFormElement extends HTMLFormElement {
  elements: HTMLFormControlsCollection & {
    password: HTMLInputElement;
    email: HTMLInputElement;
  };
}

function useLoginForm() {
  const { error: LoginError, execute } = useLogin();
  const { getSSOLink, error: SSOError } = useSSOStatus();
  const [searchParams] = useSearchParams();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const MFAStatus = searchParams.get("status") ?? undefined; //possible mfa failure states
  const [submitButtonText, setSubmitButtonText] = useState<string>("Next");
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    setMessage(getErrorMessage(LoginError, MFAStatus, SSOError));
  }, [LoginError, MFAStatus, SSOError]);

  const onSubmit = useCallback(
    async (e: FormSubmitEvent) => {
      e.preventDefault();
      const form = e.currentTarget as LoginFormElement;
      const email = form.elements.email.value;

      if (!showPasswordField) {
        const SSOLink = await getSSOLink(email);
        if (SSOLink) {
          window.location.href = SSOLink;
        } else {
          setSubmitButtonText("Log In");
          setShowPasswordField(true);
          setMessage(""); //Clearing potential messages to go to the next prompt.
        }
      } else {
        try {
          buttonRef.current?.setAttribute("disabled", "disabled");

          await execute({
            email: email,
            next: searchParams.get("next") ?? undefined,
            password: form.elements.password.value,
          });
        } catch (_ex) {
          /* only re-enable this button in case of error, e.g. wrong username/
           * password combination.  upon successful login MFA users get
           * redirected to our MFA provider and non-MFA users to the app's
           * dashboard */
          buttonRef.current?.removeAttribute("disabled");
        }
      }
    },
    [execute, getSSOLink, searchParams, showPasswordField],
  );

  return {
    buttonRef,
    onSubmit,
    submitButtonText,
    showPasswordField,
    message,
  };
}

const ForgotPasswordLink = styled(A)`
  ${text.xs.regular};
`;

const PasswordActions = styled.div`
  margin: var(--spacing-xs) 0 var(--spacing-xl);
  display: flex;
  justify-content: space-between;
`;

const FailedLoginText = styled.span`
  ${text.xs.regular};
  line-height: 16px;
  margin-left: var(--spacing-xs);
`;

const ErrorContainer = styled.div`
  padding-bottom: var(--spacing-md);
  color: var(--text-color-error-primary);
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);

  [data-icon] {
    width: 20px;
    height: 20px;
  }
`;

const LoginForm: FunctionComponent<LoginFormProps> = (props) => {
  const { buttonRef, onSubmit, submitButtonText, showPasswordField, message } =
    useLoginForm();
  const { title } = props;

  return (
    <Card>
      <FormTitle>{title}</FormTitle>
      <Form onSubmit={onSubmit}>
        <FormContentsContainer>
          <Input
            autoComplete="username"
            autoFocus
            label="Email Address"
            name="email"
            preIcon={<Icon family="untitled" name="user-01" />}
            required
            type="text"
          />
          {showPasswordField ? (
            <>
              <Input
                autoComplete="current-password"
                autoFocus
                label="Password"
                name="password"
                required
                type="password"
              />
              <PasswordActions>
                <ForgotPasswordLink href="/forgotPassword">
                  Forgot my password
                </ForgotPasswordLink>
              </PasswordActions>
            </>
          ) : null}

          {message ? (
            <ErrorContainer>
              <Icon family="untitled" name="alert-circle" />
              <FailedLoginText>{message}</FailedLoginText>
            </ErrorContainer>
          ) : (
            <span role="presentation">&#8203;</span>
          )}

          <ButtonContainer>
            <Button
              ref={buttonRef}
              data-kind={ButtonKind.Primary}
              data-size={ButtonSize.md}
              type="submit"
            >
              {submitButtonText}
            </Button>
          </ButtonContainer>
        </FormContentsContainer>
      </Form>
    </Card>
  );
};

export default LoginForm;
