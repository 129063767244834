import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  type ReactElement,
  type StyledComponent,
  type Key,
} from "react";
import {
  Tabs as AriaTabs,
  TabList,
  Tab,
  TabPanel,
  TabsContext,
} from "react-aria-components";

import { text } from "~/styles/typography";
import { exists } from "~/utils/types";

export enum TabKind {
  Outer = "outer",
  Inner = "inner",
}

export enum TabSize {
  sm = "sm",
  md = "md",
}

export type TabItemProps = {
  children: ReactElement;
  href?: string | ((name: string) => string);
  id: string;
  title: string;
};

export interface TabsProps extends StyledComponent {
  /** elements representing the individual tab items, with each having
   * its own content to be displayed when its tab is active
   */
  children: (ReactElement<TabItemProps> | null | undefined)[];
  id?: string;
  /** optional function which accepts a tab name and returns a URL */
  navLinks?: (tabName: string) => string | undefined;
  /** invoked when a new tab is selected */
  onSelectionChange?: (nextTabName: Key) => void;
  /** an id of a tab */
  selectedTab?: string;
  /** optional label for the tab list */
  label?: string;
  /** variants of the tab  */
  "data-kind": TabKind;
  /** size of the tab */
  "data-size": TabSize;
  /** whether to expand the width of the tab */
  "data-full-width"?: boolean;
}

/* these components never get rendered - `<Tabs>` extracts their props and turns
 * them into react-aria-components `<Tab>` elements */
const TabItem: FunctionComponent<TabItemProps> = () => null;

const onlyRealChildren = (list: TabsProps["children"]) => {
  return list.filter(exists);
};

const StyledAriaTabs = styled(AriaTabs)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  & > [role="tablist"] {
    display: flex;
    padding: var(--spacing-none, 0rem);
    align-items: center;
    gap: var(--spacing-xs, 0.25rem);
  }
  &[data-kind="${TabKind.Outer}"] > [role="tablist"] {
    align-items: flex-start;
    border-bottom: 1px solid var(--border-color-secondary);
    gap: var(--spacing-md, 0.5rem);
  }

  &[data-full-width="true"] {
    > [role="tablist"] {
      justify-content: space-evenly;

      > [role="tab"] {
        flex-grow: 1;
      }
    }
  }

  /* tab */
  & > [role="tablist"] > [role="tab"] {
    text-decoration: none;
    flex-shrink: 1;
    min-width: 0;
    height: 100%;
    cursor: default;
    outline: none;

    &[data-focus-visible] {
      outline: auto;
    }
  }

  &[data-size="${TabSize.sm}"] > [role="tablist"] > [role="tab"] {
    ${text.sm.semibold}
  }
  &[data-size="${TabSize.md}"] > [role="tablist"] > [role="tab"] {
    ${text.md.semibold}
  }

  &[data-kind="${TabKind.Inner}"] > [role="tablist"] > [role="tab"] {
    display: inline-flex;
    padding: var(--spacing-md, 0.5rem) var(--spacing-lg, 0.75rem);
    justify-content: center;
    align-items: center;
    gap: var(--spacing-md, 0.5rem);
    border-radius: var(--radius-sm, 0.375rem);
    color: var(--text-color-quaternary);
    outline: none; /* applied via box-shadow */

    &:hover {
      background: var(--background-color-brand-primary_alt);
      color: var(--text-color-brand-secondary);
    }

    &[data-focus-visible] {
      background: var(--background-color-primary);
      box-shadow: var(--ring-brand);
      color: var(--text-color-quaternary);
    }

    &[aria-selected="true"] {
      background: var(--background-color-brand-primary_alt);
      color: var(--text-color-brand-secondary);

      &[data-focus-visible] {
        box-shadow: var(--ring-brand);
      }
    }
  }

  &[data-kind="${TabKind.Outer}"] > [role="tablist"] > [role="tab"] {
    display: inline-flex;
    padding: var(--spacing-none, 0rem) var(--spacing-xs, 0.25rem)
      var(--spacing-lg, 0.75rem) var(--spacing-xs, 0.25rem);
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-md, 0.5rem);
    color: var(--text-color-quaternary);

    &:hover {
      border-bottom: 2px solid rgba(180, 229, 124, 1); /* FIXME: no semantic color: Component colors/Utility/Accent/utility-accent-600 */
      color: var(--text-color-brand-secondary);
    }

    &:focus {
      color: var(--text-color-quaternary);
    }

    &[aria-selected="true"],
    &[aria-selected="true"]:hover,
    &[aria-selected="true"]:focus {
      border-bottom: 2px solid rgba(180, 229, 124, 1); /* FIXME: no semantic color: Component colors/Utility/Accent/utility-accent-600 */
      color: var(--text-color-brand-secondary);
    }
  }

  & > [role="tabpanel"] {
    flex-grow: 1;
    display: flex;
    overflow: auto;
  }
`;

/**
 * This component creates a tabbed interface, allowing users to organize and switch between different content sections.
 */
const Tabs: FunctionComponent<TabsProps> = (props) => {
  const {
    children: childElements,
    navLinks,
    onSelectionChange,
    selectedTab,
    label,
    ...otherProps
  } = props;
  const children = onlyRealChildren(childElements).map((c) => c.props);

  return (
    /* looks weird but it stops nested tab-sets from bleeding into each other */
    <TabsContext.Provider value={{}}>
      <StyledAriaTabs
        onSelectionChange={onSelectionChange}
        selectedKey={selectedTab}
        {...otherProps}
      >
        <TabList aria-label={label}>
          {children.map((child) => (
            <Tab key={child.id} href={navLinks?.(child.id)} id={child.id}>
              {child.title}
            </Tab>
          ))}
        </TabList>
        {children.map((child) => (
          <TabPanel key={child.id} id={child.id}>
            {child.children}
          </TabPanel>
        ))}
      </StyledAriaTabs>
    </TabsContext.Provider>
  );
};

export { TabItem };

export default Tabs;
