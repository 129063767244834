import { styled } from "@linaria/react";
import type { PageComponent } from "react";
import { Helmet } from "react-helmet";

import ForgotPasswordForm from "~/components/ForgotPasswordForm";
import Header from "~/components/Header";

const CenteredFixedTopLayout = styled.main`
  display: flex;
  padding-top: 33vh;
  justify-content: center;
  align-items: center;

  > :first-child {
    width: 400px;
    padding: 0 var(--spacing-2xl) var(--spacing-2xl);
  }
`;

const ForgotPassword: PageComponent = () => {
  return (
    <>
      <Helmet>
        <meta content="AG Artemis Forgot Password Page" name="description" />
      </Helmet>

      <Header />
      <CenteredFixedTopLayout>
        <ForgotPasswordForm />
      </CenteredFixedTopLayout>
    </>
  );
};

export default ForgotPassword;
