import { type RawDataDTO } from "~/dto/rawData";

import {
  COORDINATION_TYPE_EVIDENCES_MAP,
  type CoordinationTypeKeys,
  coordinationTypeNames,
  type Evidence,
} from "./constants";
import { type Datum, type LinkDatum, type NodeDatum } from "./types";

export function isNode(
  obj: string | number | NodeDatum | undefined,
): obj is NodeDatum {
  return typeof obj === "object" && "id" in obj;
}

export function validateLinkNodes(
  obj: LinkDatum,
): obj is LinkDatum & { source: NodeDatum; target: NodeDatum } {
  return isNode(obj.source) && isNode(obj.target);
}

export function filterRawDataByDatum(
  data: RawDataDTO[] | undefined,
  selectedDatum: Datum | null,
) {
  if (!data) {
    return;
  }

  if (selectedDatum) {
    const { clusterId, type } = selectedDatum;

    switch (type) {
      case "cluster":
        return data.filter(
          (datum) => datum.coordination_cluster_ids?.includes(clusterId),
        );
      case "edge":
        if (!validateLinkNodes(selectedDatum)) {
          return [];
        }
        const { source, target } = selectedDatum;
        return data.filter(
          ({ user_rid }) => user_rid === source.id || user_rid === target.id,
        );
      case "node":
        return data.filter(({ user_rid }) => user_rid === selectedDatum.id);
    }
  }
  return data;
}

export function getCoordinationTypesFromEvidences(evidences: string[]) {
  const coordinationTypesSet = evidences.reduce((set, evidence) => {
    Object.entries(COORDINATION_TYPE_EVIDENCES_MAP).forEach(
      ([coordinationType, evidenceList]) => {
        if (evidenceList.includes(evidence as Evidence)) {
          set.add(
            coordinationTypeNames[coordinationType as CoordinationTypeKeys],
          );
        }
      },
    );
    return set;
  }, new Set<string>());

  return Array.from(coordinationTypesSet);
}
