import { styled } from "@linaria/react";
import { type StyledComponent, type FunctionComponent } from "react";

import { type InsightDTO } from "~/dto";
import { text } from "~/styles/typography";
import { formatList } from "~/utils/stringUtils";

import Icon from "../library/Icon";

import InsightsDialogTrigger from "./InsightsDialogTrigger";
import { type InsightKeys, type InsightsType, type InsightUid } from "./types";

interface TagSetProps extends StyledComponent {
  insight: InsightDTO;
  hasWriteAccess?: boolean;
  type?: InsightsType;
  uid?: InsightUid;
  refresh?: () => void;
}

const iconMap: Record<InsightKeys, UntitledIcon> = {
  subcategory: "user-square",
  country_of_origin: "flag-01",
  operating_country: "globe-02",
  state_association: "building-08",
  language: "translate-01",
  name: "building-01",
  region: "globe-05",
  role: "building-05",
};

const TagSetContainer = styled.li`
  padding: var(--spacing-md) 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-lg);
  ${text.xs.regular};

  &[data-pending="true"] dl {
    &,
    [data-icon] {
      color: var(--text-color-quaternary);
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-md);

    div {
      display: flex;
      flex-wrap: nowrap;
      gap: var(--spacing-xs);
    }

    [data-icon] {
      color: var(--color-profile-insights-icon);
      font-display: block;
      ${text.md.regular};
      user-select: none;
    }
  }

  button {
    align-self: flex-start;
  }
`;

function fromSnakeCase(str: string) {
  return str.split("_").join(" ");
}

const TagSet: FunctionComponent<TagSetProps> = (props) => {
  const { insight, hasWriteAccess, type, uid, ...otherProps } = props;
  const { additional_properties: additional_properties, analyzable_id } =
    insight;
  const tags = additional_properties
    ? (Object.keys(iconMap) as InsightKeys[]).filter(
        (t) =>
          additional_properties?.[t] !== null &&
          additional_properties?.[t] !== undefined,
      )
    : [];

  return (
    <TagSetContainer
      data-pending={insight.status === "pending"}
      {...otherProps}
    >
      <dl>
        {tags.map((t) => (
          <div key={t}>
            <dt title={fromSnakeCase(t)}>
              <span data-at-only>{fromSnakeCase(t)}</span>
              <Icon family="untitled" name={iconMap[t]} />
            </dt>
            <dd>
              {t === "language" || t === "subcategory"
                ? formatList(additional_properties[t]) ?? ""
                : additional_properties[t]}
            </dd>
          </div>
        ))}
      </dl>
      {hasWriteAccess && analyzable_id && (
        <InsightsDialogTrigger
          id={`insight-panel-${analyzable_id.slice(1)}-edit`}
          insight={insight}
          mode="Edit"
          onSuccess={props.refresh}
          type={type}
          uid={uid}
        />
      )}
    </TagSetContainer>
  );
};

export default TagSet;
