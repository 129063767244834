import { styled } from "@linaria/react";
import {
  type ReactNode,
  useCallback,
  useState,
  type FunctionComponent,
  type MouseEventHandler,
} from "react";

import { text } from "~/styles/typography";

import Icon from "./library/Icon";

export interface BaseAccordionProps {
  beforeTitleElement?: ReactNode;
  headerId?: string;
  title: string;
  children: ReactNode;
}

interface ControlledProps {
  isOpen: boolean;
  onClickHeader: MouseEventHandler<HTMLButtonElement>;
}

interface ControlledRefProps {
  isOpen?: undefined;
  onClickHeader?: never;
}

type AccordionProps = BaseAccordionProps &
  (ControlledProps | ControlledRefProps);

const Chevron = styled(Icon)`
  transition: all 0.25s ease-in-out !important;

  &[data-expanded="true"] {
    transform: rotate(-180deg) !important;
  }

  &[data-expanded="false"] {
    transform: rotate(0deg) !important;
  }

  @media (prefers-reduced-motion: true) {
    transition: none;
  }
`;

const Container = styled.div`
  border: var(--border-standard);
  border-radius: var(--border-radius-default);
`;

const Header = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  ${text.sm.regular};
  ${text.md.semibold};
  cursor: pointer;
  background-color: var(--color-white);
  padding: var(--spacing-lg);

  &[data-expanded="true"] {
    border-radius: var(--border-radius-dialog) var(--border-radius-dialog) 0px
      0px;
    border-bottom: var(--border-group);
  }

  &[data-expanded="false"] {
    border-radius: var(--border-radius-dialog);
  }
`;

const ContentWrapper = styled.div`
  overflow: hidden;
  background-color: var(--color-white);
  border-radius: 0 0 var(--border-radius-dialog) var(--border-radius-dialog);

  &[data-expanded="true"] {
    max-height: 200px;
  }

  &[data-expanded="false"] {
    max-height: 0px;
  }
`;

const Content = styled.div`
  padding: var(--spacing-md);
  line-height: 1.5;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
`;

const Accordion: FunctionComponent<AccordionProps> = (props) => {
  const {
    beforeTitleElement,
    headerId,
    title,
    children,
    isOpen,
    onClickHeader,
  } = props;

  const [isExpanded, setExpand] = useState<boolean>(false);

  const handleExpandToggle = useCallback(() => {
    setExpand(!isExpanded);
  }, [isExpanded]);

  return (
    <Container>
      <Header
        data-expanded={isOpen ?? isExpanded}
        data-for-header={headerId}
        onClick={onClickHeader ?? handleExpandToggle}
      >
        <TitleContainer>
          {beforeTitleElement}
          <div>{title}</div>
        </TitleContainer>
        <Chevron
          data-expanded={isOpen ?? isExpanded}
          family="untitled"
          name="chevron-down"
        />
      </Header>

      {(isOpen || isExpanded) && (
        <ContentWrapper data-expanded={isOpen ?? isExpanded}>
          <Content data-slot="content">{children}</Content>
        </ContentWrapper>
      )}
    </Container>
  );
};

export default Accordion;
