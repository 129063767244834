import { styled } from "@linaria/react";
import { type FunctionComponent, type ReactNode, useId } from "react";

import { text } from "~/styles/typography";

import { XButton } from "./ModalDialog";
import Icon from "./library/Icon";

export enum NotifyType {
  Success,
  Warning,
  Error,
  Info,
}

const StyledCard = styled.div`
  max-width: 500px;
  padding: var(--spacing-xl) var(--spacing-md);
  border-radius: var(--border-radius-default);
  display: flex;
  align-items: center;
  gap: var(--spacing-md);

  &[data-notifytype="${NotifyType.Success}"] {
    background-color: var(--color-success-200);
  }
  &[data-notifytype="${NotifyType.Warning}"] {
    background-color: var(--color-warning-200);
  }
  &[data-notifytype="${NotifyType.Error}"] {
    background-color: var(--color-error-200);
  }
  &[data-notifytype="${NotifyType.Info}"] {
    background-color: var(--color-info-200);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
`;

const SuccessIcon = styled(Icon)`
  color: var(--color-success-600);
  height: 15px;
  width: 15px;
  margin-right: var(--spacing-md);
`;

const WarningIcon = styled(Icon)`
  color: var(--color-warning-600);
  height: 15px;
  width: 15px;
  margin-right: var(--spacing-md);
`;

const ErrorIcon = styled(Icon)`
  color: var(--color-error-600);
  height: 15px;
  width: 15px;
  margin-right: var(--spacing-md);
  flex-shrink: 0;
`;

const InfoIcon = styled(Icon)`
  color: var(--color-info-600);
  height: 15px;
  width: 15px;
  margin-right: var(--spacing-md);
`;

const MessageContainer = styled.div`
  align-items: center;
  display: flex;
  ${text.sm.regular};
`;

const StyledXButton = styled(XButton)`
  align-self: flex-start;
  position: relative;
  top: calc(-1 * var(--spacing-xl));
`;

interface DismissableMessageProps {
  children: ReactNode;
  onClose: () => void;
  type: NotifyType;
}

function getNotifyType(type: NotifyType) {
  switch (type) {
    case NotifyType.Success:
      return <SuccessIcon family="untitled" name="check-circle" />;
    case NotifyType.Warning:
      return <WarningIcon family="untitled" name="alert-square" />;
    case NotifyType.Error:
      return <ErrorIcon family="untitled" name="alert-octagon" />;
    case NotifyType.Info:
      return <InfoIcon family="untitled" name="info-circle" />;
  }
}

const DismissableMessage: FunctionComponent<DismissableMessageProps> = (
  props,
) => {
  const { children, onClose, type } = props;
  const id = useId();
  const Icon = getNotifyType(type);
  let message;

  switch (type) {
    case NotifyType.Error: {
      message = "Error";
      break;
    }
    case NotifyType.Info: {
      message = "Info";
      break;
    }
    case NotifyType.Success: {
      message = "Success";
      break;
    }
    case NotifyType.Warning: {
      message = "warning";
      break;
    }
  }

  return (
    <StyledCard
      aria-labelledby={`${id}-title`}
      data-notifytype={type}
      role="alert"
    >
      <span data-at-only id={`${id}-title`}>
        {message}
      </span>
      <Container>
        <MessageContainer>
          {Icon}
          {children}
        </MessageContainer>
        <StyledXButton onPress={onClose} />
      </Container>
    </StyledCard>
  );
};

export default DismissableMessage;
