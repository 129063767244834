import { useEffect, useState } from "react";

import { useLogger } from "~/hooks/useLogger";

import type {
  ProcessedData,
  AllAvailableEdges,
  NodesByEdgeCount,
} from "../types";

import type { WorkerExport } from "./getNodesByEdgeCount.worker.ts";

export const useNodesByEdgeCount = (
  data: ProcessedData | undefined,
  selectedEdge: AllAvailableEdges[],
  setNumOfMinEdge: (
    selection: string | string[] | ((prev: string[]) => string[]),
  ) => void,
) => {
  const logger = useLogger();
  const [nodesByEdgeCount, setNodesByEdgeCount] = useState<
    NodesByEdgeCount | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!data || !selectedEdge.length) {
      setNodesByEdgeCount(undefined);
      setNumOfMinEdge("0");
      setIsLoading(false);
      return;
    }

    const worker = new Worker(
      new URL("./getNodesByEdgeCount.worker.ts", import.meta.url),
      {
        type: "module",
      },
    );

    setIsLoading(true);
    worker.postMessage(data);

    worker.onmessage = (event: MessageEvent<WorkerExport>) => {
      const { nodesByEdgeCount } = event.data;

      setNodesByEdgeCount(nodesByEdgeCount);
      setNumOfMinEdge((prev) => {
        const edgeCounts = Object.keys(nodesByEdgeCount);
        return edgeCounts.includes(prev[0]) ? [prev[0]] : [edgeCounts[0]];
      });
      setIsLoading(false);
    };

    worker.onerror = (error) => {
      logger.error("Error in worker:", error);
      setNodesByEdgeCount(undefined);
      setNumOfMinEdge("0");
      setIsLoading(false);
    };

    return () => {
      worker.terminate();
    };

    /* eslint-disable-next-line react-hooks/exhaustive-deps
    -- This is to prevent setNumOfMinEdge from re-rendering this useEffect. Everytime
    we update the minEdge via dropdown, it will update setNumOfMinEdge. We only want to re-render this useEffect only
    when we have new data (selectedEdge.length will also update date so its okay to be in here)
    */
  }, [data, logger, selectedEdge.length]);

  return { isLoading, nodesByEdgeCount };
};
