import { styled } from "@linaria/react";
import { type FunctionComponent } from "react";

import { type InsightListDTO, type UserDetailsDTO } from "~/dto";
import { useCurrentUser } from "~/hooks/useCurrentUser";
import { useAccountInsights, useDomainInsights } from "~/hooks/useInsights";
import { text } from "~/styles/typography";

import Section, { Heading } from "../Section";
import { ButtonSize } from "../library/Button";

import InsightsDialogTrigger from "./InsightsDialogTrigger";
import TagSet from "./TagSet";
import { type InsightsType } from "./types";

interface AccountInsightsProps {
  userDetails: UserDetailsDTO;
}

interface DomainInsightsProps {
  domain: string;
}

const StyledHeading = styled(Heading)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${text.sm.regular};
  ${text.md.semibold};

  img {
    margin-right: var(--spacing-md);
    vertical-align: text-bottom;
  }

  button {
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledInsightsPanel = styled(Section)`
  ${text.xs.regular};

  ${StyledHeading} {
    margin-bottom: var(--spacing-lg);
  }

  :not(:has(li)) {
    /* for a11y purposes we always render a <section>, but for product
     * purposes we want to hide that <section> and only show an add-insights
     * button when no insights are present */
    display: contents;

    && button {
      margin-right: auto;
    }
  }

  li + li {
    border-top: var(--border-divider);
  }
`;

type InsightsPanelProps = {
  isLoading: boolean;
  data?: InsightListDTO;
  error?: string;
  id: string;
  type: InsightsType;
  uid: string | UserDetailsDTO;
  refresh: () => void;
};

const useInsightsPanel = (props: InsightsPanelProps) => {
  const { isLoading, data, error, id, type, uid, ...otherProps } = props;
  const { isAnalyst, isGlobalAdmin, isSeniorAnalyst } = useCurrentUser();
  const hasWriteAccess = isAnalyst() || isSeniorAnalyst() || isGlobalAdmin();
  const { data: results = [] } = data ?? {};
  /* don't show any data with null values */
  const result = results?.map((r) => ({
    ...r,
    additional_properties: Object.fromEntries(
      Object.entries(r.additional_properties)
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars
                -- we don't care about keys here */
        .filter(([_k, v]) => v !== null),
    ),
  }));

  return {
    data: result,
    error,
    hasWriteAccess,
    id,
    isLoading,
    otherProps,
    type,
    uid,
  };
};

const InsightsPanel: FunctionComponent<InsightsPanelProps> = (props) => {
  const { data, hasWriteAccess, id, isLoading, type, uid } =
    useInsightsPanel(props);
  if (isLoading) {
    return null;
  }

  const insightUid = typeof uid === "string" ? uid : uid.id;

  return (
    <StyledInsightsPanel aria-busy={isLoading} aria-labelledby={id}>
      {data.length === 0 ? (
        <>
          <StyledHeading data-at-only id={id}>
            Analyst Insights
          </StyledHeading>
          {hasWriteAccess && (
            <InsightsDialogTrigger
              data-size={ButtonSize.sm}
              id={`insight-panel-${insightUid}-create`}
              mode="Create"
              onSuccess={props.refresh}
              type={props.type}
              uid={uid}
            />
          )}
        </>
      ) : (
        <>
          <StyledHeading id={id}>
            <span>
              <img aria-hidden="true" src="/assets/images/insights.svg" />
              Analyst Insights
            </span>
          </StyledHeading>
          <ul>
            {data.map((r) => (
              <TagSet
                key={r.analyzable_id}
                hasWriteAccess={hasWriteAccess}
                insight={r}
                refresh={props.refresh}
                type={type}
                uid={uid}
              />
            ))}
          </ul>
        </>
      )}
    </StyledInsightsPanel>
  );
};

const DomainInsights: FunctionComponent<DomainInsightsProps> = (props) => {
  const { domain } = props;
  const domainInsights = useDomainInsights(domain);
  const elId = `insights-panel-domain-${domain}`;

  return (
    <InsightsPanel {...domainInsights} id={elId} type="domain" uid={domain} />
  );
};

const AccountInsights: FunctionComponent<AccountInsightsProps> = (props) => {
  const { userDetails } = props;
  const { id } = userDetails;
  const accountInsights = useAccountInsights(id);

  if (!id) {
    return null;
  }

  const elId = `insights-panel-account-${id}`;

  return (
    <InsightsPanel
      {...accountInsights}
      id={elId}
      type="account"
      uid={userDetails}
    />
  );
};

export { AccountInsights, DomainInsights };
