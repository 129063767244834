import {
  type FunctionComponent,
  type ReactNode,
  useCallback,
  useContext,
  createContext,
} from "react";
import { useSWRConfig } from "swr";

import ErrorMessageContainer from "~/components/ErrorMessageContainer";
import LoadingIndicator from "~/components/library/LoadingIndicator/LoadingIndicator";
import {
  type ConversationDTO,
  ConversationListValidator,
  ConversationValidator,
} from "~/dto/conversation";
import { type ProjectDTO } from "~/dto/project";
import { MILLISECONDS_IN_DAY, isValidDate } from "~/utils/datetime";
import { getMessage } from "~/utils/error";

import { useProjects } from "./useProjects";
import {
  useRemoteCollection,
  useRemoteObjectFromCollection,
  useRemoteObject,
} from "./useRemoteData";
import {
  defaultUpdateFetcherFactory,
  type RemoveFetcherFactory,
  type UpdateBuilderFactory,
  type UpdateFetcherFactory,
} from "./useRemoteData/utils";

export type UpdateConversationOptions = {
  refresh?: boolean;
};

type RemoteConversationObject = ReturnType<
  typeof useRemoteObjectFromCollection<ConversationDTO>
>;

export type ConversationQuery = ReturnType<typeof useConversationMutations>;

const POLL_MS = 1000 * 30;

export enum ConversationCreateErrors {
  QuerySyntaxError,
  TimeRangeError,
  DataSourceQuotaLimitError,
  UnknownError,
}

export type ConversationParams = {
  conversationId: string;
  projectName: string;
};

type ConversationContextShape = Omit<
  ConversationQuery,
  "data" | "error" | "isLoading"
> & {
  conversation: ConversationDTO;
  project?: ProjectDTO;
};

const ConversationContext = createContext<ConversationContextShape | undefined>(
  undefined,
);

export const ConversationContextProvider: FunctionComponent<{
  children: ReactNode;
  project?: ProjectDTO;
  query: ConversationQuery;
  loadingFallback?: ReactNode;
  renderError?: (errorInfo: { message: string }) => ReactNode;
}> = (props) => {
  const { children, project, query, loadingFallback, renderError } = props;
  const { data, error, isLoading, ...mutations } = query;

  if (isLoading) {
    return loadingFallback ?? <LoadingIndicator />;
  }
  if (error || !data) {
    const message = error ? getMessage(error) : "Conversation not found";
    return renderError ? (
      renderError({ message })
    ) : (
      <ErrorMessageContainer>{message}</ErrorMessageContainer>
    );
  }

  return (
    <ConversationContext.Provider
      value={{
        conversation: data,
        project: project,
        ...mutations,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export const useCurrentConversation = () => {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error(
      "useCurrentConversation must be used within a ConversationContextProvider",
    );
  }
  return context;
};

/* conversations are fetched at one URL but updated/deleted at a different one,
 * which means we need a couple of custom fetchers */
const conversationCreateFetcherFactory: UpdateFetcherFactory<
  Partial<ConversationDTO>
> = (authToken, payload) => async (_request, init) => {
  if (!authToken) {
    throw new Error(`can't use fetchers to make unauthenticated API requests`);
  }

  const response = await window.fetch("/api/v2/conversations", {
    ...init,
    body: JSON.stringify(payload),
    headers: {
      ...init?.headers,
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": authToken,
    },
    method: "POST",
  });

  if (response.status === 400 || response.status === 422) {
    const payload = await response.json();

    if (response.status === 422) {
      throw new Error(
        String(ConversationCreateErrors.DataSourceQuotaLimitError),
      );
    }

    if (payload.msg.includes("Your boolean string could not be validated")) {
      throw new Error(String(ConversationCreateErrors.QuerySyntaxError));
    }

    if (payload.msg.includes("Your start date or end date is invalid")) {
      throw new Error(String(ConversationCreateErrors.TimeRangeError));
    }

    throw new Error(String(ConversationCreateErrors.UnknownError));
  }

  return Promise.resolve(response) as Promise<never>;
};

const conversationUpdateFetcherFactory: UpdateFetcherFactory<
  Partial<ConversationDTO>,
  RequestInfo,
  never
> = (authToken, payload) => async (request, init) => {
  const req = String(request);
  const path = req.split("?")[0];
  const uuid = req.split("/").at(-1);

  return defaultUpdateFetcherFactory(authToken, payload)(
    `${path}/${uuid}`,
    init,
  );
};
const conversationRemoveFetcherFactory: RemoveFetcherFactory =
  (authToken) => async (request, init) => {
    if (!authToken) {
      throw new Error(
        `can't use fetchers to make unauthenticated API requests`,
      );
    }

    const req = String(request);
    const path = req.split("?")[0];
    const uuid = req.split("/").at(-1);

    return window.fetch(`${path}/${uuid}`, {
      ...init,
      headers: {
        ...init?.headers,
        "X-CSRF-TOKEN": authToken,
      },
      method: "DELETE",
    }) as Promise<never>;
  };

const useConversations = (project_id?: string, poll?: boolean) => {
  const apiEndpoint = project_id
    ? `/api/v2/conversations?project_id=${project_id}`
    : undefined;
  return useRemoteCollection<ConversationDTO>(apiEndpoint, {
    cacheOpts: poll
      ? {
          refreshInterval: POLL_MS,
        }
      : {},
    createBuilder: () => (payload: Partial<ConversationDTO>) => ({
      ...payload,
      status: "PENDING",
    }),
    createFetcher: conversationCreateFetcherFactory,
    removeFetcher: conversationRemoveFetcherFactory,
    schemaValidator: ConversationListValidator,
    updateFetcher: conversationUpdateFetcherFactory,
  });
};

const getValidTimestamp = (timestamp: string | undefined) => {
  if (!timestamp) {
    return undefined;
  }
  const date = new Date(timestamp);
  if (isValidDate(date)) {
    return timestamp;
  }
  if (timestamp.endsWith("T")) {
    // timestamp is missing the time because the user wanted to use the default
    return timestamp + "00:00:00";
  }
  return undefined;
};

const conversationUpdateBuilderFactory: UpdateBuilderFactory<
  ConversationDTO,
  Partial<ConversationDTO>
> = () => (current, update) => ({
  ...current,
  ...update,
  start_date:
    getValidTimestamp(update.start_date) ??
    new Date(Date.now() - 3 * MILLISECONDS_IN_DAY).toISOString(),
  end_date: getValidTimestamp(update.end_date) ?? new Date().toISOString(),
});

const useConversationMutations = (
  conversationId: string,
  remoteObject: RemoteConversationObject,
) => {
  const { update } = remoteObject;
  const { mutate } = useSWRConfig();

  const updateConversation = useCallback(
    async (
      conversation: Partial<ConversationDTO>,
      options: UpdateConversationOptions = {},
    ) => {
      await update(conversation);

      if (options.refresh) {
        /* all other data belonging to this conversation (raw-data records, topic
         * list, etc.) is now invalid; clear it from our cache.  since we use api
         * endpoint url as our swr cache key, and since we pass conversation id
         * as a query param to those endpoints, we can invalidate all cache
         * entries containing the conversation's uuid as part of their key */
        mutate((key) => String(key).includes(conversationId), undefined, {
          revalidate: false,
        });
      }
    },
    [update, mutate, conversationId],
  );

  return {
    ...remoteObject,
    update: updateConversation,
    rerun: useCallback(
      async (conversation: ConversationDTO) => {
        await updateConversation(
          {
            ...conversation,
            rerun: true,
          },
          { refresh: true },
        );
      },
      [updateConversation],
    ),
  };
};

type BaseConversationProjectOptions = {
  poll?: boolean;
};

type ConversationProjectIdOptions = BaseConversationProjectOptions & {
  projectId: string;
};

type ConversationProjectNameOptions = BaseConversationProjectOptions & {
  projectName: string;
};

export type ConversationProjectOptions =
  | ConversationProjectIdOptions
  | ConversationProjectNameOptions;

const useConversationFromProject = (
  conversationId: string,
  options: ConversationProjectOptions,
) => {
  const projects = useProjects();
  const projectId =
    "projectId" in options
      ? options.projectId
      : projects.data?.find((p) => p.name === options.projectName)?.id;
  const apiEndpoint = projectId
    ? `/api/v2/conversations?project_id=${projectId}`
    : undefined;
  const remoteObject = useRemoteObjectFromCollection<ConversationDTO>(
    apiEndpoint,
    conversationId,
    {
      cacheOpts: options.poll
        ? {
            refreshInterval: POLL_MS,
          }
        : {},
      removeFetcher: conversationRemoveFetcherFactory,
      schemaValidator: ConversationListValidator,
      updateBuilder: conversationUpdateBuilderFactory,
      updateFetcher: conversationUpdateFetcherFactory,
    },
  );

  return useConversationMutations(conversationId, remoteObject);
};

/* the backend queries airflow to get the DAG step in the GET /conversation/id endpoint, so we need to
 * hit that endpoint directly to get the conversation's DAG step, resulting in the need for this hook
 * instead of using useConversationFromProject, which uses useRemoteObjectFromCollection and gets optimistic updates
 */
const useSingleConversation = (conversationId: string, poll?: boolean) => {
  const { data } = useRemoteObject<ConversationDTO>(
    `/api/v2/conversations/${conversationId}`,
    {
      schemaValidator: ConversationValidator,
      cacheOpts: poll
        ? {
            refreshInterval: POLL_MS,
          }
        : {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
          },
    },
  );
  return data;
};

export {
  useConversations,
  useConversationFromProject,
  useSingleConversation,
  useConversationMutations,
};
