import type { NrmsConversationThemeMetricsDTO } from "~/dto/nrmsConversationThemeMetrics";

export const EvergreenThemeLabels: {
  [key in NrmsConversationThemeMetricsDTO["theme_name"]]: string;
} = {
  BOYCOTT: "Boycott",
  DEI: "DEI",
  ELECTION_DENIAL: "Election Denial",
  FINANCE: "Finance",
  QANON: "QAnon",
  SHORTSELLING: "Short Selling",
  UNION: "Union",
};
