import { styled } from "@linaria/react";
import {
  type FunctionComponent,
  type ReactNode,
  type StyledComponent,
} from "react";
import {
  type PopoverProps as ReactAriaPopoverProps,
  Button,
  Dialog,
  DialogTrigger,
  Popover as ReactAriaPopover,
} from "react-aria-components";

import { OverlayArrow } from "~/styles/overlays";
import { text } from "~/styles/typography";

type PopoverProps = Omit<ReactAriaPopoverProps, "children"> &
  StyledComponent & {
    children: ReactNode;
    "aria-label"?: string;
    triggerText: ReactNode;
  };

const TriggerButton = styled(Button)`
  background-color: transparent;
`;

const PopoverArrow = styled(OverlayArrow)`
  --overlay-arrow-offset: -7px;

  isolation: isolate;
  z-index: 1;

  svg {
    --stroke-width: 2px;

    fill: var(--background-color-primary);
    stroke-width: var(--stroke-width);
    stroke: var(--background-color-quaternary);
    clip-path: polygon(
      0 var(--stroke-width),
      100% var(--stroke-width),
      100% 100%,
      0 100%
    );
  }
`;

const PopoverDialog = styled(Dialog)`
  /* we will want to focus some content inside the popover but not the popover itself */
  outline: none;
  border: 1px solid var(--border-color-primary);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-md);
  box-shadow: var(--shadow-md);
  /* don't let content bleed out of the dialog.  can't use overflow though
   * or else the box shadow goes away */
  clip-path: polygon(
    -3px -3px,
    calc(100% + 3px) -3px,
    calc(100% + 3px) calc(100% + 3px),
    -3px calc(100% + 3px)
  );
  background-color: var(--background-color-primary);
  ${text.md.regular};
`;

const Popover: FunctionComponent<PopoverProps> = (props) => {
  const {
    "aria-label": ariaLabel,
    children,
    triggerText,
    ...otherProps
  } = props;

  return (
    <DialogTrigger>
      <TriggerButton aria-label={ariaLabel}>{triggerText}</TriggerButton>
      <ReactAriaPopover {...otherProps} placement="bottom">
        <PopoverArrow data-placement="bottom" />
        <PopoverDialog>{children}</PopoverDialog>
      </ReactAriaPopover>
    </DialogTrigger>
  );
};

export default Popover;
