import { useMemo } from "react";

export type PaginationOptions = {
  /** an ordered list of human-visible column names (and values) used for filtering db records.  entries are boolean-AND */
  columnSearch?: {
    column: string;
    query: string;
  }[];
  /** any-column search string */
  globalSearch?: string;
  /** number of records to return */
  limit?: number;
  /** database cursor start position */
  offset?: number;
  /** an ordered list of human-visible column names (and directions) used for sorting db records */
  order?: {
    column: string;
    direction: "asc" | "desc";
  }[];
};

const usePagination = (opts: PaginationOptions, params?: URLSearchParams) => {
  const searchParams = useMemo(() => {
    const searchParams = params ?? new URLSearchParams();

    opts.columnSearch?.forEach((opt) =>
      searchParams.append(
        `search`,
        // We have to change the value of the search param here so that it matches what is expected in atlas searchable_columns_mapping
        opt.column === "firstNgram"
          ? `narrative:${String(opt.query)}`
          : `${String(opt.column)}:${String(opt.query)}`,
      ),
    );

    if ("globalSearch" in opts) {
      searchParams.set("global_search", String(opts.globalSearch));
    }

    if ("limit" in opts) {
      searchParams.set("limit", String(opts.limit));
    }

    if ("offset" in opts) {
      searchParams.set("record_offset", String(opts.offset));
    }

    opts.order?.forEach((opt) =>
      searchParams.append("order_by", `${String(opt.column)}:${opt.direction}`),
    );

    return searchParams;
  }, [opts, params]);

  return {
    searchParams,
  };
};

export { usePagination };
