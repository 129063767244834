import { type FunctionComponent, useCallback, useMemo } from "react";
import { useCollator } from "react-aria";
import { useNavigate } from "react-router-dom";
import type { Key } from "react-stately";

import { useCurrentUser } from "~/hooks/useCurrentUser";
import { useOrganizations } from "~/hooks/useOrganization";

import { Option, Select } from "./library/Select";

const useOrgSwitcher = () => {
  const navigate = useNavigate();
  const { isGlobalAdmin, organizationId, switchOrganization } =
    useCurrentUser();
  const organizations = useOrganizations();
  const collator = useCollator({ usage: "sort", sensitivity: "base" });
  const sortedOrgs = useMemo(
    () => organizations?.toSorted((a, b) => collator.compare(a.name, b.name)),
    [collator, organizations],
  );

  const onChange = useCallback(
    async (key: Key) => {
      const selectedOrg = organizations?.find((org) => org.id === String(key));

      if (selectedOrg) {
        await switchOrganization(selectedOrg.id);
        navigate("/");
      }
    },
    [navigate, organizations, switchOrganization],
  );

  return {
    enabled: isGlobalAdmin(),
    onChange,
    orgId: organizationId,
    organizations: sortedOrgs,
  };
};

const UserOrganizationSwitcher: FunctionComponent = () => {
  const { enabled, onChange, orgId, organizations } = useOrgSwitcher();

  if (!enabled) {
    return null;
  }

  return (
    <Select
      label="global-admin org switcher"
      onSelectionChange={onChange}
      selectedKey={orgId}
    >
      {organizations?.map((organization) => (
        <Option key={organization.id} id={organization.id}>
          {organization.name}
        </Option>
      ))}
    </Select>
  );
};

export default UserOrganizationSwitcher;
