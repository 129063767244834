import { useMemo } from "react";

import { getFinalTopicArray } from "~/components/TopicsOverview";
import { type TopicInsightCategory } from "~/constants";
import { type TopicListDTO } from "~/dto";

import { type ConversationFilters } from "./useConversationFilters";
import { useRiskCategories, useTopicInsights } from "./useInsights";
import { useConversationTopics } from "./useNarrative";

export const useBubbleGraph = (
  conversationId: string,
  topicsList: TopicListDTO,
) => {
  const topic_ids = useMemo(() => {
    return topicsList.topics.map((t) => t.rid);
  }, [topicsList]);

  const { data } = useRiskCategories();
  const risk_categories_map = data?.data;

  const { data: topicInsights, isLoading } = useTopicInsights(conversationId);
  /* if no insights are returned but we get a 200 back, return an empty array */
  /* if we don't get a 200 back, return undefined */
  const finalTopicArray = useMemo(() => {
    if (topicInsights?.status === 200) {
      return getFinalTopicArray(
        topicsList,
        risk_categories_map,
        topicInsights?.data.filter((t) => topic_ids.includes(t.topic)) ?? [],
      );
    } else {
      return undefined;
    }
  }, [
    risk_categories_map,
    topicsList,
    topicInsights?.data,
    topicInsights?.status,
    topic_ids,
  ]);

  return {
    finalTopicArray,
    isLoading,
  };
};

export function useTopicData(
  conversationId: string,
  filters: ConversationFilters,
) {
  const allTopicsData = useConversationTopics(conversationId);
  const filteredTopicsData = useConversationTopics(conversationId, filters);

  const { finalTopicArray, isLoading: isBubbleGraphLoading } = useBubbleGraph(
    conversationId,
    allTopicsData,
  );

  const topicData = useMemo(() => {
    /* wait until finalTopicArray has returned before rendering anything else */
    if (!finalTopicArray) {
      return [];
    }

    const activeTopicsByRid = new Map(
      filteredTopicsData.topics.map((t) => [t.rid, t]),
    );

    return allTopicsData.topics.map((el) => {
      const postDates = activeTopicsByRid.has(el.rid)
        ? /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                -- we know this is defined because we just checked for it above */
          activeTopicsByRid.get(el.rid)!.post_dates
        : el.post_dates;
      const subcategory =
        finalTopicArray.find((t) => t.topicRid === el.rid)?.subcategory ??
        "Default";

      return {
        primaryTopicPhrase: el.ngrams[0],
        fullTopicPhrase: el.ngrams.join(", "),
        postDates,
        topicPostCount: postDates.length,
        rid: el.rid,
        isActive: activeTopicsByRid.has(el.rid),
        country_of_origin: finalTopicArray.find((t) => t.topicRid === el.rid)
          ?.country_of_origin,
        subcategory: subcategory as TopicInsightCategory,
      };
    });
  }, [finalTopicArray, filteredTopicsData.topics, allTopicsData.topics]);

  return {
    topicData,
    isLoading:
      isBubbleGraphLoading ||
      allTopicsData.isLoading ||
      filteredTopicsData.isLoading,
  };
}

export function useSubcategoryByTopic(
  conversationId: string,
  filters: ConversationFilters,
) {
  const filteredTopicsData = useConversationTopics(conversationId, filters);
  const { finalTopicArray, isLoading: isBubbleGraphLoading } = useBubbleGraph(
    conversationId,
    filteredTopicsData,
  );

  const subcategoryByTopic = useMemo(() => {
    if (!finalTopicArray) {
      return undefined;
    }

    return filteredTopicsData.topics.reduce<Record<string, string>>(
      (acc, el) => {
        const subcategory =
          finalTopicArray.find((t) => t.topicRid === el.rid)?.subcategory ??
          "Default";

        return {
          ...acc,
          [el.rid]: subcategory,
        };
      },
      {},
    );
  }, [finalTopicArray, filteredTopicsData.topics]);

  return {
    subcategoryByTopic,
    isLoading: isBubbleGraphLoading || filteredTopicsData.isLoading,
  };
}
