/**
 * Returns a new array with the elements split into groups of the given size.
 * If the array can't be split evenly, the final chunk will contain the
 * remaining elements. If the array is empty or the size is less than 1, an
 * empty array is returned.
 */
export function chunk<T>(array: T[], size: number): T[][] {
  const result: T[][] = [];
  if (size < 1) {
    return result;
  }
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

// TODO: 2024-03-18: remove these set operation functions once firefox takes
// them out from behind a feature flag

/** returns any item in both s1 and s2 */
export function setIntersection<T>(s1: Set<T>, s2: Set<T>) {
  return new Set<T>([...s1].filter((el) => s2.has(el)));
}

/** returns any item in s1 not in s2 */
export function setDifference<T>(s1: Set<T>, s2: Set<T>) {
  return new Set<T>([...s1].filter((el) => !s2.has(el)));
}

/** returns the minimum and maximum values of a data set */
export function extent<T>(
  data: T[],
  getter: (datum: T) => number,
): [number, number] {
  if (data.length === 0) {
    throw new Error("Data array must have at least one element");
  }
  const values: number[] = data.map(getter);
  const minValue: number = Math.min(...values);
  const maxValue: number = Math.max(...values);
  return [minValue, maxValue];
}

function isArrayOf<T>(
  array: unknown[],
  typeCheck: (item: unknown) => item is T,
): array is T[] {
  if (!Array.isArray(array)) {
    return false;
  }
  return array.every(typeCheck);
}

export function isArrayOfStrings(array: unknown[]): array is string[] {
  return isArrayOf(array, (item): item is string => typeof item === "string");
}

export function isArrayOfNumbers(array: unknown[]): array is number[] {
  return isArrayOf(array, (item): item is number => typeof item === "number");
}
