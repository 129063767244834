import { styled } from "@linaria/react";

export const StyledLoadingIndicator = styled.svg`
  // “--duration-value” is used as the incoming property so we can recalculate
  // --duration when user prefers-reduced-motion
  --duration: var(--duration-value);

  width: var(--width);
  color: var(--color);
`;

export const Path = styled.path`
  fill: none;
  stroke: currentColor;
  opacity: 0;

  animation-name: block-animated;
  animation-duration: var(--duration);
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;

  @media (prefers-reduced-motion: reduce) {
    animation-name: block-reduced-motion;
    stroke-width: 10;
    opacity: 1;

    --duration: calc(var(--duration-value) * 2);
  }

  &:nth-of-type(1) {
    animation-delay: 0s;
  }

  &:nth-of-type(2) {
    animation-delay: calc(var(--duration) * 0.1);
  }

  &:nth-of-type(3) {
    animation-delay: calc(var(--duration) * 0.2);
  }

  &:nth-of-type(4) {
    animation-delay: calc(var(--duration) * 0.3);
  }

  @keyframes block-animated {
    25% {
      opacity: 0;
      stroke-width: 0;
    }

    50% {
      opacity: 1;
      stroke-width: 10;
    }

    75% {
      opacity: 0;
      stroke-width: 0;
    }
  }

  @keyframes block-reduced-motion {
    25% {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }

    75% {
      opacity: 0.5;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
