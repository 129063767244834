import { styled } from "@linaria/react";
import { type FunctionComponent, useCallback, useState } from "react";

import Button, { ButtonKind, ButtonSize } from "../library/Button";
import IconButton from "../library/IconButton";

import AccountInsightsDialog from "./AccountInsightsDialog";
import CreateInsightsDialog from "./CreateReviewInsightsDialog";
import DomainInsightsDialog from "./DomainInsightsDialog";
import {
  type AccountInsightsDialogTriggerProps,
  type DomainInsightsDialogTriggerProps,
  type InsightsDialogTriggerProps,
} from "./types";

const InsightsButtonPrimary = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-md);
`;

const InsightsButtonInline = styled(IconButton)``;

function isDomainProps(
  props: InsightsDialogTriggerProps,
): props is DomainInsightsDialogTriggerProps {
  return props.type === "domain";
}

function isAccountProps(
  props: InsightsDialogTriggerProps,
): props is AccountInsightsDialogTriggerProps {
  return props.type === "account";
}

const InsightsDialogTrigger: FunctionComponent<InsightsDialogTriggerProps> = (
  props,
) => {
  const { insight, id, mode, onSuccess, type, ...otherProps } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const showDialog = useCallback(() => setIsOpen(true), []);
  const hideDialog = useCallback(() => setIsOpen(false), []);
  const preOnSuccess = useCallback(() => {
    setIsOpen(false);
    onSuccess?.();
  }, [onSuccess]);

  return (
    <>
      {mode === "Create" ? (
        <InsightsButtonPrimary
          aria-label="Add Insights"
          data-kind={ButtonKind.Primary}
          data-size={ButtonSize.md}
          id={id}
          onPress={showDialog}
          preIcon="plus"
          type="button"
          {...otherProps}
        >
          Add Insights
        </InsightsButtonPrimary>
      ) : mode === "Add" ? (
        <InsightsButtonInline
          aria-label="Add insight"
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.md}
          icon="plus"
          id={id}
          onPress={showDialog}
          {...otherProps}
        />
      ) : mode === "Review" ? (
        <InsightsButtonInline
          aria-label="Review insight"
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.md}
          icon="message-check-square"
          id={id}
          onPress={showDialog}
          {...otherProps}
        />
      ) : (
        <InsightsButtonInline
          aria-label="Edit insight"
          data-kind={ButtonKind.Tertiary}
          data-size={ButtonSize.md}
          icon="edit-02"
          id={id}
          onPress={showDialog}
          {...otherProps}
        />
      )}

      {mode === "Review" ? (
        <CreateInsightsDialog
          insight={insight}
          isOpen={isOpen}
          mode="Review"
          onCancel={hideDialog}
          onSuccess={preOnSuccess}
        />
      ) : isDomainProps(props) ? (
        <DomainInsightsDialog
          insight={insight}
          isOpen={isOpen}
          mode={mode}
          onCancel={hideDialog}
          onSuccess={preOnSuccess}
          uid={props.uid}
        />
      ) : isAccountProps(props) ? (
        <AccountInsightsDialog
          insight={insight}
          isOpen={isOpen}
          mode={mode}
          onCancel={hideDialog}
          onSuccess={preOnSuccess}
          uid={props.uid}
        />
      ) : (
        <CreateInsightsDialog
          isOpen={isOpen}
          mode="Create"
          onCancel={hideDialog}
          onSuccess={preOnSuccess}
        />
      )}
    </>
  );
};

export default InsightsDialogTrigger;
