import type { FunctionComponent } from "react";

import { useLogger } from "~/hooks/useLogger";
import {
  EdgeProfile,
  type EdgeProfileProps,
} from "~/pages/Graph/components/popups/EdgeProfile";
import {
  NarrativeProfile,
  type NarrativeProfileProps,
} from "~/pages/Graph/components/popups/NarrativeProfile";
import { ClusterPopUp } from "~/pages/coordination/components/ClusterPopUp";
import { EdgePopUp } from "~/pages/coordination/components/EdgePopUp";
import type {
  ClusterCoordination,
  EdgeCoordination,
} from "~/pages/coordination/types";

import {
  AccountProfile,
  type AccountProfileProps,
} from "../pages/Graph/components/popups/AccountProfile";
import {
  DomainProfile,
  type DomainProfileProps,
} from "../pages/Graph/components/popups/DomainProfile";
import {
  HashtagProfile,
  type HashtagProfileProps,
} from "../pages/Graph/components/popups/HashtagProfile";
import {
  PostProfile,
  type PostProfileProps,
} from "../pages/Graph/components/popups/PostProfile";

import URLNodePopUp, { type URLNodePopUpProps } from "./URLNodePopUp";

interface URLProfileProps extends URLNodePopUpProps {
  type: "links";
}

export type GraphPopupProps =
  | AccountProfileProps
  | PostProfileProps
  | DomainProfileProps
  | URLProfileProps
  | HashtagProfileProps
  | EdgeProfileProps
  | EdgeCoordination
  | ClusterCoordination
  | NarrativeProfileProps;

//TODO: refactor this page to assimilate with ProfileDialogContext.tsx

export const GraphPopup: FunctionComponent<GraphPopupProps> = (props) => {
  const { type } = props;
  const logger = useLogger();

  switch (type) {
    case "accounts":
      return <AccountProfile {...props} />;
    case "posts":
      return <PostProfile {...props} />;
    case "domains":
      return <DomainProfile {...props} />;
    case "links":
      const { type, ...otherProps } = props;
      return <URLNodePopUp {...otherProps} />;
    case "hashtags":
      return <HashtagProfile {...props} />;
    case "coordinationEdge":
      return <EdgePopUp {...props} />;
    case "cluster": //TODO: we only have 1 cluster popup for now (coordination), change this to be more specific if we add more
      return <ClusterPopUp {...props} />;
    case "genericEdge":
      return <EdgeProfile {...props} />;
    case "narrative":
      return <NarrativeProfile {...props} />;
    default:
      logger.warn("the type is not specified when using popups");
      return null;
  }
};
