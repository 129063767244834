import { styled } from "@linaria/react";
import { type HTMLAttributes, type FunctionComponent } from "react";

import { text } from "~/styles/typography";

export enum TooltipPosition {
  Top = 0,
  Right = 1,
  Bottom = 2,
  Left = 3,
}

export type TooltipProps = HTMLAttributes<HTMLDivElement> & {
  "data-position": TooltipPosition;
};

const TooltipContainer = styled.div`
  position: relative;
  padding: var(--spacing-md) var(--spacing-xl);
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-lg);
  background-color: var(--background-color-primary-solid);
  color: var(
    --text-color-white
  ); /* FIXME: not semantic, but same as library tooltip */
  ${text.xs.semibold};

  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    background-color: var(--background-color-primary-solid);
  }

  &[data-position="${TooltipPosition.Top}"]::before {
    top: 100%;
    left: calc(50% - 5px);
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  }

  &[data-position="${TooltipPosition.Right}"]::before {
    top: calc(50% - 5px);
    right: 100%;
    clip-path: polygon(0% 50%, 100% 0%, 100% 100%);
  }

  &[data-position="${TooltipPosition.Bottom}"]::before {
    bottom: calc(100% - 1px);
    left: calc(50% - 5px);
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
  }

  &[data-position="${TooltipPosition.Left}"]::before {
    left: 100%;
    top: calc(50% - 5px);
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  }
`;

const Tooltip: FunctionComponent<TooltipProps> = (props) => {
  const { children, className, style, ...otherProps } = props;

  return (
    /* TooltipContainer needs to be position:relative so that its ::before
     * can be position:absolute .  but consumers will also want to set the
     * position of the tooltip as a whole, which means TooltipContainer can't
     * be the top-level element */
    <div className={className} style={style}>
      <TooltipContainer {...otherProps}>{children}</TooltipContainer>
    </div>
  );
};

export default Tooltip;
